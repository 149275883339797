
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');

.custom-css-style
  .elementor-element.elementor-element-c5d9e56:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-c5d9e56
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-c5d9e56 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-c5d9e56
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-fd701a0
   .elementor-element-populated {
  margin: 0;
  padding: 200px 0 0 40px;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper {
  height: 550px;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  h2.slideshow-slide-caption-title {
  color: #fff;
  width: 30%;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  .container
  h2.slideshow-slide-caption-title {
  font-size: 75px;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  .slideshow-slide
  p.slideshow-slide-caption-content {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  .slideshow-slide-caption-content {
  width: 30%;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  .slideshow-slide-caption-subtitle-label {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  .o-hsub::before {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  .slideshow-slide-caption-subtitle:hover
  .slideshow-slide-caption-subtitle-label {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  .slideshow-slide-caption-subtitle.o-hsub:hover::before {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  .slideshow-slide-caption-subtitle
  .slideshow-slide-caption-subtitle-label {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  button.js-slider-home-prev {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  button.js-slider-home-next {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  .pagination-item.is-current {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e4e2c1b
  .animated-slider-wrapper
  .pagination-item {
  border-color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style .elementor-element.elementor-element-c5d9e56 {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-fd701a0
     .elementor-element-populated {
    padding: 180px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e4e2c1b
    .animated-slider-wrapper {
    height: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e4e2c1b
    .animated-slider-wrapper
    .container
    h2.slideshow-slide-caption-title {
    font-size: 55px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-e4e2c1b
    .animated-slider-wrapper
    h2.slideshow-slide-caption-title {
    width: 55%;
  }
  .custom-css-style
    .elementor-element.elementor-element-e4e2c1b
    .animated-slider-wrapper
    .slideshow-slide-caption-subtitle
    .slideshow-slide-caption-subtitle-label {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-e4e2c1b
    .animated-slider-wrapper
    .container
    h2.slideshow-slide-caption-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e4e2c1b
    .animated-slider-wrapper
    h2.slideshow-slide-caption-title {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-e4e2c1b
    .animated-slider-wrapper
    .slideshow-slide-caption-subtitle
    .slideshow-slide-caption-subtitle-label {
    font-size: 13px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-5e96c90
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-81373dc
  .slider-zoom-wrapper {
  height: 700px;
  background: #000000;
}
.custom-css-style
  .elementor-element.elementor-element-81373dc
  .slideshow-slide-caption-text
  .container
  .slideshow-slide-caption-title {
  width: 50%;
}
.custom-css-style
  .elementor-element.elementor-element-81373dc
  .slideshow-slide-caption-text
  .container
  .slideshow-slide-desc {
  width: 50%;
}
.custom-css-style
  .elementor-element.elementor-element-81373dc
  .slideshow-slide-caption-text {
  padding-top: 220px;
}
.custom-css-style
  .elementor-element.elementor-element-81373dc
  .slideshow-slide-caption-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-81373dc
  h2.slideshow-slide-caption-title {
  font-size: 80px;
}
.custom-css-style
  .elementor-element.elementor-element-81373dc
  .slideshow-slide-caption-text
  .slideshow-slide-desc {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-81373dc
  .slideshow-slide-caption-subtitle-label {
  color: #fff;
  font-family: Oswald, Sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
}
.custom-css-style .elementor-element.elementor-element-81373dc .o-hsub.-link {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-81373dc
  .slider-zoom-wrapper
  .slideshow-slide-image::before {
  background-color: #000000;
}
.custom-css-style
  .elementor-element.elementor-element-81373dc
  .slider-zoom-wrapper
  .pagination-item.is-current,
.custom-css-style
  .elementor-element.elementor-element-81373dc
  .slider-zoom-wrapper
  .pagination-item:hover {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-81373dc
  .slider-zoom-wrapper
  .pagination-item {
  border-color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-81373dc
    .slider-zoom-wrapper {
    height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-81373dc
    .slideshow-slide-caption-text
    .container
    .slideshow-slide-caption-title {
    width: 80%;
  }
  .custom-css-style
    .elementor-element.elementor-element-81373dc
    .slideshow-slide-caption-text
    .container
    .slideshow-slide-desc {
    width: 80%;
  }
  .custom-css-style
    .elementor-element.elementor-element-81373dc
    .slideshow-slide-caption-text {
    padding-top: 240px;
  }
  .custom-css-style
    .elementor-element.elementor-element-81373dc
    h2.slideshow-slide-caption-title {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-81373dc
    .slider-zoom-wrapper {
    height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-81373dc
    .slideshow-slide-caption-text
    .container
    .slideshow-slide-caption-title {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-81373dc
    .slideshow-slide-caption-text
    .container
    .slideshow-slide-desc {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-81373dc
    .slideshow-slide-caption-text {
    padding-top: 180px;
  }
  .custom-css-style
    .elementor-element.elementor-element-81373dc
    h2.slideshow-slide-caption-title {
    font-size: 50px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-3a03266:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-3a03266
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-3a03266 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 160px 0 40px 0;
}
.custom-css-style
  .elementor-element.elementor-element-3a03266
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-a05413b
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-8eb2bc2
  .fadeup-slider-wrapper.cd-slider {
  height: 550px;
}
.custom-css-style
  .elementor-element.elementor-element-8eb2bc2
  .fadeup-slider-wrapper
  li
  .image {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-8eb2bc2
  .fadeup-slider-wrapper
  li
  .content
  h2 {
  color: #fff;
  width: 60%;
}
.custom-css-style
  .elementor-element.elementor-element-8eb2bc2
  .fadeup-slider-wrapper
  li
  div.content
  h2 {
  font-size: 80px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-8eb2bc2
  .fadeup-slider-wrapper
  li
  .content
  .description {
  width: 60%;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8eb2bc2
  .fadeup-slider-wrapper
  li
  .content
  a.slide_link {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8eb2bc2
  .fadeup-slider-wrapper
  li
  div.content
  a.slide_link:hover {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8eb2bc2
  .fadeup-slider-wrapper
  ul
  li
  div.content
  a.slide_link {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-8eb2bc2
  .fadeup-slider-wrapper
  .prev {
  font-size: 20px;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8eb2bc2
  .fadeup-slider-wrapper
  .next {
  font-size: 20px;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8eb2bc2
  .fadeup-slider-wrapper
  .counter {
  font-size: 20px;
}
.custom-css-style
  .elementor-element.elementor-element-8eb2bc2
  .fadeup-slider-wrapper
  nav {
  color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-8eb2bc2
    .fadeup-slider-wrapper.cd-slider {
    height: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8eb2bc2
    .fadeup-slider-wrapper
    li
    div.content
    h2 {
    font-size: 55px;
    line-height: 1.1em;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-8eb2bc2
    .fadeup-slider-wrapper.cd-slider {
    height: 250px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8eb2bc2
    .fadeup-slider-wrapper
    li
    div.content
    h2 {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8eb2bc2
    .fadeup-slider-wrapper
    li
    .content
    h2 {
    width: 90%;
  }
  .custom-css-style
    .elementor-element.elementor-element-8eb2bc2
    .fadeup-slider-wrapper
    ul
    li
    div.content
    a.slide_link {
    font-size: 12px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8eb2bc2
    .fadeup-slider-wrapper
    .prev {
    font-size: 12px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8eb2bc2
    .fadeup-slider-wrapper
    .next {
    font-size: 12px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8eb2bc2
    .fadeup-slider-wrapper
    .counter {
    font-size: 12px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-b21cbae:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-b21cbae
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-b21cbae {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 120px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-b21cbae
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-228e5eb
   .elementor-element-populated {
  margin: 0 0 0 40px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d373993
  .horizontal-slider-wrapper
  .horizontal-slider-cell {
  background: rgba(256, 256, 256, 0);
}
.custom-css-style
  .elementor-element.elementor-element-d373993
  .horizontal-slide-content-title
  h2 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d373993
  div.horizontal-slide-content-title
  h2 {
  font-size: 70px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-d373993
  .horizontal-slider-wrapper
  .horizontal-slider-content
  .horizontal-slider-content-wrap {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d373993
  .horizontal-slider-content-cell
  .horizontal-slide-content-link {
  color: #fff;
  font-family: Oswald, Sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-d373993
  .horizontal-slide-content-link {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d373993
  .horizontal-slider-wrapper
  .flickity-prev-next-button.next {
  background: rgba(256, 256, 256, 0);
}
.custom-css-style
  .elementor-element.elementor-element-d373993
  .horizontal-slider-wrapper
  .flickity-prev-next-button.previous {
  background: rgba(256, 256, 256, 0);
}
.custom-css-style
  .elementor-element.elementor-element-d373993
  .horizontal-slider-wrapper
  .flickity-prev-next-button
  .arrow {
  fill: #222;
}
.custom-css-style
  .elementor-element.elementor-element-d373993
  .horizontal-slider-wrapper
  .flickity-page-dots
  .dot {
  background: #222;
}
.custom-css-style
  .elementor-element.elementor-element-d373993
   .elementor-widget-container {
  margin: 0;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style .elementor-element.elementor-element-b21cbae {
    padding: 100px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-228e5eb
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d373993
    div.horizontal-slide-content-title
    h2 {
    font-size: 50px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-d373993
    .horizontal-slider-content-cell
    .horizontal-slide-content-link {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-228e5eb
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d373993
    div.horizontal-slide-content-title
    h2 {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d373993
    .horizontal-slider-content-cell
    .horizontal-slide-content-link {
    font-size: 18px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-159840e:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-159840e
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-159840e {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0 0 40px 0;
}
.custom-css-style
  .elementor-element.elementor-element-159840e
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-2f695f5
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7cb6f48
  .motion-reveal-slider-wrapper.slideshow
  .slide {
  height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-7cb6f48
  .motion-reveal-slider-wrapper.slideshow
  .slide-img-wrap {
  width: 400px;
}
.custom-css-style
  .elementor-element.elementor-element-7cb6f48
  .motion-reveal-slider-wrapper.slideshow
  .slide-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7cb6f48
  .motion-reveal-slider-wrapper.slideshow
  h3.slide-title {
  font-size: 70px;
}
.custom-css-style
  .elementor-element.elementor-element-7cb6f48
  .motion-reveal-slider-wrapper.slideshow
  .preview-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7cb6f48
  .motion-reveal-slider-wrapper.slideshow
  h3.preview-title {
  font-size: 45px;
}
.custom-css-style
  .elementor-element.elementor-element-7cb6f48
  .motion-reveal-slider-wrapper.slideshow
  .preview-content {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7cb6f48
  .motion-reveal-slider-wrapper.slideshow
  nav
  .slidenav-item {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7cb6f48
  .motion-reveal-slider-wrapper.slideshow
  .icon {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7cb6f48
   .elementor-widget-container {
  padding: 80px 0 0 0;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style .elementor-element.elementor-element-159840e {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7cb6f48
    .motion-reveal-slider-wrapper.slideshow
    .slide-img-wrap {
    width: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7cb6f48
    .motion-reveal-slider-wrapper.slideshow
    h3.slide-title {
    font-size: 50px;
    line-height: 1em;
  }
}
@media (max-width: 767px) {
  .custom-css-style .elementor-element.elementor-element-159840e {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7cb6f48
    .motion-reveal-slider-wrapper.slideshow
    .slide-img-wrap {
    width: 350px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7cb6f48
    .motion-reveal-slider-wrapper.slideshow
    h3.slide-title {
    font-size: 40px;
  }
}
.elementor-kit-6338 {
  --e-global-color-primary: #6ec1e4;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-color-19dbc5dd: #4054b2;
  --e-global-color-7c81e273: #23a455;
  --e-global-color-733e7fab: #000;
  --e-global-color-110e9f6c: #fff;
  --e-global-typography-primary-font-family: "Roboto";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Roboto Slab";
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: "Roboto";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 500;
}
.elementor-section.elementor-section-boxed  .elementor-container {
  max-width: 1170px;
}
.elementor-widget:not(:last-child) {
  margin-bottom: 0;
}
#page-header {
  display: var(--page-title-display);
}
@media (max-width: 1024px) {
  .elementor-section.elementor-section-boxed  .elementor-container {
    max-width: 1025px;
  }
}
@media (max-width: 767px) {
  .elementor-section.elementor-section-boxed  .elementor-container {
    max-width: 768px;
  }
}
.custom-css-style .elementor-element.elementor-element-a216edb {
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: rgba(255, 255, 255, 0.4);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a216edb
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-eb2db78
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: rgba(255, 255, 255, 0.4);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 0 20px 30px;
}
.custom-css-style
  .elementor-element.elementor-element-eb2db78
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
   a {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
   li {
  margin: 0 25px 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul.sub-menu
  li
  a {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul.sub-menu
  li {
  padding: 0 20px 0 20px;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul.sub-menu {
  text-align: left;
  border-radius: 0;
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
   a:hover {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  ul
  li
   a:before,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  div
  .nav
  li
   a:before {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li.current-menu-parent
  ul
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-ancestor
   a,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-item
   a,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-parent
   a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li.current-menu-parent
  ul
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-ancestor
   a:before,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-item
   a:before,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-parent
   a:before {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  a:hover {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  a:before,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
   a:before {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li.current-menu-parent
  ul
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-ancestor
   a,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-item
   a,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-parent
   a,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li.current-menu-parent
  ul
   li.current-menu-item
   a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li.current-menu-parent
  ul
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-ancestor
   a:before,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-item
   a:before,
.custom-css-style
  .elementor-element.elementor-element-d6b5ce3
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-parent
   a:before {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8dfe958
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6f23744
  .elementor-image
  img {
  width: 130px;

}
.custom-css-style .elementor-element.elementor-element-6f23744 {
  z-index: 3;
  top: 20px;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-6f23744 {
  left: 0;
}
body.rtl .custom-css-style .elementor-element.elementor-element-6f23744 {
  right: 0;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-b401b7a.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-b401b7a.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-b401b7a.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-b401b7a
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: rgba(255, 255, 255, 0.4);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b401b7a
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-9966067 {
  --grid-template-columns: repeat(0, auto);
  --grid-column-gap: 20px;
  --grid-side-margin: 20px;
  --grid-row-gap: 0px;
  --grid-bottom-margin: 0px;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-9966067
  .elementor-widget-container {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-9966067
  .elementor-social-icon {
  background-color: rgba(2, 1, 1, 0);
  font-size: 20px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
  .craftcoffee-search-icon
  a {
  font-size: 18px;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
  .craftcoffee-search-wrapper
  input {
  width: 450px;
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
  .craftcoffee-search-wrapper
  .input-group
  .input-group-button
  button
  i {
  font-size: 26px;
}
.custom-css-style .elementor-element.elementor-element-9c9f0b7 .autocomplete {
  width: 500px;
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
  .craftcoffee-search-icon
  a:hover {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
  .craftcoffee-search-wrapper {
  background: rgba(0, 0, 0, 0.9);
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
  .craftcoffee-search-wrapper
  .input-group
  input {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
  .craftcoffee-search-wrapper
  .input-group
  input::placeholder {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
  .craftcoffee-search-wrapper
  .input-group {
  border-color: 256, 256, 256, 0.1;
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
  .craftcoffee-search-wrapper
  .input-group
  .input-group-button
  button {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
  .craftcoffee-search-wrapper
  .autocomplete
  li
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
  .autocomplete
  ul {
  background-color: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
  .autocomplete
  li:hover
  a {
  color: #fff;
  background-color: rgba(256, 256, 256, 0.1);
}
.custom-css-style
  .elementor-element.elementor-element-9c9f0b7
   .elementor-widget-container {
  margin: -3px 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-9c9f0b7 {
  width: auto;
  max-width: auto;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-fcec661.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-fcec661.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-fcec661.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-fcec661
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: rgba(255, 255, 255, 0.4);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-fcec661
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-ff52274
  .elementor-button {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 3px;
  background-color: rgba(2, 1, 1, 0);
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0b79fea
  .elementor-icon-wrapper {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-0b79fea.elementor-view-stacked
  .elementor-icon {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-0b79fea.elementor-view-default
  .elementor-icon,
.custom-css-style
  .elementor-element.elementor-element-0b79fea.elementor-view-framed
  .elementor-icon {
  color: #fff;
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-0b79fea.elementor-view-default
  .elementor-icon
  svg,
.custom-css-style
  .elementor-element.elementor-element-0b79fea.elementor-view-framed
  .elementor-icon {
  fill: #fff;
}
.custom-css-style .elementor-element.elementor-element-0b79fea .elementor-icon {
  font-size: 20px;
}
.custom-css-style
  .elementor-element.elementor-element-0b79fea
  .elementor-icon
  i,
.custom-css-style
  .elementor-element.elementor-element-0b79fea
  .elementor-icon
  svg {
  transform: rotate(0);
}
.custom-css-style
  .elementor-element.elementor-element-0b79fea
   .elementor-widget-container {
  margin: 10px 0 0 15px;
}
.custom-css-style .elementor-element.elementor-element-0b79fea {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-f260150
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-d00d5a2 {
  --divider-border-style: solid;
  --divider-color: rgba(255, 255, 255, 0.4);
  --divider-border-width: 3px;
}
.custom-css-style
  .elementor-element.elementor-element-d00d5a2
  .elementor-divider-separator {
  width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-d00d5a2
  .elementor-divider {
  padding-top: 3px;
  padding-bottom: 3px;
}
body.elementor-page-3099:not(.elementor-motion-effects-element-type-background),
body.elementor-page-3099
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
@media (max-width: 767px) {
  .custom-css-style .elementor-element.elementor-element-8dfe958 {
    width: 40%;
  }
  .custom-css-style
    .elementor-element.elementor-element-6f23744
    .elementor-image
    img {
    width: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6f23744
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  .custom-css-style .elementor-element.elementor-element-fcec661 {
    width: 60%;
  }
  .custom-css-style
    .elementor-element.elementor-element-fcec661.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: flex-end;
  }
  .custom-css-style
    .elementor-element.elementor-element-fcec661
     .elementor-element-populated {
    border-width: 0;
    padding: 20px 0 20px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0b79fea
     .elementor-widget-container {
    margin: 12px 20px 0 0;
  }
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-eb2db78 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-8dfe958 {
    width: 20%;
  }
  .custom-css-style .elementor-element.elementor-element-b401b7a {
    width: 25%;
  }
  .custom-css-style .elementor-element.elementor-element-fcec661 {
    width: 15%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-8dfe958 {
    width: 30%;
  }
  .custom-css-style .elementor-element.elementor-element-b401b7a {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-fcec661 {
    width: 30%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-8dfe958
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-6f23744 {
    text-align: left;
    top: 10px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6f23744
    .elementor-image
    img {
    width: 110px;
    margin-top: 14px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-6f23744
     .elementor-widget-container {
    margin: 0 0 0 30px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b401b7a
     .elementor-element-populated {
    border-width: 0;
    padding: 20px 0 20px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-fcec661
     .elementor-element-populated {
    border-width: 0 0 0 1px;
    height: 76px;
  }
  .custom-css-style .elementor-element.elementor-element-ff52274 {
    width: auto;
    max-width: auto;
  }
}
.custom-css-style .elementor-element.elementor-element-5e2123d {
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5e2123d
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-101763f
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 0 20px 30px;
}
.custom-css-style
  .elementor-element.elementor-element-101763f
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
   a {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
   li {
  margin: 0 25px 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul.sub-menu
  li
  a {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul.sub-menu
  li {
  padding: 0 20px 0 20px;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul.sub-menu {
  text-align: left;
  border-radius: 0;
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
   a:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  ul
  li
   a:before,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  div
  .nav
  li
   a:before {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li.current-menu-parent
  ul
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-ancestor
   a,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-item
   a,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-parent
   a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li.current-menu-parent
  ul
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-ancestor
   a:before,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-item
   a:before,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-parent
   a:before {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  a:hover {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  a:before,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
   a:before {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li.current-menu-parent
  ul
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-ancestor
   a,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-item
   a,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-parent
   a,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li.current-menu-parent
  ul
   li.current-menu-item
   a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li.current-menu-parent
  ul
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-ancestor
   a:before,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-item
   a:before,
.custom-css-style
  .elementor-element.elementor-element-d74dd93
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-parent
   a:before {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e616ee4
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-20a7022
  .elementor-image
  img {
  width: 130px;
}
.custom-css-style .elementor-element.elementor-element-20a7022 {
  z-index: 3;
  top: 20px;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-20a7022 {
  left: 0;
}
body.rtl .custom-css-style .elementor-element.elementor-element-20a7022 {
  right: 0;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-2787b9c.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-2787b9c.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-2787b9c.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-2787b9c
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2787b9c
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-381d8a7 {
  --grid-template-columns: repeat(0, auto);
  --grid-column-gap: 20px;
  --grid-side-margin: 20px;
  --grid-row-gap: 0px;
  --grid-bottom-margin: 0px;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-381d8a7
  .elementor-widget-container {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-381d8a7
  .elementor-social-icon {
  background-color: rgba(255, 255, 255, 0);
  font-size: 20px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-381d8a7
  .elementor-social-icon
  i {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-381d8a7
  .elementor-social-icon
  svg {
  fill: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
  .craftcoffee-search-icon
  a {
  font-size: 18px;
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
  .craftcoffee-search-wrapper
  input {
  width: 450px;
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
  .craftcoffee-search-wrapper
  .input-group
  .input-group-button
  button
  i {
  font-size: 26px;
}
.custom-css-style .elementor-element.elementor-element-1cc8560 .autocomplete {
  width: 500px;
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
  .craftcoffee-search-icon
  a:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
  .craftcoffee-search-wrapper {
  background: rgba(0, 0, 0, 0.9);
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
  .craftcoffee-search-wrapper
  .input-group
  input {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
  .craftcoffee-search-wrapper
  .input-group
  input::placeholder {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
  .craftcoffee-search-wrapper
  .input-group {
  border-color: 256, 256, 256, 0.1;
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
  .craftcoffee-search-wrapper
  .input-group
  .input-group-button
  button {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
  .craftcoffee-search-wrapper
  .autocomplete
  li
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
  .autocomplete
  ul {
  background-color: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
  .autocomplete
  li:hover
  a {
  color: #fff;
  background-color: rgba(256, 256, 256, 0.1);
}
.custom-css-style
  .elementor-element.elementor-element-1cc8560
   .elementor-widget-container {
  margin: -3px 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-1cc8560 {
  width: auto;
  max-width: auto;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-d0b399e.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-d0b399e.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-d0b399e.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-d0b399e
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-d0b399e
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-9104deb
  .elementor-button {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #000;
  color: #000;
  background-color: rgba(2, 1, 1, 0);
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-94b837a
  .elementor-icon-wrapper {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-94b837a.elementor-view-stacked
  .elementor-icon {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-94b837a.elementor-view-default
  .elementor-icon,
.custom-css-style
  .elementor-element.elementor-element-94b837a.elementor-view-framed
  .elementor-icon {
  color: #000;
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-94b837a.elementor-view-default
  .elementor-icon
  svg,
.custom-css-style
  .elementor-element.elementor-element-94b837a.elementor-view-framed
  .elementor-icon {
  fill: #000;
}
.custom-css-style .elementor-element.elementor-element-94b837a .elementor-icon {
  font-size: 20px;
}
.custom-css-style
  .elementor-element.elementor-element-94b837a
  .elementor-icon
  i,
.custom-css-style
  .elementor-element.elementor-element-94b837a
  .elementor-icon
  svg {
  transform: rotate(0);
}
.custom-css-style
  .elementor-element.elementor-element-94b837a
   .elementor-widget-container {
  margin: 10px 0 0 15px;
}
.custom-css-style .elementor-element.elementor-element-94b837a {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-8b2408a
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-6e24c71 {
  --divider-border-style: solid;
  --divider-color: #000;
  --divider-border-width: 3px;
}
.custom-css-style
  .elementor-element.elementor-element-6e24c71
  .elementor-divider-separator {
  width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-6e24c71
  .elementor-divider {
  padding-top: 3px;
  padding-bottom: 3px;
}
@media (max-width: 767px) {
  .custom-css-style .elementor-element.elementor-element-e616ee4 {
    width: 40%;
  }
  .custom-css-style
    .elementor-element.elementor-element-20a7022
    .elementor-image
    img {
    width: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-20a7022
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  .custom-css-style .elementor-element.elementor-element-d0b399e {
    width: 60%;
  }
  .custom-css-style
    .elementor-element.elementor-element-d0b399e.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: flex-end;
  }
  .custom-css-style
    .elementor-element.elementor-element-d0b399e
     .elementor-element-populated {
    border-width: 0;
    padding: 20px 0 20px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-94b837a
     .elementor-widget-container {
    margin: 12px 20px 0 0;
  }
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-101763f {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-e616ee4 {
    width: 20%;
  }
  .custom-css-style .elementor-element.elementor-element-2787b9c {
    width: 25%;
  }
  .custom-css-style .elementor-element.elementor-element-d0b399e {
    width: 15%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-e616ee4 {
    width: 30%;
  }
  .custom-css-style .elementor-element.elementor-element-2787b9c {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-d0b399e {
    width: 30%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-e616ee4
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-20a7022 {
    text-align: left;
    top: 10px;
  }
  .custom-css-style
    .elementor-element.elementor-element-20a7022
    .elementor-image
    img {
    width: 110px;
  }
  .custom-css-style
    .elementor-element.elementor-element-20a7022
     .elementor-widget-container {
    margin: 0 0 0 30px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2787b9c
     .elementor-element-populated {
    border-width: 0;
    padding: 20px 0 20px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d0b399e
     .elementor-element-populated {
    border-width: 0 0 0 1px;
  }
  .custom-css-style .elementor-element.elementor-element-9104deb {
    width: auto;
    max-width: auto;
  }
}
.custom-css-style
  .elementor-element.elementor-element-431cc15
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-431cc15
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-431cc15:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-431cc15
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  /* background-image: url(../upload/glasses-with-black-coffee-and-filtered-water-TRQLF3Z.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-431cc15
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-431cc15 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-9fea6e9
   .elementor-element-populated {
  margin: 60px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-8e44902 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-8e44902
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-8e44902 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-8e44902 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-58b5858 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-58b5858
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-58b5858
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-58b5858 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-58b5858 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b560130
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-b560130
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-b560130:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-b560130
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-b560130 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b560130
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-e3f2240
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6333612
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-f02cdf6 {
  padding: 0 40px 0 40px;
}
.custom-css-style
  .elementor-element.elementor-element-4c8fcfb
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e7347eb
  .post-header
  h5
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-e7347eb
  .post-info-cat
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-e7347eb
  .post-header-wrapper
   p {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-e7347eb
  a.continue-reading {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-e7347eb
  .post-featured-date-wrapper {
  background: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-e7347eb
  .post-featured-date {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e7347eb
  .post-featured-month {
  color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-431cc15
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-431cc15
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-8e44902
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-58b5858
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b560130:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-b560130
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-b560130 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-f02cdf6 {
    margin-top: -20px;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-431cc15
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-431cc15
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-8e44902
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-58b5858
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-58b5858
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b560130:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-b560130
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-b560130 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6333612
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-f02cdf6 {
    margin-top: -60px;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e7347eb
     .elementor-widget-container {
    margin: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-329119f
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2d817c0
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide::after {
  background-color: rgba(0, 0, 0, 0.3);
}
.custom-css-style
  .elementor-element.elementor-element-2d817c0
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2d817c0
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  h4.timeline-title {
  font-size: 70px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-2d817c0
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide-content {
  width: 40%;
}
.custom-css-style
  .elementor-element.elementor-element-2d817c0
  .portfolio-classic-grid-wrapper
  .portfolio-classic-content
  .portfolio-classic-subtitle {
  color: rgba(255, 255, 255, 0.24);
}
.custom-css-style
  .elementor-element.elementor-element-2d817c0
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-year {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-2d817c0
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-text {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2d817c0
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination::before {
  background-color: rgba(255, 255, 255, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-2d817c0
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination-bullet {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2d817c0
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination-bullet::before {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2d817c0
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-button-next,
.portfolio-timeline-vertical-content-wrapper .timeline .swiper-button-prev {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2d817c0
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination {
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-e79a7d8
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c48351c
  .post-header
  h5
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-c48351c
  .post-info-cat
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-c48351c
  .post-header-wrapper
   p {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-c48351c
  a.continue-reading {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-c48351c
  .post-featured-date-wrapper {
  background: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-c48351c
  .post-featured-date {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c48351c
  .post-featured-month {
  color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-2d817c0
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide
    h4.timeline-title {
    font-size: 55px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2d817c0
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide-content {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-c4b0d2d {
    margin-top: 0;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c48351c
    .post-header
    h5 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-2d817c0
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide
    h4.timeline-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2d817c0
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide-content {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-c4b0d2d {
    margin-top: 0;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c48351c
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
}
.custom-css-style
  .elementor-element.elementor-element-523421d
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-523421d
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-523421d:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-523421d
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/barista-prepare-coffee-working-order-concept-PBZ6VQ6.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-523421d
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-523421d {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-f88add7
   .elementor-element-populated {
  margin: 60px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-6c96578 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-6c96578
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-6c96578 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-6c96578 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-5cd83b9 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-5cd83b9
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-5cd83b9
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-5cd83b9 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-5cd83b9 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ffa7c34
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-ffa7c34
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-ffa7c34:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-ffa7c34
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-ffa7c34 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ffa7c34
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-4947960
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-8532206
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-2100ffd {
  padding: 0 40px 0 40px;
}
.custom-css-style
  .elementor-element.elementor-element-8d40c5c
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-58da07e
  .post-header
  h5
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-58da07e
  .post-info-cat
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-58da07e
  .post-header-wrapper
   p {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-58da07e
  a.continue-reading {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-58da07e
  .post-featured-date-wrapper {
  background: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-58da07e
  .post-featured-date {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-58da07e
  .post-featured-month {
  color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-523421d
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-523421d
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-6c96578
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5cd83b9
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ffa7c34:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-ffa7c34
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-ffa7c34 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-2100ffd {
    margin-top: -20px;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-523421d
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-523421d
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-6c96578
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5cd83b9
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5cd83b9
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ffa7c34:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-ffa7c34
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-ffa7c34 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8532206
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-2100ffd {
    margin-top: -60px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8d40c5c
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-2e8855f
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ee7f35e
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide::after {
  background-color: rgba(0, 0, 0, 0.3);
}
.custom-css-style
  .elementor-element.elementor-element-ee7f35e
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-ee7f35e
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  h4.timeline-title {
  font-size: 70px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-ee7f35e
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide-content {
  width: 50%;
}
.custom-css-style
  .elementor-element.elementor-element-ee7f35e
  .portfolio-classic-grid-wrapper
  .portfolio-classic-content
  .portfolio-classic-subtitle {
  color: rgba(255, 255, 255, 0.24);
}
.custom-css-style
  .elementor-element.elementor-element-ee7f35e
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-year {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-ee7f35e
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-text {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-ee7f35e
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination::before {
  background-color: rgba(255, 255, 255, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-ee7f35e
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination-bullet {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-ee7f35e
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination-bullet::before {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-ee7f35e
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-button-next,
.portfolio-timeline-vertical-content-wrapper .timeline .swiper-button-prev {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-ee7f35e
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination {
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-f404459
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-da622c5
  .post-header
  h5
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-da622c5
  .post-info-cat
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-da622c5
  .post-header-wrapper
   p {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-da622c5
  a.continue-reading {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-da622c5
  .post-featured-date-wrapper {
  background: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-da622c5
  .post-featured-date {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-da622c5
  .post-featured-month {
  color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-ee7f35e
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide
    h4.timeline-title {
    font-size: 65px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ee7f35e
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide-content {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-05267dc {
    margin-top: -20px;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-ee7f35e
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide
    h4.timeline-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ee7f35e
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide-content {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-05267dc {
    margin-top: 0;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f404459
     .elementor-element-populated {
    margin: 0;
  }
}
.custom-css-style .elementor-element.elementor-element-0b479c6 {
  margin-top: 0;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-98827fa
   .elementor-element-populated {
  margin: 0 0 60px 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9094226
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide::after {
  background-color: rgba(0, 0, 0, 0.3);
}
.custom-css-style
  .elementor-element.elementor-element-9094226
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9094226
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  h4.timeline-title {
  font-size: 55px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-9094226
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide-content {
  width: 60%;
}
.custom-css-style
  .elementor-element.elementor-element-9094226
  .portfolio-classic-grid-wrapper
  .portfolio-classic-content
  .portfolio-classic-subtitle {
  color: rgba(255, 255, 255, 0.24);
}
.custom-css-style
  .elementor-element.elementor-element-9094226
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-year {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-9094226
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-text {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9094226
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination::before {
  background-color: rgba(255, 255, 255, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-9094226
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination-bullet {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9094226
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination-bullet::before {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9094226
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-button-next,
.portfolio-timeline-vertical-content-wrapper .timeline .swiper-button-prev {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9094226
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination {
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-07e5ccd
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2ef6447
  .post-header
  h5
  a {
  color: #000;
}
.custom-css-style .elementor-element.elementor-element-2ef6447 .post-header h5 {
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-2ef6447
  .post-info-cat
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-2ef6447
  .post-header-wrapper
   p {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-2ef6447
  a.continue-reading {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-2ef6447
  .post-featured-date-wrapper {
  background: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-2ef6447
  .post-featured-date {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2ef6447
  .post-featured-month {
  color: #fff;
}
:root {
  --page-title-display: none;
}
body.elementor-page-3346 {
  padding: 80px 0 0 0;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-9094226
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide
    h4.timeline-title {
    font-size: 55px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9094226
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide-content {
    width: 70%;
  }
  body.elementor-page-3346 {
    padding: 60px 0 0 0;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-9094226
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide
    h4.timeline-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9094226
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide-content {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-2ef6447
    .post-header
    h5 {
    font-size: 32px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-2ef6447
     .elementor-widget-container {
    margin: 0;
  }
  body.elementor-page-3346 {
    padding: 20px 0 0 0;
  }
}
.custom-css-style .elementor-element.elementor-element-7f7844d {
  margin-top: 0;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6e44e1f
   .elementor-element-populated {
  margin: 0 0 60px 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2aefe43
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide::after {
  background-color: rgba(0, 0, 0, 0.3);
}
.custom-css-style
  .elementor-element.elementor-element-2aefe43
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2aefe43
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  h4.timeline-title {
  font-size: 55px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-2aefe43
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide-content {
  width: 60%;
}
.custom-css-style
  .elementor-element.elementor-element-2aefe43
  .portfolio-classic-grid-wrapper
  .portfolio-classic-content
  .portfolio-classic-subtitle {
  color: rgba(255, 255, 255, 0.24);
}
.custom-css-style
  .elementor-element.elementor-element-2aefe43
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-year {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-2aefe43
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-text {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2aefe43
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination::before {
  background-color: rgba(255, 255, 255, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-2aefe43
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination-bullet {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2aefe43
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination-bullet::before {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2aefe43
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-button-next,
.portfolio-timeline-vertical-content-wrapper .timeline .swiper-button-prev {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2aefe43
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination {
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-619e4f0
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-01ce6ac
  .post-header
  h5
  a {
  color: #000;
}
.custom-css-style .elementor-element.elementor-element-01ce6ac .post-header h5 {
  font-size: 33px;
}
.custom-css-style
  .elementor-element.elementor-element-01ce6ac
  .post-info-cat
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-01ce6ac
  .post-header-wrapper
   p {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-01ce6ac
  a.continue-reading {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-01ce6ac
  .post-featured-date-wrapper {
  background: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-01ce6ac
  .post-featured-date {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-01ce6ac
  .post-featured-month {
  color: #fff;
}
:root {
  --page-title-display: none;
}
body.elementor-page-3359 {
  padding: 80px 0 0 0;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-2aefe43
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide
    h4.timeline-title {
    font-size: 55px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2aefe43
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide-content {
    width: 70%;
  }
  body.elementor-page-3359 {
    padding: 60px 0 0 0;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-2aefe43
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide
    h4.timeline-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2aefe43
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide-content {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-01ce6ac
    .post-header
    h5 {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-01ce6ac
     .elementor-widget-container {
    margin: 0;
  }
  body.elementor-page-3359 {
    padding: 20px 0 0 0;
  }
}
.custom-css-style
  .elementor-element.elementor-element-30d69ae
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-30d69ae
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-30d69ae:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-30d69ae
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  /* background-image: url(../barista-making-coffee-F2GU6L8.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-30d69ae
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-30d69ae {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-19d05f5
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-3083b83 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-3083b83
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-3083b83 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-3083b83 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-d15f39f {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-d15f39f
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-d15f39f
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-d15f39f {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-d15f39f {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-97e30e1
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-97e30e1
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-97e30e1:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-97e30e1
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-97e30e1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-97e30e1
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-edb40e6
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7610b21
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-fd2584a {
  padding: 0 60px 0 60px;
}
.custom-css-style
  .elementor-element.elementor-element-81a5394
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a14ec9b
  .post-header
  h5
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-a14ec9b
  .post-info-cat
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-a14ec9b
  .post-header-wrapper
   p {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-a14ec9b
  a.continue-reading {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-a14ec9b
  .post-featured-date-wrapper {
  background: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-a14ec9b
  .post-featured-date {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-a14ec9b
  .post-featured-month {
  color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-30d69ae
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-30d69ae
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-3083b83
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d15f39f
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-97e30e1:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-97e30e1
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-97e30e1 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-fd2584a {
    margin-top: -20px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a14ec9b
    .post-header
    h5 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-30d69ae
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-30d69ae
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-3083b83
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d15f39f
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d15f39f
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-97e30e1:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-97e30e1
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-97e30e1 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7610b21
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-fd2584a {
    margin-top: -100px;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a14ec9b
    .post-header
    h5 {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a14ec9b
     .elementor-widget-container {
    margin: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-0440d99
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-43139f9
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide::after {
  background-color: rgba(0, 0, 0, 0.3);
}
.custom-css-style
  .elementor-element.elementor-element-43139f9
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-43139f9
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  h4.timeline-title {
  font-size: 70px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-43139f9
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide-content {
  width: 45%;
}
.custom-css-style
  .elementor-element.elementor-element-43139f9
  .portfolio-classic-grid-wrapper
  .portfolio-classic-content
  .portfolio-classic-subtitle {
  color: rgba(255, 255, 255, 0.24);
}
.custom-css-style
  .elementor-element.elementor-element-43139f9
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-year {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-43139f9
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-text {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-43139f9
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination::before {
  background-color: rgba(255, 255, 255, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-43139f9
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination-bullet {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-43139f9
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination-bullet::before {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-43139f9
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-button-next,
.portfolio-timeline-vertical-content-wrapper .timeline .swiper-button-prev {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-43139f9
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination {
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-2e3a697
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-2e3a697
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-2e3a697:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-2e3a697
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-black.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-2e3a697 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -140px;
  margin-bottom: 0;
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-2e3a697
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-0340c29
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2bf4f80
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5a185d2:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-5a185d2
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-5a185d2 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 0 40px 0;
}
.custom-css-style
  .elementor-element.elementor-element-5a185d2
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-a792659
   .elementor-element-populated {
  margin: 0 40px 0 40px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .portfolio-classic-grid-wrapper
  .portfolio-classic-img {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .portfolio-classic-grid-wrapper
  .portfolio-classic-img
  .curl {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .portfolio-classic-content
  h3.portfolio-classic_title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .portfolio-classic-content
  h3.portfolio-classic_title
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .portfolio-classic-content
  h3.portfolio-classic_title
  a:hover {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  div.portfolio-classic-content
  h3.portfolio-classic_title {
  font-size: 32px;
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  div.portfolio-classic-content
  .portfolio-classic-subtitle {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .portfolio-classic-grid-wrapper {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .portfolio-classic-content {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .portfolio-classic-content:before {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .portfolio-classic-grid-wrapper
  .portfolio-classic-content
  .portfolio-classic-subtitle {
  color: #c7a17a;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .craftcoffee-portfolio-filter-wrapper {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  div.elementor-widget-container
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  border-color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn.active {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  .craftcoffee-portfolio-filter-wrapper
  .filter-tag-btn.active {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-57c8215
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  font-family: Roboto, Sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
:root {
  --page-title-display: none;
}
body.elementor-page-3383:not(.elementor-motion-effects-element-type-background),
body.elementor-page-3383
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-43139f9
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide
    h4.timeline-title {
    font-size: 55px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-43139f9
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide-content {
    width: 60%;
  }
  .custom-css-style
    .elementor-element.elementor-element-43139f9
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-pagination {
    font-size: 22px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2e3a697
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2e3a697
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-2e3a697:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-2e3a697
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-2e3a697 {
    margin-top: -75px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a792659
     .elementor-element-populated {
    margin: 0 30px 0 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-57c8215
    div.portfolio-classic-content
    h3.portfolio-classic_title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-57c8215
    .portfolio-classic-grid-wrapper
    .portfolio-classic-content
    .portfolio-classic-subtitle {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-43139f9
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide
    h4.timeline-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-43139f9
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide-content {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-2e3a697
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2e3a697
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-2e3a697:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-2e3a697
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-2e3a697 {
    margin-top: -40px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2bf4f80
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-5a185d2 {
    margin-top: -40px;
    margin-bottom: 0;
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a792659
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-f289aa8
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-f289aa8
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-f289aa8:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-f289aa8
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/barista-ready-for-making-classic-espresso-NB4HFU3.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-f289aa8
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-f289aa8 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-2709146
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-2c2889f {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-2c2889f
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-2c2889f {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-2c2889f {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-40567a9 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-40567a9
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-40567a9
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-40567a9 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-40567a9 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4c6c5d6
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-4c6c5d6
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-4c6c5d6:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-4c6c5d6
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-4c6c5d6 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4c6c5d6
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-cb25ba0
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-03d4b10
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-92cf6a5
   .elementor-element-populated {
  margin: 0 40px 0 40px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  .portfolio-grid-content-wrapper
  .portfolio-grid-wrapper {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  .portfolio-grid-wrapper:after {
  background-color: transparent;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.82) 0,
    rgba(0, 0, 0, 0.18) 100%
  );
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  .portfolio-grid-wrapper
  h3 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  div.portfolio-grid-wrapper
  h3 {
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  .portfolio-grid-wrapper
  .figcaption
  .portfolio-grid-subtitle {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  .portfolio-grid-wrapper
  .figcaption
  div.portfolio-grid-subtitle {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  .craftcoffee-portfolio-filter-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  div.elementor-widget-container
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  border-color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn.active {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1b19aa0
  .craftcoffee-portfolio-filter-wrapper
  .filter-tag-btn.active {
  color: #000;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-f289aa8
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f289aa8
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-2c2889f
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-40567a9
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4c6c5d6
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4c6c5d6
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-4c6c5d6:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-4c6c5d6
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-4c6c5d6 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-92cf6a5
     .elementor-element-populated {
    margin: 0 30px 0 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1b19aa0
    div.portfolio-grid-wrapper
    h3 {
    font-size: 32px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-1b19aa0
    .portfolio-grid-wrapper
    .figcaption
    div.portfolio-grid-subtitle {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-f289aa8
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f289aa8
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-2c2889f
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-40567a9
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-40567a9
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-4c6c5d6
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4c6c5d6
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-4c6c5d6:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-4c6c5d6
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-4c6c5d6 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-03d4b10
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-92cf6a5
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-bc278c2
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4b5d5e6
  .mouse-driven-vertical-carousel-wrapper {
  height: 660px;
}
.custom-css-style
  .elementor-element.elementor-element-4b5d5e6
  .mouse-driven-vertical-carousel-wrapper
  .c-gradient-overlay {
  background: rgba(0, 0, 0, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-4b5d5e6
  .mouse-driven-vertical-carousel-wrapper
  .u-media-wrapper
  .carousel-header
  h2 {
  color: #fff;
  font-size: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-4b5d5e6
  .mouse-driven-vertical-carousel-wrapper
  .u-media-wrapper
  .carousel-header
  .carousel__sub_header {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4b5d5e6
  .mouse-driven-vertical-carousel-wrapper
  .u-media-wrapper
  .c-mouse-vertical-carousel-title {
  color: #fff;
  font-family: Oswald, Sans-serif;
  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.3em;
}
.custom-css-style
  .elementor-element.elementor-element-4b5d5e6
  .mouse-driven-vertical-carousel-wrapper
  .u-media-wrapper
  .c-mouse-vertical-carousel-eyebrow {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-ef04803
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0feec77
  .portfolio-grid-content-wrapper
  .portfolio-grid-wrapper {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0feec77
  .portfolio-grid-wrapper-overlay
  .portfolio-grid-img:after {
  background-color: transparent;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.82) 0,
    rgba(0, 0, 0, 0.18) 100%
  );
}
.custom-css-style
  .elementor-element.elementor-element-0feec77
  .portfolio-grid-wrapper-overlay
  .figcaption
  .portfolio-grid-content
  .portfolio-grid-content-inner
  h3 {
  color: #fff;
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-0feec77
  .portfolio-grid-wrapper-overlay
  .figcaption
  .portfolio-grid-content
  .portfolio-grid-content-inner
  .portfolio-grid-subtitle {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-0feec77
  .craftcoffee-portfolio-filter-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-0feec77
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-0feec77
  div.elementor-widget-container
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  border-color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-0feec77
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-0feec77
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-0feec77
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn.active {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-0feec77
  .craftcoffee-portfolio-filter-wrapper
  .filter-tag-btn.active {
  color: #000;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-4b5d5e6
    .mouse-driven-vertical-carousel-wrapper {
    height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4b5d5e6
    .mouse-driven-vertical-carousel-wrapper
    .u-media-wrapper
    .carousel-header
    h2 {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4b5d5e6
    .mouse-driven-vertical-carousel-wrapper
    .u-media-wrapper
    .c-mouse-vertical-carousel-title {
    font-size: 28px;
    line-height: 1.4em;
  }
  .custom-css-style
    .elementor-element.elementor-element-0feec77
    .portfolio-grid-wrapper-overlay
    .figcaption
    .portfolio-grid-content
    .portfolio-grid-content-inner
    h3 {
    font-size: 30px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-0feec77
    .portfolio-grid-wrapper-overlay
    .figcaption
    .portfolio-grid-content
    .portfolio-grid-content-inner
    .portfolio-grid-subtitle {
    font-size: 13px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-60ec943
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper {
  height: 660px;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  .slide
  .image-container::before {
  background: rgba(0, 0, 0, 0.3);
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  .slide-content {
  text-align: center;
  margin-top: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  div.slide-content {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  .slide-content
  .standard-wrapper {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  .slide
  .title
  h2 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  .slider_parallax_slides
  .slide
  .title
  h2 {
  font-size: 80px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  .slide
  .caption {
  width: 65%;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  .slide
  .text {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  .slide
  a.button {
  background-color: rgba(34, 34, 34, 0);
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  .slide
  .button {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  div.slide
  a.button {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  .slide
  a.button:hover {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  .slide
  .button:hover {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  div.slide
  a.button:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6522c87
  .slider-parallax-wrapper
  .pagination
  .item::before {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-0306c71
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-0306c71
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-0306c71:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-0306c71
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-0306c71 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0306c71
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-31e4fe4
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-76d5bc8
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-f4ca9cf {
  overflow: hidden;
}
.custom-css-style
  .elementor-element.elementor-element-0fe8254
   .elementor-element-populated {
  margin: 0 40px 0 40px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-495162f
  .portfolio-grid-content-wrapper
  .portfolio-grid-wrapper {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-495162f
  .portfolio-grid-wrapper-overlay
  .portfolio-grid-img:after {
  background-color: transparent;
  background-image: radial-gradient(
    at center center,
    rgba(0, 0, 0, 0.82) 0,
    rgba(0, 0, 0, 0.18) 100%
  );
}
.custom-css-style
  .elementor-element.elementor-element-495162f
  .portfolio-grid-wrapper-overlay
  .figcaption
  .portfolio-grid-content
  .portfolio-grid-content-inner
  h3 {
  color: #fff;
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-495162f
  .portfolio-grid-wrapper-overlay
  .figcaption
  .portfolio-grid-content
  .portfolio-grid-content-inner
  .portfolio-grid-subtitle {
  color: #ebebeb;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.custom-css-style
  .elementor-element.elementor-element-495162f
  .craftcoffee-portfolio-filter-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-495162f
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-495162f
  div.elementor-widget-container
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  border-color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-495162f
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-495162f
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-495162f
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn.active {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-495162f
  .craftcoffee-portfolio-filter-wrapper
  .filter-tag-btn.active {
  color: #000;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-6522c87
    .slider-parallax-wrapper
    .slider_parallax_slides
    .slide
    .title
    h2 {
    font-size: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6522c87
    .slider-parallax-wrapper
    .slide-content {
    margin-top: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0306c71:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-0306c71
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-0306c71 {
    margin-top: -50px;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-6522c87
    .slider-parallax-wrapper
    .slider_parallax_slides
    .slide
    .title
    h2 {
    font-size: 40px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-6522c87
    .slider-parallax-wrapper
    .slide
    .caption {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-6522c87
    .slider-parallax-wrapper
    .slide-content {
    margin-top: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0306c71:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-0306c71
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-0306c71 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-76d5bc8
    .elementor-spacer-inner {
    height: 0;
  }
}
.custom-css-style
  .elementor-element.elementor-element-f8b0578
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-58b5447
  .slider-zoom-wrapper {
  height: 700px;
  background: #000000;
}
.custom-css-style
  .elementor-element.elementor-element-58b5447
  .slideshow-slide-caption-text
  .container
  .slideshow-slide-caption-title {
  width: 50%;
}
.custom-css-style
  .elementor-element.elementor-element-58b5447
  .slideshow-slide-caption-text
  .container
  .slideshow-slide-desc {
  width: 50%;
}
.custom-css-style
  .elementor-element.elementor-element-58b5447
  .slideshow-slide-caption-text {
  padding-top: 220px;
}
.custom-css-style
  .elementor-element.elementor-element-58b5447
  .slideshow-slide-caption-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-58b5447
  h2.slideshow-slide-caption-title {
  font-size: 80px;
}
.custom-css-style
  .elementor-element.elementor-element-58b5447
  .slideshow-slide-caption-text
  .slideshow-slide-desc {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-58b5447
  .slideshow-slide-caption-subtitle-label {
  color: #fff;
  font-family: Oswald, Sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
}
.custom-css-style .elementor-element.elementor-element-58b5447 .o-hsub.-link {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-58b5447
  .slider-zoom-wrapper
  .slideshow-slide-image::before {
  background-color: #000000;
}
.custom-css-style
  .elementor-element.elementor-element-58b5447
  .slider-zoom-wrapper
  .pagination-item.is-current,
.custom-css-style
  .elementor-element.elementor-element-58b5447
  .slider-zoom-wrapper
  .pagination-item:hover {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-58b5447
  .slider-zoom-wrapper
  .pagination-item {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-bd68167
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-bd68167
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-bd68167:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-bd68167
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-bd68167 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-bd68167
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-4ccb93a
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f073080
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9e9814b
   .elementor-element-populated {
  margin: 0 40px 0 40px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  .portfolio-classic-grid-wrapper
  .portfolio-classic-img {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  .portfolio-classic-grid-wrapper
  .portfolio-classic-img
  .curl {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  .portfolio-classic-content
  h3.portfolio-classic_title {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  .portfolio-classic-content
  h3.portfolio-classic_title
  a {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  .portfolio-classic-content
  h3.portfolio-classic_title
  a:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  div.portfolio-classic-content
  h3.portfolio-classic_title {
  font-size: 32px;
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  div.portfolio-classic-content
  .portfolio-classic-subtitle {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  .portfolio-classic-grid-wrapper {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  .portfolio-classic-grid-wrapper
  .portfolio-classic-content
  .portfolio-classic-subtitle {
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  .craftcoffee-portfolio-filter-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  div.elementor-widget-container
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  border-color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn.active {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-f89b084
  .craftcoffee-portfolio-filter-wrapper
  .filter-tag-btn.active {
  color: #000;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-58b5447
    .slider-zoom-wrapper {
    height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-58b5447
    .slideshow-slide-caption-text
    .container
    .slideshow-slide-caption-title {
    width: 70%;
  }
  .custom-css-style
    .elementor-element.elementor-element-58b5447
    .slideshow-slide-caption-text
    .container
    .slideshow-slide-desc {
    width: 70%;
  }
  .custom-css-style
    .elementor-element.elementor-element-58b5447
    h2.slideshow-slide-caption-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-bd68167
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-bd68167
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-bd68167:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-bd68167
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-bd68167 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9e9814b
     .elementor-element-populated {
    margin: 0 30px 0 30px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-58b5447
    .slider-zoom-wrapper {
    height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-58b5447
    .slideshow-slide-caption-text
    .container
    .slideshow-slide-caption-title {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-58b5447
    .slideshow-slide-caption-text
    .container
    .slideshow-slide-desc {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-58b5447
    .slideshow-slide-caption-text {
    padding-top: 180px;
  }
  .custom-css-style
    .elementor-element.elementor-element-58b5447
    h2.slideshow-slide-caption-title {
    font-size: 45px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-58b5447
    .slideshow-slide-caption-subtitle-label {
    font-size: 18px;
  }
  .custom-css-style
    .elementor-element.elementor-element-bd68167
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-bd68167
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-bd68167:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-bd68167
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-bd68167 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f073080
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9e9814b
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-d357e43:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-d357e43
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-d357e43 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 160px 0 40px 0;
}
.custom-css-style
  .elementor-element.elementor-element-d357e43
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-41b40f1
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3e5d1d5
  .fadeup-slider-wrapper.cd-slider {
  height: 550px;
}
.custom-css-style
  .elementor-element.elementor-element-3e5d1d5
  .fadeup-slider-wrapper
  li
  .image {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3e5d1d5
  .fadeup-slider-wrapper
  li
  .content
  h2 {
  color: #fff;
  width: 60%;
}
.custom-css-style
  .elementor-element.elementor-element-3e5d1d5
  .fadeup-slider-wrapper
  li
  div.content
  h2 {
  font-size: 80px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-3e5d1d5
  .fadeup-slider-wrapper
  li
  .content
  .description {
  width: 60%;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-3e5d1d5
  .fadeup-slider-wrapper
  li
  .content
  a.slide_link {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-3e5d1d5
  .fadeup-slider-wrapper
  li
  div.content
  a.slide_link:hover {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-3e5d1d5
  .fadeup-slider-wrapper
  ul
  li
  div.content
  a.slide_link {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-3e5d1d5
  .fadeup-slider-wrapper
  .prev {
  font-size: 20px;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-3e5d1d5
  .fadeup-slider-wrapper
  .next {
  font-size: 20px;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-3e5d1d5
  .fadeup-slider-wrapper
  .counter {
  font-size: 20px;
}
.custom-css-style
  .elementor-element.elementor-element-3e5d1d5
  .fadeup-slider-wrapper
  nav {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-f125607
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-f125607
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-f125607:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-f125607
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-f125607 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f125607
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-9d1320d
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-713b8c3
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-8709f31
   .elementor-element-populated {
  margin: 0 40px 0 40px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  .portfolio-masonry-grid-wrapper
  img {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  .portfolio-masonry-grid-wrapper {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  .portfolio-masonry-container.masonry-grid
  .portfolio-masonry-grid-wrapper:after {
  background-color: transparent;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.82) 0,
    rgba(0, 0, 0, 0.18) 100%
  );
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  .portfolio-masonry-container.masonry-grid
  .portfolio-masonry-grid-wrapper
  .figcaption
  .overflow-inner
  h3 {
  color: #fff;
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  div.portfolio-masonry-grid-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  .portfolio-masonry-container.masonry-grid
  .portfolio-masonry-grid-wrapper
  .figcaption
  .overflow-inner
  .portfolio-grid-subtitle {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  .craftcoffee-portfolio-filter-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  div.elementor-widget-container
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  border-color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn.active {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d9d056b
  .craftcoffee-portfolio-filter-wrapper
  .filter-tag-btn.active {
  color: #000;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-3e5d1d5
    .fadeup-slider-wrapper.cd-slider {
    height: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3e5d1d5
    .fadeup-slider-wrapper
    li
    div.content
    h2 {
    font-size: 55px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-f125607
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f125607
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-f125607:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-f125607
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-f125607 {
    margin-top: -60px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8709f31
     .elementor-element-populated {
    margin: 0 30px 0 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d9d056b
    .portfolio-masonry-container.masonry-grid
    .portfolio-masonry-grid-wrapper
    .figcaption
    .overflow-inner
    h3 {
    font-size: 26px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-d9d056b
    .portfolio-masonry-container.masonry-grid
    .portfolio-masonry-grid-wrapper
    .figcaption
    .overflow-inner
    .portfolio-grid-subtitle {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-3e5d1d5
    .fadeup-slider-wrapper.cd-slider {
    height: 250px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3e5d1d5
    .fadeup-slider-wrapper
    li
    div.content
    h2 {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3e5d1d5
    .fadeup-slider-wrapper
    li
    .content
    h2 {
    width: 90%;
  }
  .custom-css-style
    .elementor-element.elementor-element-3e5d1d5
    .fadeup-slider-wrapper
    ul
    li
    div.content
    a.slide_link {
    font-size: 12px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3e5d1d5
    .fadeup-slider-wrapper
    .prev {
    font-size: 12px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3e5d1d5
    .fadeup-slider-wrapper
    .next {
    font-size: 12px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3e5d1d5
    .fadeup-slider-wrapper
    .counter {
    font-size: 12px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f125607
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f125607
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-f125607:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-f125607
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-f125607 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-713b8c3
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8709f31
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-8582c58:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-8582c58
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-8582c58 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-8582c58
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-0d0ad28
   .elementor-element-populated {
  margin: 0;
  padding: 200px 0 0 40px;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper {
  height: 550px;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  h2.slideshow-slide-caption-title {
  color: #fff;
  width: 30%;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  .container
  h2.slideshow-slide-caption-title {
  font-size: 75px;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  .slideshow-slide
  p.slideshow-slide-caption-content {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  .slideshow-slide-caption-content {
  width: 30%;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  .slideshow-slide-caption-subtitle-label {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  .o-hsub::before {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  .slideshow-slide-caption-subtitle:hover
  .slideshow-slide-caption-subtitle-label {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  .slideshow-slide-caption-subtitle.o-hsub:hover::before {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  .slideshow-slide-caption-subtitle
  .slideshow-slide-caption-subtitle-label {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  button.js-slider-home-prev {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  button.js-slider-home-next {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  .pagination-item.is-current {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-12448d3
  .animated-slider-wrapper
  .pagination-item {
  border-color: #fff;
}
.custom-css-style .elementor-element.elementor-element-c5f698d {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0 0 40px 0;
}
.custom-css-style
  .elementor-element.elementor-element-c5f698d:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-c5f698d
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-c5f698d
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-79c1013
   .elementor-element-populated {
  margin: 0 40px 0 40px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  .portfolio-masonry-grid-wrapper
  img {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  .portfolio-masonry-grid-wrapper
  .figcaption {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  .portfolio-masonry-grid-wrapper
  h3 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  .portfolio-masonry-grid-wrapper
  .portfolio-masonry-content
  .popup-arrow {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  div.portfolio-masonry-grid-wrapper
  h3 {
  font-size: 32px;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  .portfolio-masonry-container:not(.masonry-grid)
  .portfolio-masonry-grid-wrapper
  .figcaption {
  background: rgba(0, 0, 0, 0.71);
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  .portfolio-masonry-container:not(.masonry-grid)
  .portfolio-masonry-grid-wrapper
  .figcaption:before {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  div.portfolio-masonry-grid-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  .portfolio_masonry_subtitle {
  color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  .portfolio-masonry-content
  .portfolio_masonry_subtitle {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  .craftcoffee-portfolio-filter-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  div.elementor-widget-container
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  border-color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn.active {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-5d1e27c
  .craftcoffee-portfolio-filter-wrapper
  .filter-tag-btn.active {
  color: #000;
}
:root {
  --page-title-display: none;
}
body.elementor-page-3470:not(.elementor-motion-effects-element-type-background),
body.elementor-page-3470
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
@media (max-width: 1024px) {
  .custom-css-style .elementor-element.elementor-element-8582c58 {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0d0ad28
     .elementor-element-populated {
    padding: 180px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-12448d3
    .animated-slider-wrapper {
    height: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-12448d3
    .animated-slider-wrapper
    .container
    h2.slideshow-slide-caption-title {
    font-size: 55px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-12448d3
    .animated-slider-wrapper
    h2.slideshow-slide-caption-title {
    width: 55%;
  }
  .custom-css-style
    .elementor-element.elementor-element-12448d3
    .animated-slider-wrapper
    .slideshow-slide-caption-subtitle
    .slideshow-slide-caption-subtitle-label {
    font-size: 13px;
  }
  .custom-css-style
    .elementor-element.elementor-element-79c1013
     .elementor-element-populated {
    margin: 0 30px 0 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5d1e27c
    div.portfolio-masonry-grid-wrapper
    h3 {
    font-size: 30px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-5d1e27c
    .portfolio-masonry-content
    .portfolio_masonry_subtitle {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-12448d3
    .animated-slider-wrapper
    .container
    h2.slideshow-slide-caption-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-12448d3
    .animated-slider-wrapper
    h2.slideshow-slide-caption-title {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-12448d3
    .animated-slider-wrapper
    .slideshow-slide-caption-subtitle
    .slideshow-slide-caption-subtitle-label {
    font-size: 13px;
  }
  .custom-css-style
    .elementor-element.elementor-element-79c1013
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-a3bf125:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-a3bf125
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-a3bf125 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-a3bf125
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-d6e8036
   .elementor-element-populated {
  padding: 160px 0 80px 0;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  .portfolio-coverflow
  .swiper-slide {
  width: 350px;
  height: 350px;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  .portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article {
  height: 350px;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  .portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article
  .article-thumbnail {
  height: 350px;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  .portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article
  .article-preview {
  height: 350px;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  .portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article
  .article-thumbnail
  h2 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  div.portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article
  .article-thumbnail
  h2 {
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  div.portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  .portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article
  .article-thumbnail
  h2
  span {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  div.portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article
  .article-thumbnail
  h2
  span {
  font-family: Roboto, Sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  .portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article
  .article-preview
  .controls
  label
  span {
  font-size: 26px;
  letter-spacing: 1.1px;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  .portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article
  .article-preview
  .controls
  label {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  .portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article
  .article-preview
  .controls
  label:hover
  span {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  .portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article
  .article-preview
  .controls
  label:hover
  span
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  .portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article
  .article-preview
  .controls
  label.active
  span {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d7817d4
  .portfolio-coverflow
  .swiper-slide
  .swiper-content
  .article
  .article-preview
  .controls
  label.active
  span
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4a87b0e
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-4a87b0e
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-4a87b0e:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-4a87b0e
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-4a87b0e {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4a87b0e
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-604b79a
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e6bdf19
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-8bdab56
   .elementor-element-populated {
  margin: 0 40px 0 40px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  .portfolio-masonry-grid-wrapper
  img {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  .portfolio-masonry-grid-wrapper {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  .portfolio-masonry-container.masonry-grid
  .portfolio-masonry-grid-wrapper:after {
  background-color: transparent;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.82) 0,
    rgba(0, 0, 0, 0.18) 100%
  );
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  .portfolio-masonry-container.masonry-grid
  .portfolio-masonry-grid-wrapper
  .figcaption
  .overflow-inner
  h3 {
  color: #fff;
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  div.portfolio-masonry-grid-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  .portfolio-masonry-container.masonry-grid
  .portfolio-masonry-grid-wrapper
  .figcaption
  .overflow-inner
  .portfolio-grid-subtitle {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  .craftcoffee-portfolio-filter-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  div.elementor-widget-container
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  border-color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn.active {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-a9714b4
  .craftcoffee-portfolio-filter-wrapper
  .filter-tag-btn.active {
  color: #000;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-d6e8036
     .elementor-element-populated {
    padding: 120px 0 40px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-4a87b0e
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4a87b0e
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-4a87b0e:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-4a87b0e
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-4a87b0e {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8bdab56
     .elementor-element-populated {
    margin: 0 30px 0 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a9714b4
    .portfolio-masonry-container.masonry-grid
    .portfolio-masonry-grid-wrapper
    .figcaption
    .overflow-inner
    h3 {
    font-size: 30px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-a9714b4
    .portfolio-masonry-container.masonry-grid
    .portfolio-masonry-grid-wrapper
    .figcaption
    .overflow-inner
    .portfolio-grid-subtitle {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-d6e8036
     .elementor-element-populated {
    padding: 80px 10px 20px 10px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4a87b0e
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4a87b0e
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-4a87b0e:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-4a87b0e
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-4a87b0e {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e6bdf19
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8bdab56
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-0586987
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-0586987
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-0586987:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-0586987
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  /* background-image: url(../upload/barista-making-coffee-F2GU6L8.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-0586987
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-0586987 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-b8fcd81
   .elementor-element-populated {
  margin: 60px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-3968c10 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-3968c10
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-3968c10 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-3968c10 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-d6ec0ca {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-d6ec0ca
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-d6ec0ca
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-d6ec0ca {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-d6ec0ca {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-556cae0
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-556cae0
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-556cae0:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-556cae0
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-556cae0 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-556cae0
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-65fa4ea
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-440892e
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-660498d {
  margin-top: 0;
  margin-bottom: 60px;
}
.custom-css-style
  .elementor-element.elementor-element-6c42133
   .elementor-element-populated {
  padding: 0 40px 0 40px;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .portfolio-timeline-wrapper
  .portfolio-timeline-img
  img {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-horizontal-timeline
  .events-content
  h2 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  div.cd-horizontal-timeline
  .events-content
  h2 {
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  div.cd-horizontal-timeline
  .events-content
  em {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-horizontal-timeline
  .events-content
  em {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.1em;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-horizontal-timeline
  .events-content
  li
  .events-content-desc {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .portfolio-timeline-link {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .portfolio-timeline-link.continue-reading:before {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .portfolio-timeline-link.continue-reading:after {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .portfolio-timeline-link.continue-reading
  span:before {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .portfolio-timeline-link.continue-reading
  span:after {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-horizontal-timeline
  .events {
  background: #e7e7e7;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-horizontal-timeline
  .events
  a::after {
  border-color: #e7e7e7;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-timeline-navigation
  a {
  border-color: #e7e7e7;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-horizontal-timeline
  .filling-line {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-horizontal-timeline
  .events
  a.selected::after {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-horizontal-timeline
  div.events
  a.selected::after {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-horizontal-timeline
  .events
  a.older-event::after {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-timeline-navigation
  a:hover {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-timeline-navigation
  a.prev:hover:after {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-9d7b4d2
  .cd-timeline-navigation
  a.next:hover:after {
  color: #000;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-0586987
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0586987
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-3968c10
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d6ec0ca
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-556cae0
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-556cae0
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-556cae0:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-556cae0
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-556cae0 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6c42133
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9d7b4d2
    div.cd-horizontal-timeline
    .events-content
    h2 {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9d7b4d2
    .cd-horizontal-timeline
    .events-content
    em {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-0586987
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0586987
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-3968c10
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d6ec0ca
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d6ec0ca
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-556cae0
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-556cae0
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-556cae0:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-556cae0
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-556cae0 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-440892e
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-660498d {
    margin-top: -80px;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6c42133
     .elementor-element-populated {
    margin: 0;
    padding: 0 10px 0 10px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9d7b4d2
    div.cd-horizontal-timeline
    .events-content
    h2 {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9d7b4d2
    .cd-horizontal-timeline
    .events-content
    em {
    font-size: 13px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-2b6be0c
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-8678df2
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide::after {
  background-color: rgba(0, 0, 0, 0.3);
}
.custom-css-style
  .elementor-element.elementor-element-8678df2
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8678df2
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  h4.timeline-title {
  font-size: 70px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-8678df2
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide-content {
  width: 45%;
}
.custom-css-style
  .elementor-element.elementor-element-8678df2
  .portfolio-classic-grid-wrapper
  .portfolio-classic-content
  .portfolio-classic-subtitle {
  color: rgba(255, 255, 255, 0.24);
}
.custom-css-style
  .elementor-element.elementor-element-8678df2
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-year {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-8678df2
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-slide
  .timeline-text {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8678df2
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination::before {
  background-color: rgba(255, 255, 255, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-8678df2
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination-bullet {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8678df2
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination-bullet::before {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8678df2
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-button-next,
.portfolio-timeline-vertical-content-wrapper .timeline .swiper-button-prev {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8678df2
  .portfolio-timeline-vertical-content-wrapper
  .timeline
  .swiper-pagination {
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-e8f3161
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-e8f3161
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-e8f3161:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-e8f3161
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-e8f3161 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e8f3161
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-9220943
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-08fd48e
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-30e786d {
  overflow: hidden;
}
.custom-css-style
  .elementor-element.elementor-element-d24faf6
   .elementor-element-populated {
  margin: 0 40px 0 40px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  .portfolio-masonry-grid-wrapper
  img {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  .portfolio-masonry-grid-wrapper
  .figcaption {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  .portfolio-masonry-grid-wrapper
  h3 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  .portfolio-masonry-grid-wrapper
  .portfolio-masonry-content
  .popup-arrow {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  div.portfolio-masonry-grid-wrapper
  h3 {
  font-size: 25px;
  line-height: 1.3em;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  .portfolio-masonry-container:not(.masonry-grid)
  .portfolio-masonry-grid-wrapper
  .figcaption {
  background: #fffbf2;
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  .portfolio-masonry-container:not(.masonry-grid)
  .portfolio-masonry-grid-wrapper
  .figcaption:before {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  div.portfolio-masonry-grid-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  .portfolio_masonry_subtitle {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  .portfolio-masonry-content
  .portfolio_masonry_subtitle {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  .craftcoffee-portfolio-filter-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  div.elementor-widget-container
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  border-color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  div.craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn.active {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-0c8fe3d
  .craftcoffee-portfolio-filter-wrapper
  .filter-tag-btn.active {
  color: #000;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-8678df2
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide
    h4.timeline-title {
    font-size: 65px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8678df2
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide-content {
    width: 60%;
  }
  .custom-css-style
    .elementor-element.elementor-element-e8f3161
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e8f3161
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-e8f3161:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-e8f3161
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-e8f3161 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d24faf6
     .elementor-element-populated {
    margin: 0 30px 0 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0c8fe3d
    div.portfolio-masonry-grid-wrapper
    h3 {
    font-size: 30px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-0c8fe3d
    .portfolio-masonry-content
    .portfolio_masonry_subtitle {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-8678df2
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide
    h4.timeline-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8678df2
    .portfolio-timeline-vertical-content-wrapper
    .timeline
    .swiper-slide-content {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-e8f3161
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e8f3161
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-e8f3161:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-e8f3161
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-e8f3161 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-08fd48e
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d24faf6
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-da70b24
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-da70b24
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-da70b24:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-da70b24
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/barista-making-calita-drip-brew-coffee-96MK74T.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-da70b24
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-da70b24 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-ad9c28f
   .elementor-element-populated {
  margin: 60px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-4ab4fe7 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-4ab4fe7
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-4ab4fe7 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-4ab4fe7 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-970fd8e {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-970fd8e
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-970fd8e
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-970fd8e {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-970fd8e {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-60278e8 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 60px 0 40px 0;
}
.custom-css-style
  .elementor-element.elementor-element-60278e8:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-60278e8
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-60278e8
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-aba7c95
   .elementor-element-populated {
  padding: 0 40px 0 40px;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  .portfolio-classic-grid-wrapper
  .portfolio-classic-img {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  .portfolio-classic-grid-wrapper
  h3 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  .portfolio-classic-content {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  .portfolio-classic-content:before {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  div.portfolio-classic-grid-wrapper
  h3 {
  font-size: 32px;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  div.portfolio-classic-grid-wrapper {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  .portfolio-classic-grid-wrapper
  .portfolio-classic-subtitle {
  color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  .portfolio-classic-grid-wrapper
  div.portfolio-classic-subtitle {
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  .craftcoffee-portfolio-filter-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn {
  color: #666;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  div.craftcoffee-portfolio-filter-wrapper
  .filter-tag-btn:hover {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  div.craftcoffee-portfolio-filter-wrapper
  .filter-tag-btn.active {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-cc4ac60
  .craftcoffee-portfolio-filter-wrapper
  a.filter-tag-btn.active {
  color: #000;
}
:root {
  --page-title-display: none;
}
body.elementor-page-3549:not(.elementor-motion-effects-element-type-background),
body.elementor-page-3549
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-da70b24
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-da70b24
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-4ab4fe7
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-970fd8e
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-aba7c95
     .elementor-element-populated {
    padding: 0 30px 0 30px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-da70b24
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-da70b24
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-4ab4fe7
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-970fd8e
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-970fd8e
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-aba7c95
     .elementor-element-populated {
    padding: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-a888fb6
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-a888fb6
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-a888fb6:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-a888fb6
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/barista-prepare-coffee-working-order-concept-PBZ6VQ6.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-a888fb6
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-a888fb6 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-ae0a698
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-2830265 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-2830265
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-2830265 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-2830265 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-0923c51 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-0923c51
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-0923c51
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-0923c51 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-0923c51 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-821bad4
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-821bad4
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-821bad4:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-821bad4
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-821bad4 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-821bad4
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-a2ee511
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d8f735f
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-d40e6c7 {
  overflow: hidden;
}
.custom-css-style
  .elementor-element.elementor-element-b461ec1
   .elementor-element-populated {
  margin: 0;
  padding: 0 40px 40px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-c6a71d4
  .craftcoffee-gallery-grid-content-wrapper
  .gallery-grid-item {
  border-width: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c6a71d4
  .craftcoffee-gallery-grid-content-wrapper
  div.gallery-grid-item {
  border-color: #fff;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c6a71d4
  .craftcoffee-gallery-grid-content-wrapper
  .gallery-grid-item:hover
  .bg-overlay {
  background: rgba(0, 0, 0, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-c6a71d4
  .craftcoffee-gallery-grid-content-wrapper
  .gallery-grid-item:hover
  .gallery-grid-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c6a71d4
  .craftcoffee-gallery-grid-content-wrapper
  .gallery-grid-item
  .gallery-grid-title {
  font-family: Oswald, Sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-a888fb6
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a888fb6
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-2830265
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0923c51
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-821bad4
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-821bad4
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-821bad4:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-821bad4
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-821bad4 {
    margin-top: -50px;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-a888fb6
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a888fb6
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-2830265
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0923c51
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0923c51
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-821bad4
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-821bad4
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-821bad4:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-821bad4
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-821bad4 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d8f735f
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-d40e6c7 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b461ec1
     .elementor-element-populated {
    margin: 0;
    padding: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-b7d8d94
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-b7d8d94
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-b7d8d94:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-b7d8d94
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/jonathan-borba-O2fAWP7uDSQ-unsplash.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-b7d8d94
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-b7d8d94 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-5828e4b
   .elementor-element-populated {
  margin: 60px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-43c74b5 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-43c74b5
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-43c74b5 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-43c74b5 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-c72d3fb {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-c72d3fb
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-c72d3fb
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-c72d3fb {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-c72d3fb {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-84ce4e1
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-84ce4e1
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-84ce4e1:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-84ce4e1
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-84ce4e1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-84ce4e1
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-944d57d
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b7707f4
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-36001ba {
  overflow: hidden;
}
.custom-css-style
  .elementor-element.elementor-element-798bdc4
   .elementor-element-populated {
  padding: 0 40px 40px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-824ee99
  .craftcoffee-gallery-grid-content-wrapper
  .gallery-grid-item {
  border-width: 0;
}
.custom-css-style
  .elementor-element.elementor-element-824ee99
  .craftcoffee-gallery-grid-content-wrapper
  div.gallery-grid-item {
  border-color: #fff;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-824ee99
  .craftcoffee-gallery-grid-content-wrapper
  .gallery-grid-item:hover
  .bg-overlay {
  background: rgba(0, 0, 0, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-824ee99
  .craftcoffee-gallery-grid-content-wrapper
  .gallery-grid-item:hover
  .gallery-grid-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-824ee99
  .craftcoffee-gallery-grid-content-wrapper
  .gallery-grid-item
  .gallery-grid-title {
  font-family: Oswald, Sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-b7d8d94
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b7d8d94
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-43c74b5
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c72d3fb
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-84ce4e1
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-84ce4e1
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-84ce4e1:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-84ce4e1
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-84ce4e1 {
    margin-top: -50px;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-b7d8d94
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b7d8d94
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-43c74b5
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c72d3fb
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c72d3fb
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-84ce4e1
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-84ce4e1
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-84ce4e1:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-84ce4e1
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-84ce4e1 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b7707f4
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-798bdc4
     .elementor-element-populated {
    padding: 0 20px 0 20px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-527efcc
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-527efcc
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-527efcc:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-527efcc
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/coffee-PFMG7FM.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-527efcc
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-527efcc {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-4eb3660
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-38763c4 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-38763c4
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-38763c4 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-38763c4 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-31fa868 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-31fa868
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-31fa868
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-31fa868 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-31fa868 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-0be17b9 {
  overflow: hidden;
}
.custom-css-style
  .elementor-element.elementor-element-f4f4482
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9d4024c
  .craftcoffee-gallery-grid-content-wrapper
  .gallery-grid-item {
  border-width: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9d4024c
  .craftcoffee-gallery-grid-content-wrapper
  div.gallery-grid-item {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9d4024c
  .craftcoffee-gallery-grid-content-wrapper
  .gallery-grid-item:hover
  .bg-overlay {
  background: rgba(0, 0, 0, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-9d4024c
  .craftcoffee-gallery-grid-content-wrapper
  .gallery-grid-item:hover
  .gallery-grid-title {
  color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-527efcc
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-527efcc
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-38763c4
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-31fa868
    .elementor-heading-title {
    font-size: 90px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-527efcc
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-527efcc
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-38763c4
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-31fa868
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-31fa868
     .elementor-widget-container {
    margin: 0;
  }
}
.custom-css-style
  .elementor-element.elementor-element-8aaf60d
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0dc6c1c
  .swiper-button-next
  i {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-0dc6c1c
  .swiper-button-prev
  i {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-0dc6c1c
  .gallery-fullscreen-content
  .gallery-fullscreen-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-0dc6c1c
  .gallery-fullscreen-content
  .gallery-fullscreen-caption {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-0dc6c1c
  .gallery-fullscreen-content
  .gallery-fullscreen-description {
  color: #fff;
}
:root {
  --page-title-display: none;
}
.custom-css-style
  .elementor-element.elementor-element-1ef6e22
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-1ef6e22
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-1ef6e22:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-1ef6e22
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/fresh-coffee-with-croissant-DC7H79J.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-1ef6e22
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-1ef6e22 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-6fc77c4
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-6a605a3 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-6a605a3
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-6a605a3 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-6a605a3 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-dcb02db {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-dcb02db
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-dcb02db
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-dcb02db {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-dcb02db {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-979fb6c
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-979fb6c
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-979fb6c:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-979fb6c
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-979fb6c {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-979fb6c
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-1dbd78e
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0e7f810
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e0a7a2e
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-8a5e67a
  .elementor-image
  img {
  width: 230px;
}
.custom-css-style .elementor-element.elementor-element-8a5e67a {
  width: 100%;
  max-width: 100%;
  top: 0.673%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-8a5e67a {
  left: 40.342%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-8a5e67a {
  right: 40.342%;
}
.custom-css-style .elementor-element.elementor-element-ec1f33b {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-ec1f33b
  .elementor-heading-title {
  line-height: 1.4em;
}
.custom-css-style
  .elementor-element.elementor-element-ec1f33b
   .elementor-widget-container {
  margin: 0 0 60px 0;
  padding: 0 20% 0 20%;
}
.custom-css-style .elementor-element.elementor-element-418c9ea {
  margin-top: 0;
  margin-bottom: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-fa86279
   .elementor-element-populated {
  margin: 0 20px 0 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-de65ca7
  .elementor-heading-title {
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-de65ca7
   .elementor-widget-container {
  margin: 0 20px 30px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-8cf675f
  .food-menu-content-title-holder
  h3 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-8cf675f
  .food-menu-content-title-line {
  border-color: #000;
}
.custom-css-style .elementor-element.elementor-element-8cf675f .food-menu-desc {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-8cf675f
  .food-menu-content-price-holder
  .food-menu-content-price-normal {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-8cf675f
  .food-menu-content-price-holder
  .food-menu-content-price-sale {
  color: #989898;
}
.custom-css-style
  .elementor-element.elementor-element-8cf675f
  .food-menu-content-highlight-holder
  h4 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8cf675f
  .food-menu-content-highlight-holder {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-8cf675f
  .food-menu-highlight {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-8cf675f
  .food-menu-tooltip-content {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-8cf675f
  .food-menu-tooltip-content
  h5 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-8cf675f
  .tooltipster-sidetip.tooltipster-shadow
  .tooltipster-box {
  background: #fffbf2;
}
.custom-css-style
  .elementor-element.elementor-element-44993b4
  .elementor-heading-title {
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-44993b4
   .elementor-widget-container {
  margin: 20px 20px 30px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-1396a84
  .food-menu-content-title-holder
  h3 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1396a84
  .food-menu-content-title-line {
  border-color: #000;
}
.custom-css-style .elementor-element.elementor-element-1396a84 .food-menu-desc {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1396a84
  .food-menu-content-price-holder
  .food-menu-content-price-normal {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1396a84
  .food-menu-content-price-holder
  .food-menu-content-price-sale {
  color: #989898;
}
.custom-css-style
  .elementor-element.elementor-element-1396a84
  .food-menu-content-highlight-holder
  h4 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-1396a84
  .food-menu-content-highlight-holder {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1396a84
  .food-menu-highlight {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1396a84
  .food-menu-tooltip-content {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1396a84
  .food-menu-tooltip-content
  h5 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1396a84
  .tooltipster-sidetip.tooltipster-shadow
  .tooltipster-box {
  background: #fffbf2;
}
.custom-css-style
  .elementor-element.elementor-element-5b05cd1
  .elementor-image
  img {
  width: 220px;
}
.custom-css-style .elementor-element.elementor-element-5b05cd1 {
  width: 100%;
  max-width: 100%;
  top: 78%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-5b05cd1 {
  left: -53%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-5b05cd1 {
  right: -53%;
}
.custom-css-style
  .elementor-element.elementor-element-71249e7
   .elementor-element-populated {
  margin: 0 0 0 20px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-95f23c5
  .elementor-heading-title {
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-95f23c5
   .elementor-widget-container {
  margin: 0 20px 30px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-aac8604
  .food-menu-content-title-holder
  h3 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-aac8604
  .food-menu-content-title-line {
  border-color: #000;
}
.custom-css-style .elementor-element.elementor-element-aac8604 .food-menu-desc {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-aac8604
  .food-menu-content-price-holder
  .food-menu-content-price-normal {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-aac8604
  .food-menu-content-price-holder
  .food-menu-content-price-sale {
  color: #989898;
}
.custom-css-style
  .elementor-element.elementor-element-aac8604
  .food-menu-content-highlight-holder
  h4 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-aac8604
  .food-menu-content-highlight-holder {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-aac8604
  .food-menu-highlight {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-aac8604
  .food-menu-tooltip-content {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-aac8604
  .food-menu-tooltip-content
  h5 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-aac8604
  .tooltipster-sidetip.tooltipster-shadow
  .tooltipster-box {
  background: #fffbf2;
}
.custom-css-style
  .elementor-element.elementor-element-55a9ee8
  .elementor-heading-title {
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-55a9ee8
   .elementor-widget-container {
  margin: 20px 20px 30px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-d41ab4e
  .food-menu-content-title-holder
  h3 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d41ab4e
  .food-menu-content-title-line {
  border-color: #000;
}
.custom-css-style .elementor-element.elementor-element-d41ab4e .food-menu-desc {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d41ab4e
  .food-menu-content-price-holder
  .food-menu-content-price-normal {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d41ab4e
  .food-menu-content-price-holder
  .food-menu-content-price-sale {
  color: #989898;
}
.custom-css-style
  .elementor-element.elementor-element-d41ab4e
  .food-menu-content-highlight-holder
  h4 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d41ab4e
  .food-menu-content-highlight-holder {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d41ab4e
  .food-menu-highlight {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d41ab4e
  .food-menu-tooltip-content {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d41ab4e
  .food-menu-tooltip-content
  h5 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d41ab4e
  .tooltipster-sidetip.tooltipster-shadow
  .tooltipster-box {
  background: #fffbf2;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-1ef6e22
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1ef6e22
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-6a605a3
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-dcb02db
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-979fb6c
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-979fb6c
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-979fb6c:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-979fb6c
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-979fb6c {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ec1f33b
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-418c9ea {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fa86279
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-de65ca7
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-44993b4
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-5b05cd1 {
    top: 113%;
  }
  .custom-css-style
    .elementor-element.elementor-element-71249e7
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-95f23c5
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-55a9ee8
    .elementor-heading-title {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-1ef6e22
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1ef6e22
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-6a605a3
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-dcb02db
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-979fb6c
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-979fb6c
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-979fb6c:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-979fb6c
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-979fb6c {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0e7f810
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ec1f33b
    .elementor-heading-title {
    font-size: 28px;
  }
  .custom-css-style .elementor-element.elementor-element-418c9ea {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fa86279
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-de65ca7 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-de65ca7
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-de65ca7
     .elementor-widget-container {
    margin: 0 0 30px 0;
  }
  .custom-css-style .elementor-element.elementor-element-44993b4 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-44993b4
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-44993b4
     .elementor-widget-container {
    margin: 20px 0 30px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-71249e7
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-95f23c5 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-95f23c5
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-95f23c5
     .elementor-widget-container {
    margin: 20px 0 30px 0;
  }
  .custom-css-style .elementor-element.elementor-element-55a9ee8 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-55a9ee8
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-55a9ee8
     .elementor-widget-container {
    margin: 20px 0 30px 0;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-fa86279 {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-71249e7 {
    width: 100%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-c7d144f
   .elementor-container {
  min-height: 400px;
}
.custom-css-style
  .elementor-element.elementor-element-c7d144f
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-c7d144f:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-c7d144f
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #1e1d19;
}
.custom-css-style .elementor-element.elementor-element-c7d144f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-c7d144f
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-38383f6.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-38383f6.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-38383f6
   .elementor-element-populated {
  padding: 0 200px 0 90px;
}
.custom-css-style
  .elementor-element.elementor-element-c795a27
  .elementor-heading-title {
  color: #fff;
  font-size: 45px;
}
.custom-css-style .elementor-element.elementor-element-fe30d60 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-fe30d60
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-bbbbc70 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-bbbbc70
  .elementor-image
  img {
  width: 250px;
}
.custom-css-style
  .elementor-element.elementor-element-bbbbc70
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-ff896b7:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-ff896b7
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/jonathan-borba-5G9uIkAXLSc-unsplash.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-ff896b7
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 200px 0 200px 0;
}
.custom-css-style
  .elementor-element.elementor-element-ff896b7
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-8af6019
  .elementor-image
  img {
  width: 170px;
}
.custom-css-style .elementor-element.elementor-element-8af6019 {
  width: auto;
  max-width: auto;
  top: -100px;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-8af6019 {
  left: -85px;
}
body.rtl .custom-css-style .elementor-element.elementor-element-8af6019 {
  right: -85px;
}
.custom-css-style
  .elementor-element.elementor-element-9229aee:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-9229aee
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-9229aee {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 60px 0 60px 0;
}
.custom-css-style
  .elementor-element.elementor-element-9229aee
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-7e367f8 {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(255, 255, 255, 0.4);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7e367f8
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-4d8e803
   .elementor-element-populated {
  padding: 20px 40px 20px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-658e32a
  .elementor-image
  img {
  width: 62px;
}
.custom-css-style
  .elementor-element.elementor-element-658e32a
   .elementor-widget-container {
  margin: 0 20px 0 0;
}
.custom-css-style .elementor-element.elementor-element-658e32a {
  width: auto;
  max-width: auto;
  align-self: center;
}
.custom-css-style .elementor-element.elementor-element-e3d4439 {
  color: #fff;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-8d59401
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: rgba(255, 255, 255, 0.4);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 40px 20px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-8d59401
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-03d2b2d
  .elementor-image
  img {
  width: 62px;
}
.custom-css-style
  .elementor-element.elementor-element-03d2b2d
   .elementor-widget-container {
  margin: 0 20px 0 0;
}
.custom-css-style .elementor-element.elementor-element-03d2b2d {
  width: auto;
  max-width: auto;
  align-self: center;
}
.custom-css-style .elementor-element.elementor-element-5662f5b {
  color: #fff;
  width: auto;
  max-width: auto;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-06fc5ca.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-06fc5ca.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-06fc5ca.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-06fc5ca
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: rgba(255, 255, 255, 0.4);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-06fc5ca
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-9c89ed9 {
  --grid-template-columns: repeat(0, auto);
  --grid-column-gap: 20px;
  --grid-side-margin: 20px;
  --grid-row-gap: 0px;
  --grid-bottom-margin: 0px;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-9c89ed9
  .elementor-widget-container {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-9c89ed9
  .elementor-social-icon {
  background-color: rgba(2, 1, 1, 0);
  font-size: 26px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3374de8:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-3374de8
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-3374de8 {
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: rgba(255, 255, 255, 0.4);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 0 30px 0;
}
.custom-css-style
  .elementor-element.elementor-element-3374de8
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-ebb6c30 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-ebb6c30
  .elementor-heading-title {
  color: #fff;
  font-size: 22px;
  letter-spacing: 2px;
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-4d8e803 {
    width: 35%;
  }
  .custom-css-style .elementor-element.elementor-element-8d59401 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-06fc5ca {
    width: 24.332%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-38383f6
     .elementor-element-populated {
    padding: 0 90px 0 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c795a27
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4d8e803
     .elementor-element-populated {
    padding: 40px 30px 40px 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8d59401
     .elementor-element-populated {
    padding: 40px 30px 40px 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-06fc5ca
     .elementor-element-populated {
    border-width: 1px 0 0 0;
    padding: 40px 0 40px 0;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-38383f6
     .elementor-element-populated {
    padding: 60px 30px 60px 30px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-8af6019 {
    left: 25%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-8af6019 {
    right: 25%;
  }
  .custom-css-style
    .elementor-element.elementor-element-8d59401
     .elementor-element-populated {
    border-width: 1px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ebb6c30
    .elementor-heading-title {
    line-height: 1.4em;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-4d8e803 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-8d59401 {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-06fc5ca {
    width: 100%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-ae608b9
   .elementor-container {
  min-height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-ae608b9
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-ae608b9:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-ae608b9
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/glasses-with-black-coffee-and-filtered-water-TRQLF3Z.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-ae608b9
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-ae608b9 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-ff078ad
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-ba5ab05 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-ba5ab05
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
  line-height: 0.7em;
}
.elementor-element.elementor-element-ba5ab05 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 1s !important;
}
.elementor-widget.elementor-element-ba5ab05 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-0ae1fcb {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-0ae1fcb
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-0ae1fcb {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-0ae1fcb {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-ee246c3 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-ee246c3
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-ee246c3
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-ee246c3 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-ee246c3 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-2ce3b94 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-2ce3b94
  .elementor-heading-title {
  color: #fff;
  font-size: 35px;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-2ce3b94
   .elementor-widget-container {
  margin: 0;
}
.elementor-element.elementor-element-2ce3b94 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 1s !important;
}
.elementor-widget.elementor-element-2ce3b94 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-267896a
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-267896a
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-267896a:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-267896a
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-267896a {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-267896a
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-28b66f2
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0e0a2c0
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-ac4e16d {
  margin-top: 0;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a107470
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b627d57
  .timeline-wrapper:before {
  background: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-b627d57
  .timeline-wrapper
  .timeline-entry
  .timeline-title:before {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-b627d57
  div.timeline-wrapper
  div.timeline-entry
  .timeline-title:before {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-b627d57
  .timeline-wrapper
  .timeline-entry
  .timeline-title
  h3 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-b627d57
  div.timeline-wrapper
  .timeline-entry
  .timeline-title
  h3 {
  font-size: 60px;
}
.custom-css-style
  .elementor-element.elementor-element-b627d57
  .timeline-wrapper
  .timeline-entry
  .timeline-body {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7c7afd5
  .elementor-image
  img {
  width: 250px;
}
.custom-css-style .elementor-element.elementor-element-7c7afd5 {
  width: 100%;
  max-width: 100%;
  top: 78%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-7c7afd5 {
  left: -53%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-7c7afd5 {
  right: -53%;
}
.custom-css-style
  .elementor-element.elementor-element-0203f2c
  .elementor-image
  img {
  width: 260px;
}
.custom-css-style .elementor-element.elementor-element-0203f2c {
  width: 100%;
  max-width: 100%;
  top: 0.673%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-0203f2c {
  left: 40.342%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-0203f2c {
  right: 40.342%;
}
.custom-css-style
  .elementor-element.elementor-element-08166d2
  .elementor-image
  img {
  width: 120px;
}
.custom-css-style .elementor-element.elementor-element-08166d2 {
  width: 100%;
  max-width: 100%;
  top: 49%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-08166d2 {
  left: 49%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-08166d2 {
  right: 49%;
}
.custom-css-style
  .elementor-element.elementor-element-540f6f5
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-540f6f5
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-540f6f5:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-540f6f5
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/bg-header-overlay-lg-2.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-540f6f5 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-540f6f5
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-e4ef267
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-45ba65e
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-410742a:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-410742a
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-410742a {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-410742a
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-b114008
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4053966
  .elementor-image
  img {
  width: 65%;
}
.custom-css-style
  .elementor-element.elementor-element-4053966
   .elementor-widget-container {
  margin: -40px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-2c136c0
  .elementor-image
  img {
  width: 250px;
  opacity: 0.4;
  filter: brightness(200%) contrast(0) saturate(0) blur(0) hue-rotate(0deg);
}
.custom-css-style .elementor-element.elementor-element-2c136c0 {
  width: 100%;
  max-width: 100%;
  top: 64%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-2c136c0 {
  left: -34%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-2c136c0 {
  right: -34%;
}
.custom-css-style
  .elementor-element.elementor-element-3f40959
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3ac3103
  .elementor-image
  img {
  width: 65%;
}
.custom-css-style
  .elementor-element.elementor-element-3ac3103
   .elementor-widget-container {
  margin: 100px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-922f175 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0 0 100px 0;
}
.custom-css-style
  .elementor-element.elementor-element-922f175:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-922f175
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-922f175
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-b64a969
   .elementor-element-populated {
  padding: 0 25% 0 25%;
}
.custom-css-style
  .elementor-element.elementor-element-3ffff08
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.05);
  font-size: 400px;
}
.custom-css-style .elementor-element.elementor-element-3ffff08 {
  width: 500vw;
  max-width: 500vw;
  top: 36%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-3ffff08 {
  left: -42%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-3ffff08 {
  right: -42%;
}
.custom-css-style .elementor-element.elementor-element-acbcf31 {
  color: #fff;
  font-size: 20px;
}
.custom-css-style
  .elementor-element.elementor-element-acbcf31
   .elementor-widget-container {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9150bc2
  .elementor-image
  img {
  width: 250px;
}
.custom-css-style
  .elementor-element.elementor-element-9150bc2
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-aee0cd9 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-aee0cd9
  .elementor-heading-title {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-aee0cd9
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-ae608b9
     .elementor-container {
    min-height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ae608b9
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-ba5ab05
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0ae1fcb
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ee246c3
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ee246c3
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2ce3b94
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-267896a
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-267896a
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-267896a:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-267896a
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-267896a {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-ac4e16d {
    padding: 0 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b627d57
    div.timeline-wrapper
    .timeline-entry
    .timeline-title
    h3 {
    font-size: 50px;
  }
  .custom-css-style .elementor-element.elementor-element-7c7afd5 {
    top: 113%;
  }
  .custom-css-style
    .elementor-element.elementor-element-0203f2c
    .elementor-image
    img {
    width: 205px;
  }
  .custom-css-style
    .elementor-element.elementor-element-540f6f5
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-540f6f5
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-540f6f5:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-540f6f5
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-540f6f5 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-2c136c0 {
    left: -16%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-2c136c0 {
    right: -16%;
  }
  .custom-css-style .elementor-element.elementor-element-2c136c0 {
    top: 36%;
  }
  .custom-css-style
    .elementor-element.elementor-element-b64a969
     .elementor-element-populated {
    padding: 0 10% 0 10%;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-ae608b9
     .elementor-container {
    min-height: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ae608b9
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-ba5ab05
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0ae1fcb
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ee246c3
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ee246c3
     .elementor-widget-container {
    margin: 10px 0 10px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2ce3b94
    .elementor-heading-title {
    font-size: 24px;
  }
  .custom-css-style
    .elementor-element.elementor-element-267896a
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-267896a
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-267896a:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-267896a
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-267896a {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0e0a2c0
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b627d57
    div.timeline-wrapper
    .timeline-entry
    .timeline-title
    h3 {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-540f6f5
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-540f6f5
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-540f6f5:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-540f6f5
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-540f6f5 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-45ba65e
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-4053966
    .elementor-image
    img {
    width: 80%;
  }
  .custom-css-style
    .elementor-element.elementor-element-4053966
     .elementor-widget-container {
    margin: 60px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-3ac3103
    .elementor-image
    img {
    width: 80%;
  }
  .custom-css-style
    .elementor-element.elementor-element-3ac3103
     .elementor-widget-container {
    margin: 40px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-922f175 {
    padding: 0 0 60px 0;
  }
  .custom-css-style .elementor-element.elementor-element-acbcf31 {
    font-size: 16px;
  }
  .custom-css-style
    .elementor-element.elementor-element-acbcf31
     .elementor-widget-container {
    margin: 40px 0 0 0;
  }
}
.custom-css-style
  .elementor-element.elementor-element-e95f1df
   .elementor-container {
  min-height: 800px;
}
.custom-css-style
  .elementor-element.elementor-element-e95f1df
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-e95f1df:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-e95f1df
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-e95f1df {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 200px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-e95f1df
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-03c8bb0
   .elementor-element-populated {
  padding: 0 0 0 100px;
}
.custom-css-style
  .elementor-element.elementor-element-de04247
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
}
.custom-css-style .elementor-element.elementor-element-de04247 {
  z-index: 2;
}
.elementor-element.elementor-element-de04247 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-de04247 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-824d33d {
  width: initial;
  max-width: initial;
  bottom: -37%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-824d33d {
  left: -47%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-824d33d {
  right: -47%;
}
.custom-css-style
  .elementor-element.elementor-element-5501ad7
   .elementor-element-populated {
  padding: 170px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-19d72e6 {
  --divider-border-style: solid;
  --divider-color: #ad9a74;
  --divider-border-width: 4px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-19d72e6
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-19d72e6
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-19d72e6
   .elementor-widget-container {
  margin: 0 0 0 -85px;
}
.elementor-element.elementor-element-19d72e6 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-19d72e6 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-70016a9
  .elementor-heading-title {
  color: #ad9a74;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-70016a9
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-70016a9 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-f205c73 {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-f205c73
   .elementor-widget-container {
  margin: 20px 0 0 105px;
}
.custom-css-style
  .elementor-element.elementor-element-7865358
  .elementor-image
  img {
  width: 260px;
  filter: brightness(100%) contrast(200%) saturate(0) blur(0) hue-rotate(0deg);
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-7865358 {
  right: -214.3px;
}
body.rtl .custom-css-style .elementor-element.elementor-element-7865358 {
  left: -214.3px;
}
.custom-css-style .elementor-element.elementor-element-7865358 {
  top: -27%;
}
.custom-css-style
  .elementor-element.elementor-element-5d98b17
   .elementor-container {
  min-height: 800px;
}
.custom-css-style
  .elementor-element.elementor-element-5d98b17
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style .elementor-element.elementor-element-5d98b17 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5d98b17:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-5d98b17
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-5d98b17
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-95aeb6c
   .elementor-element-populated {
  padding: 170px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-2fa0630 {
  --divider-border-style: solid;
  --divider-color: #ad9a74;
  --divider-border-width: 4px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-2fa0630
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2fa0630
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-2fa0630
   .elementor-widget-container {
  margin: 0 0 0 -85px;
}
.elementor-element.elementor-element-2fa0630 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-2fa0630 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7572299
  .elementor-heading-title {
  color: #ad9a74;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-7572299
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-7572299 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-ac2bd9e {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-ac2bd9e
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-08c005a
  .elementor-image
  img {
  width: 250px;
  opacity: 1;
  filter: brightness(200%) contrast(0) saturate(0) blur(0) hue-rotate(0deg);
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-08c005a {
  right: 43%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-08c005a {
  left: 43%;
}
.custom-css-style .elementor-element.elementor-element-08c005a {
  top: -27%;
}
.custom-css-style
  .elementor-element.elementor-element-8cd32b9
   .elementor-element-populated {
  padding: 0 0 0 100px;
}
.custom-css-style
  .elementor-element.elementor-element-cdc0103
  .elementor-heading-title {
  color: #fff;
  font-size: 100px;
  line-height: 0.9em;
}
.custom-css-style .elementor-element.elementor-element-cdc0103 {
  z-index: 2;
  bottom: -44%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-cdc0103 {
  right: -16%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-cdc0103 {
  left: -16%;
}
.custom-css-style .elementor-element.elementor-element-3e0162f {
  width: initial;
  max-width: initial;
  bottom: -20%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-3e0162f {
  right: -51%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-3e0162f {
  left: -51%;
}
.custom-css-style
  .elementor-element.elementor-element-c236a8d
   .elementor-container {
  min-height: 900px;
}
.custom-css-style
  .elementor-element.elementor-element-c236a8d
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style .elementor-element.elementor-element-c236a8d {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c236a8d:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-c236a8d
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-c236a8d
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-235adeb
   .elementor-element-populated {
  padding: 0 0 0 100px;
}
.custom-css-style .elementor-element.elementor-element-e8ef7c0 {
  z-index: 0;
  width: 100%;
  max-width: 100%;
  bottom: -37%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-e8ef7c0 {
  left: -4%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-e8ef7c0 {
  right: -4%;
}
.custom-css-style
  .elementor-element.elementor-element-106c401
  .elementor-heading-title {
  color: #fff;
  font-size: 100px;
  line-height: 0.9em;
}
.custom-css-style .elementor-element.elementor-element-106c401 {
  z-index: 2;
  bottom: -51%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-106c401 {
  right: 20%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-106c401 {
  left: 20%;
}
.custom-css-style
  .elementor-element.elementor-element-265f0e0
   .elementor-element-populated {
  padding: 170px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-4064971 {
  --divider-border-style: solid;
  --divider-color: #ad9a74;
  --divider-border-width: 4px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-4064971
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4064971
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-4064971
   .elementor-widget-container {
  margin: 0 0 0 -85px;
}
.elementor-element.elementor-element-4064971 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-4064971 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-906e23e
  .elementor-heading-title {
  color: #ad9a74;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-906e23e
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-906e23e {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-50fee70 {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-50fee70
   .elementor-widget-container {
  margin: 20px 0 0 105px;
}
.custom-css-style
  .elementor-element.elementor-element-dee2263
  .elementor-image
  img {
  width: 300px;
  filter: brightness(100%) contrast(200%) saturate(0) blur(0) hue-rotate(0deg);
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-dee2263 {
  right: -214.3px;
}
body.rtl .custom-css-style .elementor-element.elementor-element-dee2263 {
  left: -214.3px;
}
.custom-css-style .elementor-element.elementor-element-dee2263 {
  top: -27%;
}
.custom-css-style
  .elementor-element.elementor-element-d4ed99b:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-d4ed99b
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-d4ed99b {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-d4ed99b
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-d7d6277 {
  --divider-border-style: solid;
  --divider-color: #ad9a74;
  --divider-border-width: 4px;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-d7d6277
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d7d6277
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-d7d6277
   .elementor-widget-container {
  margin: 0;
}
.elementor-element.elementor-element-d7d6277 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-d7d6277 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a43fe18
  .elementor-heading-title {
  color: #ad9a74;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-a43fe18
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-a43fe18 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-2846e8a:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-2846e8a
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-2846e8a {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 60px 0 120px 0;
}
.custom-css-style
  .elementor-element.elementor-element-2846e8a
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-ee437b3
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-49442f1
  .service-grid-wrapper {
  height: 550px;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-49442f1
  .service-grid-wrapper
  img {
  max-width: 50px !important;
}
.custom-css-style
  .elementor-element.elementor-element-49442f1
  .service-grid-wrapper
  i {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-49442f1
  .service-grid-wrapper
  .overflow-inner
  .header-wrap
  h2 {
  color: #fff;
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-49442f1
  .service-grid-wrapper
  .overflow-inner
  .hover-content {
  color: #fff;
}
:root {
  --page-title-display: none;
}
body.elementor-page-3768:not(.elementor-motion-effects-element-type-background),
body.elementor-page-3768
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-e95f1df
     .elementor-container {
    min-height: 700px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e95f1df
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-e95f1df {
    padding: 170px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-03c8bb0
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-de04247
    .elementor-heading-title {
    font-size: 80px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-824d33d {
    left: -45%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-824d33d {
    right: -45%;
  }
  .custom-css-style .elementor-element.elementor-element-824d33d {
    bottom: 17%;
  }
  .custom-css-style
    .elementor-element.elementor-element-5501ad7
     .elementor-element-populated {
    margin: 0;
    padding: 120px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-19d72e6
     .elementor-widget-container {
    margin: 0 0 0 -100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f205c73
     .elementor-widget-container {
    margin: 20px 0 0 60px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-7865358 {
    right: -18%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-7865358 {
    left: -18%;
  }
  .custom-css-style .elementor-element.elementor-element-7865358 {
    top: -14%;
  }
  .custom-css-style
    .elementor-element.elementor-element-5d98b17
     .elementor-container {
    min-height: 780px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5d98b17
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-5d98b17 {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-95aeb6c
     .elementor-element-populated {
    margin: 0;
    padding: 120px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2fa0630
     .elementor-widget-container {
    margin: 0 0 0 -100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ac2bd9e
     .elementor-widget-container {
    margin: 20px 40px 0 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-08c005a {
    right: -18%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-08c005a {
    left: -18%;
  }
  .custom-css-style .elementor-element.elementor-element-08c005a {
    top: -14%;
  }
  .custom-css-style
    .elementor-element.elementor-element-8cd32b9
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-cdc0103
    .elementor-heading-title {
    font-size: 70px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-cdc0103 {
    right: -2%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-cdc0103 {
    left: -2%;
  }
  .custom-css-style .elementor-element.elementor-element-cdc0103 {
    bottom: 9%;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-3e0162f {
    right: -69%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-3e0162f {
    left: -69%;
  }
  .custom-css-style .elementor-element.elementor-element-3e0162f {
    bottom: 17%;
  }
  .custom-css-style
    .elementor-element.elementor-element-c236a8d
     .elementor-container {
    min-height: 700px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c236a8d
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-c236a8d {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-235adeb
     .elementor-element-populated {
    padding: 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-e8ef7c0 {
    left: -10%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-e8ef7c0 {
    right: -10%;
  }
  .custom-css-style .elementor-element.elementor-element-e8ef7c0 {
    bottom: 17%;
  }
  .custom-css-style
    .elementor-element.elementor-element-106c401
    .elementor-heading-title {
    font-size: 70px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-106c401 {
    right: -2%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-106c401 {
    left: -2%;
  }
  .custom-css-style .elementor-element.elementor-element-106c401 {
    bottom: 9%;
  }
  .custom-css-style
    .elementor-element.elementor-element-265f0e0
     .elementor-element-populated {
    margin: 0;
    padding: 120px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-4064971
     .elementor-widget-container {
    margin: 0 0 0 -100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-50fee70
     .elementor-widget-container {
    margin: 20px 0 0 60px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-dee2263 {
    right: -18%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-dee2263 {
    left: -18%;
  }
  .custom-css-style .elementor-element.elementor-element-dee2263 {
    top: -14%;
  }
  .custom-css-style
    .elementor-element.elementor-element-d7d6277
     .elementor-widget-container {
    margin: 0 0 0 -100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-49442f1
    .service-grid-wrapper
    img {
    max-width: 45px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-49442f1
    .service-grid-wrapper
    .overflow-inner
    .header-wrap
    h2 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-e95f1df
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e95f1df
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-e95f1df {
    padding: 150px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-de04247 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-de04247
    .elementor-heading-title {
    font-size: 60px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-824d33d {
    left: -12%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-824d33d {
    right: -12%;
  }
  .custom-css-style .elementor-element.elementor-element-824d33d {
    bottom: -200%;
  }
  .custom-css-style
    .elementor-element.elementor-element-5501ad7
     .elementor-element-populated {
    margin: 0 20px 0 20px;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-70016a9
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f205c73
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5d98b17
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5d98b17
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-5d98b17 {
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-95aeb6c
     .elementor-element-populated {
    margin: 0 20px 0 20px;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7572299
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ac2bd9e
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-cdc0103 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-cdc0103
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style .elementor-element.elementor-element-3e0162f {
    bottom: -200%;
  }
  .custom-css-style
    .elementor-element.elementor-element-c236a8d
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c236a8d
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-c236a8d {
    padding: 100px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-235adeb
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-e8ef7c0 {
    left: -12%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-e8ef7c0 {
    right: -12%;
  }
  .custom-css-style .elementor-element.elementor-element-e8ef7c0 {
    bottom: -200%;
  }
  .custom-css-style .elementor-element.elementor-element-106c401 {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-106c401
    .elementor-heading-title {
    font-size: 50px;
    line-height: 0.9em;
  }
  .custom-css-style
    .elementor-element.elementor-element-265f0e0
     .elementor-element-populated {
    margin: 0 20px 0 20px;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-906e23e
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-50fee70
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a43fe18
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-2846e8a {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ee437b3
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-49442f1
    .service-grid-wrapper
    img {
    max-width: 50px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-49442f1
    .service-grid-wrapper
    .overflow-inner
    .header-wrap
    h2 {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-95aeb6c {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-8cd32b9 {
    width: 60%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-f198032
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-f198032
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-f198032:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-f198032
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/barista-pours-hot-water-into-the-glass-with-coffee-RKNLX2V.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-f198032
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-f198032 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-ded849a
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-0f9fe24 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-0f9fe24
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-0f9fe24 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-0f9fe24 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-205a0be {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-205a0be
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-205a0be
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-205a0be {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-205a0be {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3bcf3af
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-3bcf3af
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-3bcf3af:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-3bcf3af
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: rgba(0, 0, 0, 0);
  background-image: url(../upload/bg-header-overlay-lg-black.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-3bcf3af {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -140px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3bcf3af
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-e67c6b7
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f740045
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-563af5c:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-563af5c
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-563af5c {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 80px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-563af5c
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-0402a64:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-0402a64
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/barista-pouring-coffee-cafe-working-startup-busine-PFHMPA7.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-0402a64
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0402a64
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-ff26c41
  .elementor-spacer-inner {
  height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-43fe0ae
   .elementor-element-populated {
  padding: 100px 200px 0 0;
}
.custom-css-style .elementor-element.elementor-element-127140c {
  --divider-border-style: solid;
  --divider-color: #ad9a74;
  --divider-border-width: 4px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-127140c
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-127140c
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-127140c
   .elementor-widget-container {
  margin: 0 0 0 -85px;
}
.elementor-element.elementor-element-127140c {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-127140c {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1005988
  .elementor-heading-title {
  color: #c7a17a;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-1005988
   .elementor-widget-container {
  margin: 0 0 0 25px;
}
.custom-css-style .elementor-element.elementor-element-1005988 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-79b23c8
  .elementor-heading-title {
  color: #fff;
  font-size: 90px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-79b23c8
   .elementor-widget-container {
  margin: 10px 0 0 105px;
}
.custom-css-style .elementor-element.elementor-element-1af6dc3 {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-1af6dc3
   .elementor-widget-container {
  margin: 20px 0 0 105px;
}
.custom-css-style .elementor-element.elementor-element-8ed920b {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-8ed920b
  .elementor-image
  img {
  width: 250px;
}
.custom-css-style
  .elementor-element.elementor-element-8ed920b
   .elementor-widget-container {
  margin: 40px 0 0 105px;
}
.custom-css-style
  .elementor-element.elementor-element-1e7a162
  .elementor-image
  img {
  width: 300px;
  filter: brightness(100%) contrast(200%) saturate(0) blur(0) hue-rotate(0deg);
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-1e7a162 {
  right: -214.3px;
}
body.rtl .custom-css-style .elementor-element.elementor-element-1e7a162 {
  left: -214.3px;
}
.custom-css-style .elementor-element.elementor-element-1e7a162 {
  top: -27%;
}
.custom-css-style
  .elementor-element.elementor-element-a22753f:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-a22753f
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-a22753f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 0 60px 0;
}
.custom-css-style
  .elementor-element.elementor-element-a22753f
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-78a07df
   .elementor-element-populated {
  margin: 0 40px 0 40px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3e83b1c
  .portfolio-classic-grid-wrapper
  .portfolio-classic-img {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3e83b1c
  .portfolio-classic-grid-wrapper
  .portfolio-classic-img
  .curl {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3e83b1c
  .portfolio-classic-content
  h3.portfolio-classic_title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-3e83b1c
  .portfolio-classic-content
  h3.portfolio-classic_title
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-3e83b1c
  .portfolio-classic-content
  h3.portfolio-classic_title
  a:hover {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-3e83b1c
  .portfolio-classic-content {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-3e83b1c
  .portfolio-classic-content:before {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-3e83b1c
  div.portfolio-classic-content
  h3.portfolio-classic_title {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-3e83b1c
  div.portfolio-classic-content
  .portfolio-classic-subtitle {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-3e83b1c
  .portfolio-classic-grid-wrapper {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-3e83b1c
  .portfolio-classic-grid-wrapper
  .portfolio-classic-content
  .portfolio-classic-subtitle {
  color: #c7a17a;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-3e83b1c
  .portfolio-classic-grid-wrapper
  .portfolio-classic-content
  .portfolio-classic-description {
  color: #b8b8b8;
}
:root {
  --page-title-display: none;
}
body.elementor-page-3954:not(.elementor-motion-effects-element-type-background),
body.elementor-page-3954
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-f198032
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f198032
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-0f9fe24
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-205a0be
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-205a0be
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-3bcf3af
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3bcf3af
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-3bcf3af:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-3bcf3af
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-3bcf3af {
    margin-top: -90px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-563af5c
     .elementor-container {
    min-height: 700px;
  }
  .custom-css-style
    .elementor-element.elementor-element-563af5c
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-563af5c {
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0402a64
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ff26c41
    .elementor-spacer-inner {
    height: 650px;
  }
  .custom-css-style
    .elementor-element.elementor-element-43fe0ae
     .elementor-element-populated {
    margin: 0;
    padding: 120px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-127140c
     .elementor-widget-container {
    margin: 0 0 0 -120px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1005988
     .elementor-widget-container {
    margin: 0 0 10px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-79b23c8
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-79b23c8
     .elementor-widget-container {
    margin: 0 0 0 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1af6dc3
     .elementor-widget-container {
    margin: 20px 0 0 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8ed920b
     .elementor-widget-container {
    margin: 40px 0 0 60px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-1e7a162 {
    right: -18%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-1e7a162 {
    left: -18%;
  }
  .custom-css-style .elementor-element.elementor-element-1e7a162 {
    top: -14%;
  }
  .custom-css-style .elementor-element.elementor-element-a22753f {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-78a07df
     .elementor-element-populated {
    margin: 0 30px 0 30px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-f198032
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f198032
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-0f9fe24
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-205a0be
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3bcf3af
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3bcf3af
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-3bcf3af:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-3bcf3af
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-3bcf3af {
    margin-top: -40px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f740045
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-563af5c
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-563af5c
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-563af5c {
    margin-top: -40px;
    margin-bottom: 0;
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ff26c41
    .elementor-spacer-inner {
    height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-43fe0ae
     .elementor-element-populated {
    margin: 40px 20px 0 20px;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1005988
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-79b23c8 {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-79b23c8
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-79b23c8
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1af6dc3
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8ed920b
     .elementor-widget-container {
    margin: 20px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-a22753f {
    margin-top: 0;
    margin-bottom: 0;
    padding: 50px 0 0 0;
  }
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-0402a64 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-43fe0ae {
    width: 60%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-4923803
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-4923803
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-4923803:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-4923803
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/barista-hand-pours-beverage-from-coffee-machine-BASWJ45.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-4923803
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-4923803 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-dc17e4f
   .elementor-element-populated {
  margin: 100px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-a5bf6ba {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-a5bf6ba
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
}
.custom-css-style
  .elementor-element.elementor-element-a5bf6ba
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-4173186 {
  color: #fff;
  font-size: 18px;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-4173186
   .elementor-widget-container {
  margin: -20px 0 0 0;
  padding: 0 55% 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-41f3271
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-41f3271
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-41f3271:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-41f3271
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-41f3271 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-41f3271
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-c1b689a
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-aa8ce85
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-a6a28d0 {
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  padding: 60px 0 40px 0;
}
.custom-css-style
  .elementor-element.elementor-element-a4153b9.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-a4153b9
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3c6bd53
  .elementor-image
  img {
  width: 260px;
}
.custom-css-style .elementor-element.elementor-element-3c6bd53 {
  width: 100%;
  max-width: 100%;
  top: 1.891%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-3c6bd53 {
  left: 40.342%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-3c6bd53 {
  right: 40.342%;
}
.custom-css-style
  .elementor-element.elementor-element-e613625
  .elementor-image
  img {
  width: 240px;
}
.custom-css-style .elementor-element.elementor-element-e613625 {
  width: 100%;
  max-width: 100%;
  top: -18%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-e613625 {
  left: -42%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-e613625 {
  right: -42%;
}
.custom-css-style .elementor-element.elementor-element-f112e18 {
  text-align: right;
  width: auto;
  max-width: auto;
  top: -13%;
}
.custom-css-style
  .elementor-element.elementor-element-f112e18
  .elementor-heading-title {
  font-size: 80px;
  font-weight: 900;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-f112e18 {
  right: 12%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-f112e18 {
  left: 12%;
}
.custom-css-style .elementor-element.elementor-element-f287cf5 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-f287cf5
  .elementor-heading-title {
  font-size: 36px;
  line-height: 1.4em;
}
.custom-css-style
  .elementor-element.elementor-element-f287cf5
   .elementor-widget-container {
  margin: 0;
  padding: 0 15% 0 15%;
}
.custom-css-style .elementor-element.elementor-element-ff8dff2 {
  --divider-border-style: solid;
  --divider-color: #c7a17a;
  --divider-border-width: 1px;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-ff8dff2
  .elementor-divider-separator {
  width: 90px;
  margin: 0 auto;
  margin-center: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ff8dff2
  .elementor-divider {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-ff8dff2
   .elementor-widget-container {
  margin: 40px 0 40px 0;
}
.elementor-element.elementor-element-ff8dff2 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-ff8dff2 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7486afc
  .elementor-image
  img {
  width: 250px;
  filter: brightness(0) contrast(100%) saturate(100%) blur(0) hue-rotate(0deg);
}
.custom-css-style
  .elementor-element.elementor-element-7486afc
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-c76bd2b {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-c76bd2b
  .elementor-heading-title {
  color: #000;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-c76bd2b
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-042750e
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-042750e
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-042750e:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-042750e
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/bg-header-overlay-lg-2.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-042750e {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-042750e
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-e30bedf
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-797cff3
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5ddcf91
   .elementor-container {
  min-height: 900px;
}
.custom-css-style
  .elementor-element.elementor-element-5ddcf91
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style .elementor-element.elementor-element-5ddcf91 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5ddcf91:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-5ddcf91
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-5ddcf91
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-c1d79de
   .elementor-element-populated {
  padding: 0 0 0 100px;
}
.custom-css-style .elementor-element.elementor-element-ac54be6 {
  z-index: 0;
  width: 100%;
  max-width: 100%;
  bottom: -37%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-ac54be6 {
  left: -4%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-ac54be6 {
  right: -4%;
}
.custom-css-style
  .elementor-element.elementor-element-14c202f
  .elementor-heading-title {
  color: #fff;
  font-size: 100px;
  line-height: 0.9em;
}
.custom-css-style .elementor-element.elementor-element-14c202f {
  z-index: 2;
  bottom: -51%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-14c202f {
  right: 22%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-14c202f {
  left: 22%;
}
.custom-css-style
  .elementor-element.elementor-element-e99994a
   .elementor-element-populated {
  padding: 170px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-c36a487 {
  --divider-border-style: solid;
  --divider-color: #c7a17a;
  --divider-border-width: 1px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-c36a487
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c36a487
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-c36a487
   .elementor-widget-container {
  margin: 0 0 0 -85px;
}
.elementor-element.elementor-element-c36a487 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-c36a487 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ec6d718
  .elementor-heading-title {
  color: #c7a17a;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-ec6d718
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-ec6d718 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-79fd4aa {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-79fd4aa
   .elementor-widget-container {
  margin: 20px 0 0 105px;
}
.custom-css-style
  .elementor-element.elementor-element-6d858a9:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-6d858a9
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-6d858a9 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0 0 140px 0;
}
.custom-css-style
  .elementor-element.elementor-element-6d858a9
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-b478d88
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-75bf935
  .elementor-image
  img {
  width: 75%;
}
.custom-css-style
  .elementor-element.elementor-element-75bf935
   .elementor-widget-container {
  margin: -40px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-516b825
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6b2d466
  .elementor-image
  img {
  width: 80%;
}
.custom-css-style
  .elementor-element.elementor-element-6b2d466
   .elementor-widget-container {
  margin: -180px 0 0 40px;
}
.custom-css-style
  .elementor-element.elementor-element-1dc1c7a
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-1dc1c7a
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-1dc1c7a:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-1dc1c7a
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-1dc1c7a {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1dc1c7a
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-dc67c05
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-bb3d0af
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-0d34fa2 {
  padding: 60px 0 160px 0;
}
.custom-css-style
  .elementor-element.elementor-element-724381c
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6dcc3ab
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4b25ca4
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-4b25ca4
   .elementor-widget-container {
  margin: 0 0 0 40px;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #c7a17a;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-4b25ca4 {
  left: 0;
}
body.rtl .custom-css-style .elementor-element.elementor-element-4b25ca4 {
  right: 0;
}
.custom-css-style .elementor-element.elementor-element-4b25ca4 {
  top: 0;
}
.custom-css-style
  .elementor-element.elementor-element-64877cb
  .elementor-image
  img {
  width: 230px;
}
.custom-css-style .elementor-element.elementor-element-64877cb {
  width: 100%;
  max-width: 100%;
  bottom: 48%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-64877cb {
  left: -28%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-64877cb {
  right: -28%;
}
.custom-css-style
  .elementor-element.elementor-element-6086ff0
  .elementor-heading-title {
  font-size: 55px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-6086ff0
   .elementor-widget-container {
  margin: 50% 10% 0 0;
}
.custom-css-style .elementor-element.elementor-element-66c75e2 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-66c75e2
  .elementor-heading-title {
  color: #000;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-66c75e2
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-25bd1c1:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-25bd1c1
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-25bd1c1
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-25bd1c1
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-3577726 {
  width: 470px;
  max-width: 470px;
  top: -11%;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-f17e985.elementor-column
  .elementor-column-wrap {
  align-items: flex-start;
}
.custom-css-style
  .elementor-element.elementor-element-f17e985.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: flex-start;
  align-items: flex-start;
}
.custom-css-style
  .elementor-element.elementor-element-f17e985:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-f17e985
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-f17e985
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 80px 0 0 0;
  padding: 60px 50px 60px 50px;
}
.custom-css-style
  .elementor-element.elementor-element-f17e985
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-f17e985 {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-e3d5cfd
  .elementor-heading-title {
  color: #fff;
  font-size: 40px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-e3d5cfd
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-28b3844 {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-28b3844
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-2f6c860
  .elementor-button {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #c7a17a;
  color: #c7a17a;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c7a17a;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2f6c860
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-2f6c860
  .elementor-button:hover {
  color: #fff;
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-2f6c860
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-2f6c860
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2f6c860
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-2f6c860 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-a7b6d89
  .elementor-image
  img {
  width: 110px;
}
.custom-css-style .elementor-element.elementor-element-a7b6d89 {
  width: 100%;
  max-width: 100%;
  top: 59%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-a7b6d89 {
  left: 78%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-a7b6d89 {
  right: 78%;
}
:root {
  --page-title-display: none;
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-25bd1c1 {
    width: 25%;
  }
  .custom-css-style .elementor-element.elementor-element-f17e985 {
    width: 41.332%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-4923803
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4923803
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-a5bf6ba
    .elementor-heading-title {
    font-size: 80px;
  }
  .custom-css-style .elementor-element.elementor-element-4173186 {
    font-size: 16px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4173186
     .elementor-widget-container {
    padding: 0 40% 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-41f3271
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-41f3271
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-41f3271:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-41f3271
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-41f3271 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-a6a28d0 {
    padding: 60px 0 100px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-3c6bd53
    .elementor-image
    img {
    width: 179px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e613625
    .elementor-image
    img {
    width: 154px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f287cf5
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ff8dff2
     .elementor-widget-container {
    margin: 0 0 0 -100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7486afc
     .elementor-widget-container {
    margin: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-042750e
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-042750e
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-042750e:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-042750e
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-042750e {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5ddcf91
     .elementor-container {
    min-height: 700px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5ddcf91
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-5ddcf91 {
    padding: 0 0 80px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c1d79de
     .elementor-element-populated {
    padding: 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-ac54be6 {
    left: 14%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-ac54be6 {
    right: 14%;
  }
  .custom-css-style .elementor-element.elementor-element-ac54be6 {
    bottom: 17%;
  }
  .custom-css-style
    .elementor-element.elementor-element-14c202f
    .elementor-heading-title {
    font-size: 70px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-14c202f {
    right: 4%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-14c202f {
    left: 4%;
  }
  .custom-css-style .elementor-element.elementor-element-14c202f {
    bottom: 9%;
  }
  .custom-css-style
    .elementor-element.elementor-element-e99994a
     .elementor-element-populated {
    margin: 0;
    padding: 120px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c36a487
     .elementor-widget-container {
    margin: 0 0 0 -100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-79fd4aa
     .elementor-widget-container {
    margin: 20px 0 0 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6b2d466
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1dc1c7a
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1dc1c7a
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-1dc1c7a:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-1dc1c7a
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-1dc1c7a {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-0d34fa2 {
    padding: 60px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-4b25ca4
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4b25ca4
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-64877cb
    .elementor-image
    img {
    width: 220px;
  }
  .custom-css-style .elementor-element.elementor-element-64877cb {
    bottom: 36%;
  }
  .custom-css-style
    .elementor-element.elementor-element-6086ff0
    .elementor-heading-title {
    font-size: 42px;
  }
  .custom-css-style .elementor-element.elementor-element-3577726 {
    width: 350px;
    max-width: 350px;
    top: -6%;
  }
  .custom-css-style
    .elementor-element.elementor-element-f17e985
     .elementor-element-populated {
    margin: 250px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-28b3844
     .elementor-widget-container {
    margin: 20px 40px 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-2f6c860 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-a7b6d89
    .elementor-image
    img {
    width: 100px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-a7b6d89 {
    left: -74%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-a7b6d89 {
    right: -74%;
  }
  .custom-css-style .elementor-element.elementor-element-a7b6d89 {
    top: 46%;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-4923803
     .elementor-container {
    min-height: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4923803
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-dc17e4f.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-dc17e4f
     .elementor-element-populated {
    margin: 120px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-a5bf6ba {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-a5bf6ba
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4173186
    .elementor-text-editor {
    text-align: center;
  }
  .custom-css-style .elementor-element.elementor-element-4173186 {
    font-size: 16px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4173186
     .elementor-widget-container {
    margin: -10px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-41f3271
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-41f3271
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-41f3271:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-41f3271
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-41f3271 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-aa8ce85
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-a6a28d0 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a4153b9
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f112e18
    .elementor-heading-title {
    font-size: 60px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-f112e18 {
    right: 2%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-f112e18 {
    left: 2%;
  }
  .custom-css-style .elementor-element.elementor-element-f112e18 {
    top: -15%;
  }
  .custom-css-style
    .elementor-element.elementor-element-f287cf5
    .elementor-heading-title {
    font-size: 24px;
  }
  .custom-css-style
    .elementor-element.elementor-element-042750e
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-042750e
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-042750e:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-042750e
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-042750e {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-797cff3
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5ddcf91
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5ddcf91
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-5ddcf91 {
    padding: 100px 0 0 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-ac54be6 {
    left: -12%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-ac54be6 {
    right: -12%;
  }
  .custom-css-style .elementor-element.elementor-element-ac54be6 {
    bottom: -200%;
  }
  .custom-css-style .elementor-element.elementor-element-14c202f {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-14c202f
    .elementor-heading-title {
    font-size: 50px;
    line-height: 0.9em;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-14c202f {
    right: -6%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-14c202f {
    left: -6%;
  }
  .custom-css-style
    .elementor-element.elementor-element-e99994a
     .elementor-element-populated {
    margin: 0 20px 0 20px;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ec6d718
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-79fd4aa
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-75bf935
    .elementor-image
    img {
    width: 80%;
  }
  .custom-css-style
    .elementor-element.elementor-element-75bf935
     .elementor-widget-container {
    margin: 60px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6b2d466
    .elementor-image
    img {
    width: 80%;
  }
  .custom-css-style
    .elementor-element.elementor-element-6b2d466
     .elementor-widget-container {
    margin: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1dc1c7a
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1dc1c7a
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-1dc1c7a:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-1dc1c7a
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-1dc1c7a {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-bb3d0af
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-0d34fa2 {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-4b25ca4
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-64877cb {
    left: 10%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-64877cb {
    right: 10%;
  }
  .custom-css-style .elementor-element.elementor-element-64877cb {
    bottom: 24%;
  }
  .custom-css-style
    .elementor-element.elementor-element-6086ff0
     .elementor-widget-container {
    margin: 120px 20px 0 20px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-66c75e2
     .elementor-widget-container {
    margin: 0 0 60px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-25bd1c1.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-25bd1c1
     .elementor-element-populated {
    padding: 0 0 350px 0;
  }
  .custom-css-style .elementor-element.elementor-element-3577726 {
    width: 320px;
    max-width: 320px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f17e985
     .elementor-element-populated {
    margin: 130px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-28b3844
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-25bd1c1 {
    width: 10%;
  }
  .custom-css-style .elementor-element.elementor-element-f17e985 {
    width: 55%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-201413e
   .elementor-container {
  min-height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-201413e
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-201413e:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-201413e
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-201413e {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 95px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-201413e
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-35b833f
   .elementor-element-populated {
  padding: 140px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-35b833f {
  z-index: 2;
}
.custom-css-style .elementor-element.elementor-element-f0edd35 {
  text-align: left;
  z-index: 3;
  width: 100%;
  max-width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-f0edd35
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.custom-css-style .elementor-element.elementor-element-fcdb6b4 {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-fcdb6b4
  .elementor-heading-title {
  color: #fff;
  font-size: 80px;
}
.custom-css-style
  .elementor-element.elementor-element-fcdb6b4
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ae2141d
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ae2141d
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-bae5268 {
  width: 210%;
  max-width: 210%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-bae5268 {
  left: -87%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-bae5268 {
  right: -87%;
}
.custom-css-style
  .elementor-element.elementor-element-c1b50f6:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-c1b50f6
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-c1b50f6 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 0 100px 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-c1b50f6
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-f2afcc2
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-dd8589e
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-41843a8
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-41843a8
   .elementor-widget-container {
  margin: 0 0 0 40px;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #c7a17a;
}
.elementor-element.elementor-element-41843a8 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-41843a8 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-41843a8 {
  width: auto;
  max-width: auto;
  top: 0;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-41843a8 {
  left: 0;
}
body.rtl .custom-css-style .elementor-element.elementor-element-41843a8 {
  right: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c961c6a
  .elementor-image
  img {
  width: 230px;
  opacity: 0.3;
  filter: brightness(200%) contrast(0) saturate(100%) blur(0) hue-rotate(0deg);
}
.custom-css-style .elementor-element.elementor-element-c961c6a {
  width: 100%;
  max-width: 100%;
  bottom: 48%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-c961c6a {
  left: 43%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-c961c6a {
  right: 43%;
}
.custom-css-style
  .elementor-element.elementor-element-576781b
  .elementor-heading-title {
  color: #fff;
  font-size: 55px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-576781b
   .elementor-widget-container {
  margin: 50% 10% 0 0;
}
.custom-css-style .elementor-element.elementor-element-e4b17af {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-e4b17af
  .elementor-heading-title {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-e4b17af
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-c4b3e0e.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-c4b3e0e.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-c4b3e0e:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-c4b3e0e
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-c4b3e0e
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0 15% 0 15%;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c4b3e0e
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-2e3ca8b {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-2e3ca8b
   .elementor-widget-container {
  margin: 60px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-9c3c4e5:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-9c3c4e5
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-9c3c4e5 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9c3c4e5
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-969784d
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7343d0f
  .service-grid-wrapper {
  height: 550px;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7343d0f
  .service-grid-wrapper
  img {
  max-width: 50px !important;
}
.custom-css-style
  .elementor-element.elementor-element-7343d0f
  .service-grid-wrapper
  i {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7343d0f
  .service-grid-wrapper
  .overflow-inner
  .header-wrap
  h2 {
  color: #fff;
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-7343d0f
  .service-grid-wrapper
  .overflow-inner
  .hover-content {
  color: #fff;
}
.custom-css-style .elementor-element.elementor-element-4859cb4 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 120px 0 140px 0;
}
.custom-css-style
  .elementor-element.elementor-element-4859cb4:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-4859cb4
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-4859cb4
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-11a6017
   .elementor-element-populated {
  margin: 0 60px 0 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-212592f
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style
  .elementor-element.elementor-element-46c5d3e
  .elementor-heading-title {
  color: #fff;
  font-size: 90px;
  line-height: 0.8em;
}
.custom-css-style .elementor-element.elementor-element-46c5d3e {
  z-index: 2;
  top: 3%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-46c5d3e {
  left: 8%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-46c5d3e {
  right: 8%;
}
.custom-css-style
  .elementor-element.elementor-element-dc9689d
   .elementor-element-populated {
  margin: 0 0 0 20px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-73ad331
   .elementor-widget-container {
  margin: 0 0 0 40px;
}
.custom-css-style
  .elementor-element.elementor-element-3bc55cc
  .elementor-heading-title {
  color: #fff;
  font-size: 90px;
  line-height: 0.9em;
}
.custom-css-style .elementor-element.elementor-element-3bc55cc {
  z-index: 2;
  bottom: -10%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-3bc55cc {
  right: -14%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-3bc55cc {
  left: -14%;
}
.custom-css-style
  .elementor-element.elementor-element-8f52c37
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-1e47063 .eb-map {
  height: 600px;
}
.custom-css-style .elementor-element.elementor-element-296adfe {
  text-align: left;
  width: auto;
  max-width: auto;
  top: 25%;
}
.custom-css-style
  .elementor-element.elementor-element-296adfe
  .elementor-heading-title {
  color: #fff;
  font-size: 100px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-296adfe
   .elementor-widget-container {
  margin: 0;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-296adfe {
  left: 5%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-296adfe {
  right: 5%;
}
.custom-css-style
  .elementor-element.elementor-element-5de5bae
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #c7a17a;
  color: #c7a17a;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c7a17a;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5de5bae
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-5de5bae
  .elementor-button:hover {
  color: #fff;
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-5de5bae
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-5de5bae
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-5de5bae
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-5de5bae {
  width: auto;
  max-width: auto;
  top: 58%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-5de5bae {
  left: 4%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-5de5bae {
  right: 4%;
}
:root {
  --page-title-display: none;
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-35b833f {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-ae2141d {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-dd8589e {
    width: 30%;
  }
  .custom-css-style .elementor-element.elementor-element-c4b3e0e {
    width: 70%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-201413e
     .elementor-container {
    min-height: 460px;
  }
  .custom-css-style
    .elementor-element.elementor-element-201413e
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-201413e {
    padding: 70px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-35b833f
     .elementor-element-populated {
    margin: 0;
    padding: 100px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f0edd35
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fcdb6b4
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ae2141d
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-c1b50f6 {
    padding: 0 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-41843a8
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-41843a8
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  .custom-css-style .elementor-element.elementor-element-c961c6a {
    bottom: 31%;
  }
  .custom-css-style
    .elementor-element.elementor-element-576781b
    .elementor-heading-title {
    font-size: 42px;
  }
  .custom-css-style
    .elementor-element.elementor-element-576781b
     .elementor-widget-container {
    margin: 120px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c4b3e0e
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2e3ca8b
     .elementor-widget-container {
    margin: 100px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7343d0f
    .service-grid-wrapper
    img {
    max-width: 45px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-7343d0f
    .service-grid-wrapper
    .overflow-inner
    .header-wrap
    h2 {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-4859cb4 {
    padding: 100px 0 100px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-11a6017
     .elementor-element-populated {
    margin: 0 20px 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-46c5d3e
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-dc9689d
     .elementor-element-populated {
    margin: 0 0 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-73ad331
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-3bc55cc
    .elementor-heading-title {
    font-size: 70px;
    line-height: 0.9em;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-3bc55cc {
    right: 4%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-3bc55cc {
    left: 4%;
  }
  .custom-css-style .elementor-element.elementor-element-3bc55cc {
    bottom: 9%;
  }
  .custom-css-style .elementor-element.elementor-element-1e47063 .eb-map {
    height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-296adfe
    .elementor-heading-title {
    font-size: 70px;
    line-height: 1em;
  }
  .custom-css-style .elementor-element.elementor-element-5de5bae {
    width: auto;
    max-width: auto;
    top: 55%;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-201413e
     .elementor-container {
    min-height: 800px;
  }
  .custom-css-style
    .elementor-element.elementor-element-201413e
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-201413e {
    padding: 140px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-35b833f
     .elementor-element-populated {
    margin: 40px 20px 0 20px;
    padding: 20px 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f0edd35
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fcdb6b4
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style .elementor-element.elementor-element-c1b50f6 {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-41843a8
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-c961c6a {
    left: 10%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-c961c6a {
    right: 10%;
  }
  .custom-css-style .elementor-element.elementor-element-c961c6a {
    bottom: 24%;
  }
  .custom-css-style
    .elementor-element.elementor-element-576781b
     .elementor-widget-container {
    margin: 120px 20px 0 20px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e4b17af
     .elementor-widget-container {
    margin: 10px 0 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c4b3e0e.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-c4b3e0e
     .elementor-element-populated {
    margin: 0 20px 0 20px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2e3ca8b
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-9c3c4e5 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-969784d
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7343d0f
    .service-grid-wrapper
    img {
    max-width: 50px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-7343d0f
    .service-grid-wrapper
    .overflow-inner
    .header-wrap
    h2 {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-4859cb4 {
    padding: 0 0 80px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-11a6017
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-212592f
    .elementor-image
    img {
    width: 80%;
  }
  .custom-css-style
    .elementor-element.elementor-element-212592f
     .elementor-widget-container {
    margin: 60px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-46c5d3e {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-46c5d3e
    .elementor-heading-title {
    font-size: 60px;
    line-height: 0.9em;
  }
  .custom-css-style
    .elementor-element.elementor-element-dc9689d
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-73ad331
    .elementor-image
    img {
    width: 80%;
  }
  .custom-css-style
    .elementor-element.elementor-element-73ad331
     .elementor-widget-container {
    margin: 40px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-3bc55cc {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-3bc55cc
    .elementor-heading-title {
    font-size: 60px;
    line-height: 0.9em;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-3bc55cc {
    right: -6%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-3bc55cc {
    left: -6%;
  }
  .custom-css-style .elementor-element.elementor-element-1e47063 .eb-map {
    height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-296adfe
    .elementor-heading-title {
    font-size: 50px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-dd8589e {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-c4b3e0e {
    width: 60%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-7817036
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-7817036
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-7817036:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-7817036
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/aerial-view-of-various-coffee-P7X5ZDW.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-7817036
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-7817036 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-1ec019d
   .elementor-element-populated {
  margin: 60px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-74dd2a2 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-74dd2a2
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-74dd2a2 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-74dd2a2 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-999231e {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-999231e
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-999231e
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-999231e {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-999231e {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ac83152
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-ac83152
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-ac83152:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-ac83152
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-ac83152 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ac83152
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-0a8ccdd
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c63482c
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-725040bb {
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  padding: 20px 0 80px 0;
}
.custom-css-style
  .elementor-element.elementor-element-70495302
   .elementor-element-populated {
  margin: 0 10% 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-51693a15 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-51693a15
  .elementor-heading-title {
  font-size: 30px;
  line-height: 1.3em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-51693a15
   .elementor-widget-container {
  margin: 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-667baa1b {
  margin-top: 20px;
  margin-bottom: 5px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-3d509a15.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-3d509a15.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-319de458
  .elementor-heading-title {
  font-size: 16px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-3088cd1f.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-3088cd1f.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style .elementor-element.elementor-element-40ad7680 {
  text-align: right;
}
.custom-css-style .elementor-element.elementor-element-e0f8070 {
  --divider-border-style: solid;
  --divider-color: #000000;
  --divider-border-width: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-e0f8070
  .elementor-divider-separator {
  width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-e0f8070
  .elementor-divider {
  padding-top: 0;
  padding-bottom: 0;
}
.custom-css-style .elementor-element.elementor-element-641d043c {
  margin-top: 5px;
  margin-bottom: 5px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-f74d244.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-f74d244.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-564ee92e
  .elementor-heading-title {
  font-size: 16px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-37abba3.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-37abba3.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style .elementor-element.elementor-element-64021edb {
  text-align: right;
}
.custom-css-style .elementor-element.elementor-element-246661e7 {
  --divider-border-style: solid;
  --divider-color: #000000;
  --divider-border-width: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-246661e7
  .elementor-divider-separator {
  width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-246661e7
  .elementor-divider {
  padding-top: 0;
  padding-bottom: 0;
}
.custom-css-style .elementor-element.elementor-element-50132018 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-1144689e.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-1144689e.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-52813759
  .elementor-heading-title {
  font-size: 16px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-97842fe.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-97842fe.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style .elementor-element.elementor-element-69dc5685 {
  text-align: right;
}
.custom-css-style .elementor-element.elementor-element-33003b81 {
  --divider-border-style: solid;
  --divider-color: #000000;
  --divider-border-width: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-33003b81
  .elementor-divider-separator {
  width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-33003b81
  .elementor-divider {
  padding-top: 0;
  padding-bottom: 0;
}
.custom-css-style .elementor-element.elementor-element-197fa6bd {
  margin-top: 60px;
  margin-bottom: 0;
}
.custom-css-style .elementor-element.elementor-element-32067a3 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-32067a3
  .elementor-heading-title {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.custom-css-style
  .elementor-element.elementor-element-23973719
   .elementor-widget-container {
  padding: 0 40px 20px 0;
}
.custom-css-style .elementor-element.elementor-element-884f6b9 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-884f6b9
  .elementor-heading-title {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.custom-css-style
  .elementor-element.elementor-element-1e185e5
  .elementor-heading-title {
  color: #c7a17a;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-1e185e5
   .elementor-widget-container {
  margin: 15px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-4aa8ee25 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-4aa8ee25
  .elementor-heading-title {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.custom-css-style
  .elementor-element.elementor-element-9e36a08
   .elementor-widget-container {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f44674b
   .elementor-widget-container {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-376f5883:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-376f5883
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-376f5883
   .elementor-background-slideshow,
.custom-css-style
  .elementor-element.elementor-element-376f5883
   .elementor-element-populated,
.custom-css-style
  .elementor-element.elementor-element-376f5883
   .elementor-element-populated
   .elementor-background-overlay {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-376f5883
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0;
  padding: 20px 20px 20px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-376f5883
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-376f5883 {
  z-index: 3;
}
.custom-css-style .elementor-element.elementor-element-a0c2942 {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 40px 30px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-a0c2942
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-e3a3e0a
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0b0ac16
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-dcea9c1 .eb-map {
  height: 600px;
}
:root {
  --page-title-display: none;
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-70495302 {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-376f5883 {
    width: 40%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-7817036
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7817036
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-74dd2a2
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-999231e
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ac83152
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ac83152
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-ac83152:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-ac83152
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-ac83152 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-725040bb {
    margin-top: 0;
    margin-bottom: 80px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-70495302
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-51693a15
    .elementor-heading-title {
    font-size: 24px;
  }
  .custom-css-style .elementor-element.elementor-element-197fa6bd {
    margin-top: 40px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-48c82ee
     .elementor-element-populated {
    padding: 0 0 40px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-32067a3
    .elementor-heading-title {
    line-height: 1.4em;
  }
  .custom-css-style
    .elementor-element.elementor-element-884f6b9
    .elementor-heading-title {
    line-height: 1.4em;
  }
  .custom-css-style
    .elementor-element.elementor-element-1e185e5
    .elementor-heading-title {
    font-size: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6afca6ae
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-4aa8ee25
    .elementor-heading-title {
    line-height: 1.4em;
  }
  .custom-css-style
    .elementor-element.elementor-element-376f5883
     .elementor-element-populated {
    margin: 20px 0 0 0;
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style .elementor-element.elementor-element-a0c2942 {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style .elementor-element.elementor-element-dcea9c1 .eb-map {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-7817036
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7817036
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-74dd2a2
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-999231e
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-999231e
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ac83152
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ac83152
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-ac83152:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-ac83152
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-ac83152 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c63482c
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-725040bb {
    margin-top: 0;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-70495302
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-3d509a15 {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-3088cd1f {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-f74d244 {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-37abba3 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-1144689e {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-97842fe {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-197fa6bd {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-48c82ee
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6afca6ae
     .elementor-element-populated {
    margin: 10px 0 0 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-376f5883
     .elementor-element-populated {
    margin: 0 0 60px 0;
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style .elementor-element.elementor-element-a0c2942 {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e3a3e0a
     .elementor-element-populated {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style .elementor-element.elementor-element-dcea9c1 .eb-map {
    height: 400px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-70495302 {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-48c82ee {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-6afca6ae {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-376f5883 {
    width: 100%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-77e82ca
   .elementor-container {
  min-height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-77e82ca
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-77e82ca:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-77e82ca
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/jonathan-borba-O2fAWP7uDSQ-unsplash.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-77e82ca
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-77e82ca {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-e217d06
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-14a7eb0 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-14a7eb0
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-14a7eb0 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-14a7eb0 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-d456784 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-d456784
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-d456784
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-d456784 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-d456784 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b8ef0ad
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-b8ef0ad
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-b8ef0ad:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-b8ef0ad
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-black.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-b8ef0ad {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -140px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b8ef0ad
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-1150e70
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b91da62
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3f7f261:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-3f7f261
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-3f7f261 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 0 40px 0;
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-3f7f261
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-b4fd3fa.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-b4fd3fa
   .elementor-element-populated {
  margin: 0;
  padding: 0 60px 0 60px;
}
.custom-css-style
  .elementor-element.elementor-element-24b0ad5
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-24b0ad5
   .elementor-widget-container {
  margin: -130px 0 0 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #c7a17a;
}
.custom-css-style .elementor-element.elementor-element-24b0ad5 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-b1c9a8e {
  text-align: center;
  width: 100%;
  max-width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-b1c9a8e
  .elementor-heading-title {
  color: #c7a17a;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-b1c9a8e
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-3d37993
   .elementor-widget-container {
  margin: 60px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-3d37993 {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-da32670
   .elementor-element-populated {
  margin: -20px 0 0 30px;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-da32670 {
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-3534e8b
  .elementor-heading-title {
  color: #fff;
  font-size: 80px;
  line-height: 1em;
}
.custom-css-style .elementor-element.elementor-element-3534e8b {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-795b5f2
  .elementor-text-editor {
  column-gap: 0;
}
.custom-css-style .elementor-element.elementor-element-795b5f2 {
  color: rgba(255, 255, 255, 0.68);
}
.custom-css-style
  .elementor-element.elementor-element-795b5f2
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-4fbb3b1
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #c7a17a;
  color: #c7a17a;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c7a17a;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4fbb3b1
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-4fbb3b1
  .elementor-button:hover {
  color: #fff;
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-4fbb3b1
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-4fbb3b1
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4fbb3b1
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-4fbb3b1 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-204e1d3
  .elementor-image
  img {
  width: 300px;
  filter: brightness(100%) contrast(200%) saturate(0) blur(0) hue-rotate(0deg);
}
.custom-css-style
  .elementor-element.elementor-element-204e1d3
   .elementor-widget-container {
  margin: 80px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-204e1d3 {
  width: 100%;
  max-width: 100%;
  top: -20%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-204e1d3 {
  left: 60%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-204e1d3 {
  right: 60%;
}
.custom-css-style
  .elementor-element.elementor-element-d63c863
  .elementor-image
  img {
  width: 250px;
  opacity: 0.4;
  filter: brightness(200%) contrast(0) saturate(0) blur(0) hue-rotate(0deg);
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-d63c863 {
  left: -48%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-d63c863 {
  right: -48%;
}
.custom-css-style .elementor-element.elementor-element-d63c863 {
  top: 4%;
}
.custom-css-style
  .elementor-element.elementor-element-1f223fa:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-1f223fa
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-1f223fa {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1f223fa
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-38e6115
   .elementor-element-populated {
  margin: 0 40px 0 0;
  padding: 0 0 0 50px;
}
.custom-css-style
  .elementor-element.elementor-element-3e3a2be
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f725625
   .elementor-element-populated {
  margin: 0 0 0 30px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c53037f
  .elementor-heading-title {
  color: #fff;
  font-size: 60px;
  line-height: 1em;
}
.custom-css-style .elementor-element.elementor-element-c53037f {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-22cc622
  .elementor-text-editor {
  column-gap: 0;
}
.custom-css-style .elementor-element.elementor-element-22cc622 {
  color: rgba(255, 255, 255, 0.68);
}
.custom-css-style
  .elementor-element.elementor-element-22cc622
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-0dbffbd
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #c7a17a;
  color: #c7a17a;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c7a17a;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0dbffbd
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-0dbffbd
  .elementor-button:hover {
  color: #fff;
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-0dbffbd
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-0dbffbd
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-0dbffbd
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-0dbffbd {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-c13589c
   .elementor-element-populated {
  margin: 0 0 0 40px;
  padding: 0 50px 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-2b6c6c0
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d0e6de4
   .elementor-element-populated {
  margin: 0 0 0 30px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1cec951
  .elementor-heading-title {
  color: #fff;
  font-size: 60px;
  line-height: 1em;
}
.custom-css-style .elementor-element.elementor-element-1cec951 {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-1eae37a
  .elementor-text-editor {
  column-gap: 0;
}
.custom-css-style .elementor-element.elementor-element-1eae37a {
  color: rgba(255, 255, 255, 0.68);
}
.custom-css-style
  .elementor-element.elementor-element-1eae37a
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-ed88fbd
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #c7a17a;
  color: #c7a17a;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c7a17a;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ed88fbd
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-ed88fbd
  .elementor-button:hover {
  color: #fff;
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-ed88fbd
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-ed88fbd
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-ed88fbd
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-ed88fbd {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-e614d2b:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-e614d2b
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-e614d2b {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0 0 60px 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-e614d2b
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-e738070.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-0783352
  .elementor-spacer-inner {
  height: 200px;
}
.custom-css-style
  .elementor-element.elementor-element-0783352
   .elementor-widget-container {
  margin: -60px 0 0 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #c7a17a;
}
.custom-css-style .elementor-element.elementor-element-0783352 {
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-2f7873d {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-2f7873d
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 50px;
  text-transform: none;
}
.custom-css-style
  .elementor-element.elementor-element-2f7873d
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.elementor-element.elementor-element-2f7873d {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-2f7873d {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-6bf87e9 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-6bf87e9
  .elementor-heading-title {
  color: #fff;
  font-size: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-6bf87e9
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-6bf87e9 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-6bf87e9 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-d3354f7 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-d3354f7
  .elementor-heading-title {
  color: #fff;
  font-size: 30px;
  line-height: 1.3em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-d3354f7
   .elementor-widget-container {
  margin: 0 25% 0 25%;
  padding: 40px 0 40px 0;
}
.custom-css-style
  .elementor-element.elementor-element-365c617
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.05);
  font-size: 300px;
}
.custom-css-style .elementor-element.elementor-element-365c617 {
  width: 500vw;
  max-width: 500vw;
  top: 10.268%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-365c617 {
  left: 14.833%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-365c617 {
  right: 14.833%;
}
.custom-css-style
  .elementor-element.elementor-element-8ed104a
   .elementor-widget-container {
  margin: 0 25% 0 25%;
}
:root {
  --page-title-display: none;
}
body.elementor-page-4149:not(.elementor-motion-effects-element-type-background),
body.elementor-page-4149
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-77e82ca
     .elementor-container {
    min-height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-77e82ca
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-14a7eb0
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d456784
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8ef0ad
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8ef0ad
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8ef0ad:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-b8ef0ad
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-b8ef0ad {
    margin-top: -75px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b4fd3fa
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-24b0ad5
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-24b0ad5
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-4fc65ac {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-3534e8b
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style .elementor-element.elementor-element-4fbb3b1 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style .elementor-element.elementor-element-204e1d3 {
    top: -14%;
  }
  .custom-css-style
    .elementor-element.elementor-element-d63c863
    .elementor-image
    img {
    width: 210px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-d63c863 {
    left: -34%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-d63c863 {
    right: -34%;
  }
  .custom-css-style .elementor-element.elementor-element-d63c863 {
    top: 5%;
  }
  .custom-css-style .elementor-element.elementor-element-1f223fa {
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-38e6115
     .elementor-element-populated {
    margin: 0 20px 0 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c53037f
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style .elementor-element.elementor-element-0dbffbd {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-c13589c
     .elementor-element-populated {
    margin: 0 0 0 20px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1cec951
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style .elementor-element.elementor-element-ed88fbd {
    width: auto;
    max-width: auto;
  }
  .custom-css-style .elementor-element.elementor-element-e614d2b {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0 80px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e738070
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0783352
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0783352
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2f7873d
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6bf87e9
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d3354f7
    .elementor-heading-title {
    font-size: 24px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d3354f7
     .elementor-widget-container {
    margin: 0 40px 0 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-365c617
    .elementor-heading-title {
    font-size: 200px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8ed104a
     .elementor-widget-container {
    margin: 0 40px 0 40px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-77e82ca
     .elementor-container {
    min-height: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-77e82ca
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-14a7eb0
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d456784
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8ef0ad
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8ef0ad
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8ef0ad:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-b8ef0ad
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-b8ef0ad {
    margin-top: -40px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b91da62
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-3f7f261 {
    margin-top: -40px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b4fd3fa
     .elementor-element-populated {
    margin: 0 20px 0 20px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-24b0ad5
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b1c9a8e
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-da32670
     .elementor-element-populated {
    margin: 30px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-3534e8b {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-3534e8b
    .elementor-heading-title {
    font-size: 40px;
    line-height: 1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-d63c863
    .elementor-image
    img {
    width: 169px;
  }
  .custom-css-style
    .elementor-element.elementor-element-38e6115
     .elementor-element-populated {
    margin: 40px 20px 20px 20px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f725625
     .elementor-element-populated {
    margin: 30px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-c53037f {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-c53037f
    .elementor-heading-title {
    font-size: 40px;
    line-height: 1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-c13589c
     .elementor-element-populated {
    margin: 60px 20px 80px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d0e6de4
     .elementor-element-populated {
    margin: 30px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-1cec951 {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-1cec951
    .elementor-heading-title {
    font-size: 40px;
    line-height: 1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-0783352
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2f7873d
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6bf87e9
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d3354f7
    .elementor-heading-title {
    line-height: 1.4em;
  }
  .custom-css-style
    .elementor-element.elementor-element-d3354f7
     .elementor-widget-container {
    margin: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-365c617
    .elementor-heading-title {
    font-size: 130px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8ed104a
     .elementor-widget-container {
    margin: 0 20px 0 20px;
  }
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-da32670 {
    width: 90%;
  }
  .custom-css-style .elementor-element.elementor-element-db2cf09 {
    width: 10%;
  }
  .custom-css-style .elementor-element.elementor-element-f725625 {
    width: 90%;
  }
  .custom-css-style .elementor-element.elementor-element-e2d2d19 {
    width: 10%;
  }
  .custom-css-style .elementor-element.elementor-element-d0e6de4 {
    width: 90%;
  }
  .custom-css-style .elementor-element.elementor-element-a3111ca {
    width: 10%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-d7dd32f
   .elementor-container {
  min-height: 690px;
}
.custom-css-style
  .elementor-element.elementor-element-d7dd32f
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-d7dd32f:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-d7dd32f
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/barista-pours-water-from-coffee-pot-into-the-glass-D57H3WU.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-d7dd32f
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-d7dd32f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-8097d67
   .elementor-element-populated {
  margin: 20px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-c3943f2 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-c3943f2
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-c3943f2 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-c3943f2 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-cf04b71 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-cf04b71
  .elementor-heading-title {
  color: #fff;
  font-size: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-cf04b71
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-cf04b71 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-cf04b71 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-0fcccdb {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -100px;
  margin-bottom: 0;
  padding: 30px 0 30px 0;
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-0fcccdb:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-0fcccdb
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #fffbf2;
}
.custom-css-style
  .elementor-element.elementor-element-0fcccdb
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-3487a29
   .elementor-element-populated {
  padding: 20px 50px 20px 40px;
}
.custom-css-style .elementor-element.elementor-element-650ff5b {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-650ff5b
  .elementor-heading-title {
  font-size: 28px;
  line-height: 1.3em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-650ff5b
   .elementor-widget-container {
  margin: 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-eeac618
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-f983ef8
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 40px 20px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-f983ef8
   .elementor-background-slideshow,
.custom-css-style
  .elementor-element.elementor-element-f983ef8
   .elementor-element-populated,
.custom-css-style
  .elementor-element.elementor-element-f983ef8
   .elementor-element-populated
   .elementor-background-overlay {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f983ef8
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-43b2807 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-43b2807
  .elementor-heading-title {
  font-size: 28px;
  line-height: 1.3em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-43b2807
   .elementor-widget-container {
  margin: 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-6da7b6c {
  margin-top: 20px;
  margin-bottom: 5px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-5612ee7.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-5612ee7.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-4cf862e
  .elementor-heading-title {
  font-size: 16px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-f7cba9d.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-f7cba9d.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style .elementor-element.elementor-element-a961c82 {
  text-align: right;
}
.custom-css-style .elementor-element.elementor-element-f0e4c8d {
  --divider-border-style: solid;
  --divider-color: #000000;
  --divider-border-width: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-f0e4c8d
  .elementor-divider-separator {
  width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-f0e4c8d
  .elementor-divider {
  padding-top: 0;
  padding-bottom: 0;
}
.custom-css-style .elementor-element.elementor-element-61e17c5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-609735c.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-609735c.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-1cc8fa3
  .elementor-heading-title {
  font-size: 16px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-9ad601c.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-9ad601c.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style .elementor-element.elementor-element-894b4c1 {
  text-align: right;
}
.custom-css-style .elementor-element.elementor-element-c213088 {
  --divider-border-style: solid;
  --divider-color: #000000;
  --divider-border-width: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-c213088
  .elementor-divider-separator {
  width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-c213088
  .elementor-divider {
  padding-top: 0;
  padding-bottom: 0;
}
.custom-css-style .elementor-element.elementor-element-97f2c8a {
  margin-top: 5px;
  margin-bottom: 5px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-8d5eaea.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-8d5eaea.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-0a3e0c2
  .elementor-heading-title {
  font-size: 16px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-11a2e52.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-11a2e52.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style .elementor-element.elementor-element-5bd0ea8 {
  text-align: right;
}
.custom-css-style .elementor-element.elementor-element-151af3b {
  --divider-border-style: solid;
  --divider-color: #000000;
  --divider-border-width: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-151af3b
  .elementor-divider-separator {
  width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-151af3b
  .elementor-divider {
  padding-top: 0;
  padding-bottom: 0;
}
.custom-css-style .elementor-element.elementor-element-0d600cc {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-0d600cc
  .elementor-heading-title {
  font-size: 28px;
  line-height: 1.3em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-0d600cc
   .elementor-widget-container {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b6abc35
  .elementor-heading-title {
  color: #c7a17a;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-b6abc35
   .elementor-widget-container {
  margin: 15px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-83bcea9
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 40px 20px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-83bcea9
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-d05cd0f {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-d05cd0f
  .elementor-heading-title {
  font-size: 28px;
  line-height: 1.3em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-d05cd0f
   .elementor-widget-container {
  margin: 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7a1b592
  .elementor-button {
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 400;
  fill: #000;
  color: #000;
  background-color: rgba(2, 1, 1, 0);
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7a1b592
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-b9f1277
  .elementor-button {
  font-family: Roboto, Sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  fill: #000;
  color: #000;
  background-color: rgba(2, 1, 1, 0);
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b9f1277
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-c7369dc {
  --divider-border-style: solid;
  --divider-color: #000;
  --divider-border-width: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-c7369dc
  .elementor-divider-separator {
  width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-c7369dc
  .elementor-divider {
  padding-top: 30px;
  padding-bottom: 30px;
}
.custom-css-style .elementor-element.elementor-element-854d4ea {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-854d4ea
  .elementor-heading-title {
  font-size: 28px;
  line-height: 1.3em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-854d4ea
   .elementor-widget-container {
  margin: 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-fcf48e5
   .elementor-widget-container {
  margin: -10px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-b8aaef1 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 0 100px 0;
}
.custom-css-style
  .elementor-element.elementor-element-b8aaef1:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-b8aaef1
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-b8aaef1
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-af24652
   .elementor-element-populated {
  margin: 0 40px 0 40px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-49f82dd
  .elementor-heading-title {
  color: #c7a17a;
  font-size: 70px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-f8d1c04
  .elementor-image
  img {
  width: 300px;
  filter: brightness(100%) contrast(200%) saturate(0) blur(0) hue-rotate(0deg);
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-f8d1c04 {
  right: -214.3px;
}
body.rtl .custom-css-style .elementor-element.elementor-element-f8d1c04 {
  left: -214.3px;
}
.custom-css-style .elementor-element.elementor-element-f8d1c04 {
  top: -27%;
}
.custom-css-style .elementor-element.elementor-element-a9e6b02 {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-a9e6b02
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b44ccfe
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #c7a17a;
  color: #c7a17a;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c7a17a;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b44ccfe
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-b44ccfe
  .elementor-button:hover {
  color: #fff;
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-b44ccfe
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-b44ccfe
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b44ccfe
   .elementor-widget-container {
  margin: 30px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-b44ccfe {
  width: auto;
  max-width: auto;
}
:root {
  --page-title-display: none;
}
body.elementor-page-4212:not(.elementor-motion-effects-element-type-background),
body.elementor-page-4212
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-3487a29 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-f983ef8 {
    width: 30%;
  }
  .custom-css-style .elementor-element.elementor-element-83bcea9 {
    width: 29.332%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-d7dd32f
     .elementor-container {
    min-height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d7dd32f
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-c3943f2
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-cf04b71
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style .elementor-element.elementor-element-0fcccdb {
    padding: 20px 0 40px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-650ff5b
    .elementor-heading-title {
    font-size: 24px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f983ef8
     .elementor-element-populated {
    border-width: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-43b2807
    .elementor-heading-title {
    font-size: 24px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0d600cc
    .elementor-heading-title {
    font-size: 24px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b6abc35
    .elementor-heading-title {
    font-size: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d05cd0f
    .elementor-heading-title {
    font-size: 24px;
  }
  .custom-css-style
    .elementor-element.elementor-element-854d4ea
    .elementor-heading-title {
    font-size: 24px;
  }
  .custom-css-style .elementor-element.elementor-element-b8aaef1 {
    padding: 80px 0 80px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-49f82dd
    .elementor-heading-title {
    font-size: 60px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-f8d1c04 {
    right: -18%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-f8d1c04 {
    left: -18%;
  }
  .custom-css-style .elementor-element.elementor-element-f8d1c04 {
    top: -14%;
  }
  .custom-css-style
    .elementor-element.elementor-element-a9e6b02
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-b44ccfe {
    width: auto;
    max-width: auto;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-d7dd32f
     .elementor-container {
    min-height: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d7dd32f
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-c3943f2
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-cf04b71
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3487a29
     .elementor-element-populated {
    padding: 20px 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f983ef8
     .elementor-element-populated {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style .elementor-element.elementor-element-5612ee7 {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-f7cba9d {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-609735c {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-9ad601c {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-8d5eaea {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-11a2e52 {
    width: 40%;
  }
  .custom-css-style
    .elementor-element.elementor-element-83bcea9
     .elementor-element-populated {
    border-width: 0;
    margin: 20px 0 0 0;
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style .elementor-element.elementor-element-49f82dd {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-49f82dd
    .elementor-heading-title {
    font-size: 50px;
    line-height: 1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-a9e6b02
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-3487a29 {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-f983ef8 {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-83bcea9 {
    width: 50%;
  }
}
.custom-css-style .elementor-element.elementor-element-25463f9 {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3e878ad
   .elementor-element-populated {
  border-style: solid;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3e878ad
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-67d00ef {
  text-align: left;
  z-index: 3;
  top: 20px;
}
.custom-css-style
  .elementor-element.elementor-element-67d00ef
  .elementor-image
  img {
  width: 100px;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-67d00ef {
  left: -1px;
}
body.rtl .custom-css-style .elementor-element.elementor-element-67d00ef {
  right: -1px;
}
.custom-css-style
  .elementor-element.elementor-element-4953ee2
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 15px 0 15px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-4953ee2
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-d254fc0
  .elementor-icon-wrapper {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-d254fc0.elementor-view-stacked
  .elementor-icon {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d254fc0.elementor-view-default
  .elementor-icon,
.custom-css-style
  .elementor-element.elementor-element-d254fc0.elementor-view-framed
  .elementor-icon {
  color: #000;
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d254fc0.elementor-view-default
  .elementor-icon
  svg,
.custom-css-style
  .elementor-element.elementor-element-d254fc0.elementor-view-framed
  .elementor-icon {
  fill: #000;
}
.custom-css-style .elementor-element.elementor-element-d254fc0 .elementor-icon {
  font-size: 20px;
}
.custom-css-style
  .elementor-element.elementor-element-d254fc0
  .elementor-icon
  i,
.custom-css-style
  .elementor-element.elementor-element-d254fc0
  .elementor-icon
  svg {
  transform: rotate(0);
}
.custom-css-style
  .elementor-element.elementor-element-d254fc0
   .elementor-widget-container {
  margin: 5px 15px 0 0;
}
.custom-css-style .elementor-element.elementor-element-d254fc0 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-f161b88
  .elementor-heading-title {
  font-size: 20px;
  line-height: 1em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-f161b88
   .elementor-widget-container {
  margin: 5px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-f161b88 {
  width: auto;
  max-width: auto;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-b19755e.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-b19755e.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-b19755e.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: flex-end;
}
.custom-css-style
  .elementor-element.elementor-element-b19755e
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0 30px 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-b19755e
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-0696ed7 {
  --grid-template-columns: repeat(0, auto);
  --grid-column-gap: 20px;
  --grid-side-margin: 20px;
  --grid-row-gap: 0px;
  --grid-bottom-margin: 0px;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-0696ed7
  .elementor-widget-container {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-0696ed7
  .elementor-social-icon {
  background-color: rgba(2, 1, 1, 0);
  font-size: 20px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0696ed7
  .elementor-social-icon
  i {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-0696ed7
  .elementor-social-icon
  svg {
  fill: #000;
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
  .craftcoffee-search-icon
  a {
  font-size: 18px;
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
  .craftcoffee-search-wrapper
  input {
  width: 450px;
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
  .craftcoffee-search-wrapper
  .input-group
  .input-group-button
  button
  i {
  font-size: 26px;
}
.custom-css-style .elementor-element.elementor-element-b814c61 .autocomplete {
  width: 500px;
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
  .craftcoffee-search-icon
  a:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
  .craftcoffee-search-wrapper {
  background: rgba(0, 0, 0, 0.9);
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
  .craftcoffee-search-wrapper
  .input-group
  input {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
  .craftcoffee-search-wrapper
  .input-group
  input::placeholder {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
  .craftcoffee-search-wrapper
  .input-group {
  border-color: 256, 256, 256, 0.1;
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
  .craftcoffee-search-wrapper
  .input-group
  .input-group-button
  button {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
  .craftcoffee-search-wrapper
  .autocomplete
  li
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
  .autocomplete
  ul {
  background-color: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
  .autocomplete
  li:hover
  a {
  color: #fff;
  background-color: rgba(256, 256, 256, 0.1);
}
.custom-css-style
  .elementor-element.elementor-element-b814c61
   .elementor-widget-container {
  margin: -3px 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-b814c61 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-d63a3b9
  .elementor-icon-wrapper {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-d63a3b9.elementor-view-stacked
  .elementor-icon {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d63a3b9.elementor-view-default
  .elementor-icon,
.custom-css-style
  .elementor-element.elementor-element-d63a3b9.elementor-view-framed
  .elementor-icon {
  color: #000;
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d63a3b9.elementor-view-default
  .elementor-icon
  svg,
.custom-css-style
  .elementor-element.elementor-element-d63a3b9.elementor-view-framed
  .elementor-icon {
  fill: #000;
}
.custom-css-style .elementor-element.elementor-element-d63a3b9 .elementor-icon {
  font-size: 20px;
}
.custom-css-style
  .elementor-element.elementor-element-d63a3b9
  .elementor-icon
  i,
.custom-css-style
  .elementor-element.elementor-element-d63a3b9
  .elementor-icon
  svg {
  transform: rotate(0);
}
.custom-css-style
  .elementor-element.elementor-element-d63a3b9
   .elementor-widget-container {
  margin: 10px 0 0 15px;
}
.custom-css-style .elementor-element.elementor-element-d63a3b9 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-c2484e9,
.custom-css-style
  .elementor-element.elementor-element-c2484e9
   .elementor-background-overlay {
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e4ceae8
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 1px 0;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e4ceae8
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-d81eeec
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e55ab38
   .elementor-element-populated {
  border-style: solid;
  border-width: 1px 0 1px 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 10px 0 10px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-e55ab38
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
   a {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
   li {
  margin: 0 25px 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul.sub-menu
  li
  a {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul.sub-menu
  li {
  padding: 0 20px 0 20px;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul.sub-menu {
  text-align: left;
  border-radius: 0;
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
   a:hover {
  color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  ul
  li
   a:before,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  div
  .nav
  li
   a:before {
  background-color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li.current-menu-parent
  ul
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-ancestor
   a,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-item
   a,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-parent
   a {
  color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li.current-menu-parent
  ul
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-ancestor
   a:before,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-item
   a:before,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-parent
   a:before {
  background-color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  a:hover {
  color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  a:before,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
   a:before {
  background-color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li.current-menu-parent
  ul
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-ancestor
   a,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-item
   a,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-parent
   a,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li.current-menu-parent
  ul
   li.current-menu-item
   a {
  color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li.current-menu-parent
  ul
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-ancestor
   a:before,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-item
   a:before,
.custom-css-style
  .elementor-element.elementor-element-1b26c46
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-parent
   a:before {
  background-color: #d22226;
}
.custom-css-style .elementor-element.elementor-element-1b26c46 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
   a {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
   li {
  margin: 0 25px 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul.sub-menu
  li
  a {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul.sub-menu
  li {
  padding: 0 20px 0 20px;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul.sub-menu {
  text-align: left;
  border-radius: 0;
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
   a:hover {
  color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  ul
  li
   a:before,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  div
  .nav
  li
   a:before {
  background-color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li.current-menu-parent
  ul
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-ancestor
   a,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-item
   a,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-parent
   a {
  color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li.current-menu-parent
  ul
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-ancestor
   a:before,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-item
   a:before,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
   li.current-menu-parent
   a:before {
  background-color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  a:hover {
  color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  a:before,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
   a:before {
  background-color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li.current-menu-parent
  ul
  li.current-menu-item
  a,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-ancestor
   a,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-item
   a,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-parent
   a,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li.current-menu-parent
  ul
   li.current-menu-item
   a {
  color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li
  ul:not(.sub-menu)
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
  li.current-menu-parent
  ul
  li.current-menu-item
  a:before,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-ancestor
   a:before,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-item
   a:before,
.custom-css-style
  .elementor-element.elementor-element-6e220fe
  .themegoods-navigation-wrapper
  .nav
  li
  ul
   li.current-menu-parent
   a:before {
  background-color: #d22226;
}
.custom-css-style .elementor-element.elementor-element-6e220fe {
  width: auto;
  max-width: auto;
}
@media (max-width: 767px) {
  .custom-css-style .elementor-element.elementor-element-3e878ad {
    width: 40%;
  }
  .custom-css-style
    .elementor-element.elementor-element-3e878ad
     .elementor-element-populated {
    border-width: 0 0 1px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-3e878ad
     .elementor-background-slideshow,
  .custom-css-style
    .elementor-element.elementor-element-3e878ad
     .elementor-element-populated,
  .custom-css-style
    .elementor-element.elementor-element-3e878ad
     .elementor-element-populated
     .elementor-background-overlay {
    border-radius: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-67d00ef
    .elementor-image
    img {
    width: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-67d00ef
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  .custom-css-style .elementor-element.elementor-element-4953ee2 {
    width: 60%;
  }
  .custom-css-style
    .elementor-element.elementor-element-4953ee2
     .elementor-element-populated {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f161b88
    .elementor-heading-title {
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-b19755e
     .elementor-element-populated {
    border-width: 0 0 1px 0;
    margin: 0;
    padding: 10px 20px 10px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d63a3b9
     .elementor-widget-container {
    margin: 10px 0 0 20px;
  }
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-3e878ad {
    width: 25%;
  }
  .custom-css-style .elementor-element.elementor-element-4953ee2 {
    width: 55%;
  }
  .custom-css-style .elementor-element.elementor-element-b19755e {
    width: 19.664%;
  }
  .custom-css-style .elementor-element.elementor-element-e4ceae8 {
    width: 25%;
  }
  .custom-css-style .elementor-element.elementor-element-e55ab38 {
    width: 75%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-3e878ad {
    width: 25%;
  }
  .custom-css-style .elementor-element.elementor-element-4953ee2 {
    width: 45%;
  }
  .custom-css-style .elementor-element.elementor-element-b19755e {
    width: 30%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-3e878ad
     .elementor-element-populated {
    border-width: 0 0 1px 0;
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-67d00ef {
    text-align: left;
    top: 10px;
  }
  .custom-css-style
    .elementor-element.elementor-element-67d00ef
    .elementor-image
    img {
    width: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-67d00ef
     .elementor-widget-container {
    margin: 0 0 0 30px;
    padding: 0;
  }
  .elementor-bc-flex-widget
    .custom-css-style
    .elementor-element.elementor-element-4953ee2.elementor-column
    .elementor-column-wrap {
    align-items: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-4953ee2.elementor-column.elementor-element[data-element_type="column"]
     .elementor-column-wrap.elementor-element-populated
     .elementor-widget-wrap {
    align-content: center;
    align-items: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-4953ee2.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-4953ee2
     .elementor-element-populated {
    border-width: 0 0 1px 1px;
    padding: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f161b88
     .elementor-widget-container {
    margin: 0;
  }
  .elementor-bc-flex-widget
    .custom-css-style
    .elementor-element.elementor-element-b19755e.elementor-column
    .elementor-column-wrap {
    align-items: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-b19755e.elementor-column.elementor-element[data-element_type="column"]
     .elementor-column-wrap.elementor-element-populated
     .elementor-widget-wrap {
    align-content: center;
    align-items: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-b19755e
     .elementor-element-populated {
    border-width: 0 0 1px 1px;
    padding: 20px 30px 20px 0;
  }
}
.custom-css-style
  .elementor-element.elementor-element-578ab53
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  .slider-zoom-wrapper {
  height: 700px;
  background: #000000;
}
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  .slideshow-slide-caption-text
  .container
  .slideshow-slide-caption-title {
  width: 45%;
}
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  .slideshow-slide-caption-text
  .container
  .slideshow-slide-desc {
  width: 45%;
}
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  .slideshow-slide-caption-text {
  padding-top: 230px;
}
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  .slideshow-slide-caption-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  h2.slideshow-slide-caption-title {
  font-size: 90px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  .slideshow-slide-caption-text
  .slideshow-slide-desc {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  .slideshow-slide-caption-text
  div.slideshow-slide-desc {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  .slideshow-slide-caption-subtitle-label {
  color: #fff;
  font-family: Oswald, Sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
}
.custom-css-style .elementor-element.elementor-element-eda0b3b .o-hsub.-link {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  .slider-zoom-wrapper
  .slideshow-slide-image::before {
  background-color: #000000;
}
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  .slider-zoom-wrapper
  .pagination-item.is-current,
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  .slider-zoom-wrapper
  .pagination-item:hover {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-eda0b3b
  .slider-zoom-wrapper
  .pagination-item {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-1e6246b
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-1e6246b
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-1e6246b:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-1e6246b
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-1e6246b {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1e6246b
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-a0c7682
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ef6106a
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-b375f9f {
  margin-top: 0;
  margin-bottom: 60px;
}
.custom-css-style
  .elementor-element.elementor-element-40bae1a.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-40bae1a
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-40bae1a {
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-98a5fe1
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-98a5fe1
   .elementor-widget-container {
  margin: -130px 0 0 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #c7a17a;
}
.custom-css-style .elementor-element.elementor-element-98a5fe1 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-95396ad {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-95396ad
  .elementor-heading-title {
  line-height: 1.4em;
}
.custom-css-style
  .elementor-element.elementor-element-95396ad
   .elementor-widget-container {
  margin: 20px 0 0 0;
  padding: 0 15% 0 15%;
}
.custom-css-style
  .elementor-element.elementor-element-168e2f5
  .elementor-image
  img {
  width: 250px;
}
.custom-css-style .elementor-element.elementor-element-168e2f5 {
  width: 100%;
  max-width: 100%;
  top: -38%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-168e2f5 {
  left: -52%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-168e2f5 {
  right: -52%;
}
.custom-css-style .elementor-element.elementor-element-7faa272 {
  margin-top: 0;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d2621c4.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-d2621c4
   .elementor-element-populated {
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0 40px 0 0;
  padding: 6px 6px 6px 6px;
}
.custom-css-style
  .elementor-element.elementor-element-d2621c4
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-a9e9b2a {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-a9e9b2a
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-ad75b1f.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-ad75b1f
   .elementor-element-populated {
  padding: 30px 30px 20px 30px;
}
.custom-css-style
  .elementor-element.elementor-element-24db929
  .elementor-image
  img {
  width: 200px;
}
.custom-css-style
  .elementor-element.elementor-element-24db929
   .elementor-widget-container {
  margin: 0 0 10px 0;
}
.elementor-element.elementor-element-24db929 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-24db929 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-96c80f1 {
  text-align: center;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-96c80f1
  .elementor-heading-title {
  color: #000;
  font-size: 28px;
}
.custom-css-style
  .elementor-element.elementor-element-96c80f1
   .elementor-widget-container {
  margin: 15px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-aac67b6
  .elementor-spacer-inner {
  height: 1px;
}
.custom-css-style .elementor-element.elementor-element-fad1c51 {
  text-align: center;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-fad1c51
  .elementor-heading-title {
  color: #c7a17a;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 5px;
}
.custom-css-style
  .elementor-element.elementor-element-fad1c51
   .elementor-widget-container {
  margin: 2px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-767ac19
  .elementor-text-editor {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-767ac19
   .elementor-widget-container {
  margin: 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-05ae0dc.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-05ae0dc
   .elementor-element-populated {
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0 20px 0 20px;
  padding: 6px 6px 6px 6px;
}
.custom-css-style
  .elementor-element.elementor-element-05ae0dc
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-84b86df {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-84b86df
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-2704a34.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-2704a34
   .elementor-element-populated {
  padding: 30px 30px 20px 30px;
}
.custom-css-style
  .elementor-element.elementor-element-52aa29e
  .elementor-image
  img {
  width: 200px;
}
.custom-css-style
  .elementor-element.elementor-element-52aa29e
   .elementor-widget-container {
  margin: 0 0 10px 0;
}
.elementor-element.elementor-element-52aa29e {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-52aa29e {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-ca7d5b7 {
  text-align: center;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-ca7d5b7
  .elementor-heading-title {
  color: #000;
  font-size: 28px;
}
.custom-css-style
  .elementor-element.elementor-element-ca7d5b7
   .elementor-widget-container {
  margin: 15px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-138cf91
  .elementor-spacer-inner {
  height: 1px;
}
.custom-css-style .elementor-element.elementor-element-c0a5183 {
  text-align: center;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-c0a5183
  .elementor-heading-title {
  color: #c7a17a;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 5px;
}
.custom-css-style
  .elementor-element.elementor-element-c0a5183
   .elementor-widget-container {
  margin: 2px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-b4de5ac
  .elementor-text-editor {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-b4de5ac
   .elementor-widget-container {
  margin: 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9e18ebf.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-9e18ebf
   .elementor-element-populated {
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0 0 0 40px;
  padding: 6px 6px 6px 6px;
}
.custom-css-style
  .elementor-element.elementor-element-9e18ebf
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-cc18d28 {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-cc18d28
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-32c65ec.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-32c65ec
   .elementor-element-populated {
  padding: 30px 30px 20px 30px;
}
.custom-css-style
  .elementor-element.elementor-element-5ffe014
  .elementor-image
  img {
  width: 200px;
}
.custom-css-style
  .elementor-element.elementor-element-5ffe014
   .elementor-widget-container {
  margin: 0 0 10px 0;
}
.elementor-element.elementor-element-5ffe014 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-5ffe014 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-8bd697c {
  text-align: center;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-8bd697c
  .elementor-heading-title {
  color: #000;
  font-size: 28px;
}
.custom-css-style
  .elementor-element.elementor-element-8bd697c
   .elementor-widget-container {
  margin: 15px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-8209cca
  .elementor-spacer-inner {
  height: 1px;
}
.custom-css-style .elementor-element.elementor-element-aa42434 {
  text-align: center;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-aa42434
  .elementor-heading-title {
  color: #c7a17a;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 5px;
}
.custom-css-style
  .elementor-element.elementor-element-aa42434
   .elementor-widget-container {
  margin: 2px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-14e3067
  .elementor-text-editor {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-14e3067
   .elementor-widget-container {
  margin: 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-cd67cb4
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-cd67cb4
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-cd67cb4:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-cd67cb4
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-2.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-cd67cb4 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-cd67cb4
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-5fc86ba
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c2cde4a
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7fd5bfd
   .elementor-container {
  min-height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-7fd5bfd
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-7fd5bfd:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-7fd5bfd
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/barista-prepare-coffee-working-order-concept-PBZ6VQ6.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-7fd5bfd
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-7fd5bfd {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -100px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6d7aa79
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-b84cd55 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-b84cd55
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-b84cd55 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-b84cd55 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-6847537 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-6847537
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
}
.custom-css-style
  .elementor-element.elementor-element-6847537
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-6847537 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-6847537 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a5831b0
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-a5831b0
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-a5831b0:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-a5831b0
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-a5831b0 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a5831b0
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-f1f1003
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c60fa92
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-7704856 {
  margin-top: 0;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-dc9e1bf
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper
  .inner-wrap
  .overflow-inner {
  padding: 60px;
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper
  .inner-wrap {
  background: #fffbf2;
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper
  .inner-wrap:hover {
  background: #fffbf2;
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper
  .inner-wrap:hover
  .overflow-inner {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper.featured-pricing-plan
  .inner-wrap {
  background: #fffbf2;
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrappe.featured-pricing-planr
  .inner-wrap
  .overflow-inner {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper
  h2.pricing-plan-title {
  color: #000;
  font-size: 28px;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper:hover
  h2.pricing-plan-title {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper.featured-pricing-plan
  h2.pricing-plan-title {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-plan-price-wrap
  h3.pricing-plan-price {
  color: #000;
  font-size: 80px;
  line-height: 1.3em;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-plan-price-wrap {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper:hover
  h3.pricing-plan-price {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper.featured-pricing-plan
  h3.pricing-plan-price {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper.featured-pricing-plan
  .pricing-plan-unit-month {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper.featured-pricing-plan
  .pricing-plan-unit-year {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper
  .pricing-plan-content-list {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-plan-content
  ul.pricing-plan-content-list
  li:before {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper:hover
  .pricing-plan-content-list {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-table-wrapper.featured-pricing-plan
  .pricing-plan-content-list {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-plan-content
  .pricing-plan-button {
  color: #fff;
  background: #c7a17a;
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-plan-content
  .pricing-plan-button:hover {
  color: #c7a17a;
  background: rgba(255, 255, 255, 0);
  border-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
  .pricing-plan-switch-wrap {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501979f
   .elementor-widget-container {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e709873
  .elementor-image
  img {
  width: 120px;
}
.custom-css-style .elementor-element.elementor-element-e709873 {
  width: 100%;
  max-width: 100%;
  top: 2%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-e709873 {
  left: -57%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-e709873 {
  right: -57%;
}
.custom-css-style
  .elementor-element.elementor-element-a6394cf
  .elementor-image
  img {
  width: 250px;
}
.custom-css-style .elementor-element.elementor-element-a6394cf {
  width: 100%;
  max-width: 100%;
  top: 78%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-a6394cf {
  right: -53%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-a6394cf {
  left: -53%;
}
.custom-css-style
  .elementor-element.elementor-element-f4987bf
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-f4987bf
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-f4987bf:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-f4987bf
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-2.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-f4987bf {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-f4987bf
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-e2c877d
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2497026
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a31e13e
   .elementor-container {
  min-height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-a31e13e
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-a31e13e:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-a31e13e
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/fresh-coffee-with-cinnamon-QR8W4GN.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-a31e13e
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-a31e13e {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -100px;
  margin-bottom: 0;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-c7f5c8c.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-c7f5c8c.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-c7f5c8c.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-c7f5c8c
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-147a53e
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #fff;
  color: #fff;
  background-color: #c7a17a;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c7a17a;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-147a53e
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-147a53e
  .elementor-button:hover {
  color: #fff;
  background-color: rgba(199, 161, 122, 0);
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-147a53e
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-147a53e
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-147a53e
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-147a53e {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-ea28ee3
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-ea28ee3
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-ea28ee3:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-ea28ee3
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-ea28ee3 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ea28ee3
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-b610706
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b91ce1c
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-530e1fa {
  z-index: 2;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-e377ba2.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-e377ba2.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-e377ba2.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-70d5c54
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-70d5c54
   .elementor-widget-container {
  margin: -130px 0 0 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #c7a17a;
}
.custom-css-style .elementor-element.elementor-element-70d5c54 {
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-92654db
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-9a7ab21 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-9a7ab21
  .elementor-heading-title {
  line-height: 1.4em;
}
.custom-css-style
  .elementor-element.elementor-element-9a7ab21
   .elementor-widget-container {
  margin: 20px 0 40px 0;
  padding: 0 15% 0 15%;
}
.custom-css-style .elementor-element.elementor-element-86795ec {
  margin-top: 0;
  margin-bottom: 80px;
}
.custom-css-style
  .elementor-element.elementor-element-5fa05ad
   .elementor-element-populated {
  padding: 0 10% 0 10%;
}
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-accordion
  .elementor-accordion-item {
  border-width: 1px;
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-accordion
  .elementor-accordion-item
  .elementor-tab-content {
  border-width: 1px;
  border-top-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-accordion
  .elementor-accordion-item
  .elementor-tab-title.elementor-active {
  border-width: 1px;
  border-bottom-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-accordion
  .elementor-tab-title {
  background-color: #fffbf2;
  padding: 20px 20px 20px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-accordion-icon,
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-accordion-title {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-active
  .elementor-accordion-icon,
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-active
  .elementor-accordion-title {
  color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-accordion
  .elementor-accordion-title {
  font-size: 24px;
  letter-spacing: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-accordion
  .elementor-tab-title
  .elementor-accordion-icon
  i:before {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-accordion
  .elementor-tab-title
  .elementor-accordion-icon
  svg {
  fill: #000;
}
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-accordion
  .elementor-tab-title.elementor-active
  .elementor-accordion-icon
  i:before {
  color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-accordion
  .elementor-tab-title.elementor-active
  .elementor-accordion-icon
  svg {
  fill: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-43333f3
  .elementor-accordion
  .elementor-tab-content {
  background-color: #fffbf2;
  color: #000;
  padding: 10px 30px 10px 30px;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-eda0b3b
    .slider-zoom-wrapper {
    height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-eda0b3b
    .slideshow-slide-caption-text
    .container
    .slideshow-slide-caption-title {
    width: 70%;
  }
  .custom-css-style
    .elementor-element.elementor-element-eda0b3b
    .slideshow-slide-caption-text
    .container
    .slideshow-slide-desc {
    width: 70%;
  }
  .custom-css-style
    .elementor-element.elementor-element-eda0b3b
    h2.slideshow-slide-caption-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1e6246b
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1e6246b
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-1e6246b:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-1e6246b
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-1e6246b {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-b375f9f {
    margin-top: 0;
    margin-bottom: 30px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-98a5fe1
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-98a5fe1
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-95396ad
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-7faa272 {
    padding: 0 0 100px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d2621c4
     .elementor-element-populated {
    margin: 0 20px 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ad75b1f
     .elementor-element-populated {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-24db929
    .elementor-image
    img {
    width: 130px;
  }
  .custom-css-style
    .elementor-element.elementor-element-05ae0dc
     .elementor-element-populated {
    margin: 0 10px 0 10px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2704a34
     .elementor-element-populated {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-52aa29e
    .elementor-image
    img {
    width: 130px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9e18ebf
     .elementor-element-populated {
    margin: 0 0 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-32c65ec
     .elementor-element-populated {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5ffe014
    .elementor-image
    img {
    width: 130px;
  }
  .custom-css-style
    .elementor-element.elementor-element-cd67cb4
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-cd67cb4
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-cd67cb4:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-cd67cb4
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-cd67cb4 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7fd5bfd
     .elementor-container {
    min-height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7fd5bfd
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-b84cd55
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6847537
    .elementor-heading-title {
    font-size: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a5831b0
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a5831b0
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-a5831b0:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-a5831b0
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-a5831b0 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-7704856 {
    padding: 0 0 100px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-501979f
    .pricing-table-wrapper
    .inner-wrap
    .overflow-inner {
    padding: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-501979f
    .pricing-table-wrapper
    h2.pricing-plan-title {
    font-size: 24px;
  }
  .custom-css-style
    .elementor-element.elementor-element-501979f
    .pricing-plan-price-wrap
    h3.pricing-plan-price {
    font-size: 55px;
    line-height: 1.5em;
  }
  .custom-css-style .elementor-element.elementor-element-a6394cf {
    top: 113%;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4987bf
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4987bf
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4987bf:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-f4987bf
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-f4987bf {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a31e13e
     .elementor-container {
    min-height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a31e13e
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-147a53e {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-ea28ee3
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ea28ee3
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-ea28ee3:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-ea28ee3
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-ea28ee3 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-70d5c54
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-70d5c54
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9a7ab21
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5fa05ad
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-43333f3
    .elementor-accordion
    .elementor-accordion-title {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-eda0b3b
    .slider-zoom-wrapper {
    height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-eda0b3b
    .slideshow-slide-caption-text
    .container
    .slideshow-slide-caption-title {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-eda0b3b
    .slideshow-slide-caption-text
    .container
    .slideshow-slide-desc {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-eda0b3b
    .slideshow-slide-caption-text {
    padding-top: 210px;
  }
  .custom-css-style
    .elementor-element.elementor-element-eda0b3b
    h2.slideshow-slide-caption-title {
    font-size: 50px;
    line-height: 1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-eda0b3b
    .slideshow-slide-caption-subtitle-label {
    font-size: 18px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1e6246b
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1e6246b
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-1e6246b:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-1e6246b
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-1e6246b {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ef6106a
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-b375f9f {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-98a5fe1
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-95396ad
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-168e2f5
    .elementor-image
    img {
    width: 185px;
  }
  .custom-css-style .elementor-element.elementor-element-168e2f5 {
    top: -4%;
  }
  .custom-css-style .elementor-element.elementor-element-7faa272 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d2621c4
     .elementor-element-populated {
    margin: 0 0 40px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ad75b1f
     .elementor-element-populated {
    padding: 30px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-24db929
    .elementor-image
    img {
    width: 150px;
  }
  .custom-css-style
    .elementor-element.elementor-element-96c80f1
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fad1c51
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-05ae0dc
     .elementor-element-populated {
    margin: 0 0 40px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2704a34
     .elementor-element-populated {
    padding: 30px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-52aa29e
    .elementor-image
    img {
    width: 150px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ca7d5b7
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c0a5183
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9e18ebf
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-32c65ec
     .elementor-element-populated {
    padding: 30px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5ffe014
    .elementor-image
    img {
    width: 150px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8bd697c
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-aa42434
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-cd67cb4
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-cd67cb4
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-cd67cb4:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-cd67cb4
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-cd67cb4 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5fc86ba
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c2cde4a
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c2cde4a
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7fd5bfd
     .elementor-container {
    min-height: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7fd5bfd
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-b84cd55
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6847537
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6847537
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a5831b0
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a5831b0
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-a5831b0:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-a5831b0
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-a5831b0 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c60fa92
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4987bf
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4987bf
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4987bf:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-f4987bf
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-f4987bf {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2497026
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a31e13e
     .elementor-container {
    min-height: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a31e13e
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-ea28ee3
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ea28ee3
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-ea28ee3:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-ea28ee3
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-ea28ee3 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b91ce1c
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-530e1fa {
    margin-top: -70px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-70d5c54
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9a7ab21
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-43333f3
    .elementor-accordion
    .elementor-accordion-title {
    line-height: 1.4em;
  }
}
.custom-css-style
  .elementor-element.elementor-element-c48c6d7
   .elementor-container {
  min-height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-c48c6d7
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-c48c6d7:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-c48c6d7
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  /* background-image: url(../barista-making-coffee-F2GU6L8.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-c48c6d7
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-c48c6d7 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-6957e71
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-6684a99 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-6684a99
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
  line-height: 0.7em;
}
.elementor-element.elementor-element-6684a99 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 1s !important;
}
.elementor-widget.elementor-element-6684a99 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-5e3984a {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-5e3984a
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-5e3984a {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-5e3984a {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-2cba262 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-2cba262
  .elementor-heading-title {
  color: #fff;
  font-size: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-2cba262
   .elementor-widget-container {
  margin: -20px 0 0 0;
}
.elementor-element.elementor-element-2cba262 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-2cba262 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-95baf5c {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-95baf5c
  .elementor-heading-title {
  color: #fff;
  font-size: 30px;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-95baf5c
   .elementor-widget-container {
  margin: 0;
}
.elementor-element.elementor-element-95baf5c {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 1s !important;
}
.elementor-widget.elementor-element-95baf5c {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c087f73
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-c087f73
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-c087f73:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-c087f73
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-c087f73 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c087f73
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-bc41ba8
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-783cc60
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-0dee37d {
  padding: 0 0 40px 0;
  z-index: 2;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-11b9c38.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-11b9c38.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-11b9c38.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-11b9c38
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5d9474c
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-5d9474c
   .elementor-widget-container {
  margin: -40px 0 0 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #c7a17a;
}
.custom-css-style .elementor-element.elementor-element-5d9474c {
  z-index: 2;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-fb4857b
  .elementor-image
  img {
  width: 250px;
}
.custom-css-style .elementor-element.elementor-element-fb4857b {
  width: 100%;
  max-width: 100%;
  top: -0.698%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-fb4857b {
  left: -45.999%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-fb4857b {
  right: -45.999%;
}
.custom-css-style .elementor-element.elementor-element-e84a0e6 {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-900d959
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a9e393d
  .elementor-image
  img {
  width: 75%;
}
.custom-css-style
  .elementor-element.elementor-element-a9e393d
   .elementor-widget-container {
  margin: -100px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-f06fc51
   .elementor-element-populated {
  padding: 0 0 0 40px;
}
.custom-css-style .elementor-element.elementor-element-80a3390 {
  text-align: center;
  z-index: 4;
}
.custom-css-style
  .elementor-element.elementor-element-80a3390
  .elementor-heading-title {
  font-size: 40px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-80a3390
   .elementor-widget-container {
  margin: 20px 0 0 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7c5423f
  .elementor-image
  img {
  width: 230px;
}
.custom-css-style .elementor-element.elementor-element-7c5423f {
  width: 100%;
  max-width: 100%;
  top: -20%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-7c5423f {
  right: -41%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-7c5423f {
  left: -41%;
}
.custom-css-style
  .elementor-element.elementor-element-10812c3
  .elementor-heading-title {
  color: #000;
  font-size: 100px;
  line-height: 1em;
}
.custom-css-style .elementor-element.elementor-element-10812c3 {
  z-index: 2;
  width: 100%;
  max-width: 100%;
  top: 48%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-10812c3 {
  right: 33%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-10812c3 {
  left: 33%;
}
.custom-css-style
  .elementor-element.elementor-element-de37a31
  .elementor-image
  img {
  width: 110px;
}
.custom-css-style .elementor-element.elementor-element-de37a31 {
  width: 100%;
  max-width: 100%;
  top: 57%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-de37a31 {
  left: 51%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-de37a31 {
  right: 51%;
}
.custom-css-style
  .elementor-element.elementor-element-05306e2
   .elementor-element-populated {
  padding: 70px 40px 0 0;
}
.custom-css-style .elementor-element.elementor-element-a5ee4c4 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-a5ee4c4
   .elementor-widget-container {
  margin: 0;
}
.elementor-element.elementor-element-a5ee4c4 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-a5ee4c4 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-ad1eab2 {
  --divider-border-style: solid;
  --divider-color: #c7a17a;
  --divider-border-width: 1px;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-ad1eab2
  .elementor-divider-separator {
  width: 90px;
  margin: 0 auto;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ad1eab2
  .elementor-divider {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-ad1eab2
   .elementor-widget-container {
  margin: 10px 0 20px 0;
}
.elementor-element.elementor-element-ad1eab2 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-ad1eab2 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-f4502c6 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-f4502c6
  .elementor-image
  img {
  width: 250px;
  filter: brightness(0) contrast(100%) saturate(100%) blur(0) hue-rotate(0deg);
}
.custom-css-style
  .elementor-element.elementor-element-f4502c6
   .elementor-widget-container {
  margin: 0;
}
.elementor-element.elementor-element-f4502c6 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-f4502c6 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-7467473 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-7467473
  .elementor-heading-title {
  color: #000;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-7467473
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.elementor-element.elementor-element-7467473 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.6s !important;
}
.elementor-widget.elementor-element-7467473 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d3afde9
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-aa30c4d {
  text-align: right;
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-aa30c4d
  .elementor-image
  img {
  width: 95%;
}
.custom-css-style
  .elementor-element.elementor-element-da9d353
  .elementor-image
  img {
  width: 270px;
}
.custom-css-style .elementor-element.elementor-element-da9d353 {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: 41%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-da9d353 {
  left: -42%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-da9d353 {
  right: -42%;
}
.custom-css-style
  .elementor-element.elementor-element-72155ed
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-72155ed
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-72155ed:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-72155ed
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-2.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-72155ed {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-72155ed
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-30d0b2d
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ba8a7ff
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ee6c107
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-ee6c107
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-ee6c107:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-ee6c107
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/tim-st-martin-IjnAc0vyqGs-unsplash.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-ee6c107
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-ee6c107 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -100px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7f4ed7f
   .elementor-element-populated {
  margin: 20px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-e26396e {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-e26396e
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-e26396e {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-e26396e {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-25a1057 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-25a1057
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
}
.custom-css-style
  .elementor-element.elementor-element-25a1057
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-25a1057 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-25a1057 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e8d4d80
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-e8d4d80
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-e8d4d80:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-e8d4d80
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-e8d4d80 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e8d4d80
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-3f89243
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-088011b
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-bd5941a {
  padding: 0 0 80px 0;
  z-index: 2;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-2f73c83.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-2f73c83.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-2f73c83.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-2f73c83
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-96e9cf2
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-96e9cf2
   .elementor-widget-container {
  margin: -40px 0 0 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #c7a17a;
}
.custom-css-style .elementor-element.elementor-element-96e9cf2 {
  z-index: 2;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-a9367d4
  .elementor-image
  img {
  width: 260px;
}
.custom-css-style .elementor-element.elementor-element-a9367d4 {
  width: 100%;
  max-width: 100%;
  top: 17%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-a9367d4 {
  left: -50%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-a9367d4 {
  right: -50%;
}
.custom-css-style .elementor-element.elementor-element-183f684 {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-24b53d2
   .elementor-element-populated {
  margin: 0 0 0 -20px;
  padding: 0 40px 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-c9c393e
  .elementor-image
  img {
  width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-c9c393e
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-c9c393e {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-d95b506
   .elementor-element-populated {
  padding: 0 0 0 60px;
}
.custom-css-style .elementor-element.elementor-element-8456cf2 {
  text-align: center;
  z-index: 4;
}
.custom-css-style
  .elementor-element.elementor-element-8456cf2
  .elementor-heading-title {
  font-size: 40px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-8456cf2
   .elementor-widget-container {
  margin: 20px 0 0 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e53fdd7
  .elementor-image
  img {
  width: 240px;
}
.custom-css-style .elementor-element.elementor-element-e53fdd7 {
  width: 100%;
  max-width: 100%;
  top: -20%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-e53fdd7 {
  right: -41%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-e53fdd7 {
  left: -41%;
}
.custom-css-style .elementor-element.elementor-element-42b392b {
  padding: 40px 0 400px 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-bb20033
   .elementor-element-populated {
  padding: 20px 40px 0 0;
}
.custom-css-style .elementor-element.elementor-element-9bafdb1 {
  text-align: right;
  z-index: 3;
  top: -200%;
}
.custom-css-style
  .elementor-element.elementor-element-9bafdb1
  .elementor-image
  img {
  width: 65%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-9bafdb1 {
  right: -159%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-9bafdb1 {
  left: -159%;
}
.custom-css-style
  .elementor-element.elementor-element-2d8e628
  .elementor-heading-title {
  color: #000;
  font-size: 100px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-2d8e628
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-2d8e628 {
  z-index: 2;
  width: initial;
  max-width: initial;
  top: 200%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-2d8e628 {
  right: -53%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-2d8e628 {
  left: -53%;
}
.custom-css-style
  .elementor-element.elementor-element-50256db
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-50256db {
  z-index: 0;
}
.custom-css-style .elementor-element.elementor-element-295cd9d {
  z-index: 1;
}
.custom-css-style
  .elementor-element.elementor-element-2d0a2ed
   .elementor-element-populated {
  margin: -100px 0 0 0;
  padding: 0 40px 0 0;
}
.custom-css-style .elementor-element.elementor-element-06a08e0 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-06a08e0
   .elementor-widget-container {
  margin: 0;
}
.elementor-element.elementor-element-06a08e0 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-06a08e0 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-1cb22f2 {
  text-align: right;
}
.custom-css-style
  .elementor-element.elementor-element-1cb22f2
  .elementor-image
  img {
  width: 210px;
  opacity: 1;
}
.custom-css-style
  .elementor-element.elementor-element-1cb22f2
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-57d796d
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-3cc8f2d {
  text-align: center;
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-3cc8f2d
  .elementor-image
  img {
  width: 65%;
}
.custom-css-style
  .elementor-element.elementor-element-3cc8f2d
   .elementor-widget-container {
  margin: -140px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-989557e
  .elementor-image
  img {
  width: 110px;
}
.custom-css-style .elementor-element.elementor-element-989557e {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: -59%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-989557e {
  left: -138%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-989557e {
  right: -138%;
}
.custom-css-style
  .elementor-element.elementor-element-66ed23f
  .elementor-image
  img {
  width: 230px;
}
.custom-css-style .elementor-element.elementor-element-66ed23f {
  z-index: 1;
  width: 260px;
  max-width: 260px;
  bottom: 76%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-66ed23f {
  left: -9%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-66ed23f {
  right: -9%;
}
.custom-css-style .elementor-element.elementor-element-ea812cf {
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-feaaa0e.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-feaaa0e
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c411cf0
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-c411cf0
   .elementor-widget-container {
  margin: 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
}
.custom-css-style .elementor-element.elementor-element-c411cf0 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-efe116b {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-efe116b
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.custom-css-style
  .elementor-element.elementor-element-efe116b
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.elementor-element.elementor-element-efe116b {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-efe116b {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b22170b
  .elementor-image
  img {
  width: 110px;
}
.custom-css-style .elementor-element.elementor-element-b22170b {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: 6%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-b22170b {
  left: 50%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-b22170b {
  right: 50%;
}
.custom-css-style .elementor-element.elementor-element-6a1c1da {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-6a1c1da
  .elementor-heading-title {
  color: #000;
  font-size: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-6a1c1da
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-6a1c1da {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-6a1c1da {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6c407bf
  .elementor-image
  img {
  width: 230px;
}
.custom-css-style .elementor-element.elementor-element-6c407bf {
  width: 100%;
  max-width: 100%;
  top: 51%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-6c407bf {
  left: -45%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-6c407bf {
  right: -45%;
}
.custom-css-style .elementor-element.elementor-element-52dc38a {
  margin-top: 40px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c9f3e7c
   .elementor-element-populated {
  margin: 0 20px 0 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6cd32de
  .elementor-heading-title {
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-6cd32de
   .elementor-widget-container {
  margin: 0 20px 30px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-6a86839
  .food-menu-content-title-holder
  h3 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6a86839
  .food-menu-content-title-line {
  border-color: #000;
}
.custom-css-style .elementor-element.elementor-element-6a86839 .food-menu-desc {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6a86839
  .food-menu-content-price-holder
  .food-menu-content-price-normal {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6a86839
  .food-menu-content-price-holder
  .food-menu-content-price-sale {
  color: #989898;
}
.custom-css-style
  .elementor-element.elementor-element-6a86839
  .food-menu-content-highlight-holder
  h4 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6a86839
  .food-menu-content-highlight-holder {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6a86839
  .food-menu-highlight {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6a86839
  .food-menu-tooltip-content {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6a86839
  .food-menu-tooltip-content
  h5 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6a86839
  .tooltipster-sidetip.tooltipster-shadow
  .tooltipster-box {
  background: #fffbf2;
}
.custom-css-style
  .elementor-element.elementor-element-8c73091
  .elementor-heading-title {
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-8c73091
   .elementor-widget-container {
  margin: 20px 20px 30px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-7548d32
  .food-menu-content-title-holder
  h3 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7548d32
  .food-menu-content-title-line {
  border-color: #000;
}
.custom-css-style .elementor-element.elementor-element-7548d32 .food-menu-desc {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7548d32
  .food-menu-content-price-holder
  .food-menu-content-price-normal {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7548d32
  .food-menu-content-price-holder
  .food-menu-content-price-sale {
  color: #989898;
}
.custom-css-style
  .elementor-element.elementor-element-7548d32
  .food-menu-content-highlight-holder
  h4 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7548d32
  .food-menu-content-highlight-holder {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7548d32
  .food-menu-highlight {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7548d32
  .food-menu-tooltip-content {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7548d32
  .food-menu-tooltip-content
  h5 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7548d32
  .tooltipster-sidetip.tooltipster-shadow
  .tooltipster-box {
  background: #fffbf2;
}
.custom-css-style
  .elementor-element.elementor-element-61d58b4
   .elementor-element-populated {
  margin: 0 0 0 20px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-99e2131
  .elementor-heading-title {
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-99e2131
   .elementor-widget-container {
  margin: 0 20px 30px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-7ac7369
  .food-menu-content-title-holder
  h3 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7ac7369
  .food-menu-content-title-line {
  border-color: #000;
}
.custom-css-style .elementor-element.elementor-element-7ac7369 .food-menu-desc {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7ac7369
  .food-menu-content-price-holder
  .food-menu-content-price-normal {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7ac7369
  .food-menu-content-price-holder
  .food-menu-content-price-sale {
  color: #989898;
}
.custom-css-style
  .elementor-element.elementor-element-7ac7369
  .food-menu-content-highlight-holder
  h4 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7ac7369
  .food-menu-content-highlight-holder {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7ac7369
  .food-menu-highlight {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7ac7369
  .food-menu-tooltip-content {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7ac7369
  .food-menu-tooltip-content
  h5 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7ac7369
  .tooltipster-sidetip.tooltipster-shadow
  .tooltipster-box {
  background: #fffbf2;
}
.custom-css-style
  .elementor-element.elementor-element-2fc9b99
  .elementor-heading-title {
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-2fc9b99
   .elementor-widget-container {
  margin: 20px 20px 30px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-47d0ea2
  .food-menu-content-title-holder
  h3 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-47d0ea2
  .food-menu-content-title-line {
  border-color: #000;
}
.custom-css-style .elementor-element.elementor-element-47d0ea2 .food-menu-desc {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-47d0ea2
  .food-menu-content-price-holder
  .food-menu-content-price-normal {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-47d0ea2
  .food-menu-content-price-holder
  .food-menu-content-price-sale {
  color: #989898;
}
.custom-css-style
  .elementor-element.elementor-element-47d0ea2
  .food-menu-content-highlight-holder
  h4 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-47d0ea2
  .food-menu-content-highlight-holder {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-47d0ea2
  .food-menu-highlight {
  border-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-47d0ea2
  .food-menu-tooltip-content {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-47d0ea2
  .food-menu-tooltip-content
  h5 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-47d0ea2
  .tooltipster-sidetip.tooltipster-shadow
  .tooltipster-box {
  background: #fffbf2;
}
.custom-css-style
  .elementor-element.elementor-element-041856f
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-041856f
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-041856f:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-041856f
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-2.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-041856f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-041856f
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-f333ce5
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-bd023c9
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-db40567
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-db40567
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-db40567:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-db40567
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/jonathan-borba-O2fAWP7uDSQ-unsplash.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-db40567
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-db40567 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -100px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-50d593a
   .elementor-element-populated {
  margin: 20px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-0286ef1 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-0286ef1
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-0286ef1 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-0286ef1 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-56938e0 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-56938e0
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
}
.custom-css-style
  .elementor-element.elementor-element-56938e0
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-56938e0 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-56938e0 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c22aa4e
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-c22aa4e
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-c22aa4e:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-c22aa4e
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-c22aa4e {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c22aa4e
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-9dd2a97
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c39c493
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d28e719
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-bedfc57
  ul.products
  li.product
  h2.woocommerce-loop-product__title {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-bedfc57
  ul.products
  li.product {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-bedfc57
  ul.products
  li.product
  span.price {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-bedfc57
  ul.products
  li.product
  .onsale {
  background: #d22226;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-bedfc57
  ul.products
  li.product
  a.button.ajax_add_to_cart {
  background: #000;
  border-color: #000;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-bedfc57
  ul.products
  li.product
  a.button.ajax_add_to_cart:hover {
  background: #000;
  border-color: #000;
  color: #fff;
}
.custom-css-style .elementor-element.elementor-element-e57e3cb {
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-ffd0cbd.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-ffd0cbd
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1ae22a0
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-1ae22a0
   .elementor-widget-container {
  margin: 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
}
.custom-css-style .elementor-element.elementor-element-1ae22a0 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-e3d5380 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-e3d5380
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.custom-css-style
  .elementor-element.elementor-element-e3d5380
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.elementor-element.elementor-element-e3d5380 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-e3d5380 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4e3cd96
  .elementor-image
  img {
  width: 230px;
}
.custom-css-style .elementor-element.elementor-element-4e3cd96 {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: 30%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-4e3cd96 {
  left: 50%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-4e3cd96 {
  right: 50%;
}
.custom-css-style .elementor-element.elementor-element-2921510 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-2921510
  .elementor-heading-title {
  color: #000;
  font-size: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-2921510
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-2921510 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-2921510 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-42e2b84
  .elementor-image
  img {
  width: 240px;
}
.custom-css-style .elementor-element.elementor-element-42e2b84 {
  width: 100%;
  max-width: 100%;
  top: -6%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-42e2b84 {
  left: -55%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-42e2b84 {
  right: -55%;
}
.custom-css-style
  .elementor-element.elementor-element-7efb73b
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-7efb73b
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-7efb73b:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-7efb73b
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-4.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-7efb73b {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7efb73b
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-2ea96f8
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c3379f2
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-67fe11f:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-67fe11f
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #f0e9da;
}
.custom-css-style .elementor-element.elementor-element-67fe11f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-67fe11f
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-3e0a9ea
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-477fd83
  .horizontal-gallery-wrapper
  .flickity-prev-next-button.next {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-477fd83
  .horizontal-gallery-wrapper
  .flickity-prev-next-button.previous {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-477fd83
  .horizontal-gallery-wrapper
  .flickity-prev-next-button
  .arrow {
  fill: #222;
}
.custom-css-style
  .elementor-element.elementor-element-477fd83
  .horizontal-gallery-wrapper
  .flickity-page-dots
  .dot {
  background: #222;
}
.custom-css-style
  .elementor-element.elementor-element-9f23810
  .elementor-button {
  font-family: Roboto, Sans-serif;
  font-size: 18px;
  text-transform: none;
  fill: #000;
  color: #000;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #c7a17a;
  border-radius: 0;
  padding: 0 2px 10px 2px;
}
.custom-css-style
  .elementor-element.elementor-element-9f23810
   .elementor-widget-container {
  margin: 40px 0 40px 0;
}
:root {
  --page-title-display: none;
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-900d959 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-f06fc51 {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-2f73c83 {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-24b53d2 {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-d95b506 {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-57d796d {
    width: 44.332%;
  }
  .custom-css-style .elementor-element.elementor-element-c1b4f3d {
    width: 22%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-c48c6d7
     .elementor-container {
    min-height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c48c6d7
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-6684a99
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5e3984a
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2cba262
    .elementor-heading-title {
    font-size: 110px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2cba262
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-95baf5c
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c087f73
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c087f73
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-c087f73:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-c087f73
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-c087f73 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5d9474c
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5d9474c
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-80a3390
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-7c5423f {
    top: 113%;
  }
  .custom-css-style
    .elementor-element.elementor-element-10812c3
    .elementor-heading-title {
    font-size: 70px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-10812c3 {
    right: 27%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-10812c3 {
    left: 27%;
  }
  .custom-css-style .elementor-element.elementor-element-10812c3 {
    top: 77%;
  }
  .custom-css-style .elementor-element.elementor-element-9a66623 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-05306e2
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ad1eab2
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4502c6
     .elementor-widget-container {
    margin: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-aa30c4d
     .elementor-widget-container {
    margin: 80px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-72155ed
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-72155ed
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-72155ed:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-72155ed
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-72155ed {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ee6c107
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ee6c107
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-e26396e
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-25a1057
    .elementor-heading-title {
    font-size: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-25a1057
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e8d4d80
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e8d4d80
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-e8d4d80:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-e8d4d80
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-e8d4d80 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-bd5941a {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-96e9cf2
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-96e9cf2
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8456cf2
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e53fdd7
    .elementor-image
    img {
    width: 200px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-e53fdd7 {
    right: -34%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-e53fdd7 {
    left: -34%;
  }
  .custom-css-style .elementor-element.elementor-element-e53fdd7 {
    top: -30%;
  }
  .custom-css-style .elementor-element.elementor-element-42b392b {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0 100px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-bb20033
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9bafdb1
     .elementor-widget-container {
    margin: 80px 0 0 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-9bafdb1 {
    right: -120%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-9bafdb1 {
    left: -120%;
  }
  .custom-css-style .elementor-element.elementor-element-9bafdb1 {
    top: -57%;
  }
  .custom-css-style
    .elementor-element.elementor-element-2d8e628
    .elementor-heading-title {
    font-size: 60px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-2d8e628 {
    right: -27%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-2d8e628 {
    left: -27%;
  }
  .custom-css-style .elementor-element.elementor-element-2d8e628 {
    top: 65%;
  }
  .custom-css-style .elementor-element.elementor-element-295cd9d {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2d0a2ed
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1cb22f2
    .elementor-image
    img {
    width: 150px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3cc8f2d
     .elementor-widget-container {
    margin: 80px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c411cf0
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c411cf0
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-efe116b
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6a1c1da
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style .elementor-element.elementor-element-6c407bf {
    top: 113%;
  }
  .custom-css-style .elementor-element.elementor-element-52dc38a {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c9f3e7c
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6cd32de
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c73091
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-61d58b4
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-99e2131
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2fc9b99
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-041856f
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-041856f
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-041856f:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-041856f
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-041856f {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-db40567
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-db40567
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-0286ef1
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-56938e0
    .elementor-heading-title {
    font-size: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c22aa4e
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c22aa4e
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-c22aa4e:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-c22aa4e
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-c22aa4e {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1ae22a0
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1ae22a0
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e3d5380
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2921510
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style .elementor-element.elementor-element-42e2b84 {
    top: 113%;
  }
  .custom-css-style
    .elementor-element.elementor-element-7efb73b
     .elementor-container {
    min-height: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7efb73b
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-7efb73b:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-7efb73b
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-7efb73b {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-c48c6d7
     .elementor-container {
    min-height: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c48c6d7
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-6957e71
     .elementor-element-populated {
    margin: 30px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6684a99
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5e3984a
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2cba262
    .elementor-heading-title {
    font-size: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2cba262
     .elementor-widget-container {
    margin: 0 0 10px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-95baf5c
    .elementor-heading-title {
    font-size: 24px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c087f73
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c087f73
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-c087f73:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-c087f73
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-c087f73 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-783cc60
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-11b9c38
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5d9474c
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-fb4857b
    .elementor-image
    img {
    width: 220px;
  }
  .custom-css-style .elementor-element.elementor-element-e84a0e6 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-900d959
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a9e393d
    .elementor-image
    img {
    width: 90%;
  }
  .custom-css-style
    .elementor-element.elementor-element-a9e393d
     .elementor-widget-container {
    margin: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f06fc51
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-80a3390
    .elementor-heading-title {
    font-size: 28px;
  }
  .custom-css-style
    .elementor-element.elementor-element-80a3390
     .elementor-widget-container {
    margin: 40px 0 100px 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-10812c3 {
    text-align: center;
    top: 78%;
  }
  .custom-css-style
    .elementor-element.elementor-element-10812c3
    .elementor-heading-title {
    font-size: 50px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-10812c3 {
    right: 2%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-10812c3 {
    left: 2%;
  }
  .custom-css-style .elementor-element.elementor-element-9a66623 {
    margin-top: 20px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-05306e2.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-a5ee4c4
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ad1eab2
    .elementor-divider {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-ad1eab2
    .elementor-divider-separator {
    margin: 0 auto;
    margin-left: 0;
  }
  .custom-css-style .elementor-element.elementor-element-f4502c6 {
    text-align: center;
  }
  .custom-css-style .elementor-element.elementor-element-7467473 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-d3afde9.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-d3afde9
     .elementor-element-populated {
    margin: 40px 0 0 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-aa30c4d {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-aa30c4d
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-72155ed
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-72155ed
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-72155ed:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-72155ed
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-72155ed {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-30d0b2d
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ba8a7ff
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ba8a7ff
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ee6c107
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ee6c107
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-e26396e
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-25a1057
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-25a1057
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e8d4d80
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e8d4d80
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-e8d4d80:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-e8d4d80
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-e8d4d80 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-088011b
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-bd5941a {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-96e9cf2
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a9367d4
    .elementor-image
    img {
    width: 205px;
  }
  .custom-css-style .elementor-element.elementor-element-183f684 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-24b53d2.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-24b53d2
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-c9c393e {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-c9c393e
    .elementor-image
    img {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-c9c393e
     .elementor-widget-container {
    margin: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d95b506
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8456cf2
    .elementor-heading-title {
    font-size: 28px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8456cf2
     .elementor-widget-container {
    margin: 40px 0 0 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-e53fdd7 {
    top: -88%;
  }
  .custom-css-style .elementor-element.elementor-element-42b392b {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-9bafdb1 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-9bafdb1
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-2d8e628 {
    text-align: center;
    top: 90%;
  }
  .custom-css-style
    .elementor-element.elementor-element-2d8e628
    .elementor-heading-title {
    font-size: 50px;
    line-height: 1em;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-2d8e628 {
    right: 20%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-2d8e628 {
    left: 20%;
  }
  .custom-css-style
    .elementor-element.elementor-element-50256db.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-50256db
     .elementor-element-populated {
    margin: 40px 0 0 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-295cd9d {
    margin-top: 140px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-06a08e0
    .elementor-text-editor {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-06a08e0
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-1cb22f2 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-1cb22f2
     .elementor-widget-container {
    margin: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-57d796d.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-57d796d
     .elementor-element-populated {
    margin: 40px 0 0 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-3cc8f2d {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-3cc8f2d
    .elementor-image
    img {
    width: 90%;
  }
  .custom-css-style
    .elementor-element.elementor-element-3cc8f2d
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-66ed23f {
    bottom: -43%;
  }
  .custom-css-style
    .elementor-element.elementor-element-c411cf0
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-efe116b
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6a1c1da
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6a1c1da
     .elementor-widget-container {
    margin: 0 0 10px 0;
  }
  .custom-css-style .elementor-element.elementor-element-52dc38a {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c9f3e7c
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-6cd32de {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-6cd32de
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6cd32de
     .elementor-widget-container {
    margin: 0 0 30px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6a86839
    .food-menu-content-title-holder
    h3 {
    font-size: 22px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6a86839
    .food-menu-content-price-holder {
    font-size: 18px;
  }
  .custom-css-style .elementor-element.elementor-element-8c73091 {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c73091
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c73091
     .elementor-widget-container {
    margin: 20px 0 30px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7548d32
    .food-menu-content-title-holder
    h3 {
    font-size: 22px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7548d32
    .food-menu-content-price-holder {
    font-size: 18px;
  }
  .custom-css-style
    .elementor-element.elementor-element-61d58b4
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-99e2131 {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-99e2131
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-99e2131
     .elementor-widget-container {
    margin: 20px 0 30px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7ac7369
    .food-menu-content-title-holder
    h3 {
    font-size: 22px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7ac7369
    .food-menu-content-price-holder {
    font-size: 18px;
  }
  .custom-css-style .elementor-element.elementor-element-2fc9b99 {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-2fc9b99
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2fc9b99
     .elementor-widget-container {
    margin: 20px 0 30px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-47d0ea2
    .food-menu-content-title-holder
    h3 {
    font-size: 22px;
  }
  .custom-css-style
    .elementor-element.elementor-element-47d0ea2
    .food-menu-content-price-holder {
    font-size: 18px;
  }
  .custom-css-style
    .elementor-element.elementor-element-041856f
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-041856f
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-041856f:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-041856f
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-041856f {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f333ce5
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-bd023c9
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-bd023c9
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-db40567
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-db40567
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-0286ef1
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-56938e0
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-56938e0
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c22aa4e
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c22aa4e
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-c22aa4e:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-c22aa4e
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-c22aa4e {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c39c493
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1ae22a0
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e3d5380
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2921510
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2921510
     .elementor-widget-container {
    margin: 0 0 10px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7efb73b
     .elementor-container {
    min-height: 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7efb73b
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-7efb73b:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-7efb73b
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-7efb73b {
    margin-top: 0;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c3379f2
    .elementor-spacer-inner {
    height: 0;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-c9f3e7c {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-61d58b4 {
    width: 100%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-e3b069f
   .elementor-container {
  min-height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-e3b069f
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-e3b069f:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-e3b069f
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-e3b069f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 95px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-e3b069f
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-63c834d
   .elementor-element-populated {
  padding: 140px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-63c834d {
  z-index: 2;
}
.custom-css-style .elementor-element.elementor-element-6428adc {
  text-align: left;
  z-index: 3;
  width: 100%;
  max-width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-6428adc
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.custom-css-style .elementor-element.elementor-element-e71c87c {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-e71c87c
  .elementor-heading-title {
  color: #fff;
  font-size: 80px;
}
.custom-css-style
  .elementor-element.elementor-element-e71c87c
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style
  .elementor-element.elementor-element-84debc0
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-84debc0
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-2b6f949 {
  width: 210%;
  max-width: 210%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-2b6f949 {
  left: -87%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-2b6f949 {
  right: -87%;
}
.custom-css-style
  .elementor-element.elementor-element-c30d934:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-c30d934
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-c30d934 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 0 140px 0;
}
.custom-css-style
  .elementor-element.elementor-element-c30d934
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-109ff09
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-abd9e71
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e827bee
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-e827bee
   .elementor-widget-container {
  margin: 0 0 0 40px;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #c7a17a;
}
.elementor-element.elementor-element-e827bee {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-e827bee {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-e827bee {
  width: auto;
  max-width: auto;
  top: 0;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-e827bee {
  left: 0;
}
body.rtl .custom-css-style .elementor-element.elementor-element-e827bee {
  right: 0;
}
.custom-css-style
  .elementor-element.elementor-element-68b2b28
  .elementor-heading-title {
  color: #fff;
  font-size: 55px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-68b2b28
   .elementor-widget-container {
  margin: 50% 10% 0 0;
}
.elementor-element.elementor-element-68b2b28 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-68b2b28 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-f4263ef {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-f4263ef
  .elementor-image
  img {
  width: 250px;
  opacity: 1;
  filter: brightness(0) contrast(0) saturate(100%) blur(0) hue-rotate(0deg);
}
.custom-css-style
  .elementor-element.elementor-element-f4263ef
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.elementor-element.elementor-element-f4263ef {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.3s !important;
}
.elementor-widget.elementor-element-f4263ef {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-5861e0f {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-5861e0f
  .elementor-heading-title {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-5861e0f
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.elementor-element.elementor-element-5861e0f {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-5861e0f {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1c0053b
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.05);
  font-size: 300px;
}
.custom-css-style .elementor-element.elementor-element-1c0053b {
  width: 500vw;
  max-width: 500vw;
  top: 37%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-1c0053b {
  left: 14.833%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-1c0053b {
  right: 14.833%;
}
.custom-css-style .elementor-element.elementor-element-74ca67b {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-74ca67b
  .elementor-heading-title {
  color: #000;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-74ca67b
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-644248f:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-644248f
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-644248f
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-644248f
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-6f6f28f {
  width: 470px;
  max-width: 470px;
  top: -11%;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-181b7c2.elementor-column
  .elementor-column-wrap {
  align-items: flex-start;
}
.custom-css-style
  .elementor-element.elementor-element-181b7c2.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: flex-start;
  align-items: flex-start;
}
.custom-css-style
  .elementor-element.elementor-element-181b7c2:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-181b7c2
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-181b7c2
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 80px 0 0 0;
  padding: 60px 50px 60px 50px;
}
.custom-css-style
  .elementor-element.elementor-element-181b7c2
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-181b7c2 {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-94710fc
  .elementor-heading-title {
  color: #fff;
  font-size: 40px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-94710fc
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-07a0511 {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-07a0511
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-2350176
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #fff;
  color: #fff;
  background-color: #000;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #000;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2350176
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-2350176
  .elementor-button:hover {
  color: #fff;
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-2350176
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-2350176
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2350176
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-2350176 {
  width: 100%;
  max-width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-8e1818f
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-8e1818f
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-8e1818f:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-8e1818f
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: rgba(0, 0, 0, 0);
  background-image: url(../upload/bg-header-overlay-lg-black-2.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-8e1818f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 4;
}
.custom-css-style
  .elementor-element.elementor-element-8e1818f
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-86a5b6d
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-09d53a0
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0ffe668
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-0ffe668
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-0ffe668:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-0ffe668
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/ingredients-for-making-coffee-flat-lay-ASPVLKY.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-0ffe668
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-0ffe668 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -150px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c955dd1
   .elementor-element-populated {
  margin: 20px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-c210f40 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-c210f40
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-c210f40 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-c210f40 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-47b5589 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-47b5589
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
}
.custom-css-style
  .elementor-element.elementor-element-47b5589
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-47b5589 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-47b5589 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-bdce35b
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-bdce35b
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-bdce35b:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-bdce35b
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-black.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-bdce35b {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -150px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-bdce35b
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-85a86c3
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-20df643
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-be44c2f:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-be44c2f
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-be44c2f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-be44c2f
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-801ab45
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-79aa5a8
  .testimonials-card-wrapper
  .owl-carousel
  .shadow-effect {
  background: #111;
  border-color: #111;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-79aa5a8
  .testimonials-card-wrapper
  .owl-carousel
  .shadow-effect
  .testimonial-name {
  color: #fff;
  font-size: 24px;
}
.custom-css-style
  .elementor-element.elementor-element-79aa5a8
  .testimonials-card-wrapper
  .owl-carousel
  .shadow-effect
  .testimonial-info-desc {
  color: #fff;
  font-size: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-79aa5a8
  .testimonials-card-wrapper
  .owl-carousel
  .owl-dots
  .owl-dot
  span {
  background: rgba(255, 255, 255, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-79aa5a8
  .testimonials-card-wrapper
  .owl-carousel
  .owl-dots
  .owl-dot.active
  span {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b8d2731
   .elementor-container {
  min-height: 800px;
}
.custom-css-style
  .elementor-element.elementor-element-b8d2731
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style .elementor-element.elementor-element-b8d2731 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b8d2731:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-b8d2731
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-b8d2731
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-e3d0412
   .elementor-element-populated {
  padding: 170px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-5b806e8 {
  --divider-border-style: solid;
  --divider-color: #ad9a74;
  --divider-border-width: 4px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-5b806e8
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5b806e8
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-5b806e8
   .elementor-widget-container {
  margin: 0 0 0 -85px;
}
.elementor-element.elementor-element-5b806e8 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-5b806e8 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-16fe375
  .elementor-heading-title {
  color: #c7a17a;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-16fe375
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.elementor-element.elementor-element-16fe375 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-16fe375 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-16fe375 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-1c207ca {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-1c207ca
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-85d4b8d
   .elementor-element-populated {
  padding: 0 0 0 100px;
}
.custom-css-style
  .elementor-element.elementor-element-3d34e49
  .elementor-heading-title {
  color: #fff;
  font-size: 100px;
  line-height: 0.9em;
}
.custom-css-style .elementor-element.elementor-element-3d34e49 {
  z-index: 2;
  bottom: -47%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-3d34e49 {
  right: -16%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-3d34e49 {
  left: -16%;
}
.custom-css-style .elementor-element.elementor-element-2d56c72 {
  width: initial;
  max-width: initial;
  bottom: -20%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-2d56c72 {
  right: -51%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-2d56c72 {
  left: -51%;
}
.custom-css-style
  .elementor-element.elementor-element-b6d9c2c:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-b6d9c2c
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-b6d9c2c {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-b6d9c2c
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-9a5a03d.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-9a5a03d
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-77b10eb
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-77b10eb
   .elementor-widget-container {
  margin: 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #c7a17a;
}
.custom-css-style .elementor-element.elementor-element-77b10eb {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-5406d17 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-5406d17
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.custom-css-style
  .elementor-element.elementor-element-5406d17
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.elementor-element.elementor-element-5406d17 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-5406d17 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-88f6783 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-88f6783
  .elementor-heading-title {
  color: #fff;
  font-size: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-88f6783
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-88f6783 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-88f6783 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1c7dc8a
  .elementor-image
  img {
  width: 230px;
  opacity: 1;
}
.custom-css-style .elementor-element.elementor-element-1c7dc8a {
  width: 100%;
  max-width: 100%;
  top: -2%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-1c7dc8a {
  left: -45%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-1c7dc8a {
  right: -45%;
}
.custom-css-style
  .elementor-element.elementor-element-a2cb95f:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-a2cb95f
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-a2cb95f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 40px 0 80px 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-a2cb95f
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-993b823
   .elementor-element-populated {
  padding: 0 20% 0 20%;
}
.custom-css-style .elementor-element.elementor-element-993b823 {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-6f63d75
  .food-menu-content-title-holder
  h3 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6f63d75
  .food-menu-content-title-line {
  border-color: #fff;
}
.custom-css-style .elementor-element.elementor-element-6f63d75 .food-menu-desc {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6f63d75
  .food-menu-content-price-holder
  .food-menu-content-price-normal {
  color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-6f63d75
  .food-menu-content-price-holder
  .food-menu-content-price-sale {
  color: rgba(199, 161, 122, 0.58);
}
.custom-css-style
  .elementor-element.elementor-element-6f63d75
  .food-menu-content-highlight-holder
  h4 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6f63d75
  .food-menu-content-highlight-holder {
  background: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-6f63d75
  .food-menu-highlight {
  border-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-6f63d75
  .food-menu-tooltip-content {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6f63d75
  .food-menu-tooltip-content
  h5 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6f63d75
  .tooltipster-sidetip.tooltipster-shadow
  .tooltipster-box {
  background: #fffbf2;
}
.custom-css-style .elementor-element.elementor-element-f4a0449 {
  text-align: right;
  z-index: 3;
  top: -53%;
}
.custom-css-style
  .elementor-element.elementor-element-f4a0449
  .elementor-image
  img {
  width: 50%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-f4a0449 {
  right: -75%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-f4a0449 {
  left: -75%;
}
.custom-css-style
  .elementor-element.elementor-element-fd6fbae
  .elementor-image
  img {
  width: 260px;
  opacity: 1;
  filter: brightness(0) contrast(0) saturate(0) blur(0) hue-rotate(0deg);
}
.custom-css-style .elementor-element.elementor-element-fd6fbae {
  width: 100%;
  max-width: 100%;
  top: 16%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-fd6fbae {
  left: 79%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-fd6fbae {
  right: 79%;
}
.custom-css-style .elementor-element.elementor-element-a865f10 {
  text-align: right;
  z-index: 3;
  top: 20%;
}
.custom-css-style
  .elementor-element.elementor-element-a865f10
  .elementor-image
  img {
  width: 50%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-a865f10 {
  left: -124%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-a865f10 {
  right: -124%;
}
.custom-css-style .elementor-element.elementor-element-bf91dd9 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 100px 40px 100px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-bf91dd9:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-bf91dd9
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-bf91dd9
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-0807839:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-0807839
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #1f1e1d;
}
.custom-css-style
  .elementor-element.elementor-element-0807839
   .elementor-element-populated {
  border-style: solid;
  border-width: 0;
  border-color: #c7a17a;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0;
  padding: 20px 70px 20px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-0807839
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-0807839 {
  z-index: 3;
}
.custom-css-style .elementor-element.elementor-element-d0f9595 {
  border-style: solid;
  border-width: 0;
  border-color: #c7a17a;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 40px 30px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-d0f9595
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-f4c2905
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-cdf3312 {
  --divider-border-style: solid;
  --divider-color: #c7a17a;
  --divider-border-width: 4px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-cdf3312
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-cdf3312
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-cdf3312
   .elementor-widget-container {
  margin: 0 0 0 -85px;
}
.elementor-element.elementor-element-cdf3312 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-cdf3312 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c425ebc
  .elementor-heading-title {
  color: #c7a17a;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-c425ebc
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.elementor-element.elementor-element-c425ebc {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-c425ebc {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-c425ebc {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-6e14cd6.elementor-view-stacked
  .elementor-icon {
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-6e14cd6.elementor-view-default
  .elementor-icon,
.custom-css-style
  .elementor-element.elementor-element-6e14cd6.elementor-view-framed
  .elementor-icon {
  fill: #c7a17a;
  color: #c7a17a;
  border-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-6e14cd6.elementor-position-right
  .elementor-icon-box-icon {
  margin-left: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-6e14cd6.elementor-position-left
  .elementor-icon-box-icon {
  margin-right: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-6e14cd6.elementor-position-top
  .elementor-icon-box-icon {
  margin-bottom: 30px;
}
.custom-css-style .elementor-element.elementor-element-6e14cd6 .elementor-icon {
  font-size: 28px;
}
.custom-css-style
  .elementor-element.elementor-element-6e14cd6
  .elementor-icon
  i {
  transform: rotate(0);
}
.custom-css-style
  .elementor-element.elementor-element-6e14cd6
  .elementor-icon-box-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-6e14cd6
  .elementor-icon-box-title {
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6e14cd6
  .elementor-icon-box-content
  .elementor-icon-box-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6e14cd6
  .elementor-icon-box-content
  .elementor-icon-box-title,
.custom-css-style
  .elementor-element.elementor-element-6e14cd6
  .elementor-icon-box-content
  .elementor-icon-box-title
  a {
  font-size: 22px;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-6e14cd6
  .elementor-icon-box-content
  .elementor-icon-box-description {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6e14cd6
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-7718d32.elementor-view-stacked
  .elementor-icon {
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-7718d32.elementor-view-default
  .elementor-icon,
.custom-css-style
  .elementor-element.elementor-element-7718d32.elementor-view-framed
  .elementor-icon {
  fill: #c7a17a;
  color: #c7a17a;
  border-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-7718d32.elementor-position-right
  .elementor-icon-box-icon {
  margin-left: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-7718d32.elementor-position-left
  .elementor-icon-box-icon {
  margin-right: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-7718d32.elementor-position-top
  .elementor-icon-box-icon {
  margin-bottom: 30px;
}
.custom-css-style .elementor-element.elementor-element-7718d32 .elementor-icon {
  font-size: 28px;
}
.custom-css-style
  .elementor-element.elementor-element-7718d32
  .elementor-icon
  i {
  transform: rotate(0);
}
.custom-css-style
  .elementor-element.elementor-element-7718d32
  .elementor-icon-box-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-7718d32
  .elementor-icon-box-title {
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7718d32
  .elementor-icon-box-content
  .elementor-icon-box-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7718d32
  .elementor-icon-box-content
  .elementor-icon-box-title,
.custom-css-style
  .elementor-element.elementor-element-7718d32
  .elementor-icon-box-content
  .elementor-icon-box-title
  a {
  font-size: 22px;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-7718d32
  .elementor-icon-box-content
  .elementor-icon-box-description {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7718d32
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-37abee2
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-6a06ee8
  .elementor-image
  img {
  width: 240px;
  opacity: 0.3;
  filter: brightness(200%) contrast(0) saturate(100%) blur(0) hue-rotate(0deg);
}
.custom-css-style .elementor-element.elementor-element-6a06ee8 {
  width: 100%;
  max-width: 100%;
  top: -10%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-6a06ee8 {
  left: 72%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-6a06ee8 {
  right: 72%;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-c4e4c13.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-c4e4c13.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-c4e4c13
   .elementor-element-populated {
  margin: 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-f5e0c03 .eb-map {
  height: 500px;
}
.custom-css-style .elementor-element.elementor-element-f5e0c03 {
  z-index: 3;
  top: 23%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-f5e0c03 {
  right: 8%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-f5e0c03 {
  left: 8%;
}
.custom-css-style
  .elementor-element.elementor-element-9170921
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.05);
  font-size: 400px;
}
.custom-css-style .elementor-element.elementor-element-9170921 {
  width: 500vw;
  max-width: 500vw;
  top: 53%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-9170921 {
  left: 14.833%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-9170921 {
  right: 14.833%;
}
:root {
  --page-title-display: none;
}
body.elementor-page-4582:not(.elementor-motion-effects-element-type-background),
body.elementor-page-4582
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-63c834d {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-84debc0 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-644248f {
    width: 25%;
  }
  .custom-css-style .elementor-element.elementor-element-181b7c2 {
    width: 41.332%;
  }
  .custom-css-style .elementor-element.elementor-element-e3d0412 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-85d4b8d {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-0807839 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-c4e4c13 {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-e3b069f
     .elementor-container {
    min-height: 460px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e3b069f
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-e3b069f {
    padding: 70px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-63c834d
     .elementor-element-populated {
    margin: 0;
    padding: 100px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6428adc
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e71c87c
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-84debc0
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-c30d934 {
    padding: 60px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e827bee
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e827bee
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-68b2b28
    .elementor-heading-title {
    font-size: 36px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4263ef
    .elementor-image
    img {
    width: 200px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4263ef
     .elementor-widget-container {
    margin: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1c0053b
    .elementor-heading-title {
    font-size: 240px;
  }
  .custom-css-style .elementor-element.elementor-element-6f6f28f {
    width: 350px;
    max-width: 350px;
    top: -6%;
  }
  .custom-css-style
    .elementor-element.elementor-element-181b7c2
     .elementor-element-populated {
    margin: 120px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-94710fc
    .elementor-heading-title {
    font-size: 32px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-07a0511
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-2350176 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-8e1818f
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8e1818f
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-8e1818f:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-8e1818f
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-8e1818f {
    margin-top: 0;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0ffe668
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0ffe668
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-0ffe668 {
    margin-top: -80px;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c210f40
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-47b5589
    .elementor-heading-title {
    font-size: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-47b5589
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-bdce35b
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-bdce35b
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-bdce35b:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-bdce35b
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-bdce35b {
    margin-top: -75px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8d2731
     .elementor-container {
    min-height: 730px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8d2731
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-b8d2731 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e3d0412
     .elementor-element-populated {
    margin: 0;
    padding: 60px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5b806e8
     .elementor-widget-container {
    margin: 0 0 0 -100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1c207ca
     .elementor-widget-container {
    margin: 20px 40px 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-85d4b8d
     .elementor-element-populated {
    margin: 0 0 0 20px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-3d34e49
    .elementor-heading-title {
    font-size: 70px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-3d34e49 {
    right: -2%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-3d34e49 {
    left: -2%;
  }
  .custom-css-style .elementor-element.elementor-element-3d34e49 {
    bottom: 9%;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-2d56c72 {
    right: -69%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-2d56c72 {
    left: -69%;
  }
  .custom-css-style .elementor-element.elementor-element-2d56c72 {
    bottom: 17%;
  }
  .custom-css-style
    .elementor-element.elementor-element-77b10eb
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-77b10eb
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5406d17
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-88f6783
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style .elementor-element.elementor-element-1c7dc8a {
    top: 113%;
  }
  .custom-css-style .elementor-element.elementor-element-a2cb95f {
    padding: 40px 0 20px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4a0449
     .elementor-widget-container {
    margin: 80px 0 0 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-f4a0449 {
    right: -120%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-f4a0449 {
    left: -120%;
  }
  .custom-css-style .elementor-element.elementor-element-f4a0449 {
    top: -57%;
  }
  .custom-css-style .elementor-element.elementor-element-fd6fbae {
    top: 113%;
  }
  .custom-css-style
    .elementor-element.elementor-element-a865f10
     .elementor-widget-container {
    margin: 80px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-a865f10 {
    top: -57%;
  }
  .custom-css-style .elementor-element.elementor-element-bf91dd9 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0807839
     .elementor-element-populated {
    margin: 0;
    padding: 15px 15px 15px 15px;
  }
  .custom-css-style .elementor-element.elementor-element-d0f9595 {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-cdf3312
     .elementor-widget-container {
    margin: 0 0 0 -100px;
  }
  .custom-css-style .elementor-element.elementor-element-6a06ee8 {
    top: 113%;
  }
  .custom-css-style .elementor-element.elementor-element-f5e0c03 .eb-map {
    height: 600px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-f5e0c03 {
    right: 0;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-f5e0c03 {
    left: 0;
  }
  .custom-css-style .elementor-element.elementor-element-f5e0c03 {
    top: 12%;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-e3b069f
     .elementor-container {
    min-height: 800px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e3b069f
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-e3b069f {
    padding: 140px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-63c834d
     .elementor-element-populated {
    margin: 40px 20px 0 20px;
    padding: 20px 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6428adc
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e71c87c
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style .elementor-element.elementor-element-c30d934 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-109ff09
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-abd9e71
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e827bee
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-68b2b28
     .elementor-widget-container {
    margin: 60px 20px 0 20px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4263ef
     .elementor-widget-container {
    margin: 20px 0 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5861e0f
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1c0053b
    .elementor-heading-title {
    font-size: 200px;
  }
  .custom-css-style
    .elementor-element.elementor-element-74ca67b
     .elementor-widget-container {
    margin: 0 0 60px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-644248f.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-644248f
     .elementor-element-populated {
    padding: 0 0 350px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6f6f28f
    .elementor-image
    img {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-6f6f28f {
    width: 320px;
    max-width: 320px;
  }
  .custom-css-style
    .elementor-element.elementor-element-181b7c2
     .elementor-element-populated {
    margin: 160px 20px 0 20px;
    padding: 60px 30px 60px 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-07a0511
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8e1818f
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8e1818f
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-8e1818f:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-8e1818f
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-8e1818f {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-86a5b6d
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-09d53a0
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-09d53a0
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0ffe668
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0ffe668
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-0ffe668 {
    margin-top: -75px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c210f40
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-47b5589
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-47b5589
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-bdce35b
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-bdce35b
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-bdce35b:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-bdce35b
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-bdce35b {
    margin-top: -40px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-20df643
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-be44c2f {
    margin-top: -40px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-79aa5a8
    .testimonials-card-wrapper
    .owl-carousel
    .shadow-effect
    .testimonial-info-desc {
    font-size: 13px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8d2731
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8d2731
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-b8d2731 {
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e3d0412
     .elementor-element-populated {
    margin: 0 20px 0 20px;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-16fe375
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1c207ca
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-3d34e49 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-3d34e49
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style .elementor-element.elementor-element-2d56c72 {
    bottom: -200%;
  }
  .custom-css-style
    .elementor-element.elementor-element-77b10eb
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5406d17
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-88f6783
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-88f6783
     .elementor-widget-container {
    margin: 0 0 10px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-993b823
     .elementor-element-populated {
    margin: 0;
    padding: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6f63d75
    .food-menu-content-title-holder
    h3 {
    font-size: 22px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6f63d75
    .food-menu-content-price-holder {
    font-size: 18px;
  }
  .custom-css-style .elementor-element.elementor-element-f4a0449 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4a0449
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-a865f10 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-a865f10
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-bf91dd9 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0807839
     .elementor-element-populated {
    margin: 400px 0 40px 0;
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style .elementor-element.elementor-element-d0f9595 {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f4c2905
     .elementor-element-populated {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c425ebc
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6e14cd6
    .elementor-icon-box-icon {
    margin-bottom: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7718d32
    .elementor-icon-box-icon {
    margin-bottom: 30px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-6a06ee8 {
    left: 10%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-6a06ee8 {
    right: 10%;
  }
  .custom-css-style
    .elementor-element.elementor-element-c4e4c13
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-f5e0c03 .eb-map {
    height: 400px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-644248f {
    width: 10%;
  }
  .custom-css-style .elementor-element.elementor-element-181b7c2 {
    width: 55%;
  }
  .custom-css-style .elementor-element.elementor-element-0807839 {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-c4e4c13 {
    width: 50%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-4d1dc58
   .elementor-container {
  min-height: 500px;
}
.custom-css-style
  .elementor-element.elementor-element-4d1dc58
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-4d1dc58
   .elementor-container
   .elementor-row
   .elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  align-content: flex-end;
  align-items: flex-end;
}
.custom-css-style
  .elementor-element.elementor-element-4d1dc58:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-4d1dc58
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/joshua-rodriguez-f7zm5TDOi4g-unsplash.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-4d1dc58 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 40px;
  margin-bottom: 0;
  padding: 30px 30px 30px 30px;
}
.custom-css-style
  .elementor-element.elementor-element-4d1dc58
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-63640e5.elementor-column
  .elementor-column-wrap {
  align-items: flex-end;
}
.custom-css-style
  .elementor-element.elementor-element-63640e5.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: flex-end;
  align-items: flex-end;
}
.custom-css-style
  .elementor-element.elementor-element-63640e5:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-63640e5
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-63640e5
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-63640e5
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-81d1bec {
  border-style: solid;
  border-width: 0;
  border-color: #fff;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 5px 5px 5px 5px;
}
.custom-css-style
  .elementor-element.elementor-element-81d1bec
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-ffaad0d
   .elementor-element-populated {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 30px 30px 30px;
}
.custom-css-style
  .elementor-element.elementor-element-ffaad0d
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-f6d1373
  .elementor-heading-title {
  color: #fff;
  font-size: 50px;
}
.custom-css-style .elementor-element.elementor-element-2c2b5e5 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-2c2b5e5
  .elementor-heading-title {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-2c2b5e5
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-a0af5c0
   .elementor-container {
  min-height: 400px;
}
.custom-css-style
  .elementor-element.elementor-element-a0af5c0
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style .elementor-element.elementor-element-a0af5c0 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-c8d5b00:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-c8d5b00
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/coffee-cafe-barista-apron-uniform-brew-concept-PUPM2KN.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-c8d5b00
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-c8d5b00
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-386460a:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-386460a
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-386460a
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-386460a
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-7e48f26 {
  border-style: solid;
  border-width: 0;
  border-color: #fff;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 5px 5px 5px 5px;
}
.custom-css-style
  .elementor-element.elementor-element-7e48f26
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-55e1317
   .elementor-element-populated {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 30px 30px 30px;
}
.custom-css-style
  .elementor-element.elementor-element-55e1317
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-74430cb
  .elementor-heading-title {
  color: #fff;
  font-size: 40px;
  line-height: 1.1em;
}
.custom-css-style .elementor-element.elementor-element-0f992ff {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-0f992ff
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-3a17b93 {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-59e3ce5
   .elementor-element-populated {
  padding: 40px 40px 20px 0;
}
.custom-css-style
  .elementor-element.elementor-element-ea772b1
  .elementor-heading-title {
  color: #000;
  font-size: 40px;
  line-height: 1.1em;
}
.custom-css-style .elementor-element.elementor-element-fceff85 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-fceff85
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-dc3ecec
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #000;
  color: #000;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #000;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-dc3ecec
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-dc3ecec
  .elementor-button:hover {
  color: #fff;
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-dc3ecec
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-dc3ecec
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-dc3ecec
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-dc3ecec {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-b46a21a
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0 0 40px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-b46a21a
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-8d7c08a
  .service-grid-wrapper {
  height: 520px;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-8d7c08a
  .service-grid-wrapper
  img {
  max-width: 50px !important;
}
.custom-css-style
  .elementor-element.elementor-element-8d7c08a
  .service-grid-wrapper
  i {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8d7c08a
  .service-grid-wrapper
  .overflow-inner
  .header-wrap
  h2 {
  color: #fff;
  font-size: 32px;
}
.custom-css-style
  .elementor-element.elementor-element-8d7c08a
  .service-grid-wrapper
  .overflow-inner
  .hover-content {
  color: #fff;
}
.custom-css-style .elementor-element.elementor-element-c568dcd {
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c568dcd
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-c3556cc
   .elementor-element-populated {
  padding: 60px 40px 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-f49441e
  .elementor-heading-title {
  color: #000;
  font-size: 40px;
  line-height: 1.1em;
}
.custom-css-style .elementor-element.elementor-element-362c920 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-362c920
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-43e21b0
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #000;
  color: #000;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #000;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-43e21b0
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-43e21b0
  .elementor-button:hover {
  color: #fff;
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-43e21b0
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-43e21b0
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-43e21b0
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-43e21b0 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-45dc006
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 0 0 40px;
}
.custom-css-style
  .elementor-element.elementor-element-45dc006
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-501ce06
  ul.products
  li.product
  h2.woocommerce-loop-product__title {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501ce06
  ul.products
  li.product {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-501ce06
  ul.products
  li.product
  span.price {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-501ce06
  ul.products
  li.product
  .onsale {
  background: #d22226;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-501ce06
  ul.products
  li.product
  a.button.ajax_add_to_cart {
  background: #000;
  border-color: #000;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-501ce06
  ul.products
  li.product
  a.button.ajax_add_to_cart:hover {
  background: #000;
  border-color: #000;
  color: #fff;
}
.custom-css-style .elementor-element.elementor-element-ce38327 {
  border-style: solid;
  border-width: 1px 0 1px 0;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 40px;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ce38327
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-a9cf87d
   .elementor-element-populated {
  padding: 60px 40px 60px 0;
}
.custom-css-style
  .elementor-element.elementor-element-de42897
  .elementor-heading-title {
  color: #000;
  font-size: 40px;
  line-height: 1.1em;
}
.custom-css-style .elementor-element.elementor-element-b5cd618 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-b5cd618
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-760ab70
   .elementor-widget-container {
  margin: 20px 40px 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-2eb916c
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 60px 40px 60px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-2eb916c
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-c67bd8d
  .elementor-heading-title {
  color: #000;
  font-size: 40px;
  line-height: 1.1em;
}
.custom-css-style .elementor-element.elementor-element-005851d {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-005851d
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-0325ffa
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-2ec3afa
   .elementor-element-populated {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 60px 40px 60px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-2ec3afa
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-74ed0eb
  .elementor-heading-title {
  color: #000;
  font-size: 40px;
  line-height: 1.1em;
}
.custom-css-style .elementor-element.elementor-element-d7be0f1 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-d7be0f1
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-0fb36b8
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
:root {
  --page-title-display: none;
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-63640e5 {
    width: 45%;
  }
  .custom-css-style .elementor-element.elementor-element-6f8f728 {
    width: 55%;
  }
  .custom-css-style .elementor-element.elementor-element-59e3ce5 {
    width: 33%;
  }
  .custom-css-style .elementor-element.elementor-element-b46a21a {
    width: 66.665%;
  }
  .custom-css-style .elementor-element.elementor-element-c3556cc {
    width: 33%;
  }
  .custom-css-style .elementor-element.elementor-element-45dc006 {
    width: 66.665%;
  }
  .custom-css-style .elementor-element.elementor-element-a9cf87d {
    width: 33%;
  }
  .custom-css-style .elementor-element.elementor-element-2eb916c {
    width: 33.332%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style .elementor-element.elementor-element-4d1dc58 {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f6d1373
    .elementor-heading-title {
    font-size: 40px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-a0af5c0
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a0af5c0
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-74430cb
    .elementor-heading-title {
    font-size: 36px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-0f992ff
     .elementor-widget-container {
    margin: 20px 40px 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-59e3ce5
     .elementor-element-populated {
    margin: 20px 0 0 0;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ea772b1
    .elementor-heading-title {
    font-size: 36px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-fceff85
     .elementor-widget-container {
    margin: 0 20px 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-dc3ecec {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-b46a21a
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8d7c08a
    .service-grid-wrapper
    img {
    max-width: 45px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-8d7c08a
    .service-grid-wrapper
    .overflow-inner
    .header-wrap
    h2 {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-c568dcd {
    margin-top: 60px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c3556cc
     .elementor-element-populated {
    margin: 0 0 60px 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f49441e
    .elementor-heading-title {
    font-size: 36px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-362c920
     .elementor-widget-container {
    margin: 0 20px 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-43e21b0 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-45dc006
     .elementor-element-populated {
    border-width: 0;
    margin: 40px 0 0 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-ce38327 {
    border-width: 1px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a9cf87d
     .elementor-element-populated {
    margin: 40px 0 0 0;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-de42897
    .elementor-heading-title {
    font-size: 36px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-b5cd618
     .elementor-widget-container {
    margin: 0 20px 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2eb916c
     .elementor-element-populated {
    border-width: 0;
    margin: 40px 0 0 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c67bd8d
    .elementor-heading-title {
    font-size: 36px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-005851d
     .elementor-widget-container {
    margin: 0 20px 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2ec3afa
     .elementor-element-populated {
    border-width: 0;
    margin: 40px 0 0 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-74ed0eb
    .elementor-heading-title {
    font-size: 36px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-d7be0f1
     .elementor-widget-container {
    margin: 0 20px 0 0;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-4d1dc58
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4d1dc58
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-4d1dc58 {
    margin-top: 60px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-63640e5
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f6d1373
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2c2b5e5
     .elementor-widget-container {
    margin: 10px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-74430cb
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0f992ff
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-59e3ce5
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ea772b1
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fceff85
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b46a21a
     .elementor-element-populated {
    border-width: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8d7c08a
    .service-grid-wrapper
    img {
    max-width: 50px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-8d7c08a
    .service-grid-wrapper
    .overflow-inner
    .header-wrap
    h2 {
    font-size: 40px;
  }
  .custom-css-style .elementor-element.elementor-element-c568dcd {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c3556cc
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f49441e
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-362c920
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-45dc006
     .elementor-element-populated {
    border-width: 0;
    margin: 0;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a9cf87d
     .elementor-element-populated {
    padding: 20px 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-de42897
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b5cd618
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-760ab70
     .elementor-widget-container {
    margin: 20px 0 0 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2eb916c
     .elementor-element-populated {
    margin: 0;
    padding: 0 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c67bd8d
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-005851d
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2ec3afa
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-74ed0eb
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d7be0f1
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-63640e5 {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-c8d5b00 {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-386460a {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-59e3ce5 {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-b46a21a {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-c3556cc {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-45dc006 {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-a9cf87d {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-2eb916c {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-2ec3afa {
    width: 100%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-8788954
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper {
  height: 750px;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  .slide
  .image-container::before {
  background: rgba(0, 0, 0, 0.3);
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  .slide-content {
  text-align: left;
  margin-top: 20px;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  div.slide-content {
  justify-content: left;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  .slide-content
  .standard-wrapper {
  justify-content: left;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  .slide
  .title
  h2 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  .slider_parallax_slides
  .slide
  .title
  h2 {
  font-size: 80px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  .slide
  .caption {
  width: 48%;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  .slide
  .text {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  .slide
  a.button {
  background-color: rgba(34, 34, 34, 0);
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  .slide
  .button {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  div.slide
  a.button {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  .slide
  a.button:hover {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  .slide
  .button:hover {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  div.slide
  a.button:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  div.slider-parallax-wrapper
  div.slide
  a.button {
  font-size: 20px;
}
.custom-css-style
  .elementor-element.elementor-element-8c2164d
  .slider-parallax-wrapper
  .pagination
  .item::before {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-dd13ab8
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-dd13ab8
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-dd13ab8:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-dd13ab8
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-dd13ab8 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-dd13ab8
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-0dc025f
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f761604
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-aae75dc
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-aae75dc
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-aae75dc:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-aae75dc
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-aae75dc {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-aae75dc
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-c879994
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c624154
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-c3806dc {
  margin-top: -250px;
  margin-bottom: 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-349dd0a.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-349dd0a
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f8bacc1
  .elementor-spacer-inner {
  height: 200px;
}
.custom-css-style
  .elementor-element.elementor-element-f8bacc1
   .elementor-widget-container {
  margin: 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
}
.elementor-element.elementor-element-f8bacc1 {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-f8bacc1 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-f8bacc1 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-0a2fa18 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-0a2fa18
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.custom-css-style
  .elementor-element.elementor-element-0a2fa18
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.elementor-element.elementor-element-0a2fa18 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-0a2fa18 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1f1bc7d
  .elementor-image
  img {
  width: 230px;
}
.custom-css-style .elementor-element.elementor-element-1f1bc7d {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: 35%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-1f1bc7d {
  left: 50%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-1f1bc7d {
  right: 50%;
}
.custom-css-style .elementor-element.elementor-element-5989ae9 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-5989ae9
  .elementor-heading-title {
  color: #000;
  font-size: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-5989ae9
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-5989ae9 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-5989ae9 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6585a04
   .elementor-container {
  min-height: 740px;
}
.custom-css-style
  .elementor-element.elementor-element-6585a04
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style .elementor-element.elementor-element-6585a04 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6585a04
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-76b8211
   .elementor-element-populated {
  padding: 0 0 0 100px;
}
.custom-css-style
  .elementor-element.elementor-element-4ba1d2f
  .elementor-heading-title {
  color: #000;
  font-size: 100px;
  line-height: 0.9em;
}
.custom-css-style .elementor-element.elementor-element-4ba1d2f {
  z-index: 2;
  bottom: -59%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-4ba1d2f {
  left: -13%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-4ba1d2f {
  right: -13%;
}
.custom-css-style .elementor-element.elementor-element-7c9f668 {
  width: initial;
  max-width: initial;
  bottom: -37%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-7c9f668 {
  left: -43%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-7c9f668 {
  right: -43%;
}
.custom-css-style
  .elementor-element.elementor-element-1f7a1bf
   .elementor-element-populated {
  padding: 170px 0 0 50px;
}
.custom-css-style .elementor-element.elementor-element-1f7a1bf {
  z-index: 2;
}
.custom-css-style .elementor-element.elementor-element-8bb6e1e {
  --divider-border-style: solid;
  --divider-color: #000000;
  --divider-border-width: 1px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-8bb6e1e
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-8bb6e1e
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-8bb6e1e
   .elementor-widget-container {
  margin: 4px 0 0 -85px;
}
.elementor-element.elementor-element-8bb6e1e {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-8bb6e1e {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-bbab5a5
  .elementor-heading-title {
  color: #000;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-bbab5a5
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-bbab5a5 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-402ca2e {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-402ca2e
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-5bc627a
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-5bc627a
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-5bc627a:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-5bc627a
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-2.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-5bc627a {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-5bc627a
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-9c7ea32
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-26c36dc
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2d132ec
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-2d132ec
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-2d132ec:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-2d132ec
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/tim-st-martin-IjnAc0vyqGs-unsplash.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-2d132ec
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-2d132ec {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -100px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b2cea3e
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-760ea7d {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-760ea7d
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-760ea7d {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-760ea7d {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-37a9cbe {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-37a9cbe
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
}
.custom-css-style
  .elementor-element.elementor-element-37a9cbe
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-37a9cbe {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-37a9cbe {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b5a4bc4
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-b5a4bc4
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-b5a4bc4:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-b5a4bc4
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-b5a4bc4 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b5a4bc4
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-fff8ebd
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-00c2ae9
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-2058fec {
  margin-top: -200px;
  margin-bottom: 0;
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-62a061f.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-62a061f
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-fae6fed
  .elementor-spacer-inner {
  height: 200px;
}
.custom-css-style
  .elementor-element.elementor-element-fae6fed
   .elementor-widget-container {
  margin: 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
}
.custom-css-style .elementor-element.elementor-element-fae6fed {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-c446521 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 60px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-c446521
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-96638bb
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e6db2d6
  .service-grid-wrapper {
  height: 550px;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e6db2d6
  .service-grid-wrapper
  img {
  max-width: 50px !important;
}
.custom-css-style
  .elementor-element.elementor-element-e6db2d6
  .service-grid-wrapper
  i {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e6db2d6
  .service-grid-wrapper
  .overflow-inner
  .header-wrap
  h2 {
  color: #fff;
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-e6db2d6
  .service-grid-wrapper
  .overflow-inner
  .hover-content {
  color: #fff;
}
.custom-css-style .elementor-element.elementor-element-5b4ffee {
  overflow: hidden;
  padding: 60px 0 120px 0;
}
.custom-css-style
  .elementor-element.elementor-element-ce7f409
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c5d8159
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-688200d
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-688200d
   .elementor-widget-container {
  margin: 0 0 0 40px;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-688200d {
  left: 0;
}
body.rtl .custom-css-style .elementor-element.elementor-element-688200d {
  right: 0;
}
.custom-css-style .elementor-element.elementor-element-688200d {
  top: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ceefb8c
  .elementor-image
  img {
  width: 110px;
}
.custom-css-style .elementor-element.elementor-element-ceefb8c {
  width: 100%;
  max-width: 100%;
  bottom: 52%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-ceefb8c {
  left: -55%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-ceefb8c {
  right: -55%;
}
.custom-css-style
  .elementor-element.elementor-element-b7df262
  .elementor-heading-title {
  font-size: 55px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-b7df262
   .elementor-widget-container {
  margin: 50% 10% 0 0;
}
.custom-css-style .elementor-element.elementor-element-f1622c1 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-f1622c1
  .elementor-image
  img {
  width: 250px;
  filter: brightness(0) contrast(100%) saturate(100%) blur(0) hue-rotate(0deg);
}
.custom-css-style
  .elementor-element.elementor-element-f1622c1
   .elementor-widget-container {
  margin: 28px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-075a6dc {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-075a6dc
  .elementor-heading-title {
  color: #000;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-075a6dc
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-9fd90ed
  .elementor-image
  img {
  width: 260px;
}
.custom-css-style .elementor-element.elementor-element-9fd90ed {
  width: 100%;
  max-width: 100%;
  bottom: -16%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-9fd90ed {
  left: -55%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-9fd90ed {
  right: -55%;
}
.custom-css-style
  .elementor-element.elementor-element-c213dce:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-c213dce
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: rgba(0, 0, 0, 0);
}
.custom-css-style
  .elementor-element.elementor-element-c213dce
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c213dce
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-b647380 {
  width: 470px;
  max-width: 470px;
  top: 5%;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-bd7fca6.elementor-column
  .elementor-column-wrap {
  align-items: flex-start;
}
.custom-css-style
  .elementor-element.elementor-element-bd7fca6.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: flex-start;
  align-items: flex-start;
}
.custom-css-style
  .elementor-element.elementor-element-bd7fca6:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-bd7fca6
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-bd7fca6
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 200px 0 0 0;
  padding: 60px 50px 60px 50px;
}
.custom-css-style
  .elementor-element.elementor-element-bd7fca6
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-bd7fca6 {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-c951798
  .elementor-heading-title {
  color: #fff;
  font-size: 40px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-c951798
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-9abb5bd {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-9abb5bd
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-473d5e2
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #c7a17a;
  color: #c7a17a;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c7a17a;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-473d5e2
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-473d5e2
  .elementor-button:hover {
  color: #fff;
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-473d5e2
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-473d5e2
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-473d5e2
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-473d5e2 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-f8e743a
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-f8e743a
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-f8e743a:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-f8e743a
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-2.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-f8e743a {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-f8e743a
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-26fa2b0
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-60781f7
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-332af23
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-332af23
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-332af23:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-332af23
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/jonathan-borba-O2fAWP7uDSQ-unsplash.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-332af23
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-332af23 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -100px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-93b13dd
   .elementor-element-populated {
  margin: 40px 0 0 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-e5ce508 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-e5ce508
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.elementor-element.elementor-element-e5ce508 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-e5ce508 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-4b7f987 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-4b7f987
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
}
.custom-css-style
  .elementor-element.elementor-element-4b7f987
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-4b7f987 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-4b7f987 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f30dbd1
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-f30dbd1
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-f30dbd1:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-f30dbd1
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-f30dbd1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f30dbd1
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-ad509ed
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b7cf77d
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9cf6187
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-357fd3e
  ul.products
  li.product
  h2.woocommerce-loop-product__title {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-357fd3e
  ul.products
  li.product {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-357fd3e
  ul.products
  li.product
  span.price {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-357fd3e
  ul.products
  li.product
  .onsale {
  background: #d22226;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-357fd3e
  ul.products
  li.product
  a.button.ajax_add_to_cart {
  background: #000;
  border-color: #000;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-357fd3e
  ul.products
  li.product
  a.button.ajax_add_to_cart:hover {
  background: #000;
  border-color: #000;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-357fd3e
   .elementor-widget-container {
  margin: 0 0 40px 0;
}
:root {
  --page-title-display: none;
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-76b8211 {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-1f7a1bf {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-c213dce {
    width: 25%;
  }
  .custom-css-style .elementor-element.elementor-element-bd7fca6 {
    width: 41.332%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-8c2164d
    .slider-parallax-wrapper
    .slider_parallax_slides
    .slide
    .title
    h2 {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c2164d
    .slider-parallax-wrapper
    .slide
    .caption {
    width: 70%;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c2164d
    .slider-parallax-wrapper
    .slide-content {
    margin-top: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-dd13ab8
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-dd13ab8
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-dd13ab8:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-dd13ab8
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-dd13ab8 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-aae75dc:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-aae75dc
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-aae75dc {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-c3806dc {
    margin-top: -200px;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f8bacc1
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f8bacc1
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0a2fa18
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1f1bc7d
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5989ae9
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6585a04
     .elementor-container {
    min-height: 720px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6585a04
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-6585a04 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-76b8211
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-4ba1d2f
    .elementor-heading-title {
    font-size: 70px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-4ba1d2f {
    left: 4%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-4ba1d2f {
    right: 4%;
  }
  .custom-css-style .elementor-element.elementor-element-4ba1d2f {
    bottom: -3%;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-7c9f668 {
    left: -45%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-7c9f668 {
    right: -45%;
  }
  .custom-css-style .elementor-element.elementor-element-7c9f668 {
    bottom: 23%;
  }
  .custom-css-style
    .elementor-element.elementor-element-1f7a1bf
     .elementor-element-populated {
    margin: 0;
    padding: 120px 0 0 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8bb6e1e
     .elementor-widget-container {
    margin: 0 0 0 -100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-402ca2e
     .elementor-widget-container {
    margin: 20px 40px 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5bc627a
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5bc627a
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-5bc627a:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-5bc627a
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-5bc627a {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2d132ec
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2d132ec
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-760ea7d
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-37a9cbe
    .elementor-heading-title {
    font-size: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b5a4bc4
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b5a4bc4
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-b5a4bc4:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-b5a4bc4
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-b5a4bc4 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-2058fec {
    margin-top: -120px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-fae6fed
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fae6fed
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-c446521 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e6db2d6
    .service-grid-wrapper
    img {
    max-width: 45px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-e6db2d6
    .service-grid-wrapper
    .overflow-inner
    .header-wrap
    h2 {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-5b4ffee {
    padding: 120px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c5d8159
     .elementor-element-populated {
    padding: 0 20px 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-688200d
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-688200d
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-ceefb8c {
    left: 54%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-ceefb8c {
    right: 54%;
  }
  .custom-css-style .elementor-element.elementor-element-ceefb8c {
    bottom: 18%;
  }
  .custom-css-style
    .elementor-element.elementor-element-b7df262
    .elementor-heading-title {
    font-size: 36px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-f1622c1
     .elementor-widget-container {
    margin: 20px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-b647380 {
    width: 350px;
    max-width: 350px;
    top: -6%;
  }
  .custom-css-style
    .elementor-element.elementor-element-bd7fca6
     .elementor-element-populated {
    margin: 200px 0 0 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c951798
    .elementor-heading-title {
    font-size: 32px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-9abb5bd
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-473d5e2 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-f8e743a
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f8e743a
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-f8e743a:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-f8e743a
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-f8e743a {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-332af23
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-332af23
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-e5ce508
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4b7f987
    .elementor-heading-title {
    font-size: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f30dbd1
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f30dbd1
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-f30dbd1:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-f30dbd1
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-f30dbd1 {
    margin-top: -50px;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-8c2164d
    .slider-parallax-wrapper
    .slider_parallax_slides
    .slide
    .title
    h2 {
    font-size: 45px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c2164d
    .slider-parallax-wrapper
    .slide
    .caption {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c2164d
    .slider-parallax-wrapper
    .slide-content {
    margin-top: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c2164d
    .slider-parallax-wrapper
    .slide
    .caption
    .text {
    font-size: 16px;
  }
  .custom-css-style
    .elementor-element.elementor-element-dd13ab8
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-dd13ab8
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-dd13ab8:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-dd13ab8
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-dd13ab8 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f761604
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-aae75dc:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-aae75dc
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-aae75dc {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c624154
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-c3806dc {
    margin-top: -120px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-349dd0a
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f8bacc1
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0a2fa18
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0a2fa18
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1f1bc7d
    .elementor-image
    img {
    width: 185px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5989ae9
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5989ae9
     .elementor-widget-container {
    margin: 0 0 10px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6585a04
     .elementor-container {
    min-height: 370px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6585a04
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-6585a04 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-76b8211
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-4ba1d2f {
    text-align: center;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-7c9f668 {
    left: -12%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-7c9f668 {
    right: -12%;
  }
  .custom-css-style .elementor-element.elementor-element-7c9f668 {
    bottom: -200%;
  }
  .custom-css-style
    .elementor-element.elementor-element-1f7a1bf
     .elementor-element-populated {
    margin: 0 20px 0 20px;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-bbab5a5
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-402ca2e
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5bc627a
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5bc627a
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-5bc627a:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-5bc627a
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-5bc627a {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9c7ea32
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-26c36dc
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-26c36dc
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2d132ec
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2d132ec
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-760ea7d
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-37a9cbe
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-37a9cbe
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b5a4bc4
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b5a4bc4
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-b5a4bc4:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-b5a4bc4
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-b5a4bc4 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-00c2ae9
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-fae6fed
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-c446521 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-96638bb
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e6db2d6
    .service-grid-wrapper
    img {
    max-width: 50px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-e6db2d6
    .service-grid-wrapper
    .overflow-inner
    .header-wrap
    h2 {
    font-size: 36px;
  }
  .custom-css-style .elementor-element.elementor-element-5b4ffee {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-688200d
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-ceefb8c {
    left: 10%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-ceefb8c {
    right: 10%;
  }
  .custom-css-style .elementor-element.elementor-element-ceefb8c {
    bottom: 24%;
  }
  .custom-css-style
    .elementor-element.elementor-element-b7df262
     .elementor-widget-container {
    margin: 120px 20px 0 20px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f1622c1
     .elementor-widget-container {
    margin: 40px 0 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-075a6dc
     .elementor-widget-container {
    margin: 0 0 60px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9fd90ed
    .elementor-image
    img {
    width: 175px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-9fd90ed {
    left: 26%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-9fd90ed {
    right: 26%;
  }
  .custom-css-style .elementor-element.elementor-element-9fd90ed {
    bottom: 46%;
  }
  .custom-css-style
    .elementor-element.elementor-element-c213dce.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-c213dce
     .elementor-element-populated {
    margin: 0;
    padding: 0 0 350px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b647380
    .elementor-image
    img {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-b647380 {
    width: 320px;
    max-width: 320px;
  }
  .custom-css-style
    .elementor-element.elementor-element-bd7fca6
     .elementor-element-populated {
    margin: 130px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9abb5bd
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f8e743a
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f8e743a
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-f8e743a:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-f8e743a
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-f8e743a {
    margin-top: 60px;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-26fa2b0
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-60781f7
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-60781f7
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-332af23
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-332af23
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-e5ce508
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4b7f987
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4b7f987
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f30dbd1
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f30dbd1
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-f30dbd1:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-f30dbd1
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-f30dbd1 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b7cf77d
    .elementor-spacer-inner {
    height: 0;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-c213dce {
    width: 10%;
  }
  .custom-css-style .elementor-element.elementor-element-bd7fca6 {
    width: 55%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-76f453e
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-76f453e
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-76f453e:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-76f453e
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/couple-barista-coffee-shop-service-restaurant-conc-PPBEVLC.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-76f453e
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-76f453e {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-11e91ad
   .elementor-element-populated {
  margin: 0 50% 0 0;
  padding: 0 0 100px 0;
}
.custom-css-style .elementor-element.elementor-element-7858129 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-7858129
  .elementor-heading-title {
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-7858129
   .elementor-widget-container {
  margin: 0 0 15px 0;
}
.elementor-element.elementor-element-7858129 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-7858129 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-e4284d0 {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-e4284d0
  .elementor-heading-title {
  color: #fff;
  font-size: 80px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-e4284d0
   .elementor-widget-container {
  margin: 0;
}
.elementor-element.elementor-element-e4284d0 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-e4284d0 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5ad3d19
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #fff;
  color: #fff;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  border-radius: 0;
  padding: 20px 100px 20px 100px;
}
.custom-css-style
  .elementor-element.elementor-element-5ad3d19
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-5ad3d19
  .elementor-button:hover {
  color: #fff;
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-5ad3d19
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-5ad3d19
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-5ad3d19
   .elementor-widget-container {
  margin: 30px 0 0 0;
}
.elementor-element.elementor-element-5ad3d19 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.6s !important;
}
.elementor-widget.elementor-element-5ad3d19 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-5ad3d19 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-0fa76e3
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-0fa76e3
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-0fa76e3:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-0fa76e3
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-0fa76e3 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0fa76e3
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-077af95
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-8c8de90
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-1b95624 {
  margin-top: -150px;
  margin-bottom: 0;
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-9d3d1ff.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-9d3d1ff
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1a40d53
  .elementor-spacer-inner {
  height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-1a40d53
   .elementor-widget-container {
  margin: 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #000;
}
.custom-css-style .elementor-element.elementor-element-1a40d53 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-f9dd508 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 60px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-f9dd508
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-8c1b602
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4232086
  .service-grid-wrapper {
  height: 550px;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4232086
  .service-grid-wrapper
  img {
  max-width: 50px !important;
}
.custom-css-style
  .elementor-element.elementor-element-4232086
  .service-grid-wrapper
  i {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4232086
  .service-grid-wrapper
  .overflow-inner
  .header-wrap
  h2 {
  color: #fff;
  font-size: 36px;
}
.custom-css-style
  .elementor-element.elementor-element-4232086
  .service-grid-wrapper
  .overflow-inner
  .hover-content {
  color: #fff;
}
.custom-css-style .elementor-element.elementor-element-029fb82 {
  margin-top: 0;
  margin-bottom: 0;
  padding: 100px 0 160px 0;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-e3fc870.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-e3fc870.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-e3fc870
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-c74899a {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-c74899a
  .elementor-heading-title {
  color: #000;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-c74899a
   .elementor-widget-container {
  margin: 0 0 15px 0;
}
.elementor-element.elementor-element-c74899a {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-c74899a {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2f80b27
  .elementor-image
  img {
  width: 230px;
}
.custom-css-style .elementor-element.elementor-element-2f80b27 {
  width: 100%;
  max-width: 100%;
  top: -19%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-2f80b27 {
  left: -44%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-2f80b27 {
  right: -44%;
}
.custom-css-style .elementor-element.elementor-element-909adb3 {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-909adb3
  .elementor-heading-title {
  color: #000;
  font-size: 90px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-909adb3
   .elementor-widget-container {
  margin: 0;
  padding: 0;
}
.elementor-element.elementor-element-909adb3 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-909adb3 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-fe721df
  .elementor-text-editor {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-fe721df
   .elementor-widget-container {
  margin: 0;
  padding: 0 80px 0 0;
}
.elementor-element.elementor-element-fe721df {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-fe721df {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-404d170 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-404d170
  .elementor-image
  img {
  width: 250px;
  filter: brightness(0) contrast(100%) saturate(100%) blur(0) hue-rotate(0deg);
}
.custom-css-style
  .elementor-element.elementor-element-404d170
   .elementor-widget-container {
  margin: 28px 0 0 0;
}
.elementor-element.elementor-element-404d170 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.6s !important;
}
.elementor-widget.elementor-element-404d170 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ac49309
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e2df695
  .elementor-image
  img {
  width: 260px;
}
.custom-css-style .elementor-element.elementor-element-e2df695 {
  width: 100%;
  max-width: 100%;
  bottom: -12%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-e2df695 {
  right: -265.3px;
}
body.rtl .custom-css-style .elementor-element.elementor-element-e2df695 {
  left: -265.3px;
}
.custom-css-style
  .elementor-element.elementor-element-26e4125
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-26e4125
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-26e4125:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-26e4125
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-black.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-26e4125 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-26e4125
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-bbd0bfa
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b7f29c5
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-8c297b2
   .elementor-container
   .elementor-row
   .elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-8c297b2:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-8c297b2
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-8c297b2 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 60px 0 40px 0;
}
.custom-css-style
  .elementor-element.elementor-element-8c297b2
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-ad1dd49.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-ad1dd49.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-ad1dd49
   .elementor-element-populated {
  padding: 0;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-c508a07.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-c508a07.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-c508a07
   .elementor-element-populated {
  padding: 0 0 0 60px;
}
.custom-css-style .elementor-element.elementor-element-6f9bb64 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-6f9bb64
  .elementor-heading-title {
  color: #000;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-6f9bb64
   .elementor-widget-container {
  margin: 0 0 15px 0;
}
.elementor-element.elementor-element-6f9bb64 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-6f9bb64 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-41e099e {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-41e099e
  .elementor-heading-title {
  color: #fff;
  font-size: 90px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-41e099e
   .elementor-widget-container {
  margin: 0;
}
.elementor-element.elementor-element-41e099e {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-41e099e {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-510b559
  .elementor-text-editor {
  text-align: left;
}
.custom-css-style .elementor-element.elementor-element-510b559 {
  color: rgba(255, 255, 255, 0.77);
}
.custom-css-style
  .elementor-element.elementor-element-510b559
   .elementor-widget-container {
  margin: 0;
  padding: 0 80px 0 0;
}
.elementor-element.elementor-element-510b559 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-510b559 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3add4ad
  .elementor-button {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #c7a17a;
  color: #c7a17a;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c7a17a;
  border-radius: 0;
  padding: 20px 100px 20px 100px;
}
.custom-css-style
  .elementor-element.elementor-element-3add4ad
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-3add4ad
  .elementor-button:hover {
  color: #fff;
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-3add4ad
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-3add4ad
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-3add4ad
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.elementor-element.elementor-element-3add4ad {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.6s !important;
}
.elementor-widget.elementor-element-3add4ad {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-3add4ad {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-edaf848
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-edaf848
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-edaf848:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-edaf848
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: rgba(0, 0, 0, 0);
  background-image: url(../upload/bg-header-overlay-lg-black-2.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-edaf848 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-edaf848
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-a02d737
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-cb7b5b0
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-c794f9c {
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  padding: 40px 0 0 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-1bec423.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-1bec423
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-1bec423 {
  z-index: 3;
}
.custom-css-style .elementor-element.elementor-element-dd8bd3d {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-dd8bd3d
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.custom-css-style
  .elementor-element.elementor-element-dd8bd3d
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.elementor-element.elementor-element-dd8bd3d {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-dd8bd3d {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-808abe8
  .elementor-image
  img {
  width: 220px;
}
.custom-css-style .elementor-element.elementor-element-808abe8 {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: -12%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-808abe8 {
  left: -50%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-808abe8 {
  right: -50%;
}
.custom-css-style
  .elementor-element.elementor-element-4fb49f7
  .elementor-image
  img {
  width: 220px;
}
.custom-css-style .elementor-element.elementor-element-4fb49f7 {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: 12%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-4fb49f7 {
  left: 50%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-4fb49f7 {
  right: 50%;
}
.custom-css-style .elementor-element.elementor-element-0d98013 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-0d98013
  .elementor-heading-title {
  color: #000;
  font-size: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-0d98013
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-0d98013 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-0d98013 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-c073faa {
  overflow: hidden;
}
.custom-css-style
  .elementor-element.elementor-element-5d112cc
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7e02122
  .testimonials-card-wrapper
  .owl-carousel
  .shadow-effect {
  background: #fff;
  border-color: #fff;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7e02122
  .testimonials-card-wrapper
  .owl-carousel
  .shadow-effect
  .testimonial-name {
  color: #000;
  font-size: 24px;
}
.custom-css-style
  .elementor-element.elementor-element-7e02122
  .testimonials-card-wrapper
  .owl-carousel
  .shadow-effect
  .testimonial-info-desc {
  color: #000;
  font-size: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-7e02122
  .testimonials-card-wrapper
  .owl-carousel
  .owl-dots
  .owl-dot
  span {
  background: rgba(0, 0, 0, 0.26);
}
.custom-css-style
  .elementor-element.elementor-element-7e02122
  .testimonials-card-wrapper
  .owl-carousel
  .owl-dots
  .owl-dot.active
  span {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7e9834a
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-7e9834a
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-7e9834a:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-7e9834a
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-2.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-7e9834a {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-7e9834a
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-568664e
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d5a1fff
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-f733377 {
  margin-top: -150px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d7cb49d
   .elementor-element-populated {
  padding: 80px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-020f0e4 .eb-map {
  height: 600px;
}
.custom-css-style .elementor-element.elementor-element-9047248 {
  text-align: left;
  width: auto;
  max-width: auto;
  top: 25%;
}
.custom-css-style
  .elementor-element.elementor-element-9047248
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-9047248
   .elementor-widget-container {
  margin: 0;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-9047248 {
  left: 5%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-9047248 {
  right: 5%;
}
.custom-css-style
  .elementor-element.elementor-element-48cc1db
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #c7a17a;
  color: #c7a17a;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c7a17a;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-48cc1db
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-48cc1db
  .elementor-button:hover {
  color: #fff;
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-48cc1db
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-48cc1db
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-48cc1db
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-48cc1db {
  width: auto;
  max-width: auto;
  top: 60%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-48cc1db {
  left: 6%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-48cc1db {
  right: 6%;
}
:root {
  --page-title-display: none;
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-e3fc870 {
    width: 45%;
  }
  .custom-css-style .elementor-element.elementor-element-ac49309 {
    width: 55%;
  }
  .custom-css-style .elementor-element.elementor-element-ad1dd49 {
    width: 55%;
  }
  .custom-css-style .elementor-element.elementor-element-c508a07 {
    width: 45%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-76f453e
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-76f453e
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-11e91ad
     .elementor-element-populated {
    margin: 0 35% 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e4284d0
    .elementor-heading-title {
    font-size: 60px;
    line-height: 1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-5ad3d19
    .elementor-button {
    padding: 15px 60px 15px 60px;
  }
  .custom-css-style .elementor-element.elementor-element-5ad3d19 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-0fa76e3
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0fa76e3
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-0fa76e3:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-0fa76e3
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-0fa76e3 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-1b95624 {
    margin-top: -120px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1a40d53
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1a40d53
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-f9dd508 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-4232086
    .service-grid-wrapper
    img {
    max-width: 45px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-4232086
    .service-grid-wrapper
    .overflow-inner
    .header-wrap
    h2 {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-029fb82 {
    padding: 100px 0 100px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2f80b27
    .elementor-image
    img {
    width: 220px;
  }
  .custom-css-style
    .elementor-element.elementor-element-909adb3
    .elementor-heading-title {
    font-size: 60px;
    line-height: 1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-404d170
     .elementor-widget-container {
    margin: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e2df695
    .elementor-image
    img {
    width: 259px;
  }
  .custom-css-style
    .elementor-element.elementor-element-26e4125
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-26e4125
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-26e4125:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-26e4125
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-26e4125 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-8c297b2 {
    padding: 40px 0 100px 0;
  }
  .elementor-bc-flex-widget
    .custom-css-style
    .elementor-element.elementor-element-ad1dd49.elementor-column
    .elementor-column-wrap {
    align-items: flex-start;
  }
  .custom-css-style
    .elementor-element.elementor-element-ad1dd49.elementor-column.elementor-element[data-element_type="column"]
     .elementor-column-wrap.elementor-element-populated
     .elementor-widget-wrap {
    align-content: flex-start;
    align-items: flex-start;
  }
  .custom-css-style
    .elementor-element.elementor-element-ad1dd49
     .elementor-element-populated {
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c508a07
     .elementor-element-populated {
    margin: 0;
    padding: 0 0 0 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-41e099e
    .elementor-heading-title {
    font-size: 60px;
    line-height: 1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-41e099e
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-3add4ad
    .elementor-button {
    font-size: 20px;
    padding: 15px 40px 15px 40px;
  }
  .custom-css-style .elementor-element.elementor-element-3add4ad {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-edaf848
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-edaf848
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-edaf848:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-edaf848
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-edaf848 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-c794f9c {
    padding: 40px 0 40px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-dd8bd3d
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-808abe8
    .elementor-image
    img {
    width: 225px;
  }
  .custom-css-style
    .elementor-element.elementor-element-808abe8
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-4fb49f7
    .elementor-image
    img {
    width: 200px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4fb49f7
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0d98013
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style .elementor-element.elementor-element-c073faa {
    padding: 0 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7e9834a
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7e9834a
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-7e9834a:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-7e9834a
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-7e9834a {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-020f0e4 .eb-map {
    height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9047248
    .elementor-heading-title {
    font-size: 80px;
  }
  .custom-css-style .elementor-element.elementor-element-48cc1db {
    width: auto;
    max-width: auto;
    top: 53%;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-76f453e
     .elementor-container {
    min-height: 450px;
  }
  .custom-css-style
    .elementor-element.elementor-element-76f453e
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-76f453e {
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-11e91ad {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-11e91ad.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: flex-start;
  }
  .custom-css-style
    .elementor-element.elementor-element-11e91ad
     .elementor-element-populated {
    margin: 0;
    padding: 0 20px 80px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7858129
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-e4284d0 {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-e4284d0
    .elementor-heading-title {
    font-size: 50px;
    line-height: 1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-0fa76e3
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0fa76e3
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-0fa76e3:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-0fa76e3
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-0fa76e3 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c8de90
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1a40d53
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-f9dd508 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c1b602
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4232086
    .service-grid-wrapper
    img {
    max-width: 50px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-4232086
    .service-grid-wrapper
    .overflow-inner
    .header-wrap
    h2 {
    font-size: 36px;
  }
  .custom-css-style .elementor-element.elementor-element-029fb82 {
    padding: 80px 0 80px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e3fc870.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: flex-start;
  }
  .custom-css-style
    .elementor-element.elementor-element-e3fc870
     .elementor-element-populated {
    padding: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c74899a
     .elementor-widget-container {
    margin: 0 0 15px 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-909adb3 {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-909adb3
    .elementor-heading-title {
    font-size: 50px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-909adb3
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-404d170
     .elementor-widget-container {
    margin: 10px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ac49309
     .elementor-element-populated {
    margin: 60px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-e2df695 {
    bottom: 24%;
  }
  .custom-css-style
    .elementor-element.elementor-element-26e4125
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-26e4125
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-26e4125:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-26e4125
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-26e4125 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b7f29c5
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-8c297b2 {
    margin-top: -39px;
    margin-bottom: 0;
    padding: 0 0 46px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ad1dd49
     .elementor-element-populated {
    padding: 30px 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c508a07.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: flex-start;
  }
  .custom-css-style
    .elementor-element.elementor-element-c508a07
     .elementor-element-populated {
    padding: 20px 20px 0 20px;
  }
  .custom-css-style .elementor-element.elementor-element-41e099e {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-41e099e
    .elementor-heading-title {
    font-size: 50px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-edaf848
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-edaf848
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-edaf848:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-edaf848
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-edaf848 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-a02d737
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-cb7b5b0
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-cb7b5b0
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-c794f9c {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1bec423
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-dd8bd3d
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0d98013
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0d98013
     .elementor-widget-container {
    margin: 0 0 10px 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7e02122
    .testimonials-card-wrapper
    .owl-carousel
    .shadow-effect
    .testimonial-name {
    font-size: 22px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7e02122
    .testimonials-card-wrapper
    .owl-carousel
    .shadow-effect
    .testimonial-info-desc {
    font-size: 13px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7e9834a
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7e9834a
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-7e9834a:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-7e9834a
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-7e9834a {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-568664e
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d5a1fff
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d5a1fff
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-020f0e4 .eb-map {
    height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9047248
    .elementor-heading-title {
    font-size: 60px;
    line-height: 1em;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-ad1dd49 {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-c508a07 {
    width: 50%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-1e57013
   .elementor-container {
  min-height: 600px;
}
.custom-css-style
  .elementor-element.elementor-element-1e57013
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-1e57013:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-1e57013
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-1e57013 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 220px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-1e57013
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-1194578
   .elementor-element-populated {
  padding: 0 0 0 100px;
}
.custom-css-style .elementor-element.elementor-element-48570eb {
  width: 120%;
  max-width: 120%;
  bottom: -46%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-48570eb {
  left: 13%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-48570eb {
  right: 13%;
}
.custom-css-style
  .elementor-element.elementor-element-c41da91
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-c41da91 {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-b062939
  .elementor-heading-title {
  color: #fff;
  font-size: 90px;
}
.custom-css-style .elementor-element.elementor-element-b062939 {
  z-index: 2;
  width: auto;
  max-width: auto;
}
.elementor-element.elementor-element-b062939 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-b062939 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-dbffe80 {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-dbffe80
  .elementor-heading-title {
  color: #ad9a74;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
  line-height: 0.7em;
}
.custom-css-style
  .elementor-element.elementor-element-dbffe80
   .elementor-widget-container {
  margin: 25px 0 0 40px;
  padding: 0 20px 0 20px;
  border-style: solid;
  border-width: 3px 0 3px 0;
  border-color: #ad9a74;
}
.elementor-element.elementor-element-dbffe80 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-dbffe80 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5250f39
  .elementor-heading-title {
  color: #fff;
  font-size: 90px;
}
.custom-css-style .elementor-element.elementor-element-5250f39 {
  z-index: 2;
  width: auto;
  max-width: auto;
}
.elementor-element.elementor-element-5250f39 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-5250f39 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d4fa113
   .elementor-element-populated {
  padding: 0 100px 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-b27b667.elementor-view-stacked
  .elementor-icon {
  background-color: #ad9a74;
}
.custom-css-style
  .elementor-element.elementor-element-b27b667.elementor-view-default
  .elementor-icon,
.custom-css-style
  .elementor-element.elementor-element-b27b667.elementor-view-framed
  .elementor-icon {
  fill: #ad9a74;
  color: #ad9a74;
  border-color: #ad9a74;
}
.custom-css-style
  .elementor-element.elementor-element-b27b667.elementor-position-right
  .elementor-icon-box-icon {
  margin-left: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-b27b667.elementor-position-left
  .elementor-icon-box-icon {
  margin-right: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-b27b667.elementor-position-top
  .elementor-icon-box-icon {
  margin-bottom: 30px;
}
.custom-css-style .elementor-element.elementor-element-b27b667 .elementor-icon {
  font-size: 28px;
}
.custom-css-style
  .elementor-element.elementor-element-b27b667
  .elementor-icon
  i {
  transform: rotate(0);
}
.custom-css-style
  .elementor-element.elementor-element-b27b667
  .elementor-icon-box-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-b27b667
  .elementor-icon-box-title {
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b27b667
  .elementor-icon-box-content
  .elementor-icon-box-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b27b667
  .elementor-icon-box-content
  .elementor-icon-box-title,
.custom-css-style
  .elementor-element.elementor-element-b27b667
  .elementor-icon-box-content
  .elementor-icon-box-title
  a {
  font-size: 22px;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-b27b667
  .elementor-icon-box-content
  .elementor-icon-box-description {
  color: #fff;
  font-family: Oswald, Sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-b27b667
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.elementor-element.elementor-element-b27b667 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.6s !important;
}
.elementor-widget.elementor-element-b27b667 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f1400b4.elementor-view-stacked
  .elementor-icon {
  background-color: #ad9a74;
}
.custom-css-style
  .elementor-element.elementor-element-f1400b4.elementor-view-default
  .elementor-icon,
.custom-css-style
  .elementor-element.elementor-element-f1400b4.elementor-view-framed
  .elementor-icon {
  fill: #ad9a74;
  color: #ad9a74;
  border-color: #ad9a74;
}
.custom-css-style
  .elementor-element.elementor-element-f1400b4.elementor-position-right
  .elementor-icon-box-icon {
  margin-left: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-f1400b4.elementor-position-left
  .elementor-icon-box-icon {
  margin-right: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-f1400b4.elementor-position-top
  .elementor-icon-box-icon {
  margin-bottom: 30px;
}
.custom-css-style .elementor-element.elementor-element-f1400b4 .elementor-icon {
  font-size: 28px;
}
.custom-css-style
  .elementor-element.elementor-element-f1400b4
  .elementor-icon
  i {
  transform: rotate(0);
}
.custom-css-style
  .elementor-element.elementor-element-f1400b4
  .elementor-icon-box-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-f1400b4
  .elementor-icon-box-title {
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f1400b4
  .elementor-icon-box-content
  .elementor-icon-box-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-f1400b4
  .elementor-icon-box-content
  .elementor-icon-box-title,
.custom-css-style
  .elementor-element.elementor-element-f1400b4
  .elementor-icon-box-content
  .elementor-icon-box-title
  a {
  font-size: 22px;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-f1400b4
  .elementor-icon-box-content
  .elementor-icon-box-description {
  color: #fff;
  font-family: Oswald, Sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-f1400b4
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.elementor-element.elementor-element-f1400b4 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.8s !important;
}
.elementor-widget.elementor-element-f1400b4 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e0cbc30:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-e0cbc30
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-e0cbc30 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e0cbc30
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-9190df2.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-9190df2.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-9190df2.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-9190df2
   .elementor-element-populated {
  padding: 0 10% 0 25%;
}
.custom-css-style .elementor-element.elementor-element-42f2cca {
  text-align: center;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-42f2cca
  .elementor-heading-title {
  color: #ad9a74;
  font-family: Oswald, Sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-42f2cca
   .elementor-widget-container {
  margin: -60px 0 0 40px;
  padding: 20px 20px 20px 20px;
  border-style: solid;
  border-width: 3px 0 3px 0;
  border-color: #ad9a74;
}
.custom-css-style
  .elementor-element.elementor-element-ac3d4bb
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9b6bd15
   .elementor-widget-container {
  margin: -160px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-631d177
   .elementor-container {
  min-height: 850px;
}
.custom-css-style
  .elementor-element.elementor-element-631d177
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style .elementor-element.elementor-element-631d177 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-631d177:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-631d177
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-631d177
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-61ab6ca
   .elementor-element-populated {
  padding: 0 0 0 100px;
}
.custom-css-style
  .elementor-element.elementor-element-e5133bc
  .elementor-image
  img {
  width: 85%;
}
.custom-css-style .elementor-element.elementor-element-e5133bc {
  z-index: 0;
  width: 100%;
  max-width: 100%;
  bottom: -23%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-e5133bc {
  left: 14%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-e5133bc {
  right: 14%;
}
.custom-css-style
  .elementor-element.elementor-element-bd2a275
  .elementor-image
  img {
  width: 55%;
}
.custom-css-style .elementor-element.elementor-element-bd2a275 {
  z-index: 0;
  width: 100%;
  max-width: 100%;
  bottom: 3%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-bd2a275 {
  left: -37%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-bd2a275 {
  right: -37%;
}
.custom-css-style
  .elementor-element.elementor-element-96bc542
  .elementor-heading-title {
  color: #fff;
  font-size: 100px;
  line-height: 0.9em;
}
.custom-css-style .elementor-element.elementor-element-96bc542 {
  z-index: 2;
  bottom: -45%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-96bc542 {
  right: 10%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-96bc542 {
  left: 10%;
}
.custom-css-style
  .elementor-element.elementor-element-2b93dab
   .elementor-element-populated {
  padding: 140px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-fc98a48 {
  --divider-border-style: solid;
  --divider-color: #ad9a74;
  --divider-border-width: 4px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-fc98a48
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-fc98a48
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-fc98a48
   .elementor-widget-container {
  margin: 0 0 0 -85px;
}
.elementor-element.elementor-element-fc98a48 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-fc98a48 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7d159b5
  .elementor-heading-title {
  color: #ad9a74;
  font-size: 40px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-7d159b5
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-7d159b5 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-3b841d7 {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-3b841d7
   .elementor-widget-container {
  margin: 20px 0 0 105px;
}
.custom-css-style
  .elementor-element.elementor-element-ff65626:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-ff65626
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-ff65626 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0 0 80px 0;
}
.custom-css-style
  .elementor-element.elementor-element-ff65626
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-7fec964
   .elementor-element-populated {
  padding: 0 0 0 35%;
}
.custom-css-style
  .elementor-element.elementor-element-17d15b6
  .elementor-heading-title {
  color: #ad9a74;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-17d15b6
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-17d15b6 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-c3348bb {
  --divider-border-style: solid;
  --divider-color: #ad9a74;
  --divider-border-width: 4px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-c3348bb
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c3348bb
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-c3348bb
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.elementor-element.elementor-element-c3348bb {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-c3348bb {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-119b5fb
  .elementor-image
  img {
  width: 85%;
}
.custom-css-style .elementor-element.elementor-element-119b5fb {
  z-index: 2;
  width: 100%;
  max-width: 100%;
  bottom: 2.785%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-119b5fb {
  left: -98.12%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-119b5fb {
  right: -98.12%;
}
.custom-css-style
  .elementor-element.elementor-element-0e3e931
  .elementor-image
  img {
  width: 60%;
}
.custom-css-style .elementor-element.elementor-element-0e3e931 {
  z-index: 0;
  width: 100%;
  max-width: 100%;
  bottom: -3%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-0e3e931 {
  left: -55%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-0e3e931 {
  right: -55%;
}
.custom-css-style
  .elementor-element.elementor-element-c223066
  .elementor-image
  img {
  width: 250px;
  opacity: 0.3;
  filter: brightness(200%) contrast(0) saturate(100%) blur(0) hue-rotate(0deg);
}
.custom-css-style .elementor-element.elementor-element-c223066 {
  width: 100%;
  max-width: 100%;
  top: 17%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-c223066 {
  left: -37%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-c223066 {
  right: -37%;
}
.custom-css-style
  .elementor-element.elementor-element-3c323d7
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7e63ac1
  .food-menu-content-title-holder
  h3 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7e63ac1
  .food-menu-content-title-line {
  border-color: #fff;
}
.custom-css-style .elementor-element.elementor-element-7e63ac1 .food-menu-desc {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7e63ac1
  .food-menu-content-price-holder
  .food-menu-content-price-normal {
  color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-7e63ac1
  .food-menu-content-price-holder
  .food-menu-content-price-sale {
  color: rgba(199, 161, 122, 0.58);
}
.custom-css-style
  .elementor-element.elementor-element-7e63ac1
  .food-menu-content-highlight-holder
  h4 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7e63ac1
  .food-menu-content-highlight-holder {
  background: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-7e63ac1
  .food-menu-highlight {
  border-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-7e63ac1
  .food-menu-tooltip-content {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7e63ac1
  .food-menu-tooltip-content
  h5 {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-7e63ac1
  .tooltipster-sidetip.tooltipster-shadow
  .tooltipster-box {
  background: #fffbf2;
}
.custom-css-style
  .elementor-element.elementor-element-fe1deb2
   .elementor-container {
  min-height: 760px;
}
.custom-css-style
  .elementor-element.elementor-element-fe1deb2
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-fe1deb2:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-fe1deb2
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-fe1deb2 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-fe1deb2
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-cfc8a3e
   .elementor-element-populated {
  padding: 0 0 0 100px;
}
.custom-css-style
  .elementor-element.elementor-element-4af9d4f
  .elementor-heading-title {
  color: #fff;
  font-size: 140px;
}
.custom-css-style .elementor-element.elementor-element-4af9d4f {
  z-index: 2;
}
.elementor-element.elementor-element-4af9d4f {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-4af9d4f {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e48930c
  .elementor-image
  img {
  width: 100%;
}
.custom-css-style .elementor-element.elementor-element-e48930c {
  width: 170%;
  max-width: 170%;
  bottom: 0;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-e48930c {
  left: -67%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-e48930c {
  right: -67%;
}
.custom-css-style
  .elementor-element.elementor-element-6b0a998
   .elementor-element-populated {
  padding: 60px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-b7e06dd {
  text-align: left;
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-b7e06dd
  .elementor-image
  img {
  width: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-b7e06dd
   .elementor-widget-container {
  margin: 0 0 40px 100px;
}
.custom-css-style .elementor-element.elementor-element-ff04b6e {
  --divider-border-style: solid;
  --divider-color: #ad9a74;
  --divider-border-width: 4px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-ff04b6e
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ff04b6e
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-ff04b6e
   .elementor-widget-container {
  margin: 0 0 0 -85px;
}
.elementor-element.elementor-element-ff04b6e {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-ff04b6e {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-543ad58
  .elementor-heading-title {
  color: #ad9a74;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-543ad58
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-543ad58 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-b8ac3e2 {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-b8ac3e2
   .elementor-widget-container {
  margin: 20px 0 0 105px;
}
.custom-css-style
  .elementor-element.elementor-element-120541f:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-120541f
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-120541f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 15% 0 15%;
}
.custom-css-style
  .elementor-element.elementor-element-120541f
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-deae2a0.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-deae2a0.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-107bbd7
  .elementor-heading-title {
  color: #ad9a74;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-107bbd7
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-107bbd7 {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-975ade0 {
  --divider-border-style: solid;
  --divider-color: #ad9a74;
  --divider-border-width: 4px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-975ade0
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-975ade0
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-975ade0
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.elementor-element.elementor-element-975ade0 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-975ade0 {
  opacity: 0;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-f1551e7.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-f1551e7.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-f1551e7.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: flex-end;
}
.custom-css-style
  .elementor-element.elementor-element-be2d333
  .elementor-button {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #ad9a74;
  color: #ad9a74;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #ad9a74;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-be2d333
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-be2d333
  .elementor-button:hover {
  color: #fff;
  background-color: #ad9a74;
}
.custom-css-style
  .elementor-element.elementor-element-be2d333
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-be2d333
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-be2d333
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-be2d333 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-376226d:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-376226d
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-376226d {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 40px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-376226d
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-367f767
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e85d7fd
  .horizontal-gallery-wrapper
  .flickity-prev-next-button.next {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e85d7fd
  .horizontal-gallery-wrapper
  .flickity-prev-next-button.previous {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e85d7fd
  .horizontal-gallery-wrapper
  .flickity-prev-next-button
  .arrow {
  fill: #222;
}
.custom-css-style
  .elementor-element.elementor-element-e85d7fd
  .horizontal-gallery-wrapper
  .flickity-page-dots
  .dot {
  background: #222;
}
.custom-css-style .elementor-element.elementor-element-8b1e94c {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 100px 40px 100px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-8b1e94c:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-8b1e94c
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-8b1e94c
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-5c5bd86:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-5c5bd86
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #1f1e1d;
}
.custom-css-style
  .elementor-element.elementor-element-5c5bd86
   .elementor-element-populated {
  border-style: solid;
  border-width: 0;
  border-color: #c7a17a;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0;
  padding: 20px 70px 20px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-5c5bd86
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-5c5bd86 {
  z-index: 3;
}
.custom-css-style .elementor-element.elementor-element-4aa2493 {
  border-style: solid;
  border-width: 0;
  border-color: #c7a17a;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 40px 30px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-4aa2493
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-0309462
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-7da8406 {
  --divider-border-style: solid;
  --divider-color: #c7a17a;
  --divider-border-width: 4px;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-7da8406
  .elementor-divider-separator {
  width: 170px;
  margin: 0 auto;
  margin-left: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7da8406
  .elementor-divider {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-7da8406
   .elementor-widget-container {
  margin: 0 0 0 -85px;
}
.elementor-element.elementor-element-7da8406 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-7da8406 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a26dc5b
  .elementor-heading-title {
  color: #c7a17a;
  font-size: 40px;
}
.custom-css-style
  .elementor-element.elementor-element-a26dc5b
   .elementor-widget-container {
  margin: 0 0 0 20px;
}
.custom-css-style .elementor-element.elementor-element-a26dc5b {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-7bf5834.elementor-view-stacked
  .elementor-icon {
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-7bf5834.elementor-view-default
  .elementor-icon,
.custom-css-style
  .elementor-element.elementor-element-7bf5834.elementor-view-framed
  .elementor-icon {
  fill: #c7a17a;
  color: #c7a17a;
  border-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-7bf5834.elementor-position-right
  .elementor-icon-box-icon {
  margin-left: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-7bf5834.elementor-position-left
  .elementor-icon-box-icon {
  margin-right: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-7bf5834.elementor-position-top
  .elementor-icon-box-icon {
  margin-bottom: 30px;
}
.custom-css-style .elementor-element.elementor-element-7bf5834 .elementor-icon {
  font-size: 28px;
}
.custom-css-style
  .elementor-element.elementor-element-7bf5834
  .elementor-icon
  i {
  transform: rotate(0);
}
.custom-css-style
  .elementor-element.elementor-element-7bf5834
  .elementor-icon-box-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-7bf5834
  .elementor-icon-box-title {
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7bf5834
  .elementor-icon-box-content
  .elementor-icon-box-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7bf5834
  .elementor-icon-box-content
  .elementor-icon-box-title,
.custom-css-style
  .elementor-element.elementor-element-7bf5834
  .elementor-icon-box-content
  .elementor-icon-box-title
  a {
  font-size: 22px;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-7bf5834
  .elementor-icon-box-content
  .elementor-icon-box-description {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-7bf5834
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-6db1076.elementor-view-stacked
  .elementor-icon {
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-6db1076.elementor-view-default
  .elementor-icon,
.custom-css-style
  .elementor-element.elementor-element-6db1076.elementor-view-framed
  .elementor-icon {
  fill: #c7a17a;
  color: #c7a17a;
  border-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-6db1076.elementor-position-right
  .elementor-icon-box-icon {
  margin-left: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-6db1076.elementor-position-left
  .elementor-icon-box-icon {
  margin-right: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-6db1076.elementor-position-top
  .elementor-icon-box-icon {
  margin-bottom: 30px;
}
.custom-css-style .elementor-element.elementor-element-6db1076 .elementor-icon {
  font-size: 28px;
}
.custom-css-style
  .elementor-element.elementor-element-6db1076
  .elementor-icon
  i {
  transform: rotate(0);
}
.custom-css-style
  .elementor-element.elementor-element-6db1076
  .elementor-icon-box-wrapper {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-6db1076
  .elementor-icon-box-title {
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6db1076
  .elementor-icon-box-content
  .elementor-icon-box-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6db1076
  .elementor-icon-box-content
  .elementor-icon-box-title,
.custom-css-style
  .elementor-element.elementor-element-6db1076
  .elementor-icon-box-content
  .elementor-icon-box-title
  a {
  font-size: 22px;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-6db1076
  .elementor-icon-box-content
  .elementor-icon-box-description {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-6db1076
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-c30ceda
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-c5f004d
  .elementor-image
  img {
  width: 250px;
  opacity: 0.3;
  filter: brightness(200%) contrast(0) saturate(100%) blur(0) hue-rotate(0deg);
}
.custom-css-style .elementor-element.elementor-element-c5f004d {
  width: 100%;
  max-width: 100%;
  top: -10%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-c5f004d {
  left: 72%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-c5f004d {
  right: 72%;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-d6a73da.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-d6a73da.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-d6a73da
   .elementor-element-populated {
  margin: 0;
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-ca0683b .eb-map {
  height: 500px;
}
.custom-css-style .elementor-element.elementor-element-ca0683b {
  z-index: 3;
  top: 23%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-ca0683b {
  right: 8%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-ca0683b {
  left: 8%;
}
.custom-css-style
  .elementor-element.elementor-element-728298d
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.05);
  font-size: 400px;
}
.custom-css-style .elementor-element.elementor-element-728298d {
  width: 500vw;
  max-width: 500vw;
  top: 53%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-728298d {
  left: 14.833%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-728298d {
  right: 14.833%;
}
:root {
  --page-title-display: none;
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-1194578 {
    width: 45%;
  }
  .custom-css-style .elementor-element.elementor-element-c41da91 {
    width: 55%;
  }
  .custom-css-style .elementor-element.elementor-element-97a4d73 {
    width: 30%;
  }
  .custom-css-style .elementor-element.elementor-element-d4fa113 {
    width: 69.961%;
  }
  .custom-css-style .elementor-element.elementor-element-9190df2 {
    width: 42%;
  }
  .custom-css-style .elementor-element.elementor-element-ac3d4bb {
    width: 58%;
  }
  .custom-css-style .elementor-element.elementor-element-5c5bd86 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-d6a73da {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-1e57013
     .elementor-container {
    min-height: 620px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1e57013
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-1e57013 {
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1194578
     .elementor-element-populated {
    padding: 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-48570eb {
    left: -10%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-48570eb {
    right: -10%;
  }
  .custom-css-style .elementor-element.elementor-element-48570eb {
    bottom: -18%;
  }
  .custom-css-style
    .elementor-element.elementor-element-c41da91
     .elementor-element-populated {
    margin: 0;
    padding: 120px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b062939
    .elementor-heading-title {
    font-size: 55px;
  }
  .custom-css-style
    .elementor-element.elementor-element-dbffe80
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-dbffe80
     .elementor-widget-container {
    margin: 18px 0 0 10px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5250f39
    .elementor-heading-title {
    font-size: 55px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d4fa113
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9190df2
     .elementor-element-populated {
    margin: 0;
    padding: 0 40px 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-42f2cca
    .elementor-heading-title {
    font-size: 20px;
    line-height: 1.5em;
  }
  .custom-css-style
    .elementor-element.elementor-element-42f2cca
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9b6bd15
     .elementor-widget-container {
    margin: -40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-631d177
     .elementor-container {
    min-height: 700px;
  }
  .custom-css-style
    .elementor-element.elementor-element-631d177
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-631d177 {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-61ab6ca
     .elementor-element-populated {
    padding: 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-e5133bc {
    left: 0;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-e5133bc {
    right: 0;
  }
  .custom-css-style .elementor-element.elementor-element-e5133bc {
    bottom: 33%;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-bd2a275 {
    left: -45%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-bd2a275 {
    right: -45%;
  }
  .custom-css-style .elementor-element.elementor-element-bd2a275 {
    bottom: 39%;
  }
  .custom-css-style
    .elementor-element.elementor-element-96bc542
    .elementor-heading-title {
    font-size: 70px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-96bc542 {
    right: -2%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-96bc542 {
    left: -2%;
  }
  .custom-css-style .elementor-element.elementor-element-96bc542 {
    bottom: 9%;
  }
  .custom-css-style
    .elementor-element.elementor-element-2b93dab
     .elementor-element-populated {
    margin: 0;
    padding: 80px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-fc98a48
     .elementor-widget-container {
    margin: 0 0 0 -160px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7d159b5
    .elementor-heading-title {
    font-size: 32px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-7d159b5
     .elementor-widget-container {
    margin: -40px 0 0 60px;
  }
  .custom-css-style .elementor-element.elementor-element-7d159b5 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-3b841d7
     .elementor-widget-container {
    margin: 20px 0 0 60px;
  }
  .custom-css-style .elementor-element.elementor-element-ff65626 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7fec964
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c3348bb
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-119b5fb {
    left: -45%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-119b5fb {
    right: -45%;
  }
  .custom-css-style .elementor-element.elementor-element-119b5fb {
    bottom: 17%;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-0e3e931 {
    left: 4%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-0e3e931 {
    right: 4%;
  }
  .custom-css-style .elementor-element.elementor-element-0e3e931 {
    bottom: 5%;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-c223066 {
    left: 4%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-c223066 {
    right: 4%;
  }
  .custom-css-style .elementor-element.elementor-element-c223066 {
    top: 33%;
  }
  .custom-css-style
    .elementor-element.elementor-element-fe1deb2
     .elementor-container {
    min-height: 700px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fe1deb2
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-fe1deb2 {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-cfc8a3e
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-4af9d4f
    .elementor-heading-title {
    font-size: 100px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-e48930c {
    left: -67%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-e48930c {
    right: -67%;
  }
  .custom-css-style .elementor-element.elementor-element-e48930c {
    bottom: 35%;
  }
  .custom-css-style
    .elementor-element.elementor-element-6b0a998
     .elementor-element-populated {
    margin: 0;
    padding: 80px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ff04b6e
     .elementor-widget-container {
    margin: 0 0 0 -100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8ac3e2
     .elementor-widget-container {
    margin: 20px 0 0 60px;
  }
  .custom-css-style .elementor-element.elementor-element-120541f {
    padding: 60px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-107bbd7 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-975ade0
     .elementor-widget-container {
    margin: 0 0 0 20px;
  }
  .custom-css-style .elementor-element.elementor-element-975ade0 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style .elementor-element.elementor-element-be2d333 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style .elementor-element.elementor-element-8b1e94c {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 40px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5c5bd86
     .elementor-element-populated {
    margin: 0;
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style .elementor-element.elementor-element-4aa2493 {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7da8406
     .elementor-widget-container {
    margin: 0 0 0 -100px;
  }
  .custom-css-style .elementor-element.elementor-element-c5f004d {
    top: 113%;
  }
  .custom-css-style .elementor-element.elementor-element-ca0683b .eb-map {
    height: 600px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-ca0683b {
    right: 0;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-ca0683b {
    left: 0;
  }
  .custom-css-style .elementor-element.elementor-element-ca0683b {
    top: 12%;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-1e57013
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1e57013
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-1e57013 {
    padding: 450px 0 0 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-48570eb {
    left: -12%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-48570eb {
    right: -12%;
  }
  .custom-css-style .elementor-element.elementor-element-48570eb {
    bottom: -200%;
  }
  .custom-css-style
    .elementor-element.elementor-element-c41da91
     .elementor-element-populated {
    margin: 0 20px 0 20px;
    padding: 60px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-b062939 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-b062939
    .elementor-heading-title {
    font-size: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-dbffe80
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-dbffe80
     .elementor-widget-container {
    margin: 5px 0 0 20px;
    padding: 0 10px 0 10px;
  }
  .custom-css-style .elementor-element.elementor-element-5250f39 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-5250f39
    .elementor-heading-title {
    font-size: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d4fa113
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-b27b667
    .elementor-icon-box-icon {
    margin-bottom: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b27b667
    .elementor-icon {
    font-size: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b27b667
    .elementor-icon-box-wrapper {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-f1400b4
    .elementor-icon-box-icon {
    margin-bottom: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f1400b4
    .elementor-icon {
    font-size: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f1400b4
    .elementor-icon-box-wrapper {
    text-align: center;
  }
  .custom-css-style .elementor-element.elementor-element-e0cbc30 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 60px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9190df2.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-9190df2
     .elementor-element-populated {
    margin: 0;
    padding: 0 20px 60px 20px;
  }
  .custom-css-style .elementor-element.elementor-element-42f2cca {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-42f2cca
    .elementor-heading-title {
    font-size: 20px;
    line-height: 1.4em;
  }
  .custom-css-style
    .elementor-element.elementor-element-42f2cca
     .elementor-widget-container {
    margin: 0;
    padding: 20px 0 20px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9b6bd15
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-631d177
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-631d177
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-631d177 {
    padding: 100px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-61ab6ca
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-e5133bc {
    left: -12%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-e5133bc {
    right: -12%;
  }
  .custom-css-style .elementor-element.elementor-element-e5133bc {
    bottom: -200%;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-bd2a275 {
    left: -12%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-bd2a275 {
    right: -12%;
  }
  .custom-css-style .elementor-element.elementor-element-bd2a275 {
    bottom: -200%;
  }
  .custom-css-style .elementor-element.elementor-element-96bc542 {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-96bc542
    .elementor-heading-title {
    font-size: 50px;
    line-height: 1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-2b93dab
     .elementor-element-populated {
    margin: 0 20px 0 20px;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-fc98a48
     .elementor-widget-container {
    margin: 0 0 0 -130px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7d159b5
    .elementor-heading-title {
    font-size: 26px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3b841d7
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-17d15b6
    .elementor-heading-title {
    font-size: 32px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-119b5fb {
    left: -12%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-119b5fb {
    right: -12%;
  }
  .custom-css-style .elementor-element.elementor-element-119b5fb {
    bottom: -200%;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-0e3e931 {
    left: -12%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-0e3e931 {
    right: -12%;
  }
  .custom-css-style .elementor-element.elementor-element-0e3e931 {
    bottom: -200%;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-c223066 {
    left: 10%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-c223066 {
    right: 10%;
  }
  .custom-css-style
    .elementor-element.elementor-element-7e63ac1
    .food-menu-content-title-holder
    h3 {
    font-size: 22px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7e63ac1
    .food-menu-content-price-holder {
    font-size: 18px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7e63ac1
     .elementor-widget-container {
    margin: 40px 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fe1deb2
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fe1deb2
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-fe1deb2 {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-cfc8a3e
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-4af9d4f {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-4af9d4f
    .elementor-heading-title {
    font-size: 80px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-e48930c {
    left: -12%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-e48930c {
    right: -12%;
  }
  .custom-css-style .elementor-element.elementor-element-e48930c {
    bottom: -200%;
  }
  .custom-css-style
    .elementor-element.elementor-element-6b0a998
     .elementor-element-populated {
    margin: 0 20px 0 20px;
    padding: 20px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-b7e06dd {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-b7e06dd
     .elementor-widget-container {
    margin: 0 0 40px 0;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-543ad58
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8ac3e2
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-107bbd7
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f1551e7.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: flex-start;
  }
  .custom-css-style .elementor-element.elementor-element-8b1e94c {
    margin-top: 0;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5c5bd86
     .elementor-element-populated {
    margin: 400px 0 40px 0;
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style .elementor-element.elementor-element-4aa2493 {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0309462
     .elementor-element-populated {
    padding: 20px 20px 20px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a26dc5b
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7bf5834
    .elementor-icon-box-icon {
    margin-bottom: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6db1076
    .elementor-icon-box-icon {
    margin-bottom: 30px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-c5f004d {
    left: 10%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-c5f004d {
    right: 10%;
  }
  .custom-css-style
    .elementor-element.elementor-element-d6a73da
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-ca0683b .eb-map {
    height: 400px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-5c5bd86 {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-d6a73da {
    width: 50%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-802c50d
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-802c50d
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style .elementor-element.elementor-element-802c50d {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 95px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-802c50d:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-802c50d
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-802c50d
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-08779a7
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-08779a7
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-d87216d
  .elementor-image
  img {
  opacity: 0.7;
}
.custom-css-style .elementor-element.elementor-element-d87216d {
  width: 210%;
  max-width: 210%;
  top: -141%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-d87216d {
  left: -61%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-d87216d {
  right: -61%;
}
.custom-css-style
  .elementor-element.elementor-element-d47f703
   .elementor-element-populated {
  padding: 140px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-d47f703 {
  z-index: 2;
}
.custom-css-style .elementor-element.elementor-element-8a2447b {
  text-align: left;
  z-index: 3;
  width: 100%;
  max-width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-8a2447b
  .elementor-heading-title {
  color: #d22226;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
  text-shadow: 0 0 25px #d22226;
}
.elementor-element.elementor-element-8a2447b {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-8a2447b {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-b8d0c1c {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-b8d0c1c
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-b8d0c1c
   .elementor-widget-container {
  margin: 10px 0 20px 0;
}
.elementor-element.elementor-element-b8d0c1c {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-b8d0c1c {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f65ff64
  .elementor-image
  img {
  width: 70px;
}
.elementor-element.elementor-element-f65ff64 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.6s !important;
}
.elementor-widget.elementor-element-f65ff64 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-f65ff64 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-ed84df8
  .elementor-heading-title {
  color: #fff;
  font-size: 30px;
  line-height: 1.2em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-ed84df8
   .elementor-widget-container {
  margin: 0 0 0 10px;
}
.elementor-element.elementor-element-ed84df8 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.6s !important;
}
.elementor-widget.elementor-element-ed84df8 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-ed84df8 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-638c133
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-638c133
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-638c133:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-638c133
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-black.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-638c133 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -120px;
  margin-bottom: 0;
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-638c133
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-ba5f109
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3f765bc
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-8abea90 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 0 100px 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-8abea90:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-8abea90
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-8abea90
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-9c1298b.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-9c1298b.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-9c1298b.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-bdfe7ac
  .elementor-image
  img {
  width: 120px;
}
.elementor-element.elementor-element-bdfe7ac {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-bdfe7ac {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-452ed66
  .elementor-heading-title {
  color: #fff;
  font-size: 36px;
  line-height: 1.2em;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-452ed66
   .elementor-widget-container {
  margin: 20px 0 0 10px;
}
.elementor-element.elementor-element-452ed66 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-452ed66 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-452ed66 {
  width: auto;
  max-width: auto;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-c195d49.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-c195d49.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-c195d49.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-e32de83
  .elementor-image
  img {
  width: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-e32de83
   .elementor-widget-container {
  margin: 80px 0 0 0;
}
.elementor-element.elementor-element-e32de83 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.3s !important;
}
.elementor-widget.elementor-element-e32de83 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2a035c1
  .elementor-heading-title {
  color: #fff;
  font-size: 36px;
  line-height: 1.2em;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-2a035c1
   .elementor-widget-container {
  margin: 20px 0 0 10px;
}
.elementor-element.elementor-element-2a035c1 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-2a035c1 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-2a035c1 {
  width: auto;
  max-width: auto;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-7744f64.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-7744f64.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-7744f64.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-3d3bb06
  .elementor-image
  img {
  width: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-3d3bb06
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.elementor-element.elementor-element-3d3bb06 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.5s !important;
}
.elementor-widget.elementor-element-3d3bb06 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7b66506
  .elementor-heading-title {
  color: #fff;
  font-size: 36px;
  line-height: 1.2em;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-7b66506
   .elementor-widget-container {
  margin: 20px 0 0 10px;
}
.elementor-element.elementor-element-7b66506 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.6s !important;
}
.elementor-widget.elementor-element-7b66506 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-7b66506 {
  width: auto;
  max-width: auto;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-6d3d21a.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-6d3d21a.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-6d3d21a.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-f7718ab
  .elementor-image
  img {
  width: 190px;
}
.custom-css-style
  .elementor-element.elementor-element-f7718ab
   .elementor-widget-container {
  margin: 60px 0 0 0;
}
.elementor-element.elementor-element-f7718ab {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.7s !important;
}
.elementor-widget.elementor-element-f7718ab {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-aaf4c66
  .elementor-heading-title {
  color: #fff;
  font-size: 36px;
  line-height: 1.2em;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-aaf4c66
   .elementor-widget-container {
  margin: 20px 0 0 10px;
}
.elementor-element.elementor-element-aaf4c66 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.8s !important;
}
.elementor-widget.elementor-element-aaf4c66 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-aaf4c66 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-6a95ea5
   .elementor-container {
  min-height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-6a95ea5
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style .elementor-element.elementor-element-6a95ea5 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 95px 0 0 0;
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-6a95ea5:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-6a95ea5
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6a95ea5
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-7319230
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-7319230
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-082ab5b
  .elementor-image
  img {
  width: 280px;
}
.custom-css-style .elementor-element.elementor-element-082ab5b {
  z-index: 2;
  width: auto;
  max-width: auto;
  bottom: -16%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-082ab5b {
  left: -23%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-082ab5b {
  right: -23%;
}
.custom-css-style
  .elementor-element.elementor-element-659d2ef
  .elementor-image
  img {
  opacity: 0.7;
}
.custom-css-style
  .elementor-element.elementor-element-659d2ef
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-659d2ef {
  width: 210%;
  max-width: 210%;
  top: -17.889%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-659d2ef {
  left: -39.213%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-659d2ef {
  right: -39.213%;
}
.custom-css-style
  .elementor-element.elementor-element-5965cce
   .elementor-element-populated {
  margin: 0 0 0 60px;
  padding: 80px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-5965cce {
  z-index: 2;
}
.custom-css-style .elementor-element.elementor-element-83c7b76 {
  text-align: left;
  z-index: 3;
  width: 100%;
  max-width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-83c7b76
  .elementor-heading-title {
  color: #d22226;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
  text-shadow: 0 0 25px #d22226;
}
.custom-css-style .elementor-element.elementor-element-1903258 {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-1903258
  .elementor-heading-title {
  color: #fff;
  font-size: 90px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-1903258
   .elementor-widget-container {
  margin: 10px 0 20px 0;
}
.custom-css-style
  .elementor-element.elementor-element-92f2ce5
   .elementor-container {
  min-height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-92f2ce5
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style .elementor-element.elementor-element-92f2ce5 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 80px 0 80px 0;
}
.custom-css-style
  .elementor-element.elementor-element-92f2ce5:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-92f2ce5
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-92f2ce5
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-d52d22f
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-d52d22f {
  z-index: 2;
}
.custom-css-style .elementor-element.elementor-element-f5e6ae3 {
  text-align: left;
  z-index: 3;
  width: 100%;
  max-width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-f5e6ae3
  .elementor-heading-title {
  color: #d22226;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
  text-shadow: 0 0 25px #d22226;
}
.custom-css-style .elementor-element.elementor-element-d85cc13 {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-d85cc13
  .elementor-heading-title {
  color: #fff;
  font-size: 90px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-d85cc13
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-819f602 {
  color: rgba(255, 255, 255, 0.7215686274509804);
}
.custom-css-style
  .elementor-element.elementor-element-819f602
   .elementor-widget-container {
  margin: 0;
  padding: 0 80px 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-1025bff
  .elementor-image
  img {
  width: 70px;
}
.custom-css-style .elementor-element.elementor-element-1025bff {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-7b46f44
  .elementor-heading-title {
  color: #fff;
  font-size: 30px;
  line-height: 1.2em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-7b46f44
   .elementor-widget-container {
  margin: 0 0 0 10px;
}
.custom-css-style .elementor-element.elementor-element-7b46f44 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-cdae291
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6a417ce
  .elementor-image
  img {
  opacity: 0.7;
}
.custom-css-style
  .elementor-element.elementor-element-6a417ce
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-6a417ce {
  width: 140%;
  max-width: 140%;
  top: -34%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-6a417ce {
  left: -68%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-6a417ce {
  right: -68%;
}
.custom-css-style .elementor-element.elementor-element-79540d3 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 0 80px 0;
}
.custom-css-style
  .elementor-element.elementor-element-79540d3:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-79540d3
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-79540d3
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-23fe42c
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-46dd71a
  .elementor-heading-title {
  color: #d22226;
  font-size: 120px;
  text-transform: none;
  text-shadow: 0 0 25px #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-46dd71a
   .elementor-widget-container {
  margin: 0 0 0 90px;
}
.custom-css-style .elementor-element.elementor-element-46dd71a {
  z-index: 2;
  width: 100%;
  max-width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-dca5c1f
  .horizontal-gallery-wrapper
  .flickity-prev-next-button.next {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-dca5c1f
  .horizontal-gallery-wrapper
  .flickity-prev-next-button.previous {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-dca5c1f
  .horizontal-gallery-wrapper
  .flickity-prev-next-button
  .arrow {
  fill: #222;
}
.custom-css-style
  .elementor-element.elementor-element-dca5c1f
  .horizontal-gallery-wrapper
  .flickity-page-dots
  .dot {
  background: #222;
}
.custom-css-style
  .elementor-element.elementor-element-dca5c1f
   .elementor-widget-container {
  margin: 20px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-dca5c1f {
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-8c6a449
   .elementor-container {
  min-height: 650px;
}
.custom-css-style
  .elementor-element.elementor-element-8c6a449
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style .elementor-element.elementor-element-8c6a449 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 120px 0 40px 0;
  z-index: 2;
}
.custom-css-style
  .elementor-element.elementor-element-8c6a449:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-8c6a449
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-8c6a449
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-795aec0
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-795aec0
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-fa72f40
  .elementor-heading-title {
  color: #d22226;
  font-family: Monoton, Sans-serif;
  font-size: 250px;
  text-shadow: 0 0 25px #d22226;
}
.custom-css-style .elementor-element.elementor-element-fa72f40 {
  z-index: 2;
  width: auto;
  max-width: auto;
  bottom: -14%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-fa72f40 {
  left: -15%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-fa72f40 {
  right: -15%;
}
.custom-css-style
  .elementor-element.elementor-element-740573a
  .elementor-image
  img {
  opacity: 0.76;
}
.custom-css-style
  .elementor-element.elementor-element-740573a
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-740573a {
  width: 210%;
  max-width: 210%;
  top: -17.889%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-740573a {
  left: -39.213%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-740573a {
  right: -39.213%;
}
.custom-css-style
  .elementor-element.elementor-element-ed573e8
   .elementor-element-populated {
  margin: 0 0 0 100px;
  padding: 60px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-ed573e8 {
  z-index: 2;
}
.custom-css-style .elementor-element.elementor-element-e2031cf {
  text-align: left;
  z-index: 3;
  width: 100%;
  max-width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-e2031cf
  .elementor-heading-title {
  color: #d22226;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
  text-shadow: 0 0 25px #d22226;
}
.custom-css-style .elementor-element.elementor-element-f1718bf {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-f1718bf
  .elementor-heading-title {
  color: #fff;
  font-size: 90px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-f1718bf
   .elementor-widget-container {
  margin: 10px 0 20px 0;
}
.custom-css-style
  .elementor-element.elementor-element-fdc7640
  .elementor-image
  img {
  width: 70px;
}
.custom-css-style .elementor-element.elementor-element-fdc7640 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-d697e3d
  .elementor-heading-title {
  color: #fff;
  font-size: 30px;
  line-height: 1.2em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-d697e3d
   .elementor-widget-container {
  margin: 0 0 0 10px;
}
.custom-css-style .elementor-element.elementor-element-d697e3d {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-42deab5 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0 0 60px 0;
}
.custom-css-style
  .elementor-element.elementor-element-42deab5:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-42deab5
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-42deab5
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-fc178b9
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-1886b84 {
  text-align: left;
  z-index: 3;
  width: 100%;
  max-width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-1886b84
  .elementor-heading-title {
  color: #d22226;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
  text-shadow: 0 0 25px #d22226;
}
.custom-css-style
  .elementor-element.elementor-element-1886b84
   .elementor-widget-container {
  margin: 0 0 0 40px;
}
.custom-css-style
  .elementor-element.elementor-element-c3846c1
  .horizontal-slider-wrapper
  .horizontal-slider-cell {
  background: rgba(256, 256, 256, 0);
}
.custom-css-style
  .elementor-element.elementor-element-c3846c1
  .horizontal-slide-content-title
  h2 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c3846c1
  div.horizontal-slide-content-title
  h2 {
  font-size: 80px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-c3846c1
  .horizontal-slider-wrapper
  .horizontal-slider-content
  .horizontal-slider-content-wrap {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c3846c1
  .horizontal-slider-content-cell
  .horizontal-slide-content-link {
  color: #fff;
  font-family: Oswald, Sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-c3846c1
  .horizontal-slide-content-link {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c3846c1
  .horizontal-slider-wrapper
  .flickity-prev-next-button.next {
  background: rgba(256, 256, 256, 0);
}
.custom-css-style
  .elementor-element.elementor-element-c3846c1
  .horizontal-slider-wrapper
  .flickity-prev-next-button.previous {
  background: rgba(256, 256, 256, 0);
}
.custom-css-style
  .elementor-element.elementor-element-c3846c1
  .horizontal-slider-wrapper
  .flickity-prev-next-button
  .arrow {
  fill: #222;
}
.custom-css-style
  .elementor-element.elementor-element-c3846c1
  .horizontal-slider-wrapper
  .flickity-page-dots
  .dot {
  background: #222;
}
.custom-css-style
  .elementor-element.elementor-element-c3846c1
   .elementor-widget-container {
  margin: 0;
}
:root {
  --page-title-display: none;
}
body.elementor-page-4882:not(.elementor-motion-effects-element-type-background),
body.elementor-page-4882
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-802c50d
     .elementor-container {
    min-height: 460px;
  }
  .custom-css-style
    .elementor-element.elementor-element-802c50d
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-802c50d {
    padding: 70px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-08779a7
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d47f703
     .elementor-element-populated {
    margin: 0;
    padding: 100px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8a2447b
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8d0c1c
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f65ff64
    .elementor-image
    img {
    width: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ed84df8
    .elementor-heading-title {
    font-size: 28px;
  }
  .custom-css-style
    .elementor-element.elementor-element-638c133
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-638c133
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-638c133:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-638c133
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-638c133 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-8abea90 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-bdfe7ac
    .elementor-image
    img {
    width: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-452ed66
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c195d49
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e32de83
    .elementor-image
    img {
    width: 110px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e32de83
     .elementor-widget-container {
    margin: 62px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2a035c1
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3d3bb06
    .elementor-image
    img {
    width: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3d3bb06
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7b66506
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f7718ab
    .elementor-image
    img {
    width: 140px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f7718ab
     .elementor-widget-container {
    margin: 45px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-aaf4c66
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6a95ea5
     .elementor-container {
    min-height: 460px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6a95ea5
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-6a95ea5 {
    padding: 70px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-7319230
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-082ab5b
    .elementor-image
    img {
    width: 200px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-082ab5b {
    left: -4%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-082ab5b {
    right: -4%;
  }
  .custom-css-style
    .elementor-element.elementor-element-5965cce
     .elementor-element-populated {
    margin: 0;
    padding: 100px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-83c7b76
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1903258
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-92f2ce5
     .elementor-container {
    min-height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-92f2ce5
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-f5e6ae3
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d85cc13
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-819f602
     .elementor-widget-container {
    margin: 20px 40px 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1025bff
    .elementor-image
    img {
    width: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7b46f44
    .elementor-heading-title {
    font-size: 28px;
  }
  .custom-css-style .elementor-element.elementor-element-6a417ce {
    top: -2%;
  }
  .custom-css-style
    .elementor-element.elementor-element-46dd71a
    .elementor-heading-title {
    font-size: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-46dd71a
     .elementor-widget-container {
    margin: 0 0 0 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c6a449
     .elementor-container {
    min-height: 460px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c6a449
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-8c6a449 {
    padding: 70px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-795aec0
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-fa72f40
    .elementor-heading-title {
    font-size: 160px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-fa72f40 {
    left: 4%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-fa72f40 {
    right: 4%;
  }
  .custom-css-style
    .elementor-element.elementor-element-ed573e8
     .elementor-element-populated {
    margin: 0;
    padding: 100px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e2031cf
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f1718bf
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fdc7640
    .elementor-image
    img {
    width: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d697e3d
    .elementor-heading-title {
    font-size: 28px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fc178b9
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1886b84
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c3846c1
    div.horizontal-slide-content-title
    h2 {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c3846c1
    .horizontal-slider-content-cell
    .horizontal-slide-content-link {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-802c50d
     .elementor-container {
    min-height: 300px;
  }
  .custom-css-style
    .elementor-element.elementor-element-802c50d
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-802c50d {
    padding: 140px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-d87216d {
    top: -20%;
  }
  .custom-css-style
    .elementor-element.elementor-element-d47f703
     .elementor-element-populated {
    margin: -2px 20px 0 20px;
    padding: 20px 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8a2447b
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b8d0c1c
    .elementor-heading-title {
    font-size: 60px;
    line-height: 1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-638c133
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-638c133
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-638c133:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-638c133
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-638c133 {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-3f765bc
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-452ed66
    .elementor-heading-title {
    font-size: 38px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2a035c1
    .elementor-heading-title {
    font-size: 38px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7b66506
    .elementor-heading-title {
    font-size: 38px;
  }
  .custom-css-style
    .elementor-element.elementor-element-aaf4c66
    .elementor-heading-title {
    font-size: 38px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6a95ea5
     .elementor-container {
    min-height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6a95ea5
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-6a95ea5 {
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-082ab5b {
    bottom: -200%;
  }
  .custom-css-style .elementor-element.elementor-element-659d2ef {
    top: 165%;
  }
  .custom-css-style
    .elementor-element.elementor-element-5965cce
     .elementor-element-populated {
    margin: 40px 20px 0 20px;
    padding: 20px 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-83c7b76
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1903258
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-92f2ce5
     .elementor-container {
    min-height: 300px;
  }
  .custom-css-style
    .elementor-element.elementor-element-92f2ce5
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-92f2ce5 {
    padding: 0 30px 0 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d52d22f
     .elementor-element-populated {
    padding: 80px 30px 60px 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f5e6ae3
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d85cc13
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-819f602
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-46dd71a
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c6a449
     .elementor-container {
    min-height: 580px;
  }
  .custom-css-style
    .elementor-element.elementor-element-8c6a449
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-8c6a449 {
    padding: 140px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-ed573e8
     .elementor-element-populated {
    margin: 40px 20px 0 20px;
    padding: 20px 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e2031cf
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f1718bf
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style .elementor-element.elementor-element-42deab5 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1886b84
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c3846c1
    div.horizontal-slide-content-title
    h2 {
    font-size: 55px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c3846c1
    .horizontal-slider-content-cell
    .horizontal-slide-content-link {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-08779a7 {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-d47f703 {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-7319230 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-5965cce {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-795aec0 {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-ed573e8 {
    width: 60%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-edc8ee4
   .elementor-container {
  min-height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-edc8ee4
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-edc8ee4:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-edc8ee4
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
  background-image: url(../upload/kentaro-toma-JNm2kPF9U-Q-unsplash.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-edc8ee4
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-edc8ee4 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-fb4c63d
   .elementor-element-populated {
  margin: 0;
  padding: 0 0 120px 0;
}
.custom-css-style .elementor-element.elementor-element-c81393b {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-c81393b
  .elementor-heading-title {
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-c81393b
   .elementor-widget-container {
  margin: 0 0 15px 0;
}
.elementor-element.elementor-element-c81393b {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-c81393b {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-1783fc4 {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-1783fc4
  .elementor-heading-title {
  color: #fff;
  font-size: 80px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-1783fc4
   .elementor-widget-container {
  margin: 0;
}
.elementor-element.elementor-element-1783fc4 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-1783fc4 {
  opacity: 0;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-46bf88e.elementor-column
  .elementor-column-wrap {
  align-items: flex-start;
}
.custom-css-style
  .elementor-element.elementor-element-46bf88e.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: flex-start;
  align-items: flex-start;
}
.custom-css-style
  .elementor-element.elementor-element-46bf88e
   .elementor-element-populated {
  margin: 30px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-1f20e78 {
  text-align: right;
}
.custom-css-style
  .elementor-element.elementor-element-1f20e78
  .elementor-image
  img {
  width: 200px;
  filter: brightness(0) contrast(0) saturate(0) blur(0) hue-rotate(0deg);
}
.custom-css-style .elementor-element.elementor-element-4f9db63 {
  margin-top: -60px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-cc1f7c9:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-cc1f7c9
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-cc1f7c9
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 20px 20px 20px;
}
.custom-css-style
  .elementor-element.elementor-element-cc1f7c9
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-36313b0 {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 60px 40px 60px 40px;
}
.custom-css-style
  .elementor-element.elementor-element-36313b0
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-9c97072
   .elementor-element-populated {
  padding: 0 0 0 40px;
}
.custom-css-style .elementor-element.elementor-element-9c97072 {
  z-index: 2;
}
.custom-css-style .elementor-element.elementor-element-9a0b539 {
  z-index: 3;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-e767729.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-e767729.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-e767729
   .elementor-element-populated {
  padding: 0 0 0 60px;
}
.custom-css-style .elementor-element.elementor-element-e767729 {
  z-index: 3;
}
.custom-css-style .elementor-element.elementor-element-3123df1 {
  text-align: left;
  z-index: 3;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-3123df1
  .elementor-heading-title {
  color: #fff;
  font-size: 50px;
  line-height: 1.1em;
  letter-spacing: 1px;
}
.custom-css-style
  .elementor-element.elementor-element-3123df1
   .elementor-widget-container {
  margin: 0 20px 0 -120px;
  padding: 0;
}
.elementor-element.elementor-element-3123df1 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-3123df1 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6c0793c
  .elementor-image
  img {
  width: 240px;
  opacity: 1;
}
.custom-css-style .elementor-element.elementor-element-6c0793c {
  width: 100%;
  max-width: 100%;
  top: -2%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-6c0793c {
  right: -27%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-6c0793c {
  left: -27%;
}
.custom-css-style
  .elementor-element.elementor-element-c30afb0
  .elementor-image
  img {
  width: 250px;
  opacity: 1;
  filter: brightness(200%) contrast(0) saturate(0) blur(0) hue-rotate(0deg);
}
.custom-css-style .elementor-element.elementor-element-c30afb0 {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: 77%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-c30afb0 {
  right: 47%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-c30afb0 {
  left: 47%;
}
.custom-css-style
  .elementor-element.elementor-element-9d2abc5
  .elementor-text-editor {
  text-align: left;
}
.custom-css-style .elementor-element.elementor-element-9d2abc5 {
  color: rgba(255, 255, 255, 0.77);
}
.custom-css-style
  .elementor-element.elementor-element-9d2abc5
   .elementor-widget-container {
  margin: 10px 0 0 0;
  padding: 0 120px 0 0;
}
.elementor-element.elementor-element-9d2abc5 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-9d2abc5 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9d4b618
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #000;
  color: #000;
  background-color: #fff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  border-radius: 0;
  padding: 20px 80px 20px 80px;
}
.custom-css-style
  .elementor-element.elementor-element-9d4b618
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-9d4b618
  .elementor-button:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
}
.custom-css-style
  .elementor-element.elementor-element-9d4b618
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-9d4b618
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9d4b618
   .elementor-widget-container {
  margin: 30px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-9d4b618 {
  z-index: 2;
  width: auto;
  max-width: auto;
}
.elementor-element.elementor-element-9d4b618 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.6s !important;
}
.elementor-widget.elementor-element-9d4b618 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4230e5b
  .elementor-image
  img {
  width: 110px;
}
.custom-css-style .elementor-element.elementor-element-4230e5b {
  z-index: 2;
  width: 100%;
  max-width: 100%;
  bottom: 5%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-4230e5b {
  left: 2%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-4230e5b {
  right: 2%;
}
.custom-css-style .elementor-element.elementor-element-ab1f082 {
  margin-top: -60px;
  margin-bottom: 0;
  padding: 0 0 80px 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-0eaeaca.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-0eaeaca
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-f91ba8b
  .elementor-spacer-inner {
  height: 200px;
}
.custom-css-style
  .elementor-element.elementor-element-f91ba8b
   .elementor-widget-container {
  margin: 0;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #c7a17a;
}
.custom-css-style .elementor-element.elementor-element-f91ba8b {
  width: auto;
  max-width: auto;
}
.custom-css-style .elementor-element.elementor-element-c4836ec {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-c4836ec
  .elementor-heading-title {
  color: #000;
  font-size: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-c4836ec
   .elementor-widget-container {
  margin: 0;
}
.elementor-element.elementor-element-c4836ec {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-c4836ec {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2e681e9
  .elementor-image
  img {
  width: 260px;
}
.custom-css-style .elementor-element.elementor-element-2e681e9 {
  width: 100%;
  max-width: 100%;
  bottom: -16%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-2e681e9 {
  left: -61%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-2e681e9 {
  right: -61%;
}
.custom-css-style .elementor-element.elementor-element-3055135 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-3055135
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-d8cf263
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0a9e15c
  .service-grid-wrapper {
  height: 550px;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0a9e15c
  .service-grid-wrapper
  img {
  max-width: 50px !important;
}
.custom-css-style
  .elementor-element.elementor-element-0a9e15c
  .service-grid-wrapper
  i {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-0a9e15c
  .service-grid-wrapper
  .overflow-inner
  .header-wrap
  h2 {
  color: #fff;
  font-size: 32px;
}
.custom-css-style
  .elementor-element.elementor-element-0a9e15c
  .service-grid-wrapper
  .overflow-inner
  .hover-content {
  color: #fff;
}
.custom-css-style .elementor-element.elementor-element-848db67 {
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  padding: 80px 0 0 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-cffa23a.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-cffa23a
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-cffa23a {
  z-index: 3;
}
.custom-css-style .elementor-element.elementor-element-e758095 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-e758095
  .elementor-heading-title {
  color: #c7a17a;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.custom-css-style
  .elementor-element.elementor-element-e758095
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.elementor-element.elementor-element-e758095 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-e758095 {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4ac05cc
  .elementor-image
  img {
  width: 250px;
}
.custom-css-style .elementor-element.elementor-element-4ac05cc {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: -12%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-4ac05cc {
  left: -50%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-4ac05cc {
  right: -50%;
}
.custom-css-style
  .elementor-element.elementor-element-2a41830
  .elementor-image
  img {
  width: 280px;
}
.custom-css-style .elementor-element.elementor-element-2a41830 {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: 12%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-2a41830 {
  left: 50%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-2a41830 {
  right: 50%;
}
.custom-css-style .elementor-element.elementor-element-bce81db {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-bce81db
  .elementor-heading-title {
  color: #000;
  font-size: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-bce81db
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-bce81db {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-bce81db {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-ca5954e {
  overflow: hidden;
}
.custom-css-style
  .elementor-element.elementor-element-4bad130
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1175112
  .testimonials-card-wrapper
  .owl-carousel
  .shadow-effect {
  background: #fff;
  border-color: #fff;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1175112
  .testimonials-card-wrapper
  .owl-carousel
  .shadow-effect
  .testimonial-name {
  color: #000;
  font-size: 24px;
}
.custom-css-style
  .elementor-element.elementor-element-1175112
  .testimonials-card-wrapper
  .owl-carousel
  .shadow-effect
  .testimonial-info-desc {
  color: #000;
  font-size: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-1175112
  .testimonials-card-wrapper
  .owl-carousel
  .owl-dots
  .owl-dot
  span {
  background: rgba(0, 0, 0, 0.26);
}
.custom-css-style
  .elementor-element.elementor-element-1175112
  .testimonials-card-wrapper
  .owl-carousel
  .owl-dots
  .owl-dot.active
  span {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-503b582
   .elementor-background-overlay {
  background-color: #000;
  opacity: 0.6;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style .elementor-element.elementor-element-503b582 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 80px;
  margin-bottom: 0;
  padding: 80px 0 80px 0;
}
.custom-css-style
  .elementor-element.elementor-element-9b8a216.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-9b8a216
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-ce72bb5 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-ce72bb5
  .elementor-heading-title {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-ce72bb5
   .elementor-widget-container {
  margin: 0 0 15px 0;
}
.elementor-element.elementor-element-ce72bb5 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-ce72bb5 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-badffac {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-badffac
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-badffac
   .elementor-widget-container {
  margin: 5px 0 20px 0;
}
.elementor-element.elementor-element-badffac {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-badffac {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-c5cfc62 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-c5cfc62
  .elementor-heading-title {
  color: #fff;
  font-size: 18px;
}
.custom-css-style
  .elementor-element.elementor-element-c5cfc62
   .elementor-widget-container {
  margin: 0 0 15px 0;
}
.elementor-element.elementor-element-c5cfc62 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-c5cfc62 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-ebc7ea7 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-ebc7ea7
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-ebc7ea7
   .elementor-widget-container {
  margin: 5px 0 20px 0;
}
.elementor-element.elementor-element-ebc7ea7 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.6s !important;
}
.elementor-widget.elementor-element-ebc7ea7 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-c60e7b5 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-c60e7b5
  .elementor-heading-title {
  color: #fff;
  font-size: 18px;
}
.custom-css-style
  .elementor-element.elementor-element-c60e7b5
   .elementor-widget-container {
  margin: 0 0 15px 0;
}
.elementor-element.elementor-element-c60e7b5 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.8s !important;
}
.elementor-widget.elementor-element-c60e7b5 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-2407c2e {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-2407c2e
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-2407c2e
   .elementor-widget-container {
  margin: 5px 0 20px 0;
}
.elementor-element.elementor-element-2407c2e {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 1s !important;
}
.elementor-widget.elementor-element-2407c2e {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-76596a1 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-76596a1
  .elementor-heading-title {
  color: #fff;
  font-size: 18px;
}
.custom-css-style
  .elementor-element.elementor-element-76596a1
   .elementor-widget-container {
  margin: 0;
}
.elementor-element.elementor-element-76596a1 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 1.2s !important;
}
.elementor-widget.elementor-element-76596a1 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-bfabffd {
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  padding: 80px 0 80px 0;
}
.custom-css-style
  .elementor-element.elementor-element-f5048cc
   .elementor-element-populated {
  padding: 0;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-a8611dc.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-a8611dc.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-a8611dc
   .elementor-element-populated {
  padding: 0 120px 0 60px;
}
.custom-css-style .elementor-element.elementor-element-a9ee362 {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-a9ee362
  .elementor-heading-title {
  color: #000;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-a9ee362
   .elementor-widget-container {
  margin: 0 0 15px 0;
}
.elementor-element.elementor-element-a9ee362 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-a9ee362 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-7cc4a16 {
  text-align: right;
  width: auto;
  max-width: auto;
  top: 20%;
}
.custom-css-style
  .elementor-element.elementor-element-7cc4a16
  .elementor-heading-title {
  font-size: 80px;
  font-weight: 900;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-7cc4a16 {
  right: 12%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-7cc4a16 {
  left: 12%;
}
.custom-css-style .elementor-element.elementor-element-f16fe39 {
  font-family: Oswald, Sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: 1.5em;
}
.custom-css-style .elementor-element.elementor-element-c949c6a {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-c949c6a
  .elementor-image
  img {
  width: 250px;
  filter: brightness(0) contrast(100%) saturate(100%) blur(0) hue-rotate(0deg);
}
.custom-css-style
  .elementor-element.elementor-element-c949c6a
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-9381fab {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-9381fab
  .elementor-heading-title {
  color: #000;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-9381fab
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-c16957c
  .elementor-heading-title {
  color: rgba(0, 0, 0, 0.02);
  font-size: 300px;
  line-height: 0.7em;
}
.custom-css-style .elementor-element.elementor-element-c16957c {
  width: 500vw;
  max-width: 500vw;
  top: 71%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-c16957c {
  left: -30%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-c16957c {
  right: -30%;
}
.custom-css-style
  .elementor-element.elementor-element-c45cc62:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-c45cc62
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-c45cc62 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 90px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-c45cc62
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-f08a0e8.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-f08a0e8.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-f08a0e8.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-d4c1171
  .elementor-button {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #ad9a74;
  color: #ad9a74;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #ad9a74;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d4c1171
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-d4c1171
  .elementor-button:hover {
  color: #fff;
  background-color: #ad9a74;
}
.custom-css-style
  .elementor-element.elementor-element-d4c1171
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-d4c1171
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-d4c1171
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-d4c1171 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-2ff9132:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-2ff9132
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-2ff9132 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  padding: 40px 0 100px 0;
}
.custom-css-style
  .elementor-element.elementor-element-2ff9132
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-b46c66c
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b16eb25
  .horizontal-gallery-wrapper
  .flickity-prev-next-button.next {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b16eb25
  .horizontal-gallery-wrapper
  .flickity-prev-next-button.previous {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b16eb25
  .horizontal-gallery-wrapper
  .flickity-prev-next-button
  .arrow {
  fill: #222;
}
.custom-css-style
  .elementor-element.elementor-element-b16eb25
  .horizontal-gallery-wrapper
  .flickity-page-dots
  .dot {
  background: #222;
}
.custom-css-style
  .elementor-element.elementor-element-9a2992d
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-ad59982 .eb-map {
  height: 600px;
}
.custom-css-style .elementor-element.elementor-element-9f1c275 {
  text-align: left;
  width: auto;
  max-width: auto;
  top: 25%;
}
.custom-css-style
  .elementor-element.elementor-element-9f1c275
  .elementor-heading-title {
  color: #fff;
  font-size: 110px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-9f1c275
   .elementor-widget-container {
  margin: 0;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-9f1c275 {
  left: 5%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-9f1c275 {
  right: 5%;
}
.custom-css-style
  .elementor-element.elementor-element-c370d9e
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #c7a17a;
  color: #c7a17a;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c7a17a;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c370d9e
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-c370d9e
  .elementor-button:hover {
  color: #fff;
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-c370d9e
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-c370d9e
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c370d9e
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-c370d9e {
  width: auto;
  max-width: auto;
  top: 64%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-c370d9e {
  left: 5%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-c370d9e {
  right: 5%;
}
:root {
  --page-title-display: none;
}
@media (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-fb4c63d {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-46bf88e {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-cc1f7c9 {
    width: 79.665%;
  }
  .custom-css-style .elementor-element.elementor-element-9c97072 {
    width: 45%;
  }
  .custom-css-style .elementor-element.elementor-element-e767729 {
    width: 55%;
  }
  .custom-css-style .elementor-element.elementor-element-08cc751 {
    width: 20%;
  }
  .custom-css-style .elementor-element.elementor-element-f5048cc {
    width: 35%;
  }
  .custom-css-style .elementor-element.elementor-element-a8611dc {
    width: 64.665%;
  }
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-edc8ee4
     .elementor-container {
    min-height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-edc8ee4
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-fb4c63d
     .elementor-element-populated {
    padding: 0 0 80px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1783fc4
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style .elementor-element.elementor-element-36313b0 {
    padding: 60px 40px 60px 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9c97072
     .elementor-element-populated {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9a0b539
    .elementor-image
    img {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-e767729
     .elementor-element-populated {
    padding: 0 0 0 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3123df1
    .elementor-heading-title {
    font-size: 40px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-3123df1
     .elementor-widget-container {
    margin: 40px 0 0 -100px;
    padding: 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-6c0793c {
    right: -17%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-6c0793c {
    left: -17%;
  }
  .custom-css-style .elementor-element.elementor-element-6c0793c {
    top: -5%;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-c30afb0 {
    right: 90%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-c30afb0 {
    left: 90%;
  }
  .custom-css-style .elementor-element.elementor-element-c30afb0 {
    top: 58%;
  }
  .custom-css-style
    .elementor-element.elementor-element-9d2abc5
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9d4b618
    .elementor-button {
    padding: 15px 40px 15px 40px;
  }
  .custom-css-style .elementor-element.elementor-element-9d4b618 {
    width: auto;
    max-width: auto;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-4230e5b {
    left: 200%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-4230e5b {
    right: 200%;
  }
  .custom-css-style .elementor-element.elementor-element-ab1f082 {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f91ba8b
    .elementor-spacer-inner {
    height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f91ba8b
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c4836ec
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c4836ec
     .elementor-widget-container {
    margin: 20px 0 0 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-2e681e9 {
    left: -49%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-2e681e9 {
    right: -49%;
  }
  .custom-css-style .elementor-element.elementor-element-2e681e9 {
    bottom: 8%;
  }
  .custom-css-style .elementor-element.elementor-element-3055135 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0a9e15c
    .service-grid-wrapper
    img {
    max-width: 45px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-0a9e15c
    .service-grid-wrapper
    .overflow-inner
    .header-wrap
    h2 {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-848db67 {
    padding: 40px 0 30px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e758095
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4ac05cc
    .elementor-image
    img {
    width: 179px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4ac05cc
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-2a41830
    .elementor-image
    img {
    width: 200px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2a41830
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-bce81db
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style .elementor-element.elementor-element-ca5954e {
    padding: 0 0 60px 0;
  }
  .custom-css-style .elementor-element.elementor-element-503b582 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-badffac
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ebc7ea7
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2407c2e
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a8611dc
     .elementor-element-populated {
    padding: 0 20px 0 40px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-7cc4a16 {
    right: 0;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-7cc4a16 {
    left: 0;
  }
  .custom-css-style .elementor-element.elementor-element-7cc4a16 {
    top: -4%;
  }
  .custom-css-style .elementor-element.elementor-element-f16fe39 {
    font-size: 26px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f16fe39
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c949c6a
     .elementor-widget-container {
    margin: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c16957c
    .elementor-heading-title {
    font-size: 200px;
  }
  .custom-css-style .elementor-element.elementor-element-c45cc62 {
    padding: 80px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-d4c1171 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style .elementor-element.elementor-element-2ff9132 {
    padding: 40px 0 80px 0;
  }
  .custom-css-style .elementor-element.elementor-element-ad59982 .eb-map {
    height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9f1c275
    .elementor-heading-title {
    font-size: 80px;
  }
  .custom-css-style .elementor-element.elementor-element-c370d9e {
    width: auto;
    max-width: auto;
    top: 55%;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-edc8ee4
     .elementor-container {
    min-height: 550px;
  }
  .custom-css-style
    .elementor-element.elementor-element-edc8ee4
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style .elementor-element.elementor-element-edc8ee4 {
    padding: 30px 0 0 0;
  }
  .custom-css-style .elementor-element.elementor-element-fb4c63d {
    width: 100%;
  }
  .elementor-bc-flex-widget
    .custom-css-style
    .elementor-element.elementor-element-fb4c63d.elementor-column
    .elementor-column-wrap {
    align-items: flex-end;
  }
  .custom-css-style
    .elementor-element.elementor-element-fb4c63d.elementor-column.elementor-element[data-element_type="column"]
     .elementor-column-wrap.elementor-element-populated
     .elementor-widget-wrap {
    align-content: flex-end;
    align-items: flex-end;
  }
  .custom-css-style
    .elementor-element.elementor-element-fb4c63d.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: flex-start;
  }
  .custom-css-style
    .elementor-element.elementor-element-fb4c63d
     .elementor-element-populated {
    margin: 0;
    padding: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c81393b
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-1783fc4 {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-1783fc4
    .elementor-heading-title {
    font-size: 45px;
    line-height: 1.1em;
  }
  .custom-css-style .elementor-element.elementor-element-36313b0 {
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9a0b539
     .elementor-widget-container {
    margin: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e767729
     .elementor-element-populated {
    padding: 60px 20px 60px 20px;
  }
  .custom-css-style .elementor-element.elementor-element-3123df1 {
    text-align: left;
  }
  .custom-css-style
    .elementor-element.elementor-element-3123df1
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-3123df1
     .elementor-widget-container {
    margin: 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-4230e5b {
    left: 10%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-4230e5b {
    right: 10%;
  }
  .custom-css-style .elementor-element.elementor-element-4230e5b {
    bottom: 24%;
  }
  .custom-css-style
    .elementor-element.elementor-element-f91ba8b
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c4836ec
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c4836ec
     .elementor-widget-container {
    margin: 10px 0 10px 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-2e681e9 {
    left: 10%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-2e681e9 {
    right: 10%;
  }
  .custom-css-style .elementor-element.elementor-element-2e681e9 {
    bottom: 24%;
  }
  .custom-css-style .elementor-element.elementor-element-3055135 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d8cf263
     .elementor-element-populated {
    margin: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-0a9e15c
    .service-grid-wrapper
    img {
    max-width: 50px !important;
  }
  .custom-css-style
    .elementor-element.elementor-element-0a9e15c
    .service-grid-wrapper
    .overflow-inner
    .header-wrap
    h2 {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-848db67 {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-cffa23a
     .elementor-element-populated {
    padding: 0 10px 0 10px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e758095
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-bce81db
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-bce81db
     .elementor-widget-container {
    margin: 10px 0 10px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-1175112
    .testimonials-card-wrapper
    .owl-carousel
    .shadow-effect
    .testimonial-name {
    font-size: 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1175112
    .testimonials-card-wrapper
    .owl-carousel
    .shadow-effect
    .testimonial-info-desc {
    font-size: 13px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ce72bb5
    .elementor-heading-title {
    font-size: 12px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ce72bb5
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-badffac {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-badffac
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-badffac
     .elementor-widget-container {
    margin: 10px 0 10px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c5cfc62
    .elementor-heading-title {
    font-size: 16px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c5cfc62
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-ebc7ea7 {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-ebc7ea7
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ebc7ea7
     .elementor-widget-container {
    margin: 10px 0 10px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c60e7b5
    .elementor-heading-title {
    font-size: 16px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c60e7b5
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-2407c2e {
    text-align: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-2407c2e
    .elementor-heading-title {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2407c2e
     .elementor-widget-container {
    margin: 10px 0 10px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-76596a1
    .elementor-heading-title {
    font-size: 16px;
  }
  .custom-css-style
    .elementor-element.elementor-element-76596a1
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-bfabffd {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f5048cc
     .elementor-element-populated {
    padding: 60px 20px 60px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a8611dc
     .elementor-element-populated {
    padding: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a9ee362
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-7cc4a16 {
    right: 2%;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-7cc4a16 {
    left: 2%;
  }
  .custom-css-style .elementor-element.elementor-element-7cc4a16 {
    top: -20%;
  }
  .custom-css-style .elementor-element.elementor-element-f16fe39 {
    font-size: 24px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c949c6a
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9381fab
     .elementor-widget-container {
    margin: 0 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c16957c
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style .elementor-element.elementor-element-c16957c {
    top: 31%;
  }
  .custom-css-style .elementor-element.elementor-element-c45cc62 {
    padding: 60px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f08a0e8.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style .elementor-element.elementor-element-ad59982 .eb-map {
    height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9f1c275
    .elementor-heading-title {
    font-size: 60px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-fb4c63d {
    width: 60%;
  }
  .custom-css-style .elementor-element.elementor-element-46bf88e {
    width: 40%;
  }
  .custom-css-style .elementor-element.elementor-element-cc1f7c9 {
    width: 95%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-3ff0870
   .elementor-container
   .elementor-row
   .elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-3ff0870:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-3ff0870
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-3ff0870 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-3ff0870
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-8700e4b.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-8700e4b.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-8700e4b
   .elementor-element-populated {
  padding: 100px 100px 100px 100px;
}
.custom-css-style .elementor-element.elementor-element-2130069 {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-2130069
  .elementor-heading-title {
  color: #fff;
  font-size: 90px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-2130069
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a02404f
  .elementor-image
  img {
  width: 240px;
  opacity: 1;
}
.custom-css-style .elementor-element.elementor-element-a02404f {
  width: 100%;
  max-width: 100%;
  top: -18%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-a02404f {
  left: 33%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-a02404f {
  right: 33%;
}
.custom-css-style .elementor-element.elementor-element-c428409 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c428409
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style
  .elementor-element.elementor-element-9a18ca7
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #000;
  color: #000;
  background-color: #fff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  border-radius: 0;
  padding: 20px 60px 20px 60px;
}
.custom-css-style
  .elementor-element.elementor-element-9a18ca7
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-9a18ca7
  .elementor-button:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9a18ca7
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-9a18ca7
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-9a18ca7
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-9a18ca7 {
  z-index: 2;
  width: 100%;
  max-width: 100%;
}
.custom-css-style
  .elementor-element.elementor-element-caecb90
  .elementor-image
  img {
  width: 250px;
  opacity: 1;
  filter: brightness(200%) contrast(0) saturate(0) blur(0) hue-rotate(0deg);
}
.custom-css-style .elementor-element.elementor-element-caecb90 {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: 61%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-caecb90 {
  left: -50%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-caecb90 {
  right: -50%;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-bd28e60.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-bd28e60.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-bd28e60:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-bd28e60
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/barista-prepare-coffee-working-order-concept-PZPGYWL.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-bd28e60
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-bd28e60
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-bd74af7
  .elementor-spacer-inner {
  height: 50px;
}
.custom-css-style
  .elementor-element.elementor-element-49c3e63
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-49c3e63
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-49c3e63:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-49c3e63
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: rgba(0, 0, 0, 0);
  background-image: url(../upload/bg-header-overlay-lg-black-2.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-49c3e63 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -40px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-49c3e63
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-70251b3
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-163a025
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-1da90f7 {
  overflow: hidden;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 0 0 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-1da90f7
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-3ce90ae.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-3ce90ae.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-3ce90ae.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-fa35d1b
  .elementor-image
  img {
  width: 120px;
}
.elementor-element.elementor-element-fa35d1b {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-fa35d1b {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-947f2aa
  .elementor-heading-title {
  color: #000;
  font-size: 36px;
  line-height: 1.2em;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-947f2aa
   .elementor-widget-container {
  margin: 20px 0 0 10px;
}
.elementor-element.elementor-element-947f2aa {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.1s !important;
}
.elementor-widget.elementor-element-947f2aa {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-947f2aa {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-1f1dd83
  .elementor-text-editor {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-1f1dd83
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-1f1dd83 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-1f1dd83 {
  opacity: 0;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-a6aafb9.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-a6aafb9.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-a6aafb9.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-dd32efd
  .elementor-image
  img {
  width: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-dd32efd
   .elementor-widget-container {
  margin: 80px 0 0 0;
}
.elementor-element.elementor-element-dd32efd {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.3s !important;
}
.elementor-widget.elementor-element-dd32efd {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5a885e3
  .elementor-heading-title {
  color: #000;
  font-size: 36px;
  line-height: 1.2em;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-5a885e3
   .elementor-widget-container {
  margin: 20px 0 0 10px;
}
.elementor-element.elementor-element-5a885e3 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.4s !important;
}
.elementor-widget.elementor-element-5a885e3 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-5a885e3 {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-0a56f1e
  .elementor-text-editor {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-0a56f1e
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-0a56f1e {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.5s !important;
}
.elementor-widget.elementor-element-0a56f1e {
  opacity: 0;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-d4ba339.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-d4ba339.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-d4ba339.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-7d750fe
  .elementor-image
  img {
  width: 130px;
}
.custom-css-style
  .elementor-element.elementor-element-7d750fe
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.elementor-element.elementor-element-7d750fe {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.5s !important;
}
.elementor-widget.elementor-element-7d750fe {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-50846bf
  .elementor-heading-title {
  color: #000;
  font-size: 36px;
  line-height: 1.2em;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-50846bf
   .elementor-widget-container {
  margin: 20px 0 0 10px;
}
.elementor-element.elementor-element-50846bf {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.6s !important;
}
.elementor-widget.elementor-element-50846bf {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-50846bf {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-c426d2f
  .elementor-text-editor {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-c426d2f
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-c426d2f {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.7s !important;
}
.elementor-widget.elementor-element-c426d2f {
  opacity: 0;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-f0be27b.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-f0be27b.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-f0be27b.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-d21ed4e
  .elementor-image
  img {
  width: 190px;
}
.custom-css-style
  .elementor-element.elementor-element-d21ed4e
   .elementor-widget-container {
  margin: 60px 0 0 0;
}
.elementor-element.elementor-element-d21ed4e {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.7s !important;
}
.elementor-widget.elementor-element-d21ed4e {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d279ebe
  .elementor-heading-title {
  color: #000;
  font-size: 36px;
  line-height: 1.2em;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-d279ebe
   .elementor-widget-container {
  margin: 20px 0 0 10px;
}
.elementor-element.elementor-element-d279ebe {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.8s !important;
}
.elementor-widget.elementor-element-d279ebe {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-d279ebe {
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-f916f2d
  .elementor-text-editor {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-f916f2d
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-f916f2d {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.9s !important;
}
.elementor-widget.elementor-element-f916f2d {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-7545a2a {
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  padding: 40px 0 40px 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-453214e.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-453214e
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-453214e {
  z-index: 3;
}
.custom-css-style .elementor-element.elementor-element-edf7e5b {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-edf7e5b
  .elementor-heading-title {
  color: #d22226;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.custom-css-style
  .elementor-element.elementor-element-edf7e5b
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.elementor-element.elementor-element-edf7e5b {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-edf7e5b {
  opacity: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c20c0cd
  .elementor-image
  img {
  width: 230px;
}
.custom-css-style .elementor-element.elementor-element-c20c0cd {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: -12%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-c20c0cd {
  left: -50%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-c20c0cd {
  right: -50%;
}
.custom-css-style
  .elementor-element.elementor-element-cdb0b5c
  .elementor-image
  img {
  width: 230px;
}
.custom-css-style .elementor-element.elementor-element-cdb0b5c {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  top: -0.459%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-cdb0b5c {
  left: 50.085%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-cdb0b5c {
  right: 50.085%;
}
.custom-css-style .elementor-element.elementor-element-f24d35f {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-f24d35f
  .elementor-heading-title {
  color: #000;
  font-size: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-f24d35f
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-f24d35f {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-f24d35f {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-e65cfd0 {
  margin-top: 0;
  margin-bottom: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-d7ef593
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-26566c2
  ul.products
  li.product
  h2.woocommerce-loop-product__title {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-26566c2
  ul.products
  li.product {
  text-align: left;
}
.custom-css-style
  .elementor-element.elementor-element-26566c2
  ul.products
  li.product
  span.price {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-26566c2
  ul.products
  li.product
  .onsale {
  background: #d22226;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-26566c2
  ul.products
  li.product
  a.button.ajax_add_to_cart {
  background: #000;
  border-color: #000;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-26566c2
  ul.products
  li.product
  a.button.ajax_add_to_cart:hover {
  background: #000;
  border-color: #000;
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-e1c3f4f
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-e1c3f4f
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-e1c3f4f:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-e1c3f4f
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-black.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-e1c3f4f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -95px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e1c3f4f
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-cb38ac3
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c1ed4fd
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5c5be60
   .elementor-container
   .elementor-row
   .elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-5c5be60:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-5c5be60
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-5c5be60 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5c5be60
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-d2ca99a.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-d2ca99a.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-d2ca99a:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-d2ca99a
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/dalgona-coffee-in-glass-cup-2WAC52D.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-d2ca99a
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-d2ca99a
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-e51d3c5
  .elementor-spacer-inner {
  height: 50px;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-d595f2f.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-d595f2f.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-d595f2f.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-d595f2f
   .elementor-element-populated {
  padding: 100px 100px 100px 100px;
}
.custom-css-style .elementor-element.elementor-element-990d5a2 {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-990d5a2
  .elementor-heading-title {
  color: #fff;
  font-size: 50px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-990d5a2
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style .elementor-element.elementor-element-5e1fcf8 {
  margin-top: 40px;
  margin-bottom: 20px;
}
.custom-css-style
  .elementor-element.elementor-element-32624fc
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e4ca454
  .elementor-counter-number-wrapper {
  color: #d22226;
  font-family: Oswald, Sans-serif;
  font-size: 65px;
  font-weight: 400;
  letter-spacing: -2px;
}
.custom-css-style
  .elementor-element.elementor-element-e4ca454
  .elementor-counter-title {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 3em;
}
.custom-css-style
  .elementor-element.elementor-element-667f7ae
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-9339781
  .elementor-counter-number-wrapper {
  color: #d22226;
  font-family: Oswald, Sans-serif;
  font-size: 65px;
  font-weight: 400;
  letter-spacing: -2px;
}
.custom-css-style
  .elementor-element.elementor-element-9339781
  .elementor-counter-title {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 3em;
}
.custom-css-style
  .elementor-element.elementor-element-bbb251f
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #000;
  color: #000;
  background-color: #fff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-bbb251f
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-bbb251f
  .elementor-button:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-bbb251f
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-bbb251f
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-bbb251f
   .elementor-widget-container {
  margin: 10px 10px 0 0;
}
.custom-css-style .elementor-element.elementor-element-bbb251f {
  z-index: 2;
  width: auto;
  max-width: auto;
  align-self: center;
}
.custom-css-style
  .elementor-element.elementor-element-c8e27e2
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #fff;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c8e27e2
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-c8e27e2
  .elementor-button:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c8e27e2
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-c8e27e2
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c8e27e2
   .elementor-widget-container {
  margin: 10px 0 0 10px;
}
.custom-css-style .elementor-element.elementor-element-c8e27e2 {
  z-index: 2;
  width: auto;
  max-width: auto;
  align-self: center;
}
.custom-css-style
  .elementor-element.elementor-element-93c1c61
   .elementor-container
   .elementor-row
   .elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-93c1c61:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-93c1c61
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-93c1c61 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-93c1c61
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-4958e7b.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-4958e7b.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-4958e7b.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: flex-start;
}
.custom-css-style
  .elementor-element.elementor-element-4958e7b
   .elementor-element-populated {
  padding: 100px 100px 100px 100px;
}
.custom-css-style .elementor-element.elementor-element-017ad40 {
  text-align: left;
  width: auto;
  max-width: auto;
}
.custom-css-style
  .elementor-element.elementor-element-017ad40
  .elementor-heading-title {
  color: #fff;
  font-size: 50px;
  line-height: 1.1em;
}
.custom-css-style
  .elementor-element.elementor-element-017ad40
   .elementor-widget-container {
  margin: 0;
}
.custom-css-style
  .elementor-element.elementor-element-bc44013
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(20px / 2);
}
.custom-css-style
  .elementor-element.elementor-element-bc44013
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(20px / 2);
}
.custom-css-style
  .elementor-element.elementor-element-bc44013
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(20px / 2);
  margin-left: calc(20px / 2);
}
.custom-css-style
  .elementor-element.elementor-element-bc44013
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-20px / 2);
  margin-left: calc(-20px / 2);
}
body.rtl
  .custom-css-style
  .elementor-element.elementor-element-bc44013
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-20px / 2);
}
body:not(.rtl)
  .custom-css-style
  .elementor-element.elementor-element-bc44013
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-20px / 2);
}
.custom-css-style
  .elementor-element.elementor-element-bc44013
  .elementor-icon-list-icon
  i {
  color: #d22226;
  font-size: 16px;
}
.custom-css-style
  .elementor-element.elementor-element-bc44013
  .elementor-icon-list-icon
  svg {
  fill: #d22226;
  width: 16px;
}
.custom-css-style
  .elementor-element.elementor-element-bc44013
  .elementor-icon-list-icon {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-bc44013
  .elementor-icon-list-text {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-bc44013
   .elementor-widget-container {
  margin: 40px 0 40px 0;
}
.custom-css-style
  .elementor-element.elementor-element-5dd25e1
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #000;
  color: #000;
  background-color: #fff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5dd25e1
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-5dd25e1
  .elementor-button:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-5dd25e1
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-5dd25e1
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-5dd25e1
   .elementor-widget-container {
  margin: 10px 10px 0 0;
}
.custom-css-style .elementor-element.elementor-element-5dd25e1 {
  z-index: 2;
  width: auto;
  max-width: auto;
  align-self: center;
}
.custom-css-style
  .elementor-element.elementor-element-682122a
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #fff;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fff;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-682122a
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-682122a
  .elementor-button:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-682122a
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-682122a
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-682122a
   .elementor-widget-container {
  margin: 10px 0 0 10px;
}
.custom-css-style .elementor-element.elementor-element-682122a {
  z-index: 2;
  width: auto;
  max-width: auto;
  align-self: center;
}
.elementor-bc-flex-widget
  .custom-css-style
  .elementor-element.elementor-element-dbcc40d.elementor-column
  .elementor-column-wrap {
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-dbcc40d.elementor-column.elementor-element[data-element_type="column"]
   .elementor-column-wrap.elementor-element-populated
   .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}
.custom-css-style
  .elementor-element.elementor-element-dbcc40d:not(.elementor-motion-effects-element-type-background)
   .elementor-column-wrap,
.custom-css-style
  .elementor-element.elementor-element-dbcc40d
   .elementor-column-wrap
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/coffee-machine-making-cup-steam-cafe-steam-concept-PX752Y9.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style
  .elementor-element.elementor-element-dbcc40d
   .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-dbcc40d
   .elementor-element-populated
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-4bfade0
  .elementor-spacer-inner {
  height: 50px;
}
.custom-css-style
  .elementor-element.elementor-element-6116451
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-6116451
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-6116451:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-6116451
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: rgba(0, 0, 0, 0);
  background-image: url(../upload/bg-header-overlay-lg-black-2.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-6116451 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-6116451
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-9d7321b
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0e62f7e
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-49e8f70 {
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  padding: 60px 0 0 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-5c82cda.elementor-column
   .elementor-column-wrap
   .elementor-widget-wrap {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-5c82cda
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style .elementor-element.elementor-element-5c82cda {
  z-index: 3;
}
.custom-css-style .elementor-element.elementor-element-9d5b651 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-9d5b651
  .elementor-heading-title {
  color: #d22226;
  font-family: arkipelagoregular, Sans-serif;
  font-size: 60px;
  text-transform: none;
}
.custom-css-style
  .elementor-element.elementor-element-9d5b651
   .elementor-widget-container {
  margin: 40px 0 0 0;
}
.elementor-element.elementor-element-9d5b651 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0.2s !important;
}
.elementor-widget.elementor-element-9d5b651 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-f72df44 {
  text-align: center;
}
.custom-css-style
  .elementor-element.elementor-element-f72df44
  .elementor-heading-title {
  color: #000;
  font-size: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-f72df44
   .elementor-widget-container {
  margin: -10px 0 0 0;
}
.elementor-element.elementor-element-f72df44 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1) !important;
  transition-delay: 0s !important;
}
.elementor-widget.elementor-element-f72df44 {
  opacity: 0;
}
.custom-css-style .elementor-element.elementor-element-b035ecf {
  overflow: hidden;
}
.custom-css-style
  .elementor-element.elementor-element-80c71a6
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e60a2da
  .testimonials-card-wrapper
  .owl-carousel
  .shadow-effect {
  background: #fff;
  border-color: #fff;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-e60a2da
  .testimonials-card-wrapper
  .owl-carousel
  .shadow-effect
  .testimonial-name {
  color: #000;
  font-size: 24px;
}
.custom-css-style
  .elementor-element.elementor-element-e60a2da
  .testimonials-card-wrapper
  .owl-carousel
  .shadow-effect
  .testimonial-info-desc {
  color: #000;
  font-size: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-e60a2da
  .testimonials-card-wrapper
  .owl-carousel
  .owl-dots
  .owl-dot
  span {
  background: rgba(0, 0, 0, 0.26);
}
.custom-css-style
  .elementor-element.elementor-element-e60a2da
  .testimonials-card-wrapper
  .owl-carousel
  .owl-dots
  .owl-dot.active
  span {
  background: #000;
}
.custom-css-style
  .elementor-element.elementor-element-6498976
   .elementor-container {
  min-height: 150px;
}
.custom-css-style
  .elementor-element.elementor-element-6498976
   .elementor-container:after {
  content: "";
  min-height: inherit;
}
.custom-css-style
  .elementor-element.elementor-element-6498976:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-6498976
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-image: url(../upload/bg-header-overlay-lg-2.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-css-style .elementor-element.elementor-element-6498976 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 3;
}
.custom-css-style
  .elementor-element.elementor-element-6498976
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-42176bf
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-0903645
  .elementor-spacer-inner {
  height: 0;
}
.custom-css-style .elementor-element.elementor-element-6955e90 {
  margin-top: -150px;
  margin-bottom: 0;
}
.custom-css-style
  .elementor-element.elementor-element-d64ef6b
   .elementor-element-populated {
  padding: 80px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-7088bd9 .eb-map {
  height: 600px;
}
.custom-css-style .elementor-element.elementor-element-771d77a {
  text-align: left;
  width: auto;
  max-width: auto;
  top: 25%;
}
.custom-css-style
  .elementor-element.elementor-element-771d77a
  .elementor-heading-title {
  color: #fff;
  font-size: 100px;
  line-height: 0.9em;
}
.custom-css-style
  .elementor-element.elementor-element-771d77a
   .elementor-widget-container {
  margin: 0;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-771d77a {
  left: 5%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-771d77a {
  right: 5%;
}
.custom-css-style
  .elementor-element.elementor-element-ca0c8f9
  .elementor-button {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  fill: #c7a17a;
  color: #c7a17a;
  background-color: rgba(2, 1, 1, 0);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c7a17a;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-ca0c8f9
  .elementor-button:focus,
.custom-css-style
  .elementor-element.elementor-element-ca0c8f9
  .elementor-button:hover {
  color: #fff;
  background-color: #c7a17a;
}
.custom-css-style
  .elementor-element.elementor-element-ca0c8f9
  .elementor-button:focus
  svg,
.custom-css-style
  .elementor-element.elementor-element-ca0c8f9
  .elementor-button:hover
  svg {
  fill: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-ca0c8f9
   .elementor-widget-container {
  margin: 10px 0 0 0;
}
.custom-css-style .elementor-element.elementor-element-ca0c8f9 {
  width: auto;
  max-width: auto;
  top: 60%;
}
body:not(.rtl) .custom-css-style .elementor-element.elementor-element-ca0c8f9 {
  left: 5%;
}
body.rtl .custom-css-style .elementor-element.elementor-element-ca0c8f9 {
  right: 5%;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-8700e4b
     .elementor-element-populated {
    padding: 60px 40px 60px 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2130069
    .elementor-heading-title {
    font-size: 60px;
    line-height: 1em;
  }
  .custom-css-style .elementor-element.elementor-element-a02404f {
    top: 113%;
  }
  .custom-css-style .elementor-element.elementor-element-c428409 {
    font-size: 16px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c428409
     .elementor-widget-container {
    margin: 20px 0 20px 0;
  }
  .custom-css-style .elementor-element.elementor-element-9a18ca7 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-caecb90
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-49c3e63
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-49c3e63
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-49c3e63:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-49c3e63
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-49c3e63 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-1da90f7 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-3ce90ae
     .elementor-element-populated {
    margin: 0 10px 0 10px;
  }
  .custom-css-style
    .elementor-element.elementor-element-fa35d1b
    .elementor-image
    img {
    width: 90px;
  }
  .custom-css-style
    .elementor-element.elementor-element-947f2aa
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a6aafb9
     .elementor-element-populated {
    margin: 0 10px 0 10px;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-dd32efd
    .elementor-image
    img {
    width: 110px;
  }
  .custom-css-style
    .elementor-element.elementor-element-dd32efd
     .elementor-widget-container {
    margin: 62px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5a885e3
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d4ba339
     .elementor-element-populated {
    margin: 0 10px 0 10px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7d750fe
    .elementor-image
    img {
    width: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-7d750fe
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-50846bf
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f0be27b
     .elementor-element-populated {
    margin: 0 10px 0 10px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d21ed4e
    .elementor-image
    img {
    width: 140px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d21ed4e
     .elementor-widget-container {
    margin: 45px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d279ebe
    .elementor-heading-title {
    font-size: 30px;
  }
  .custom-css-style .elementor-element.elementor-element-7545a2a {
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 0 40px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-edf7e5b
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c20c0cd
    .elementor-image
    img {
    width: 184px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c20c0cd
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-cdb0b5c
    .elementor-image
    img {
    width: 179px;
  }
  .custom-css-style
    .elementor-element.elementor-element-cdb0b5c
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f24d35f
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style .elementor-element.elementor-element-e65cfd0 {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e1c3f4f
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e1c3f4f
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-e1c3f4f:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-e1c3f4f
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-e1c3f4f {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d2ca99a
     .elementor-element-populated {
    padding: 200px 0 200px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d595f2f
     .elementor-element-populated {
    padding: 60px 60px 70px 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-990d5a2
    .elementor-heading-title {
    font-size: 40px;
  }
  .custom-css-style .elementor-element.elementor-element-bbb251f {
    width: auto;
    max-width: auto;
  }
  .custom-css-style .elementor-element.elementor-element-c8e27e2 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-4958e7b
     .elementor-element-populated {
    padding: 60px 60px 70px 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-017ad40
    .elementor-heading-title {
    font-size: 40px;
  }
  .custom-css-style .elementor-element.elementor-element-5dd25e1 {
    width: auto;
    max-width: auto;
  }
  .custom-css-style .elementor-element.elementor-element-682122a {
    width: auto;
    max-width: auto;
  }
  .custom-css-style
    .elementor-element.elementor-element-dbcc40d
     .elementor-element-populated {
    padding: 200px 0 200px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6116451
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6116451
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-6116451:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-6116451
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-6116451 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-49e8f70 {
    padding: 40px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9d5b651
    .elementor-heading-title {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f72df44
    .elementor-heading-title {
    font-size: 60px;
  }
  .custom-css-style .elementor-element.elementor-element-b035ecf {
    padding: 0 0 60px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6498976
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6498976
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-6498976:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-6498976
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .custom-css-style .elementor-element.elementor-element-6498976 {
    margin-top: -50px;
    margin-bottom: 0;
  }
  .custom-css-style .elementor-element.elementor-element-7088bd9 .eb-map {
    height: 600px;
  }
  .custom-css-style
    .elementor-element.elementor-element-771d77a
    .elementor-heading-title {
    font-size: 80px;
    line-height: 1em;
  }
  .custom-css-style .elementor-element.elementor-element-ca0c8f9 {
    width: auto;
    max-width: auto;
    top: 55%;
  }
}
@media (max-width: 767px) {
  .custom-css-style .elementor-element.elementor-element-3ff0870 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-8700e4b
     .elementor-element-populated {
    padding: 60px 20px 60px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2130069
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c428409
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-bd28e60
     .elementor-element-populated {
    margin: 0;
    padding: 150px 0 150px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-49c3e63
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-49c3e63
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-49c3e63:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-49c3e63
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-49c3e63 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-70251b3
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-163a025
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-163a025
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-1da90f7 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-3ce90ae
     .elementor-element-populated {
    padding: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-947f2aa
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a6aafb9
     .elementor-element-populated {
    padding: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-5a885e3
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-50846bf
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style
    .elementor-element.elementor-element-d279ebe
    .elementor-heading-title {
    font-size: 32px;
  }
  .custom-css-style .elementor-element.elementor-element-7545a2a {
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-453214e
     .elementor-element-populated {
    padding: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-edf7e5b
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-cdb0b5c
    .elementor-image
    img {
    width: 117px;
  }
  body:not(.rtl)
    .custom-css-style
    .elementor-element.elementor-element-cdb0b5c {
    left: 161px;
  }
  body.rtl .custom-css-style .elementor-element.elementor-element-cdb0b5c {
    right: 161px;
  }
  .custom-css-style .elementor-element.elementor-element-cdb0b5c {
    top: 11.672px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f24d35f
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f24d35f
     .elementor-widget-container {
    margin: 10px 0 10px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e1c3f4f
     .elementor-container {
    min-height: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e1c3f4f
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-e1c3f4f:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-e1c3f4f
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-e1c3f4f {
    margin-top: -25px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c1ed4fd
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style .elementor-element.elementor-element-5c5be60 {
    margin-top: -40px;
    margin-bottom: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d2ca99a
     .elementor-element-populated {
    padding: 150px 0 150px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-d595f2f
     .elementor-element-populated {
    padding: 50px 20px 50px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-990d5a2
    .elementor-heading-title {
    font-size: 40px;
    line-height: 1.2em;
  }
  .custom-css-style .elementor-element.elementor-element-5e1fcf8 {
    margin-top: 40px;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e4ca454
    .elementor-counter-number-wrapper {
    font-size: 55px;
  }
  .custom-css-style
    .elementor-element.elementor-element-667f7ae
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9339781
    .elementor-counter-number-wrapper {
    font-size: 55px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9339781
     .elementor-widget-container {
    margin: 20px 0 40px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-bbb251f
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-c8e27e2
     .elementor-widget-container {
    margin: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-4958e7b.elementor-column
     .elementor-column-wrap
     .elementor-widget-wrap {
    justify-content: center;
  }
  .custom-css-style
    .elementor-element.elementor-element-4958e7b
     .elementor-element-populated {
    padding: 50px 20px 50px 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-017ad40
    .elementor-heading-title {
    font-size: 40px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-5dd25e1
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-682122a
     .elementor-widget-container {
    margin: 20px 0 0 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-dbcc40d
     .elementor-element-populated {
    padding: 150px 0 150px 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6116451
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6116451
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-6116451:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-6116451
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-6116451 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-9d7321b
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0e62f7e
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0e62f7e
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-49e8f70 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-5c82cda
     .elementor-element-populated {
    margin: 0;
    padding: 0 20px 0 20px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9d5b651
    .elementor-heading-title {
    font-size: 36px;
  }
  .custom-css-style
    .elementor-element.elementor-element-9d5b651
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-f72df44
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-f72df44
     .elementor-widget-container {
    margin: 10px 0 10px 0;
  }
  .custom-css-style .elementor-element.elementor-element-b035ecf {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-80c71a6
     .elementor-element-populated {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-e60a2da
    .testimonials-card-wrapper
    .owl-carousel
    .shadow-effect
    .testimonial-info-desc {
    font-size: 13px;
  }
  .custom-css-style
    .elementor-element.elementor-element-e60a2da
     .elementor-widget-container {
    margin: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-6498976
     .elementor-container {
    min-height: 100px;
  }
  .custom-css-style
    .elementor-element.elementor-element-6498976
     .elementor-container:after {
    content: "";
    min-height: inherit;
  }
  .custom-css-style
    .elementor-element.elementor-element-6498976:not(.elementor-motion-effects-element-type-background),
  .custom-css-style
    .elementor-element.elementor-element-6498976
     .elementor-motion-effects-container
     .elementor-motion-effects-layer {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .custom-css-style .elementor-element.elementor-element-6498976 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-42176bf
     .elementor-element-populated {
    margin: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0903645
    .elementor-spacer-inner {
    height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-0903645
     .elementor-widget-container {
    margin: 0;
  }
  .custom-css-style .elementor-element.elementor-element-7088bd9 .eb-map {
    height: 400px;
  }
  .custom-css-style
    .elementor-element.elementor-element-771d77a
    .elementor-heading-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-ca0c8f9
    .elementor-button {
    font-size: 20px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .custom-css-style .elementor-element.elementor-element-3ce90ae {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-a6aafb9 {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-d4ba339 {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-f0be27b {
    width: 50%;
  }
  .custom-css-style .elementor-element.elementor-element-d2ca99a {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-d595f2f {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-4958e7b {
    width: 100%;
  }
  .custom-css-style .elementor-element.elementor-element-dbcc40d {
    width: 100%;
  }
}
.custom-css-style
  .elementor-element.elementor-element-237bb28
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-5e817a2
  .parallax-slide-background:before {
  background-color: rgba(0, 0, 0, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-5e817a2
  .parallax-slide-content-wrapper
  .parallax-slide-content-title
  h2 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-5e817a2
  .parallax-slide-content-title
  h2 {
  font-size: 90px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-5e817a2
  .parallax-slide-content-subtitle {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-5e817a2
    .parallax-slide-content-title
    h2 {
    font-size: 80px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-5e817a2
    .parallax-slide-content-title
    h2 {
    font-size: 50px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-f94ee3d:not(.elementor-motion-effects-element-type-background),
.custom-css-style
  .elementor-element.elementor-element-f94ee3d
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
.custom-css-style .elementor-element.elementor-element-f94ee3d {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-f94ee3d
   .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.custom-css-style
  .elementor-element.elementor-element-b75f88c
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-325762f
  .animated-frame-slider-wrapper.slideshow
  .slides
  .slide-title {
  color: #fff;
  font-size: 80px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-325762f
  div.animated-frame-slider-wrapper.slideshow
  .slides
  .slide-desc {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-325762f
  .animated-frame-slider-wrapper.slideshow
  .slides
  .slide-desc {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.custom-css-style
  .elementor-element.elementor-element-325762f
  .animated-frame-slider-wrapper.slideshow
  .slides
  .slide-link {
  background: rgba(255, 255, 255, 0);
  color: #fff;
  border-color: #fff;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-325762f
  .animated-frame-slider-wrapper.slideshow
  .slide-link:hover {
  background: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-325762f
  .animated-frame-slider-wrapper.slideshow
  .slides
  .slide-link:hover {
  color: #000;
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-325762f
  div.animated-frame-slider-wrapper.slideshow
  .slides
  .slide-link {
  font-family: Oswald, Sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-325762f
  .animated-frame-slider-wrapper.slideshow
  .slidenav-item {
  color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-325762f
    .animated-frame-slider-wrapper.slideshow
    .slides
    .slide-title {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-325762f
    .animated-frame-slider-wrapper.slideshow
    .slides
    .slide-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-325762f
    div.animated-frame-slider-wrapper.slideshow
    .slides
    .slide-link {
    font-size: 18px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-73191f6
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a8ddaa4
  .room-slider-wrapper
  .bg-overlay {
  background: rgba(0, 0, 0, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-a8ddaa4
  .room-slider-wrapper
  .slide-name {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-a8ddaa4
  .room-slider-wrapper
  h2.slide-name {
  font-size: 70px;
}
.custom-css-style
  .elementor-element.elementor-element-a8ddaa4
  .room-slider-wrapper
  .slide-title {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.custom-css-style
  .elementor-element.elementor-element-a8ddaa4
  .room-slider-wrapper
  .slide
  .slide-date
  .button {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-a8ddaa4
  .room-slider-wrapper
  .slide-date
  .button {
  background: rgba(255, 255, 255, 0);
}
.custom-css-style
  .elementor-element.elementor-element-a8ddaa4
  .room-slider-wrapper
  .slide-date
  a.button {
  border-color: #fff;
  border-radius: 0;
}
.custom-css-style
  .elementor-element.elementor-element-a8ddaa4
  .room-slider-wrapper
  .slide-date
  a.button:hover {
  background: #fff;
  color: #000;
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-a8ddaa4
  .room-slider-wrapper
  .btn--nav {
  color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-a8ddaa4
    .room-slider-wrapper
    h2.slide-name {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a8ddaa4
    .room-slider-wrapper
    .slide-title {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-a8ddaa4
    .room-slider-wrapper
    h2.slide-name {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a8ddaa4
    .room-slider-wrapper
    .slide-title {
    font-size: 12px;
  }
  .custom-css-style
    .elementor-element.elementor-element-a8ddaa4
    div.room-slider-wrapper
    .slide-date
    a.button {
    font-size: 18px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-806cffa
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-c370123
  .popout-slide-container
  .slider-content
  h1 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c370123
  .popout-slide-container
  .slider-inner
  h1 {
  font-size: 90px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-c370123
  .popout-slide-container
  .slider-content
  .slider-desc {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c370123
  .popout-slide-container
  .slider-inner
  .slider-desc {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.custom-css-style
  .elementor-element.elementor-element-c370123
  .popout-slide-container
  .popout-slide-link {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c370123
  .popout-slide-container
  .slider-content
  .popout-slide-link {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c370123
  .popout-slide-container
  .slider-inner
  .slider-content
  .popout-slide-link {
  font-family: Oswald, Sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-c370123
  .popout-slide-container
  .slider-content
  a.go-to-next {
  color: #fff;
  font-family: Oswald, Sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.custom-css-style
  .elementor-element.elementor-element-c370123
  .popout-slide-container
  .slider-content
  a.go-to-next:before {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-c370123
  .popout-slide-container
  .slider-content
  a.go-to-next:after {
  background-color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-c370123
    .popout-slide-container
    .slider-inner
    h1 {
    font-size: 80px;
  }
  .custom-css-style
    .elementor-element.elementor-element-c370123
    .popout-slide-container
    .slider-inner
    .slider-desc {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-c370123
    .popout-slide-container
    .slider-inner
    h1 {
    font-size: 50px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-c370123
    .popout-slide-container
    .slider-content
    a.go-to-next {
    font-size: 18px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-f09caa8
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4d27f11
  .clip-path-slide-container
  .slide-inner:before {
  background: rgba(0, 0, 0, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-4d27f11
  .clip-path-slide-container
  .slide-heading {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4d27f11
  .clip-path-slide-container
  h2.slide-heading {
  font-size: 30px;
  line-height: 1.5em;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-4d27f11
  .clip-path-slide-container
  .slide-content {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4d27f11
  .clip-path-slide-container
  .slide-content
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4d27f11
  .clip-path-slide-container
  .slide-inner
  .slide-content
  a {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4d27f11
  .clip-path-slide-container
  .slide-inner
  .slide-content
  .slide-text {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.custom-css-style
  .elementor-element.elementor-element-4d27f11
  .clip-path-slide-container
  .slider-control {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4d27f11
  .clip-path-slide-container
  .slider-control-line:after {
  background-color: #999;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-4d27f11
    .clip-path-slide-container
    h2.slide-heading {
    font-size: 28px;
    line-height: 1.1em;
  }
}
.custom-css-style .elementor-element.elementor-element-e6784b4 {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-60555ee
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
  .velo-slide-container.velo-slides {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
  .velo-slide-container
  .velo-slide-bg:after {
  background: rgba(0, 0, 0, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
  .velo-slide-container
  .velo-slide-bg {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
  .velo-slide-container
  .velo-slide
  .velo-slide-bg {
  background-color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
  .velo-slide-container
  .velo-slide-title {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
  .velo-slide-container
  h2.velo-slide-title {
  font-size: 90px;
  line-height: 1em;
  letter-spacing: 0;
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
  .velo-slide-container
  .velo-slide-header
  .velo-slide-text
  .oh {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
  .velo-slide-container
  .velo-slide-text {
  font-size: 18px;
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
  .velo-slide-container
  .btn-draw
  .btn-draw-text {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
  .velo-slide-container
  .btn-draw.btn--white:before,
.velo-slide-container .btn-draw.btn--white .btn-draw-text:after,
.velo-slide-container .btn-draw.btn--white .btn-draw-text:before,
.velo-slide-container .btn-draw.btn--white .btn-draw-text  span:after,
.velo-slide-container .btn-draw.btn--white .btn-draw-text  span:before,
.velo-slide-container .btn-draw.btn--white:after {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
  .velo-slide-container
  .btn-draw.btn--white
  .btn-draw-text {
  font-family: Oswald, Sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
  .velo-slides-nav
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-2fc6e95
   .elementor-widget-container {
  margin: 0;
  padding: 0;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-2fc6e95
    .velo-slide-container
    h2.velo-slide-title {
    font-size: 70px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-2fc6e95
    .velo-slide-container
    h2.velo-slide-title {
    font-size: 50px;
  }
  .custom-css-style
    .elementor-element.elementor-element-2fc6e95
    .velo-slide-container
    .btn-draw.btn--white
    .btn-draw-text {
    font-size: 18px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-ed9595b
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-06bd9e4
  .split-slick-slide-container.split-slideshow
  .bg-overlay {
  background: rgba(0, 0, 0, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-06bd9e4
  .split-slick-slide-container
  .slideshow-text {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-06bd9e4
  .split-slick-slide-container.split-slideshow
  .slideshow-text.slick-slider
  .slick-track
  .slick-slide {
  letter-spacing: 15px;
}
.custom-css-style
  .elementor-element.elementor-element-06bd9e4
  .split-slick-slide-container.split-slideshow
  .slideshow
  .slick-dots
  li
  button:before {
  background-color: #fff;
}
:root {
  --page-title-display: none;
}
.custom-css-style
  .elementor-element.elementor-element-06de61b
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-4943da4
  .transitions-slide-container
  .bg-overlay {
  background: rgba(0, 0, 0, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-4943da4
  .transitions-slide-container
  .swiper-image-left
  h1 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4943da4
  .transitions-slide-container
  .swiper-image
  .swiper-image-left
  h1 {
  font-size: 80px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-4943da4
  .transitions-slide-container
  p.paragraph {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4943da4
  .transitions-slide-container
  .swiper-image-right
  p.paragraph {
  font-size: 16px;
}
.custom-css-style
  .elementor-element.elementor-element-4943da4
  .transitions-slide-container
  .transitions-slide-content-link {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4943da4
  .transitions-slide-container
  a.transitions-slide-content-link {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-4943da4
  div.transitions-slide-container
  a.transitions-slide-content-link {
  font-family: Oswald, Sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.custom-css-style
  .elementor-element.elementor-element-4943da4
  .transitions-slide-container
  .swiper-pagination-bullet {
  background-color: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-4943da4
    .transitions-slide-container
    .swiper-image
    .swiper-image-left
    h1 {
    font-size: 60px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-4943da4
    .transitions-slide-container
    .swiper-image-right
    p.paragraph {
    font-size: 16px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4943da4
    div.transitions-slide-container
    a.transitions-slide-content-link {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-4943da4
    .transitions-slide-container
    .swiper-image
    .swiper-image-left
    h1 {
    font-size: 40px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-4943da4
    .transitions-slide-container
    .swiper-image-right
    p.paragraph {
    font-size: 13px;
  }
  .custom-css-style
    .elementor-element.elementor-element-4943da4
    div.transitions-slide-container
    a.transitions-slide-content-link {
    font-size: 12px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-e81e024
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1fcfb82
  .flip-slide-container
  .container
  .gallery
  li {
  width: 550px;
}
.custom-css-style
  .elementor-element.elementor-element-1fcfb82
  .flip-slide-container
  .container
  .gallery
  .back-side {
  background: #fffbf2;
}
.custom-css-style
  .elementor-element.elementor-element-1fcfb82
  .flip-slide-container
  .container
  .gallery
  .content
  h2 {
  color: #222;
}
.custom-css-style
  .elementor-element.elementor-element-1fcfb82
  .flip-slide-container
  .container
  .gallery
  .content
  .text
  h2 {
  font-size: 50px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-1fcfb82
  .flip-slide-container
  .container
  .gallery
  .content
  p {
  color: #222;
}
.custom-css-style
  .elementor-element.elementor-element-1fcfb82
  .flip-slide-container
  .flip-slide-content-link {
  color: #222;
}
.custom-css-style
  .elementor-element.elementor-element-1fcfb82
  .flip-slide-container
  div.flip-slide-content-link {
  border-color: #222;
}
.custom-css-style
  .elementor-element.elementor-element-1fcfb82
  div.flip-slide-container
  div.flip-slide-content-link {
  font-family: Oswald, Sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3px;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-1fcfb82
    .flip-slide-container
    .container
    .gallery
    li {
    width: 420px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1fcfb82
    .flip-slide-container
    .container
    .gallery
    .content
    .text
    h2 {
    font-size: 45px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-1fcfb82
    .flip-slide-container
    .container
    .gallery
    .content
    p.paragraph {
    font-size: 14px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1fcfb82
    div.flip-slide-container
    div.flip-slide-content-link {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-1fcfb82
    .flip-slide-container
    .container
    .gallery
    li {
    width: 350px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1fcfb82
    .flip-slide-container
    .container
    .gallery
    .content
    .text
    h2 {
    font-size: 40px;
    line-height: 1.2em;
  }
  .custom-css-style
    .elementor-element.elementor-element-1fcfb82
    .flip-slide-container
    .container
    .gallery
    .content
    p.paragraph {
    font-size: 13px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1fcfb82
    div.flip-slide-container
    div.flip-slide-content-link {
    font-size: 15px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-34c3395
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-436944a
  .split-carousel-slider-wrapper {
  height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-436944a
  .split-carousel-slider-wrapper.carousel
  .content-left
  h1 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-436944a
  .split-carousel-slider-wrapper.carousel
  div.content-left
  h1 {
  font-size: 70px;
  line-height: 1em;
  letter-spacing: 0;
}
.custom-css-style
  .elementor-element.elementor-element-436944a
  .split-carousel-slider-wrapper.carousel
  .content-left
  h1
  span {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-436944a
  .split-carousel-slider-wrapper.carousel
  div.content-left
  h1
  span {
  font-size: 20px;
}
.custom-css-style
  .elementor-element.elementor-element-436944a
  .split-carousel-slider-wrapper.carousel
  .content-right
  .content-main {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-436944a
  div.split-carousel-slider-wrapper.carousel
  .content-right
  .content-main {
  font-size: 16px;
}
.custom-css-style
  .elementor-element.elementor-element-436944a
  .split-carousel-slider-wrapper.carousel
  .content-right
  .content-main
  a {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-436944a
  .split-carousel-slider-wrapper.carousel
  .content-right
  .content-main
  a.split-carousel-slide-content-link {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-436944a
  div.split-carousel-slider-wrapper.carousel
  .content-right
  .content-main
  a {
  font-family: Oswald, Sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.custom-css-style
  .elementor-element.elementor-element-436944a
  .split-carousel-slider-wrapper.carousel
  .carousel-control {
  background: rgba(256, 256, 256, 0);
}
.custom-css-style
  .elementor-element.elementor-element-436944a
  .split-carousel-slider-wrapper.carousel
  .carousel-control
  a.active:before,
.split-carousel-slider-wrapper.carousel .carousel-control a:before {
  background: #222;
}
:root {
  --page-title-display: none;
}
body.elementor-page-5284:not(.elementor-motion-effects-element-type-background),
body.elementor-page-5284
   .elementor-motion-effects-container
   .elementor-motion-effects-layer {
  background-color: #000;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-436944a
    .split-carousel-slider-wrapper.carousel
    div.content-left
    h1 {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-436944a
    .split-carousel-slider-wrapper.carousel
    div.content-left
    h1
    span {
    font-size: 18px;
  }
  .custom-css-style
    .elementor-element.elementor-element-436944a
    div.split-carousel-slider-wrapper.carousel
    .content-right
    .content-main {
    font-size: 16px;
  }
  .custom-css-style
    .elementor-element.elementor-element-436944a
    div.split-carousel-slider-wrapper.carousel
    .content-right
    .content-main
    a {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-436944a
    .split-carousel-slider-wrapper.carousel
    div.content-left
    h1 {
    font-size: 45px;
  }
  .custom-css-style
    .elementor-element.elementor-element-436944a
    .split-carousel-slider-wrapper.carousel
    div.content-left
    h1
    span {
    font-size: 16px;
    line-height: 0;
  }
  .custom-css-style
    .elementor-element.elementor-element-436944a
    div.split-carousel-slider-wrapper.carousel
    .content-right
    .content-main {
    font-size: 13px;
  }
  .custom-css-style
    .elementor-element.elementor-element-436944a
    div.split-carousel-slider-wrapper.carousel
    .content-right
    .content-main
    a {
    font-size: 16px;
  }
}
.custom-css-style
  .elementor-element.elementor-element-f6b378e
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-1155d61
  .mouse-driven-vertical-carousel-wrapper {
  height: 660px;
}
.custom-css-style
  .elementor-element.elementor-element-1155d61
  .mouse-driven-vertical-carousel-wrapper
  .c-gradient-overlay {
  background: rgba(0, 0, 0, 0.2);
}
.custom-css-style
  .elementor-element.elementor-element-1155d61
  .mouse-driven-vertical-carousel-wrapper
  .u-media-wrapper
  .carousel-header
  h2 {
  color: #fff;
  font-size: 100px;
}
.custom-css-style
  .elementor-element.elementor-element-1155d61
  .mouse-driven-vertical-carousel-wrapper
  .u-media-wrapper
  .carousel-header
  .carousel__sub_header {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-1155d61
  .mouse-driven-vertical-carousel-wrapper
  .u-media-wrapper
  .c-mouse-vertical-carousel-title {
  color: #fff;
  font-family: Oswald, Sans-serif;
  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.3em;
}
.custom-css-style
  .elementor-element.elementor-element-1155d61
  .mouse-driven-vertical-carousel-wrapper
  .u-media-wrapper
  .c-mouse-vertical-carousel-eyebrow {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-1155d61
    .mouse-driven-vertical-carousel-wrapper {
    height: 500px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1155d61
    .mouse-driven-vertical-carousel-wrapper
    .u-media-wrapper
    .carousel-header
    h2 {
    font-size: 70px;
  }
  .custom-css-style
    .elementor-element.elementor-element-1155d61
    .mouse-driven-vertical-carousel-wrapper
    .u-media-wrapper
    .c-mouse-vertical-carousel-title {
    font-size: 28px;
    line-height: 1.4em;
  }
}
.custom-css-style
  .elementor-element.elementor-element-65295df
   .elementor-element-populated {
  padding: 0;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper {
  height: 700px;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  .slide
  .image-container::before {
  background: rgba(0, 0, 0, 0.3);
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  .slide-content {
  text-align: center;
  margin-top: 30px;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  div.slide-content {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  .slide-content
  .standard-wrapper {
  justify-content: center;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  .slide
  .title
  h2 {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  .slider_parallax_slides
  .slide
  .title
  h2 {
  font-size: 80px;
  line-height: 1em;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  .slide
  .caption {
  width: 65%;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  .slide
  .text {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  .slide
  a.button {
  background-color: rgba(34, 34, 34, 0);
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  .slide
  .button {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  div.slide
  a.button {
  color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  .slide
  a.button:hover {
  background-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  .slide
  .button:hover {
  border-color: #fff;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  div.slide
  a.button:hover {
  color: #000;
}
.custom-css-style
  .elementor-element.elementor-element-b15f8cb
  .slider-parallax-wrapper
  .pagination
  .item::before {
  background: #fff;
}
:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .custom-css-style
    .elementor-element.elementor-element-b15f8cb
    .slider-parallax-wrapper
    .slider_parallax_slides
    .slide
    .title
    h2 {
    font-size: 60px;
  }
  .custom-css-style
    .elementor-element.elementor-element-b15f8cb
    .slider-parallax-wrapper
    .slide
    .caption {
    width: 80%;
  }
  .custom-css-style
    .elementor-element.elementor-element-b15f8cb
    .slider-parallax-wrapper
    .slide-content {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .custom-css-style
    .elementor-element.elementor-element-b15f8cb
    .slider-parallax-wrapper
    .slider_parallax_slides
    .slide
    .title
    h2 {
    font-size: 45px;
    line-height: 1.1em;
  }
  .custom-css-style
    .elementor-element.elementor-element-b15f8cb
    .slider-parallax-wrapper
    .slide
    .caption {
    width: 100%;
  }
  .custom-css-style
    .elementor-element.elementor-element-b15f8cb
    .slider-parallax-wrapper
    .slide-content {
    margin-top: 60px;
  }
}
.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
  display: flex;
  align-content: center;
}





.aa {
  color: #fff;

  
  display: inline-block;

  position: relative;
}
.aa:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.aa:hover:after { 
  width: 100%; 
  left: 0; 
}
a.kf-btn, .kf-btn, a.kf-btn-link, .kf-btn-link, button, input[type="submit"] {
  padding: 0 32px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  color: #FFF;
  font-weight: 500;
  letter-spacing: 0.03em;
  height: 50px;
  line-height: 48px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background: #754C29;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  transition: all 0.7s cubic-bezier(0.3, 0, 0.3, 1);
  -webkit-transition: all 0.7s cubic-bezier(0.3, 0, 0.3, 1);
}
